import {React,Fragment,useEffect,useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import './sharedwithme.scss'
import { Menu, Dialog, Transition } from '@headlessui/react';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SharedBuckets from "./sharedBuckets";
import SharedCards from "./sharedCards";
import SharredCards from "./sharedCards";
import SharedFiles from "./sharedFiles";
import SharedBookmarks from "./sharedBookmarks";
function ShareWithMe() {
  const [subBucket,setSubBucket]=useState({})
  const cardId = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const bucketId = useParams();
  const [filesData, setFilesData] = useState([]);
  const navigate=useNavigate()
  console.log(cardId,"carddddddddd id")

  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem('token');
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}team/shared-withMe-items`,{
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }); // Replace this with the actual API endpoint
        setSubBucket(response?.data?.data?.bucketList);
        setFilesData(response?.data?.data?.filesList)
        setIsLoading(false) // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);


  return(
    <>
    {
        isLoading ? (
          <div className='App-loading'>
            <div className='App-logo' title='Zimzee' />
          </div>
          ) 
      :  
      (
        <>
    <div className="sharedwithme-content">Shared with me</div>
    <div>
       <SharedBuckets/>
    </div>
     <div>
     <SharredCards/>
  </div>
  <div>
    <SharedFiles filesData={filesData} />
  </div>
  <div>
    <SharedBookmarks />
  </div>
  </> 
      )}
      </>
  );
}
export default ShareWithMe;  