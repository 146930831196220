import React from "react";
import './MyProfile.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import PlanDetails from "./Plan Details/PlanDetails";

function MyProfile() {
  return(
    <div className="myprofile-section">
       <div className="myprofile-content">My Profile</div>
       <div className="profile-tab">
        <Tabs>
          <TabList>
          <Tab>Personal Information</Tab>
          {/* <Tab>My subscription</Tab>  */}
          </TabList>
          <TabPanel> 
            <PersonalDetails />
          </TabPanel>
          <TabPanel>
            <PlanDetails />
          </TabPanel>
        </Tabs> 
      </div>
    </div>
  );
}
export default MyProfile; 