import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './CardList.scss';
import magnifier from "../../assets/icons/magnifyGlass.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import EditIcon from "../../assets/icons/edit.svg";
import Notes from "../../assets/icons/Clipboard.svg";
import Star from "../../assets/icons/Star.svg";
import Tag from "../../assets/icons/tag2Icon.svg";
import Share from "../../assets/icons/Share 1.svg";
import Profile1 from '../../assets/icons/profile1.png';
import Profile2 from '../../assets/icons/profile2.png';
import Profile3 from '../../assets/icons/profile3.png';
import copyIcon from "../../assets/icons/copyIcon.svg";
import linkIcon from "../../assets/icons/link_icon.svg";
import shareIcon from "../../assets/icons/sharing_icon.svg";
import CardImage from '../../assets/images/cardDetailImage.png';
import ListView from '../../assets/icons/listView.svg';
import compactCardIcon from "../../assets/icons/compactCardIcon.svg";

function CardList(shared) {
return(

<div className="cardList dark:bg-[#051534]">

<div className="row">
   <Tabs>
      <TabList>
         <div className="search-tab flex item-center ">
            <div className="w-full">
               <label  htmlFor="search" className="dark:border-white-700 sr-only">
               Search
               </label>
               <div className="relative">
                  <input
                     id="search"
                     name="search"
                     className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                     placeholder="Search"
                     type="search"
                     />
                  <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center pr-2 ">
                     <img className="magnifier" src={magnifier} alt="magnifying-glass" />
                  </div>
               </div>
            </div>
         </div>
         <div className="tab-scroll ">
         <Tab>
            <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-24"/>
               <label for="check-24" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu menuthree-icon" src={ThreeDots} alt="menu"/>

         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-25"/>
               <label for="check-25" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-26"/>
               <label for="check-26" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-27"/>
               <label for="check-27" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p className="dark:text-[#ffffff]">Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-28"/>
               <label for="check-28" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-29"/>
               <label for="check-29" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-30"/>
               <label for="check-30" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-31"/>
               <label for="check-31" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-32"/>
               <label for="check-32" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-32"/>
               <label for="check-32" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>

         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-33"/>
               <label for="check-33" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
            <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-33"/>
               <label for="check-33" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         </div>
      </TabList> 

      <TabPanel>
         <div className="panel-content">
          <div className="flex mb-3">
          <h5>Save the internet with one click - Zimzee Bucket</h5>
          <img className="edit-cardname" src={EditIcon} alt="editicon"/>
          <div className="cardmenu-icons flex">
          <img className="edit-cardname" src={Notes} alt="editicon"/>
          <img className="edit-cardname" src={Star} alt="editicon"/>
          <img className="edit-cardname" src={Tag} alt="editicon"/>
          <img className="edit-cardname" src={Share} alt="editicon"/>
          </div>
          </div>
          <p className="last-updated">Last updated on Jan 5, 2022 @ 4:30 PM</p>
          <div className="menu-cardshare flex mb--16">
            <span className="tag-menu bg-[#eadbff]">Drone</span>
            <span className="tag-menu bg-[#f7f8df]">Colorful</span>
            <div className="shareCount col-span-2 ml-7">
                      <div className="profile-imgcontent">
                        <img src={Profile1} alt="profile1" />
                        <img src={Profile2} alt="profile2" /> 
                        <img src={Profile3} alt="profile3" /><br/>
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">{shared?.length} Share(s)</p>
                    </div>
            <div className="ml-auto sharedAction flex items-center">
                        <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl public-share">
                            <p>Publicly Shared</p>
                            <span className="bg-white  rounded-[50%] p-1 ml-2">
                                <img src={copyIcon} alt="copy-icon" />
                            </span>
                        </div> 
                        <div className="w-6 mx-4 ">
                            <img className="darkicon-bc" src={linkIcon} alt="link-icon" />
                        </div>
                        <div className="w-7 ml-4">
                            <img className="darkicon-bc" src={shareIcon} alt="shareIcon" />
                        </div>
                    </div>
          </div>
          <p className="card-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
          <img className="card-detailimg test" src={CardImage} alt="cardimage" />
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
          <div className="flex mb-3">
          <h5>Save the internet with one click - Zimzee</h5>
          <img className="edit-cardname" src={EditIcon} alt="editicon"/>
          <div className="cardmenu-icons flex">
          <img className="edit-cardname" src={Notes} alt="editicon"/>
          <img className="edit-cardname" src={Star} alt="editicon"/>
          <img className="edit-cardname" src={Tag} alt="editicon"/>
          <img className="edit-cardname" src={Share} alt="editicon"/>
          </div>
          </div>
          <p className="last-updated">Last updated on Jan 5, 2022 @ 4:30 PM</p>
          <div className="menu-cardshare flex mb--16">
            <span className="tag-menu bg-[#eadbff]">Drone</span>
            <span className="tag-menu bg-[#f7f8df]">Colorful</span>
            <div className="shareCount col-span-2 ml-7">
                      <div className="profile-imgcontent">
                        <img src={Profile1} alt="profile1" />
                        <img src={Profile2} alt="profile2" /> 
                        <img src={Profile3} alt="profile3" /><br/>
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">{shared?.length} Share(s)</p>
                    </div>
            <div className="ml-auto sharedAction flex items-center">
                        <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl public-share">
                            <p>Publicly Shared</p>
                            <span className="bg-white  rounded-[50%] p-1 ml-2">
                                <img src={copyIcon} alt="copy-icon" />
                            </span>
                        </div>
                        <div className="w-6 mx-4 ">
                            <img className="darkicon-bc" src={linkIcon} alt="link-icon" />
                        </div>
                        <div className="w-7 ml-4">
                            <img className="darkicon-bc" src={shareIcon} alt="shareIcon" />
                        </div>
                    </div>
          </div>
          <p className="card-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
          <img className="card-detailimg test" src={CardImage} alt="cardimage" />
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
          <div className="flex mb-3">
          <h5>Save the internet with one click - Zimzee</h5>
          <img className="edit-cardname" src={EditIcon} alt="editicon"/>
          <div className="cardmenu-icons flex">
          <img className="edit-cardname" src={Notes} alt="editicon"/>
          <img className="edit-cardname" src={Star} alt="editicon"/>
          <img className="edit-cardname" src={Tag} alt="editicon"/>
          <img className="edit-cardname" src={Share} alt="editicon"/>
          </div>
          </div>
          <p className="last-updated">Last updated on Jan 5, 2022 @ 4:30 PM</p>
          <div className="menu-cardshare flex mb--16">
            <span className="tag-menu bg-[#eadbff]">Drone</span>
            <span className="tag-menu bg-[#f7f8df]">Colorful</span>
            <div className="shareCount col-span-2 ml-7">
                      <div className="profile-imgcontent">
                        <img src={Profile1} alt="profile1" />
                        <img src={Profile2} alt="profile2" /> 
                        <img src={Profile3} alt="profile3" /><br/>
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">{shared?.length} Share(s)</p>
                    </div>
            <div className="ml-auto sharedAction flex items-center">
                        <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl public-share">
                            <p>Publicly Shared</p>
                            <span className="bg-white  rounded-[50%] p-1 ml-2">
                                <img src={copyIcon} alt="copy-icon" />
                            </span>
                        </div>
                        <div className="w-6 mx-4 ">
                            <img className="darkicon-bc" src={linkIcon} alt="link-icon" />
                        </div>
                        <div className="w-7 ml-4">
                            <img className="darkicon-bc" src={shareIcon} alt="shareIcon" />
                        </div>
                    </div>
          </div>
          <p className="card-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
          <img className="card-detailimg test" src={CardImage} alt="cardimage" />
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>      
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>      
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
   </Tabs>
   </div>
</div>

)
}
export default CardList;