
// import './App.css';
// import Bucket from './components/Bucket';
// import NavLeft from './shades/NavLeft';
// import TopBar from './shades/topBar';
// import { useEffect, useState } from 'react';
// import { Outlet } from "react-router-dom";

// import Card from "./shades/cards/BucketInner";


// function App() {
//   const [isOpen, setIsopen] = useState(true);
//   const ToggleBtn = () => {
//     isOpen === true ? setIsopen(false) : setIsopen(true);
//   }
//   useEffect(()=>{
//     setIsopen(isOpen);
//   },[isOpen])

//   return (
//     <div className='parentDiv '>
//       <TopBar ToggleBtn = {ToggleBtn} />
//       <div className='flex'>
//         <div className= {` bg-[#FAFAFA] pb-4 sidebar dark:bg-[#0A1D42] leftSide ${isOpen == true ? 'active' : ''}`}>
//           <NavLeft isOpen={isOpen} />
//         </div>
//         <main className={`rightSide dark:bg-[#051534] bg-[#fff] ${isOpen == true ? 'w-[auto]' : '!w-[auto]'}`}>
//           <Outlet context={[isOpen]} />
//         </main>
//       </div>
//     </div>
//   );
// }

// export default App;


import './App.css';
import Bucket from './components/Bucket';
import NavLeft from './shades/NavLeft';
import TopBar from './shades/topBar';
import { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import Switcher from "./components/Switcher";
import { useNavigate } from 'react-router-dom';
import Card from "./shades/cards/BucketInner";
import FloatingMenu from "./assets/icons/floatingchatmenu.png";
// import ScrollToTop from './components/ScrollToTop';
import SidebarMbl from './SidebarMbl';
import Intercom from './components/intercom/Intercom';
import axios from 'axios';

function App() {
  const navigate = useNavigate();
  const [isOpen, setIsopen] = useState(true);
  const ToggleBtn = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  
  useEffect(()=>{
    if(localStorage.getItem("theme")){
      if(localStorage.getItem("theme") == "undefined"){
        localStorage.setItem("theme","light")
      }
    }
const user=JSON.parse(localStorage.getItem('userData'))
console.log(user,"user on app")
if(user){
  Intercom(user)
}

  },[])
  
  useEffect(()=>{
    setIsopen(isOpen); 
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('tokenId');
    // const fetchInviteVerification = async() => {
    //   if(token){
    //     try {
    //       const response = await axios.post(`${process.env.REACT_APP_API}team/team-member-invite-verification`,
    //       {
    //         token:token
    //       }
    //       );
    //       console.log(response);
    //       localStorage.setItem("settingsToken",token)
    //       navigate('/signup')
    //     } catch (error) {
    //       console.log(error.response.data);
    //       navigate('/invitationExpired')
    //       // toastify("error", error?.response?.data?.message);
    //     }
    //   }else{
    //     if(!localStorage.getItem('userData') && !localStorage.getItem('token')){
    //       navigate('/');
    //     }
    //   }
    // }
    // fetchInviteVerification();
    if(!localStorage.getItem('userData') && !localStorage.getItem('token')){
      navigate('/');
    }
  },[isOpen])
 
  
  return (
    <div>
      <div className='parentDiv sidebarmbl dark:bg-[#051534]'>
        <TopBar ToggleBtn = {ToggleBtn} />
        <div className='flex pt-[60px] site-wholespace'>
          <div className= {` bg-[#FAFAFA] dark:bg-[#0A1D42] pb-4 sidebar leftSide ${isOpen == true ? 'active' : ''}`}>
            <NavLeft isOpen={isOpen} />
          </div>
          <main className={`rightSide bg-[#fff]  dark:bg-[#051534] ${isOpen == true ? 'w-[100%] pl-[250px]' : '!w-[100%] pl-[100px]'}`}>
            <Outlet context={[isOpen]} />
          </main>
          {/* <ScrollToTop /> */} 
        </div>
        {/* <div><img className="" src={FloatingMenu} alt="FloatingMenu"/></div> */}
      </div>
      {/* <div className='sidebarmbl-section'>
        <SidebarMbl />
      </div> */}
    </div>
  ); 
}
  
export default App;
