import React from "react";
import { useState } from "react";
import { useEffect } from "react"; 
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';

import iconExport from "../components/iconExport";
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";

        
function NavLeft({isOpen}){
  const location = useLocation();
  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', icon: iconExport.DashboardIcon, href: '/app/dashboard', current: false },
    { name: 'My Buckets', icon: iconExport.bucketIcon, href: '/app/buckets', current: true },
    { name: 'Files', icon: iconExport.navFiles, href: '/app/files', current: false },
    // { name: 'Projects', icon: iconExport.projectIcon, href: '/app/gridProject', count: 4, current: false },
    { name: 'Kindle', icon: iconExport.kindleIcon, href: '/app/kindleList', count: 3, current: false },
    { name: 'Bookmarks', icon: iconExport.bookmarksIcon, href: '/app/bookmarks', current: false },
    { name: 'Starred', icon: iconExport.starIcon, href: '/app/starred', current: false },
    { name: 'Tags', icon: iconExport.tagIcon, href: '/app/tags', count: 12, current: false },
    { name: 'Cards with Notes', icon: iconExport.notesIcon, href: '/app/cardwithnotes', current: false },
    { name: 'All Cards', icon: iconExport.allCardIcon, href: '/app/Card', current: false },
    { name: 'Shared with me', icon: iconExport.shareIcon, href: '/app/SharedWithMe', current: false },
    { name: 'What I Shared', icon: iconExport.shareIcon2, href: '/app/whatishared', current: false },
    { name: 'Recently Saved', icon: iconExport.historyIcon, href: '/app/recent-saved', current: false },
    { name: 'Recently Edited', icon: iconExport.editorIcon, href: '/app/recent-edited', current: false },
    { name: 'Zimzee AI', icon: iconExport.projectsIcon, href: '/app/aiscreen', current: false },
  ]);
  const [assetCount, setAssetCount] = useState([]);
  
  
  const pathname = window.location.pathname;
    useEffect(()=>{
      // console.log("UseEffect");
      const updatedNavItems = navigation.map((item) => {
        if (item.href === pathname) {
          console.log(item.href);
          return { ...item, current: true };
        } else {
          return { ...item, current: false };
        }
      });
  
      // Update the state with the new navItems array
      setNavigation(updatedNavItems);
      // Listen for the 'popstate' event when navigating back

      const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/stats`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setAssetCount(response?.data?.data);
        console.log(response?.data?.data, "statssss data"); // Assuming the API response is an array of bucket objects
        // assetCount?.totalBookMarks
        if(response?.data?.data){
          const updatedNavItems = navigation.map((item) => {
            if (item.name === 'My Buckets') {
              return { ...item, count: response?.data?.data?.totalBuckets, current: item.href === pathname ? true : false};
            }else if(item.name === 'Files'){
              return { ...item, count: response?.data?.data?.totalFiles, current: item.href === pathname ? true : false};
            }else if(item.name === 'All Cards'){
              return { ...item, count: response?.data?.data?.totalCards, current: item.href === pathname ? true : false};
            }else if(item.name === 'Tags'){
              return { ...item, count: response?.data?.data?.totalTags, current: item.href === pathname ? true : false};
            }else {
              return { ...item, current: item.href === pathname ? true : false };
            }
          });
      
          // Update the state with the new navItems array
          setNavigation(updatedNavItems);
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    console.log(navigation,"navigation -------");
    if (token) {
      fetchBuckets();
    }
    },[location])
  const handleNavItemClick = (name) => {
    window.scrollTo(0, 0);
    // Find the index of the clicked nav item in the navItems array
    const updatedNavItems = navigation.map((item) => {
      if (item.name === name) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });

    // Update the state with the new navItems array
    setNavigation(updatedNavItems);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

    return(
        <div className={`flex flex-grow flex-col`}>
         
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1" aria-label="Sidebar">
            {navigation.map((item) => (
              <><>

                <Link
                  key={item.name}
                  to={item?.href}
                  onClick={() => { handleNavItemClick(item.name); } }
                  className={classNames(
                    item.current ? 'relative border-l-7 font-semibold	border-[#FF6600] dark:text-[#fff] dark:bg-[#202F4F] dark:hover:bg-[#2F3E5C] bg-[#FFE8D9] text-gray-900' : 'border-l-7 border-[transparent] relative text-gray-600 hover:border-l-7 hover:border-[#FF6600] hover:bg-[#FFE8D9] hover:text-gray-900 ',
                    'group flex w-full items-center rounded-r-2xl px-2 py-2 text-sm font-medium'
                  )}
                >
                  <p>
                    <img src={item.icon}
                      className={classNames(
                        item.current ? 'text-gray-500 max-w-none ' : 'max-w-none text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 w-6 h-6 ml-1'
                      )}
                      aria-hidden="true" />
                    {/* <item.icon/> */}

                  </p>
                  <span className={`relative menuNav dark:text-[#acacac]`}>{item.name}</span>
                  {/* {item.count ? (
                    <span
                      className={classNames(
                        item.current ? `bg-white ${isOpen == false ? 'absolute right-0 top-0' : 'absolute right-0 mr-2'}` : `${isOpen == false ? 'absolute right-0 top-0 mr-3' : 'absolute right-0 mr-2'} text-white bg-[#FF6600]`,
                        'ml-3 inline-block rounded-full py-0.5 text-xs font-medium w-5 h-5 flex justify-center items-center menuCount'
                      )}
                    >
                      {item.count}
                    </span>
                  ) : null} */}
                </Link>
                {item.name === "All Cards" ? <hr /> : item.name === "What I Shared" ? <hr /> : item.name === "Recently Edited" ? <hr /> : item.name === "Saved Images" ? <hr /> : item.name === "Files" ? <hr /> : null}
              </></>
            )              
            )}
          </nav>
          <hr/>
          {/* <div className="space-progressbar dark:text-[#acacac]">
          <h6 className="dark:text-[#acacac]">Space Utilized 50%</h6>
          <ProgressBar className="progress-bar" completed={50} bgColor="#ff6600" height="8px" isLabelVisible={false} animateOnRender={true} />
          <p className="dark:text-[#acacac]">100 MB of 200 MB used</p>
          </div> */}
        </div>
      </div>
    )
}

export default NavLeft;