import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './MySubscription.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BillingInfo from "./BillingInfo/BillingInfo";
import Invoices from "../Invoices/Invoices";


function MySubscription() {
  const navigate = useNavigate();
  const [priceActive, setPriceActive] = useState("");
  const [planActive, setPlanActive] = useState("");
  const [productName, setProductName] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [paymentMethod, setPaymentMethod] =useState([]);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedLast, setSelectedLast] = useState("");
  const [activeSubcriptionrefresh, SetActiveSubscriptionrefresh] = useState(false);
  const [purchaseTeamMembers, setPurchaseTeamMembers] = useState("");

  useEffect(() => {
    const findObjectById = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };

    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const token = localStorage.getItem("token");
    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectById(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          if(productList){
            console.log(productList,"productList");
            console.log(productList.items.data[0].plan.interval,"productList");
            console.log(productList.current_period_end,"current_period_end");
            setProductName(productList.plan.product.name);
            setCurrentPeriodEnd(productList.current_period_end);
            setPriceActive(productList.items.data[0].plan.amount);
            setPlanActive(productList.items.data[0].plan.interval);
          }

          // let purchaseTeamMember = findObjectById(response.data.data.subscriptions,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          setPurchaseTeamMembers(response.data.data.totalAvailableSeats);
          // console.log(purchaseTeamMember,"purchaseTeamMember");
          // if(purchaseTeamMember){
          //   console.log(purchaseTeamMember,"teamMember");
          //   setPurchaseTeamMembers(purchaseTeamMember);
          // }

          
          // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
          // setPlanActive()
          // response.data.data.subscriptions[0].items.data        
        }

        if(response.data.data.userDetails){
          if(response.data.data.userDetails.isComplementary){
            setPlanActive('Lifetime Promo');
          }
        }
        // console.log(response.data.data.subscriptions,"response");
        if(response.data.data.paymentMethods){
          console.log(response.data.data.paymentMethods,"response.data.data.paymentMethods.data");
          setPaymentMethod(response.data.data.paymentMethods);
          const paymentDefault = response.data.data.paymentMethods.find(item => item?.isDefault);
          // console.log(paymentDefault,"paymentDefault");
          if(paymentDefault){
            setSelectedTag(paymentDefault.id);
            setSelectedBrand(paymentDefault.card.brand);
            setSelectedLast(paymentDefault.card.last4);
          }else{
            setSelectedTag(response.data.data.paymentMethods[0].id);
            setSelectedBrand(response.data.data.paymentMethods[0].card.brand);
            setSelectedLast(response.data.data.paymentMethods[0].card.last4);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchInvoices = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/invoices-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        console.log(response);
        setInvoiceList(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        console.log(response,"response");
        if(response){
          // let teamMember = findObjectById(response.data.data.productList,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          // console.log(teamMember,"teamMember");
          // if(teamMember){
          //   console.log(teamMember,"teamMember");
          //   setTeamMember(teamMember);
          // }
          // console.log(process.env.REACT_APP_MAIN_PLAN_ID,"process.env.REACT_APP_TEAM_MEMBER_PLAN_ID");
          let productList = findObjectByProductId(response?.data?.data?.productList,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          console.log(productList,"productList team member");
          setTeamMember(productList.priceList[0].id);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (token) {
      fetchActiveSubscription();
      fetchPlans();
      fetchInvoices();
    }
  }, [activeSubcriptionrefresh]);

  const handleDeattachRefresh = async() => {
    SetActiveSubscriptionrefresh(!activeSubcriptionrefresh);
  }

  return(
    <div className="mysubscription-section">
       <div className="myprofile-content">My subscription</div>
       <div className="profile-tab">
        <Tabs>
          <TabList>
          <Tab>My Subscription</Tab>
          <Tab>Invoices</Tab> 
          </TabList>
          <TabPanel>
            {
              planActive ? <BillingInfo priceActive={priceActive} planActive={planActive} paymentMethod={paymentMethod} currentPeriodEnd={currentPeriodEnd} teamMember={teamMember} purchaseTeamMembers={purchaseTeamMembers} selectedTag={selectedTag} selectedBrand={selectedBrand} selectedLast={selectedLast} productName={productName} handleDeattachRefresh={handleDeattachRefresh} /> : ""
            } 
          </TabPanel>
          <TabPanel>
            <Invoices invoiceList={invoiceList} />
          </TabPanel>
        </Tabs> 
      </div>
    </div>
  );
}
export default MySubscription; 