import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect } from "react";
import { toastify } from '../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateBucket({setPopup,parent,cardUpdate,bucketUpdate,bucketOperation,bucketNameText,changeTxt,bucketUpdateId}){
   
    const createBucket = async (name) => {
       const userData = JSON.parse(localStorage.getItem('userData'));
       const token = localStorage.getItem('token');
        try {
          const response = await axios.post('https://prdapi.zimzee.com/api/buckets/add', { name,userId:userData._id,parentId:parent },
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          if(bucketOperation == 'subbucket'){
            cardUpdate()
          }
          toastify("success", response.data.message);
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.message );
        }
      };

      const updateCreateBucket = async (id,name) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if(name == ''){
          toastify("error",  "Enter a bucket name to save this bucket" );
          return;
        }
         try {
           const response = await axios.patch('https://prdapi.zimzee.com/api/buckets/update', {bucketId:id,name:name },
           {
             headers: {
                 Authorization: `Bearer ${token}`,
               }
           }
           );
           console.log(response);
           toastify("success", response.data.message);
         } catch (error) {
           console.error(error);
           toastify("error", error.response.data.message );
         }
       };
       
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(bucketUpdateId,"bucketUpdateId");
        const name = event.target.name.value.trim(); // Get the name value from the form
        if(bucketOperation == 'Add'){
            await createBucket(name);
        }else if(bucketOperation == 'subbucket'){
            await createBucket(name);
        }else{
            console.log(bucketUpdateId,"bucketNameText");
            console.log(name,"name params");
            await updateCreateBucket(bucketUpdateId,name);
        }
         
        setPopup(false);
        bucketUpdate();
      };

    function closePopup(){
        setPopup(false);
    }
    
    return(
        <div className="bucketCreationPopuptags relative !z-[999]" onClick={closePopup}>
            <form className="addBucket relative w-[85%] md:w-[500px]" onSubmit={handleSubmit} onClick={(e)=>e.stopPropagation()}> 
                <span onClick={closePopup} className="absolute right-0 top-0 w-6 h-6 bg-[#000] m-4 flex justify-center items-center rounded-full">
                    <FontAwesomeIcon className="cursor-pointer text-white text-[14px]" icon={faClose} />
                </span>
                <h2 style={{textAlign:"left"}}>{
                  (bucketOperation == "Update")? "Edit" : (bucketOperation == "subbucket")? "Create New Sub": (bucketOperation == "Update Sub")? "Edit Sub" :"Create New"
                } Bucket</h2>
                <input className="p-3 border border-1 rounded-lg mt-4 inputspaces-content" type="text" name="name" placeholder="Enter Bucket Name" onChange={changeTxt} value={bucketNameText} />
                <div className="flex justify-end">
                  <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">{bucketOperation == 'Add' || bucketOperation == 'subbucket' ? 'Create' : 'Update'}</span>
                  </button>
                </div> 
                {/* <button className="savebutton-context" type="submit">{(bucketOperation == "subbucket")? "Add": (bucketOperation == "Update Sub")? "Update":bucketOperation}</button> */}
                {/* <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                  <span className="savebutton-context">Create</span>
                </button> */}
            </form>
        </div>
    )
}

export default CreateBucket;