import {React, Fragment, useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
// import {faPlus, faChevronDown, faCamera} from "@fortawesome/free-solid-svg-icons";
import {faFolder as fasFaFolder} from '@fortawesome/free-regular-svg-icons';
import { Menu, Dialog, Transition } from '@headlessui/react'
import SingleBucket from "../../../shades/SingleBucket";
import CreateBucket from "../../../shades/CreateBucket";
import axios from 'axios';
import moment  from "moment";
import '../../../shades/compact.scss'
import BucketIcon from '../../../assets/images/bucket.svg';
import { useOutletContext } from "react-router-dom";
import Compacticongrey from '../../../assets/icons/Compact icon - black.svg';
import NormaliconBlack from '../../../assets/icons/normalicon - black.svg';
import { toastify } from "../../Toast";
import { ToastContainer, toast } from 'react-toastify';
import CustomizePopup from '../../../shades/cards/popupfinal'
import 'react-toastify/dist/ReactToastify.css';
import DismissButton from "../../../assets/icons/orangedismiss.svg";
// import ScrollToTop from "../../ScrollToTop";

function RecentBuckets({RecentBucket,RecentCardUpdate}) {
const [bucketList,setBucketList] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [totalBuckets,setTotalBuckets] = useState(0);
const [bucketCreated,setBucketCreated] = useState(false);
// const [bucketRectangle,setRectangle] = useState(false);
const [bucketOperation,setBucketOperation] = useState('Add');
const [bucketNameText,setBucketNameText] = useState('');
const [bucketUpdateId,setBucketUpdateId] = useState(0);
const [selectedBucketName,setSelectedBucketName]=useState('')
const [selectedBucketId,setSelectedBucketId]=useState('')
const [Custompopup,setCustomPopup] = useState(false);
let [isOpens, setIsOpen] = useState(false);
const [isOpen] = useOutletContext();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
 const filterDropDown = [
    { name: 'Created Date', href: '#' },
    { name: 'Recent Activity', href: '#' },
    { name: 'Ascending Order', href: '#' },
    { name: 'Descending Order', href: '#' },
    // { name: 'Manual', href: '#' },
  ]
  useEffect(()=>{
 console.log(RecentBucket,"inner recent bucket");
  },[RecentBucket])
  let bucketFilterv = '';
  if (!localStorage.getItem("bucketFilter")) {
    localStorage.setItem('bucketFilter','Created_Date');
    bucketFilterv = 'Created_Date';
  }else{
    bucketFilterv = localStorage.getItem("bucketFilter");
  }
  console.log(bucketFilterv,"bucketFilterv");
  const [bucketFilterDefault,setBucketFilterDefault] = useState(bucketFilterv);
  let bucketViewv = 'compact';
  if (!localStorage.getItem("bucketView")){
    localStorage.setItem('bucketView','compact');
    bucketViewv = 'compact';
  }else{
    bucketViewv = localStorage.getItem("bucketView");
  }
  const [bucketView,setBucketView] = useState(bucketViewv);
  // const bucketData = [
  //   {bucketName: 'Fashion Trends 2022',bucketTag: 'Sketch Bucket',bucketIcon:faCamera, subBucket: 'true'},
  //   {bucketName: 'Andy Birthday Party Celebration',bucketTag: 'Plan',bucketIcon:faCamera, subBucket: 'false'},
  //   {bucketName: 'Cooking Receipes',bucketTag: '',bucketIcon:faCamera, subBucket: 'true'}
  // ]
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const closeCustomPopup=()=>{
    setCustomPopup(false)
  }
    const handleChildClick = (event) => {
    event.stopPropagation();
    console.log('Child clicked');
};
  const [filtersText, setFiltersText] = useState((bucketFilterv == 'Created_Date') ? filterDropDown[0].name : (
    bucketFilterv == 'Descending_Order' ?  filterDropDown[3].name : (
      bucketFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
        bucketFilterv == 'Recent_Activity' ? filterDropDown[1].name : ""
      )
    )
    ));
  // const [bucketDetails,setBucketDetails] = useState(bucketData);
  // console.log(bucketDetails);
  const [popup,setPopup] = useState(false);

  const handlePopup = () =>{
    setBucketOperation("Add");
    setBucketNameText("");
    setBucketCreated(false);
    setPopup(true);
  }
  // const handleSquare = () =>{
  //   setRectangle(false);
  //   setSquare(true);
  // }

  const handleRectangle = () =>{
    // setSquare(false);
    // setRectangle(true);
    localStorage.setItem('bucketView','compact');
    setBucketView('compact');
  }
  const handleSquare = () =>{
    // setRectangle(false);
    localStorage.setItem('bucketView','grid');
    setBucketView('grid');
  }
   const handleCustomPopup = (name,id) => {
    setSelectedBucketName(name)
    setSelectedBucketId(id)
    setCustomPopup(true);
  }
  const handleFilterText = (text) => {
    console.log(text,"handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues = (text == 'Created Date') ? "Created_Date" : (
      text == 'Descending Order' ?  "Descending_Order" : (
        text == 'Ascending Order' ? "Ascending_Order" : (
          text == 'Recent Activity' ? "Recent_Activity" : ""
        )
      )
      );
    localStorage.setItem('bucketFilter',filterValues);
    setBucketFilterDefault(filterValues);  
    const token = localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API}buckets/list`, {
        limit:100,
        page:1,
        filterValue:filterValues
      },{
        headers: {
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        setIsLoading(false);
        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        console.log(bucketList);
      })
    .catch(function (error) {
      console.log(error);
    });
  }


  function bucketUpdate(){
    RecentCardUpdate()
     setBucketCreated(!bucketCreated);
  }

  function changeTxt(event){
    setBucketNameText(event.target.value);                
  }

  const handleDeleteClick = (id) => {
    setIsOpen(true);
    console.log(id);
    setBucketUpdateId(id);
  }


  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}buckets/delete/${id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
      );
     console.log(response,"response");
    //  toastify("sucess", response.message );
      toastify("success", response.data.message);
      axios.post(`${process.env.REACT_APP_API}buckets/list`, {
        limit:100,
        page:1,
        filterValue:bucketFilterDefault
      },{
        headers: {
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        setIsLoading(false);
        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        console.log(bucketList);
      })
    .catch(function (error) {
      console.log(error);
    });
      // setIsLoading(false);
    //  setBucketNameText(response.data.data.name); 
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message );
    }
    setIsOpen(false);
  }

  const handleClick = async (id) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}buckets/single/${id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
      );
     console.log(response.data.data.name,"response");
     setBucketNameText(response.data.data.name); 
    } catch (error) {
      console.error(error);
    }
    console.log('Button clicked!');
    console.log(id);
    setPopup(true);
    setBucketOperation("Update");
    setBucketUpdateId(id);

  };
  !bucketView ?
   console.log("not exist view")
   :
   console.log(bucketView,"bucketView test")
  ;

    return(
      <>

        
        <div className={bucketView == 'compact' ? 'regular' : 'CompactView-wholesection' }>
          <div className="bucketDashboard">
           {Custompopup &&
              <div className="filesbucketuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeCustomPopup}>
                <div className="subfilesbucketupload-section" onClick={handleChildClick}>
                  <div onClick={closeCustomPopup}><img className="dismissSubfiles-content" src={DismissButton} alt="dismissButton" /></div>
                  <CustomizePopup selectedBucketName={selectedBucketName} selectedBucketId={selectedBucketId} closeCustomPopup={closeCustomPopup} bucketUpdate={bucketUpdate} />
                </div>
              </div>
            }
          {
            RecentBucket ? 
            <div className="mybucketswhole-content">
              <div className={`BucketListHolder-content`}>
              {/* <div className="fixed inset-0 flex items-center justify-center">
                <button
                  type="button"
                  onClick={openModal}
                  className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  Open dialog
                </button>
            </div> */}

      <Transition appear show={isOpens} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100" 
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete?
                    </p>
                  </div>

                  <div className="mt-4">
                    <button style={{background:'#FF6600',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => handleButtonDelete(bucketUpdateId)}
                    >
                      Yes
                    </button>
                    <button style={{background:'#000',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
                {RecentBucket && Array.isArray(RecentBucket) && RecentBucket?.map((bucket)=>(
                
                
                 
                  <SingleBucket bucketId={bucket?._id} bucketView={bucketView} handleCustomPopup={handleCustomPopup} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket?.name} bucketCard={bucket?.totalCards} subBucket={bucket?.subBuckets} pined={bucket.pinned} shared={bucket?.shared} bucketDesign={bucket?.bucketDesignId} defaults={bucket?.default ? bucket?.default : ""}/>
             
              
                  ))}
              </div>
            </div>
            : null
          }
          {popup === true ? <CreateBucket bucketUpdate={bucketUpdate} changeTxt={changeTxt} bucketOperation={bucketOperation} bucketNameText={bucketNameText} bucketUpdateId={bucketUpdateId} parent= {null} setPopup = {setPopup} /> : null}
      </div>
      <ToastContainer />
      </div>
        
      {/* <ScrollToTop /> */}

      </>
      
    )
}

export default RecentBuckets; 