import React, { useState } from 'react';
import parse from 'html-react-parser';

const Paragraph = ({ text, maxLength }) => {
    // var res = 0;
    console.log(text, 'sdasdasdasdsadsa');
    console.log(isHTML(text));
    if(isHTML(text) == true){
        const tempElement = document.createElement('div');
        tempElement.innerHTML = text;
        var res = Array.from(tempElement.querySelector('*').children, ({textContent}) => textContent.trim()).filter(Boolean).join(';');
        console.log(tempElement.querySelector('*').children,"trim text")
        console.log(res.length);
    }else{
        var res = text;
    }
    // console.log(res.length);
    
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  function isHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const divElements = doc.getElementsByTagName('div');
    console.log(divElements);
    if(divElements.length){
        return Array.from(divElements).every((element) => {
            return element instanceof HTMLDivElement;
        });
    }
    return false;
    // Check if the HTML string contains properly formed <div> elements
    
  }



  // function extractPlainText(htmlContent) {
  //   const div = document.createElement("div");
  //   div.innerHTML = htmlContent;
  //   const plainTextWithBR = div.textContent || div.innerText || "";
  //   return plainTextWithBR.replace(/\n/g, "<br/>");
  // }
  
  // const htmlContent = text;
  // const plainText = extractPlainText(htmlContent);
  // console.log(plainText);

  return (
    <>
    <div className='detail-page-see-data'>
    <div className={`detail-text-wrapper ${showFullText || res.length < maxLength ? 'active' : ''}`}>
      {parse(text)}
    </div>
    {
      res.length > maxLength ? (
        <div className="seemore-text d-block">
          <button className={`see-more`} onClick={toggleText}>&nbsp;<strong>...See more</strong></button>
        </div>
      ) : ""
    }
   
      {/* {showFullText ? (
        parse(text)
      ) : (
        res.length > maxLength ? (
            <div className="cardseemore">{res.slice(0, maxLength)} ...
            <button className={`see-more`} onClick={toggleText}>&nbsp;<strong>See more</strong></button>
            </div>
          )  : parse(text)
          
      )} */}
      </div>
    </>
  );
};

export default Paragraph;