import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./PlanCheckout.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import magnifier from "../../../assets/icons/magnifyGlass.svg";
import Visacards from "../../../assets/icons/Visa Cards.svg";
import Bank from "../../../assets/icons/bank-grey.svg";
import Cardtab from "../../../assets/icons/card-grey.svg";
import Bankorange from "../../../assets/icons/bank-orange.svg";
import Cardorange from "../../../assets/icons/card-orange.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import RenewPlan from "../Plans/PurchaseSummary.js";
import Visacardsdark from "../../../assets/icons/Visa card-dark mode.svg";
import Bankcardsdark from "../../../assets/icons/bank-dark mode (1).svg";
import SetupForm from './SetupForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51NirjwECd7IPo9wQqnEOpaKs1NayfLxeQXJ0cG4Gd8sw8eo9LrbQoVZid8XhRIqHabPo12d2OyXUWgRe8BkQTH3Q00gwcBBE64');

function PlanCheckout() {
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const clientSecret = urlSearchParams.get('clientSecret');
  // console.log(clientSecret);
  const planPrice = localStorage.getItem('planPrice');

  useEffect(() => {
    if(!localStorage.getItem('subscriptionplanId')){
      navigate('/app/pricingPlans');
    }

    // if(urlSearchParams.get('setup_intent') && urlSearchParams.get('setup_intent_client_secret') && urlSearchParams.get('setup_intent_client_secret') && urlSearchParams.get('redirect_status')){
        
    // }

  }, []);
  const appearance = {
    theme: 'stripe',
  
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };
  const options = {
    mode: 'subscription',
    amount: Number(localStorage.getItem('planPrice')),
    currency: 'usd',
    paymentMethodTypes: ['card', 'us_bank_account',],
    // paymentMethodTypes: ['card', 'us_bank_account',],
    // clientSecret: "seti_1OIwUlECd7IPo9wQcP88mqJy_secret_P7Aq6prLZYrEcbwc6TAW20beO8F1n5j",
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
    // layout: {
    //   type: 'accordion',
    //   defaultCollapsed: false,
    //   radios: true,
    //   spacedAccordionItems: false
    // }
  };

  return (
    <div className="pricing-checkout">
      <div className="pagination-checkout">
        <p>
          <span><a href="/app/pricingPlans">Pricing & Plans</a></span> / <a href="/app/planCheckout">Payment</a>
        </p>
      </div>
      <div className="plan-billing">
        <Elements stripe={stripePromise} options={options}>
            <SetupForm planPrice={planPrice}/>
        </Elements>
      </div>
    </div>
  );
}
export default PlanCheckout;
