import { React, useEffect, useState } from "react";
import axios from "axios";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SetupForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [priceListY, setPriceListY] = useState([]);
    const [priceListM, setPriceListM] = useState([]);
    const [subscriptionType, setSubscriptionType] = useState(localStorage.getItem('subscriptionType'));
    const [planPrice, setPlanPrice] = useState(props.planPrice);
    const [toggle, setToggle] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [couponError, setCouponError] = useState(null);
    const [couponData, setCouponData] = useState(null);
    const [totalPrice, setTotalPrice] = useState(props.planPrice);
    const [planName, setPlanName] = useState("Essential");
    const [isChecked, setChecked] = useState(false);
    const [calculatedDiscountPrice, setDiscountPrice] = useState();

    useEffect(() => {
        const token = localStorage.getItem("token");
    
        const findObjectById = (array, id) => {
          return array.find(obj => obj.id === id);
        };
    
        const findObjectBytype = (array, subscriptionType) => {
          return array.find(obj => obj.subscriptionType === subscriptionType);
        };
    
        const fetchPlans = async () => {
          setIsLoading(true);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API}plans/list`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the request header
                },
              }
            ); // Replace this with the actual API endpoint
            // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
            console.log(localStorage.getItem('subscriptionType'),"subscriptionType");
            if(response){
              let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
              let pricelistY = findObjectBytype(productList.priceList,'year');
              let pricelistM = findObjectBytype(productList.priceList,'month');
              setPlanName(productList.name);
              setPriceListY(pricelistY);
              setPriceListM(pricelistM);
            }
            setIsLoading(false);
            
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        if (token) {
          fetchPlans();
        }
      }, []);

      const handlePlanSelect = (e,planId,priceId,type,planPrice) => {
        e.stopPropagation();
        console.log(type,planId,priceId,planPrice,"plan details");
        localStorage.setItem('subscriptionType',type);
        localStorage.setItem('subscriptionplanId',planId);
        localStorage.setItem('subscriptionpriceId',priceId);
        localStorage.setItem('planPrice',planPrice / 100);
        setCouponData(null);
        setPromoCode('');
        setSubscriptionType(type);
        setToggle(false);
        setPlanPrice(planPrice / 100);
        setTotalPrice(planPrice / 100);
      }
    
      const handleError = (error) => {
        setLoading(false);
        toastify("error", error.message);
        // setErrorMessage(error.message);
      }

      const addPromoCode = (e) => {
        e.stopPropagation();
        setToggle(!toggle)
      }

      const applyPromoCode = (e) => {
        e.stopPropagation();
        const token = localStorage.getItem("token");
        const fetchCouponData = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API}subscriptions/coupon/${promoCode}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the request header
                },
              }
            );
    
    
            /// we'll update the 
    
            if (response?.data?.data?.coupon?.valid && response?.data?.statusCode === 200) {
              setCouponError(null)
              setCouponData(response?.data?.data?.coupon)
              // here we'ill update the subscription to add promo their
              try {
                // calculate the price
                const calculatedPrice = Number(((planPrice * response?.data?.data?.coupon?.percent_off) / 100).toFixed(2))
                setDiscountPrice(calculatedPrice);
                setTotalPrice(planPrice - calculatedPrice)
              } catch (error) {
                console.log(error, "logging error while updating subscriptoio")
              }
    
            }
          } catch (error) {
            console.error("Error fetching data:", error?.response?.data?.message?.error?.message);
            toastify("error", error?.response?.data?.message?.error?.message );
            // setCouponError(error?.response?.data?.message?.error?.message)
            // setCouponError(err)
          }
        };
        if (token) {
          fetchCouponData();
        }
      } 

      const handleCheckboxChange = () => {
        setChecked(!isChecked);
      };

      const handleSubmit = async (event) => {
        // event.preventDefault();
        // console.log(promoCode,"promoCode parent");
        // return;
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        // setIsLoading(true);
        
        const token = localStorage.getItem("token");
    
        try {
          // if(event.target.name.value == ''){
          //     toastify("error", "Name is required" );
          //     return;
          // }
          // if(event.target.email.value == ''){
          //   toastify("error", "Email is required" );
          //   return;
          // }
          
          // console.log(response);
          if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }
    
          setLoading(true);
    
          // Trigger form validation and wallet collection
          const { error: submitError } = await elements.submit();
          if (submitError) {
            handleError(submitError);
            return;
          }
          console.log(subscriptionType,planName,"subscriptionType planName");
          let subscriptionObj = { priceId:localStorage.getItem("subscriptionpriceId"),planId:localStorage.getItem("subscriptionplanId"), planName: planName}
          if(promoCode){
            subscriptionObj = { priceId:localStorage.getItem("subscriptionpriceId"),planId:localStorage.getItem("subscriptionplanId"), planName: planName, couponId: promoCode}
          }
          const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/create`, subscriptionObj,
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
    
          if(response){
            let clientSecret = response.data.data.clientSecret;
            
              // const response = await axios.patch(
              //   `${process.env.REACT_APP_API}customer/update-customer`,
              //   { name: event.target.name.value, email: event.target.email.value, phone: event.target.phone.value },
              //   {
              //     headers: {
              //       Authorization: `Bearer ${token}`,
              //     },
              //   }
              // );
              // console.log(response);
              if(clientSecret){
                let paymentDefault = '';
                if (isChecked) {
                  // Checkbox is checked, handle the form submission accordingly
                  console.log('Checkbox is checked');
                  paymentDefault = '?paymentDefault='+event.target.payment.value;
                  console.log(event.target.payment.value, "name target");
                  // Add your form submission logic here
                }
    
                  const { error } = await stripe.confirmPayment({
                      //`Elements` instance that was used to create the Payment Element
                      elements,
                      clientSecret: clientSecret,
                      confirmParams: {
                        return_url: window.location.origin+'/app/successScreen'+paymentDefault,
                      }
                  });
    
                  
    
                  if (error) {
                      console.log(error)
                      // This point is only reached if there's an immediate error when
                      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                      // handleError(error);
                      if (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error") {
                        // setErrorMessage(error.message);
                        toastify("error", error.message);
                      } else {
                        setErrorMessage("An unexpected error occured.");
                        toastify("error", "An unexpected error occured.");
                      }
                      setLoading(false);
                  } else {
                      localStorage.removeItem('subscriptionpriceId');
                      localStorage.removeItem('subscriptionplanId');
                      localStorage.removeItem('planPrice');
                      localStorage.removeItem('subscriptionType');
                      // Your customer is redirected to your `return_url`. For some payment
                      // methods like iDEAL, your customer is redirected to an intermediate
                      // site first to authorize the payment, then redirected to the `return_url`.
                  }
              }
              // toastify("success", response.data.data.clientSecret);
            
          }
          
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.message);
        }
    
      };

    return (
    <>
        {isLoading ? (
            <div className="App-loading">
                <div className="App-logo" title="Zimzee" />
            </div>
            ) : (
            <div className="payment-section"> 
                <form onSubmit={handleSubmit}>
                    <div className="flex">
                        <div className="paymentsec-content">
                        <div className="plandetail-content">
                          <a href="/app/userPricing">Pricing & Plans</a><span className="plan-content"> / <a href="/app/paymentScreen">Payment</a></span>
                        </div>
                        <div className="flex tagradio-content">
                            <div className="priceline-section">
                            <input type="radio" name="selectedtag" className="radiobutton-content" value="monthly" checked={subscriptionType == 'monthly' ? true : false} onChange={(e) =>handlePlanSelect(e,`${process.env.REACT_APP_MAIN_PLAN_ID}`,priceListM.id,'monthly',priceListM.amount)}/>
                            {" "}
                            <label className="tagsdesc-content" for="html">Monthly</label>
                            </div>
                            <div>
                            <input type="radio" name="selectedtag" className="radiobutton-content" value="yearly" checked={subscriptionType !== 'monthly' ? true : false} onChange={(e) =>handlePlanSelect(e,`${process.env.REACT_APP_MAIN_PLAN_ID}`,priceListY.id,'yearly',priceListY.amount)} />
                            {" "}
                            <label className="tagsdesc-content" for="html">Yearly<br/></label>
                            <div style={{paddingLeft: "30px"}}>
                                <div className="yearlydet-content">Billed annually <span className="savetext-content">(Save ~$189)</span></div>
                                <div className="yearlydet-content">$20 each additional user</div>
                            </div>
                            </div>
                        </div>
                        <div className="flex renewmonth-content">
                            <div className="renewheading-content">Monthly plan, monthly billing</div>
                            <div className="renewamount-content">${planPrice}</div>
                        </div>
                        <div className="paymentsub-content">includes 1 seat</div>
                        {/* <div className="flex renewmonth-content">
                            <div className="renewheading-content">Additional members seats</div>
                            <div className="renewamount-content">$40</div>
                        </div>
                        <div className="flex teammember-section">
                            <button className="decrementbutton-content">-</button>
                            <input type="text" placeholder="2" className="focusteamcount-content" value="2" />
                            <button className="incrementbutton-content">+</button>
                        </div>
                        <div className="seat-content">$20 per seat</div>
                        <hr /> */}
                        <div className="flex renewmonth-content subtotal-section">
                            <div className="renewheading-content">Subtotal</div>
                            <div className="renewamount-content">${planPrice}</div>
                        </div>
                        {/* <div className="promotion-content">Add promotion code</div> */}
                        {
                          !couponData ?
                            (
                              <div className=" py-[16px]">
                                <button className="promotion-code" type="button" onClick={(e) => addPromoCode(e)}>
                                  Add promotion code
                                </button>
                                {toggle && (
                                  <div className="flex items-center">
                                    <div className="w-[59%]  py-[10px] pr-[10px]">
                                      <input
                                        className="card-inputfield  px-[14px] py-[10px]"
                                        type="text"
                                        placeholder="Enter code here"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                      />
                                    </div>
                                    <div className="w-[13%]">
                                      <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={(e) => applyPromoCode(e)}>
                                        <span className="saveupdatebutton-context apply">Apply</span>
                                      </button>

                                    </div>
                                    {

                                      couponError ? <div>{couponError}</div> : ""
                                    }
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="flex renewmonth-content subtotal-section">
                                <div className="renewheading-content">Discount</div>
                                <div className="renewamount-content">{calculatedDiscountPrice}</div>
                              </div>
                              
                            )
                        }
                        <div className="flex tax-section">
                            <div className="renewheading-content">Tax</div>
                            <div className="renewamount-content">0</div>
                        </div>
                        <hr/>
                        <div className="flex renewmonth-content">
                            <div className="renewheading-content">Total due today</div>
                            <div className="total-content">${totalPrice}</div>
                        </div>
                        </div> 
                        <div className="paymentsect-content">
                        <div className="paycard-content">Pay with card</div>
                        <PaymentElement />
                        {/* <input className="paymentcard-inputfield" type="text" name="name" placeholder="Card number"/>
                        <div className="flex">
                            <input className="paymentsec-inputfield" type="text" name="name" placeholder="Expiration date"/>
                            <input className="paymentcvc-inputfield" type="text" name="name" placeholder="CVC"/>
                        </div>
                        <input className="paymentcard-inputfield paymentsecond-section" type="text" name="name" placeholder="Country"/> */}
                        <div className="default-content">
                            <input type="checkbox" id="payment" name="payment" value="default" onChange={handleCheckboxChange}/>
                            <label for="payment"> Use as default payment method</label><br></br>
                        </div>
                        {/* <div className="paycard-content">Billing Information</div>
                        <input className="paymentcard-inputfield" type="text" name="name" placeholder="Name*"/>
                        <input className="paymentcard-inputfield" type="text" name="email" placeholder="Email*"/>
                        <input className="paymentcard-inputfield" type="text" name="phone" placeholder="Phone"/> */}
                        <button className="buttonpadding-content submitpay-content text-[16px] rounded-full bg-[#FF6600] text-white" type="submit" disabled={!stripe || loading}>
                            <span className="saveupdatebutton-context">{!stripe || loading ? 'Loading....' : 'Submit Purchase'}</span>
                        </button>
                          {/* {errorMessage && <div>{errorMessage}</div>} */}
                        </div>
                    </div>
                </form>
                <ToastContainer/>
            </div>
        )}
    </>
    );
}