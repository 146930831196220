import React from "react";
import logo from "../../assets/icons/logo.png";
import axios from 'axios';
import { useState,useEffect } from "react";
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Intercom from "../intercom/Intercom";
function CreatePassword(){
	const [password, setPassword] = useState("");
    const [Fname,setFname]=useState("")
	const [Lname,setLname]=useState("")
    const user = {
        firstName: null,
        email: null,
        createdAt: Math.floor(Date.now() / 1000),
      };
    useEffect(()=>{
        console.log(user,"user on login")
            Intercom(user)
          },[user])
	const handleSubmit = (e) => {
        // /setLoading(true);
		e.preventDefault();
		
		const pathslug = window.location.pathname
		.split("/")
		.filter(Boolean)
		.at(-1);
	  	console.log(pathslug);

		const data = {
			"token": pathslug,
			password: e.target.password.value,
			firstName:Fname,
			lastName:Lname
		}
        if(e.target.password.value == e.target.confirmPassword.value){
            axios
            .post(`${process.env.REACT_APP_API}users/password-reset`, data)
            .then((response) => {
                console.log(response);
                localStorage.removeItem('userData');
                localStorage.removeItem('token');
                localStorage.removeItem('settingsToken')
                localStorage.removeItem('cardFilter');
                localStorage.removeItem('bucketFilter');
                localStorage.removeItem('bucketView');
                localStorage.removeItem('sharecardFilter');
                localStorage.removeItem('subscriptionpriceId');
                localStorage.removeItem('subscriptionType');
                localStorage.removeItem('renewPlan');
                localStorage.removeItem('renewPrice');
                localStorage.removeItem('renewPriceId');
                localStorage.removeItem('planPrice');
                localStorage.removeItem('subscriptionplanId');
                toastify("success", "Password Updated Successfully" );
                setTimeout(async() => {
                    // router.push(`/login`)
                    window.location.href = `/`;
                }, 1000);
            }).catch( (err) => {
                console.log(err.response.data.message);
                toastify("error", err.response.data.message );
            });
        }else{
            toastify("error", "Confirm Password not match" );
        }
      };
    return(
        <>
        <div className="mainDiv bg-[#F3F4F6]">
        {/* <!-- Container --> */}
		<div className="loginHolder min-h-[100vh] flex items-center">
            <div className="formHolder md:w-1/2 lg:w-2/5 mx-auto my-5 p-8 rounded-lg shadow bg-white">
                <div className="logo">
                    <img src={logo} />
                </div>
				<div className="my-4">
					<h3 className="pt-4 mb-2 text-2xl font-bold">Create Your Password</h3>
					<p className="mb-4 text-sm text-gray-700">
						Enter details for your Zimzee account.
					</p>
				</div>
                <form onSubmit={handleSubmit} className="loginForm flex flex-col">
                {/* style={{borderColor:"#D9D9D9 !important", borderWidth: '1px'}} */}
				<input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="text" id="FirstName" placeholder="First Name" value={Fname} onChange={(e)=>setFname(e.target.value)} required/>
				<input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="text" id="LastName" placeholder="Last Name" value={Lname} onChange={(e)=>setLname(e.target.value)} required/>
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="password" id="password" placeholder="New Password" />
                    <input className="bg-white border-2 border-[#D9D9D9] my-3 p-3 rounded-md" type="password" id="confirmPassword" placeholder="Confirm Password" />
                    <button type="submit" className="button bg-[#FF6600] text-white py-2 rounded-[5px] mt-4">Create Password</button>
                </form>
            </div>
        </div>
        <ToastContainer />
        </div>
        </>
    )
}

export default CreatePassword;