import { React, Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./PlanTeamMembers.scss";
import { HashLink as Link } from "react-router-hash-link";
import EditPlan from "../../../../assets/icons/editplan.svg";
import DismissButton from "../../../../assets/icons/dismissButton.svg";
import { toastify } from '../../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PlanTeamMembers() {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [countPurchase, setPurchaseCount] = useState(0);
  const [teamMember, setTeamMember] = useState(0);
  const [teamMemberPurchase, setTeamMemberPurchase] = useState(0);
  const [subscriptionUpdateId, setSubscriptionUpdateId] = useState("");
  const [subscriptionTUpdateId, setSubscriptionTUpdateId] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const payment = urlSearchParams.get('payment');
  const selectedBrand = urlSearchParams.get('selectedBrand');
  const selectedLast = urlSearchParams.get('selectedLast');
  const [teamPrice, setTeamPrice] = useState(0);
  const [invitepopup, setInvitePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem("token");

    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };
    
    const findObjectById = (array, id) => {
      return array.find(obj => obj.id === id);
    };
    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          if(productList){
            console.log(productList,"productList");
            setSubscriptionUpdateId(productList.id);
          }

          let teamMembers = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          if(teamMembers){
            console.log(teamMembers,"teamMember");
            console.log(teamMembers.quantity,"teamMember quantity");
            setPurchaseCount(teamMembers.quantity);
            // setCount(teamMembers.quantity);
            let team = Number(teamMembers.quantity * 20);
            // setTeamMember(team);
            setTeamMemberPurchase(team);
            setSubscriptionTUpdateId(teamMembers.id);
            // setTeamMember(teamMember);
          }
          // console.log(productList.items.data[0].plan.interval,"productList");
          // setPlanActive(productList.items.data[0].plan.interval);
          // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
          // setPlanActive()
          // response.data.data.subscriptions[0].items.data        
        }
        // console.log(response.data.data.subscriptions,"response");
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          console.log(process.env.REACT_APP_MAIN_PLAN_ID,"process.env.REACT_APP_TEAM_MEMBER_PLAN_ID");
          let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          console.log(productList,"productList team member");
          setTeamPrice(productList.priceList[0].id);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchPlans();
      fetchActiveSubscription();
    }
  }, []);

  const handleIncrement = () => {
    let countI = count + 1;
    // let price = 20;
    // console.log(price,Number(renewPrice),Number(countI * 20));
    let team = Number(countI * 20);
    setCount(count + 1);
    setTeamMember(team);
    // setRenewPrice(price);
  };

  const handleDecrement = () => {
    if (count + countPurchase > 0) {
      let countI = count - 1;
      // let price = 20;
      // console.log(price,Number(renewPrice),Number(countI * 20));
      let team = Number(countI * 20);
      setCount(count - 1);
      setTeamMember(team);
      // setRenewPrice(price);
    }
  };

  const handleInvitePopup = () => {
    setInvitePopup(true);
  };
  const closeInvitePopup = () => {
    setInvitePopup(false);
  };


  const handleConfirmUpgrade = async() => {
    
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try{
      if(teamPrice && count){
        let planObj = { teamMemberDetails: {priceId: teamPrice, quantity: count + countPurchase, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`},paymentMethod:payment  }
        if(subscriptionTUpdateId){
          planObj = { teamMemberDetails: {priceId: teamPrice, subscriptionId:subscriptionTUpdateId, quantity: count + countPurchase, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`},paymentMethod:payment  }
        }
        const response = await axios.patch(
          `${process.env.REACT_APP_API}subscriptions/update`,
          planObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response,"response");
        setIsLoading(false);
        toastify("success", response.data.message);
        setTimeout(function(){ 
          navigate('/app/myProfile');
         }, 2000);
      }else{
        setIsLoading(false);
        toastify("error", "Price Id not exist" );
      }
       

    } catch (error) {
      console.error("Error fetching data:", error);
      toastify("error", error.response.data.data.message );
    }

  }


  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="planteammembers-section">
      <div className="plandetail-content">
        <a href="/app/myProfile">Plan details</a><span className="plan-content"> / <a href={window.location.href}>Confirm</a></span>
      </div>
      <div className="confirmrenew-content">Confirm your updates</div>
      {/* <div className="flex renewmonth-content">
        <div className="renewheading-content">
          Monthly plan, monthly billing
        </div>
        <div className="renewamount-content">$49</div>
      </div>
      <div className="billing-content">
        $49 / member / month * 1 member <br />
        billed monthly
      </div> */}
      {
        countPurchase > 0 ?
          <>
          <div className="flex totalteam-section">
          <div className="totalplan-content">Purchased team members</div>
          <div className="renewamount-content">{countPurchase}</div>
        </div>
        <div className="flex totalteam-section">
          <div className="totalplan-content">Total paid team members</div>
          <div className="renewamount-content">${teamMemberPurchase}</div>
        </div>
        </> : ""
      }
      
      
      <div className="flex"> 
        <div className="totalmembers-section">
          <div className="teamplan-content">Additional team members</div>
          <div className="teamslot-content">Add/Remove team members.</div>
          <div className="additional-content">$20 each additional user</div>
        </div>
        <div className="flex teammember-section">
          <button
            className="decrementbutton-content"
            onClick={handleDecrement}
          >
            -
          </button>
          <input
            type="text"
            placeholder="2"
            className="focusteamcount-content"
            value={count + countPurchase}
          />
          <button className="incrementbutton-content" onClick={handleIncrement}>+</button>
        </div>
      </div>
      <hr />
      <div className="flex totalteam-section">
        <div className="totalplan-content">Total team members({count > 0 ? count : '0'})</div>
        <div className="renewamount-content">${teamMember}</div>
      </div>
      <div className="flex tax-section">
        <div className="totalplan-content">Tax if applicable</div>
        <div className="renewamount-content">-</div>
      </div>
      {invitepopup && (
        <div
          className="filesuploadpopup relative !z-[999]"
          style={{ height: "100%" }}
          onClick={closeInvitePopup}
        >
          <div className="subinviteupload-section">
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Remove Team Member</div>
              <div
                className="dismissSubfiles-content"
                onClick={closeInvitePopup}
              >
                <img src={DismissButton} alt="dismissButton" />
              </div>
            </div>
            <hr />
            <div className="teammemberplan-content">
              You must remove the respective member from your team in order to
              deactivate the subscription for that individual.
            </div>
            <div className="flex justify-end lastfile-section">
              <div className="cancelteam-content">Cancel</div>
              <button className="buttonteam-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end">
                <span className="savebutton-context">Remove team member</span>
              </button>
            </div>
          </div>
        </div>
      )}
      <hr />
      <div className="flex today-section">
        <div className="totalplan-content">Total for today</div>
        <div className="renewamount-content">${(teamMember > 0 ? teamMember : '0')}</div>
      </div>
      <div className="flex monthdue-content">
        <div className="duemonth-content">
          Due on Oct 31 '23 then every month
        </div>
        <div className="flex">
          <Link to="/app/visaPlan?team=1">
            <div className="visano-content">{selectedBrand} •••• {selectedLast}</div>
          </Link>
          <img src={EditPlan} style={{ marginLeft: "8px" }} alt="EditPlan" />
        </div>
      </div>
      <div className="termsagree-content">
        By clicking agree and subscribe you are agreeing to their{" "}
        <span className="condition-content">
          terms and <br />
          conditions
        </span>
      </div>
      <div className="flex buttonplan-content">
        <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={handleConfirmUpgrade}>
          <span className="saveupdatebutton-context">Confirm</span>
        </button>
        <div className="cancelplancard-content"><a href="/app/myProfile">Cancel</a></div>
      </div>
      <ToastContainer />
    </div>
    )}
    </>
  );
}
export default PlanTeamMembers;
