import { React, Fragment, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import parse from "html-react-parser";
import moment from "moment";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import Union from "../../assets/icons/Union-19.svg";
import SubStar from "../../assets/icons/subStar.svg";
import FilesDetailIcon from "../../assets/icons/filesdetailicon.svg";
import SubShare from "../../assets/icons/subShare.svg";
// import moment from "moment";
import linkIcon from "../../assets/icons/linksicon.svg";
import FileDownloadIcon from "../../assets/icons/fileDownload.svg";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import axios from "axios";
import Avatar from "react-avatar";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import FileDownload from "../../assets/images/FilesPreview.svg";
import PDF from "../../assets/images/pdf.svg";
import Tags from "./includes/Tags";
import { faClose } from "@fortawesome/free-solid-svg-icons";
function SingleFile({
  setIsLoading,
  title,
  Url,
  content,
  note,
  tags,
  updated,
  sharedLength,
  cardsRefresh,
  created,
  cardId,
  starred,
  cardType,
  handleCheckboxChange,
  cardUpdate,
  buckets,
  postDetail,
  handleSharePop,
  SharedToken,
  PermissionState,
  sharedUsers,
  sharedBy,
}) {
  const [isActive, setIsActive] = useState(starred);
  const [isActiveVideo, setIsActiveVideo] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  // const [SelectedCard,setSelectedCard]=useState([])
  let [isOpenss, setIsOpens] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");
  const [selectedBucketId, setselectedBucketId] = useState("");
  const [searchBucket, setSearchBucket] = useState("");
  const [bucketId, setBucketId] = useState("");
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [downloadImg, setdownloadImg] = useState(false);
  const [EditFile, setEditFile] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();
  const allowedImageTypes = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "bmp",
    "webp",
    "tiff",
    "ico",
    "apng",
    "Image",
    "svg+xml",
    "x-icon",
    "zip",
    // Add more image types here if needed
  ];
  const handleCheckboxChanges = (event) => {
    handleCheckboxChange(event);
  };
  const handleBucketOpen = () => {
    setIsBucketOpen(!isBucketOpen);
  };

  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });
  const updateCard = async (cardId, star) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        { cardId: cardId, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardsRefresh();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };
  const handleDownloadFile = (type) => {
    console.log(type, "File Type");
    // return
    const token = localStorage.getItem("token");
    if (Url) {
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API}cards/download-file`,
          { fileUrl: Url },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // You should read the response data as an array buffer
          const base64Data = response?.data?.data?.fileBase64;
          if (base64Data) {
            setIsLoading(false);
          }
          const byteCharacters = atob(base64Data);

          // Convert the data to an array of bytes
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type });

          // Create an Object URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `image.${type}`; // Set the desired filename and file extension
          a.style.display = "none";
          document.body.appendChild(a);

          // Simulate a click on the anchor to trigger the download
          a.click();

          // Clean up by revoking the Object URL
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };
  const handleStarClick = async (cardId) => {
    setIsActive(!isActive);
    await updateCard(cardId, !isActive);
  };
  const handleBucketInputChange = (event) => {
    setSearchBucket(event.target.value);
  };
  const handleBucketClear = () => {
    setSearchBucket("");
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setSelectedBucket(newBucket);
    setSearchBucket(subbucket.name);
    setIsBucketOpen(false);
    // setHoveredOption(hoveredOption);
    // handleTagsChange([...tags, newTag]);
    // handleBlur()
  };
  const handleCardShare = () => {
    console.log("card share click");
    handleSharePop(cardId);
  };

  const handleCardLink = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://prdapi.zimzee.com/api/cards/public-url",
        {
          itemId: cardId,
          isPublic: true,
          itemType: "Card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        // Create a temporary input element to copy the link to clipboard
        const tempInput = document.createElement("input");
        tempInput.value = response?.data?.data;

        // Append the input element to the DOM
        document.body.appendChild(tempInput);

        // Select the input element's text and copy it to clipboard
        tempInput.select();
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can show a message to indicate that the link has been copied
        console.log(response.data.message);
        toastify("success", response.data.message);
      }
    } catch (error) {
      console.error(error?.response?.data?.message, "error msg");
      toastify("error", error?.response?.data?.message);
    }
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const createMarkup = (htmlString) => {
    const replacedHtml = htmlString
      .replace(/<\/p>/g, "<br>")
      .replace(/<p>/g, "");
    return { __html: replacedHtml };
  };
  const bucketNav = [
    { icon: faPencil, name: "Edit Name", href: "#" },
    { icon: faNoteSticky, name: "Move To", href: "javascript:void(0)" },
    { icon: faNoteSticky, name: "Copy To", href: "javascript:void(0)" },
    { icon: faTrash, name: "Delete", href: "javascript:void(0)" },
    // { icon: faFolder, name: "Download", href: "#" },
  ];
  const handleAction = (item) => {
    if (item.name === "Delete") {
      setIsOpens(true);
      setOptionSelect("");
    }
    if (item.name === "Edit Name") {
      setEditFile(title);
      setOpenEdit(true);
    }
    if (item.name === "Move To") {
      setOpenMove(true);
      setOptionSelect("MoveTo");
    }
    if (item.name === "Copy To") {
      setOpenCopy(true);
      setOptionSelect("CopyTo");
    }
  };
  const handleFileInput = (e) => {
    setEditFile(e.target.value);
  };
  const closeMoveModal = () => {
    setIsBucketOpen(false);
    setOpenMove(false);
    setselectedBucketId("");
    setSearchBucket("");
  };
  const closeEditModal = () => {
    setOpenEdit(false);
  };
  const closeCopyModal = () => {
    setOpenCopy(false);
    setselectedBucketId("");
    setSearchBucket("");
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function closeCardModal() {
    setIsOpens(false);
  }
  const handleDeleteCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://prdapi.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: Id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardsRefresh();
      closeCardModal();
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };
  const handleUpdateCardMenu = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        {
          cardId: Id,
          title: EditFile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        closeEditModal();
        cardsRefresh();
      }
      // cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleUpdateCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        {
          optionType: optionSelect,
          destinationBucketID: selectedBucket,
          cardId: Id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        // cardUpdate();
        setOpenCopy(false);
        setOpenMove(false);
        cardsRefresh();
      }

      setSelectedBucket("");
      // cardUpdate();
    } catch (error) {
      console.error(error);
      setSelectedBucket("");
      toastify("error", error.response.data.message);
    }
  };

  return (
    <div className="cardHolder-cards rounded-[14px] overflow-hidden mb-3 break-inside">
      <div
        className={`flex justify-between w-full pt-3 pb-1 ${
          SharedToken ? "pointer-events-none" : ""
        }`}
      >
        <div className="inputCheck">
          <input
            type="checkbox"
            value={cardId}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      <div className="cardHeader flex items-center pb-5">
        <div className="Cardtitle flex">
          <img className="mr-2" src={FilesDetailIcon} alt="pdf" />
          <div className="tooltip">
            <span className="tooltip-text fileshrename">
              <p className="designfile-textcontent">{title}</p>
            </span>
            <h3 className="design-textcontent">{title}</h3>
          </div>
        </div>
        <div
          className={`iconsRight flex iconsalign-content ${
            SharedToken ? "pointer-events-none" : ""
          }`}
        >
          <Menu as="div" className="relative ">
            <Menu.Button className="relative inline-flex items-center text-sm font-semibold text-gray-900 ">
              {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
              {/* <img src={ThreeDots} alt="threeDots" /> */}
              <FontAwesomeIcon
                className="text-[#707070] text-[22px] "
                icon={faEllipsisVertical}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute text-left right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {bucketNav.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={`javascript:void(0);`}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block py-2 px-4 text-sm text-gray-700"
                        )}
                        onClick={() =>
                          handleAction(item, content?.imageUrl[0]?.url)
                        }
                      >
                        <span className="pr-2">
                          <FontAwesomeIcon
                            className="text-[#797979]"
                            icon={item.icon}
                          />
                        </span>
                        <span className="text-black">{item.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <Transition appear show={isOpenss} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-[9999] test"
              onClose={closeCardModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Delete
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete?
                        </p>
                      </div>

                      <div className="mt-4">
                        <button
                          style={{
                            background: "#FF6600",
                            width: "80px",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "50px",
                          }}
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => handleDeleteCard(cardId)}
                        >
                          Yes
                        </button>
                        <button
                          style={{
                            background: "#000",
                            width: "80px",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "50px",
                          }}
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeCardModal}
                        >
                          No
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <Transition appear show={openEdit} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-[9999]"
              onClose={closeEditModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Edit Name
                      </Dialog.Title>
                      <div
                        style={{ display: "block" }}
                        className="custom-list-dropdown"
                      >
                        <div className="position-relative">
                          <input
                            type="text"
                            placeholder="Edit File Name"
                            value={EditFile}
                            onChange={handleFileInput}
                          />
                        </div>
                        <div className="flex justify-end">
                          <div
                            className="cancelfilecard-content"
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenEdit(false)}
                          >
                            Cancel
                          </div>
                          <button
                            style={{
                              filter:
                                "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                            }}
                            className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                            onClick={() => handleUpdateCardMenu(cardId)}
                          >
                            <span className="savebutton-context">Update</span>
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <Transition appear show={openMove} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeMoveModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Move To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="flex fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon test">
                              {searchBucket ? (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faClose}
                                  onClick={handleBucketClear}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faSearch}
                                  onClick={handleBucketOpen}
                                />
                              )}
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                      {option.subBuckets &&
                                      option.subBuckets.length > 0 ? (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            position: "absolute",
                                            right: "17px",
                                            top: "14px",
                                          }}
                                        />
                                      ) : null}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeMoveModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCard(cardId)}
                            >
                              <span className="savebutton-context">Move</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openCopy} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeCopyModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "20px",
                                  color: "#707070",
                                  paddingTop: "8px",
                                }}
                                icon={faSearch}
                                onClick={handleBucketOpen}
                              />
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeCopyModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCard(cardId)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
        </div>
      </div>
      <a
        href={
          SharedToken && PermissionState
            ? `/app/card-detail/${cardId}?card=file&shared=true&permission=${PermissionState}`
            : `/app/card-detail/${cardId}?card=file`
        }
      >
        <div className="cardContent">
          {content.imageUrl.map((image, index) => (
            <div key={index}>
              {content.imageUrl.map((image, index) => (
                <div key={index}>
                  {allowedImageTypes.includes(image.urlType) ? (
                    <img
                      className="fileimagespace-content"
                      src={image.url}
                      alt={`projectImg${index}`}
                    />
                  ) : (
                    <img
                      className="fileimagespace-content"
                      src={FileDownload}
                      alt={`projectImg${index}`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
          {/* <img className="w-full imagefile-content" src={CardImage2} alt="projectImg1" /> */}
        </div>
      </a>
      <a
        href={
          SharedToken && PermissionState
            ? `/app/card-detail/${cardId}?card=file&shared=true&permission=${PermissionState}`
            : `/app/card-detail/${cardId}?card=file`
        }
      >
        <div className="">
          <div className="lastUpdate">
            <p className="updatedtext-content py-1">
              Uploaded on {moment(created).format("MMM D, YYYY @ hh:mm A")}
            </p>
          </div>
          <div className="flex items-center flex-wrap">
            {/* <div
              className="cardTags cardTags-content flex flex-wrap py-1"
              style={{ width: "80%", flex: "0 0 226px", maxWidth: "226px" }}
              id="tags-container"
            >
              {tags?.length > 0 ? (
                <>
                  {tags.map((item) => (
                    <span
                      key={item.name}
                      className="tagsnamefile-textcontent tagsnamefile-truncated py-1 px-2 mr-[6px] my-1 rounded-2xl"
                    >
                      {item.name}
                    </span>
                  ))}
                  {tags?.length > 3 && (
                    <span className="tagsnamefile-textcontent tagsnamefile-truncated py-1 px-2 mr-[6px] my-1 rounded-2xl">
                      +{tags?.length - 3} {tags?.length - 3 === 1}
                    </span>
                  )}
                </>
              ) : (
                <span className="tagsnamefile-textcontent py-1 px-2 mr-[6px] my-1 rounded-2xl">
                  No Tags Assigned
                </span>
              )}
            </div> */}
            {/* <Tags */}
            <Tags
              listTags={tags}
              isShare={sharedUsers?.length > 0 ? false : true}
            />
            {sharedUsers?.length > 0 && (
              <div className="profile-displaycontent">
                <div className="profile-imgcontent">
                  {SharedToken && sharedUsers ? (
                    <div className="tooltip">
                      <span className="tooltip-text">
                        <p>Share By : &nbsp;{sharedBy}</p>
                        <p>
                          {" "}
                          Share with :&nbsp;
                          {sharedUsers?.map((user, index) => (
                            <span
                              key={index}
                              style={{ display: "inline-flex" }}
                            >
                              {user?.fullName}{" "}
                              {index < sharedUsers?.length - 1 ? "," : ""}
                            </span>
                          ))}{" "}
                        </p>
                      </span>
                      {sharedUsers?.slice(0, 3)?.map((user) => (
                        <>
                          {user?.imageUrl ? (
                            <img src={user?.imageUrl} alt="profile1" />
                          ) : (
                            <Avatar
                              name={`${user.fullName}`}
                              size="25"
                              round={true}
                              className="profile-avatar"
                            />
                          )}
                        </>
                      ))}
                    </div>
                  ) : (
                    sharedUsers?.slice(0, 3)?.map((user) => (
                      <div className="tooltip">
                        <span className="tooltip-text">
                          {/* <p>Share By &nbsp;{sharedBy}</p> */}
                          <p>
                            {" "}
                            Share with : &nbsp;
                            {sharedUsers?.map((user, index) => (
                              <span
                                key={index}
                                style={{ display: "inline-flex" }}
                              >
                                {user?.fullName}{" "}
                                {index < sharedUsers?.length - 1 ? "," : ""}
                              </span>
                            ))}{" "}
                          </p>
                        </span>
                        <>
                          {user?.imageUrl ? (
                            <img src={user?.imageUrl} alt="profile1" />
                          ) : (
                            <Avatar
                              name={`${user.fullName}`}
                              size="25"
                              round={true}
                              className="profile-avatar"
                            />
                          )}
                        </>
                      </div>
                    ))
                  )}

                  {sharedUsers?.length > 3 && (
                    <div className="plus-share">+</div>
                  )}
                </div>
                <p className="share-content dark:text-[#d3d3d3]">
                  {sharedUsers?.length > 0
                    ? sharedUsers?.length + " Share(s)"
                    : null}
                </p>
              </div>
            )}
          </div>
        </div>
        {/* <div className="flex">
              <div>
                <div className="fashioncards-content createalign-content">Fashion Trends 2022 Sketch</div>
                <div className="uploadedcards-content pb-2">Uploaded on {new Date(createdAt).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</div>
              </div>
              <div className="profile-displaycontent">
                <div className="profile-imgcontent">
                  <img src={Profile1} alt="profile1" />
                  <img src={Profile2} alt="profile2" />
                  <img src={Profile3} alt="profile3" /><br />
                </div>
                <p className="sharesright-content">0+ Shares</p>
              </div>
            </div> */}
      </a>
      <div
        className={`flex cardsicon-content ${
          SharedToken ? "pointer-events-none" : ""
        }`}
      >
        <a href={"javascript:void(0)"}>
          {" "}
          {note && /\S/.test(stripHtmlTags(note)) ? (
            <div className="tooltip">
              <span className="tooltip-text">
                <p dangerouslySetInnerHTML={createMarkup(note)} />
                {/* <p>{note ? parse(note) : ""}</p> */}
              </span>
              <img
                className="tooltip-icon iconsfiles-imgcontent"
                src={clipboardNotes}
                style={{ width: "20px", marginTop: "2px" }}
                alt="clipbaord"
              />
            </div>
          ) : (
            <img
              className="iconsfiles-imgcontent"
              src={ClipBoard}
              alt="card-note-image/>"
              style={{ marginTop: "2px" }}
            />
          )}
        </a>
        <button type="button" onClick={() => handleStarClick(cardId)}>
          {" "}
          {isActive ? (
            <img
              src={Union}
              className="iconsfiles-imgcontent"
              alt="subStar"
              style={{ width: "20px" }}
            />
          ) : (
            <img
              src={SubStar}
              className="iconsfiles-imgcontent"
              alt="subStar"
            />
          )}
        </button>
        {/* <img className="subtags mr-8" src={SubTag} alt="subTag" /> */}
        <a href={`javascript:void(0);`}>
          {" "}
          <img
            src={SubShare}
            className="iconsfiles-imgcontent"
            alt="subShare"
            onClick={handleCardShare}
            style={{ paddingTop: "2px" }}
          />{" "}
        </a>
        {/* <img
          className="linkcards-icon iconsfiles-imgcontent"
          src={linkIcon}
          alt="link-icon"
          style={{ width: "18px" }}
        /> */}
        <img
          src={FileDownloadIcon}
          style={{ width: "24px" }}
          alt="fileDownloadIcon"
          onClick={() => handleDownloadFile(title.split(".").reverse()[0])}
        />
      </div>
    </div>
  );
}

export default SingleFile;
