import { React } from "react"; 
import "./aiscreen.scss";
import AIimage1 from "../../assets/images/AIproject.svg";
import AIimage2 from "../../assets/images/AIcards.svg";
import AIimage3 from "../../assets/images/AIcreate.svg";
import AIimagelogo from "../../assets/icons/airename.svg";

function AIScreen() {
  return (
    <div className="aiscreen">
      <div className="aiscreendesktop-section">
        <div class="flex">
        <div className="w-1/3 pr-[50px] align-middle m-[auto]">
          <div className="ai-icon">
            <h2>AI</h2>
            <img className="ml-[15px]" src={AIimagelogo} alt="aiimage" />
          </div>

          <p className="text-center pb-[15px]">
            Zimzee AI is about to step into the spotlight and we are buzzing
            with anticipation!
          </p>
          <h5>Stay tuned for the updates!</h5>
        </div>
        <div className="w-2/3 pl-[50px]">
          <h6 className="pb-[51px]">
            Unleash your creativity with Zimzee AI, whether you're starting from
            scratch or feeling inspired - We've got your back!
          </h6>
          <div class="flex align-middle items-center mb-[50px]">
            <div className="aiImg-section">
              <img src={AIimage1} alt="aiimage" />
            </div>
            <div className="aidesc-content" style={{marginLeft: "40px"}}>
              <div className="text-left mb-[10px] creatingproj-content">Create a new Project</div>
              <ul>
                <li>
                  Finish your projects faster and more efficient than ever
                  before.
                </li>
                <li>
                  With our modeled templates and pathways, inspired by the best
                  marketers and copywriters in the business, you can now
                  effortlessly craft ads, webpage copy, ad copy, images,
                  summaries, CTAs, VSL scripts, and even write a book!
                </li>
                <li>
                  Simply choose a template or pathway, fill in the blanks, and
                  let Zimzee AI do the rest.
                </li>
              </ul>
            </div>
          </div>
          <div class="flex align-middle items-center mb-[40px]">
            <div className="aidesc-content">
              <div className="text-left mb-[10px] creatingproj-content">
                Choose cards your are inspired by
              </div>
              <ul>
                <li>
                  Zimzee AI will generate personalized unique versions inspired by your favorite examples.
                </li>
                <li>
                  Select your favorite inspiration and let Zimzee AI take care of the rest.Share your creations with your team members, designers, ad creators and copywriters to enhance further or use them as-is for that instant impact.
                </li>
                <li>
                  Your vision, effortlessly brought to life.
                </li>
              </ul>
            </div>
            <div className="aiImg-section">
              <img style={{marginLeft: "40px"}} src={AIimage2} alt="aiimage" />
            </div>
          </div>{" "}
          <div class="flex align-middle items-center mb-[10px] ">
            <div className="aiImg-section">
              <img className="align-middle" src={AIimage3} alt="aiimage" />
            </div>
            <div className="aidesc-content" style={{marginLeft: "40px"}}>
              <div className="text-left creatingproj-content mb-[10px]">
                It's not just about creation; it's about collaboration
              </div>
              <ul>
                <li>
                  Capture, share, and permanently store your inspiration in one place.
                </li>
                <li>
                  Effortlessly revisit past gems, with our automatic e-mail reminders and stay on top of trends, never running out of fresh ideas.
                </li>
                <li>
                  Zimzee AI is here to make your creative journey irresistibly seamless – because your success deserves nothing less.
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="aiscreenmbl-section">
        <div className="align-middle m-[auto]">
          <div className="ai-icon">
            <h2>AI</h2>
            <img className="ml-[15px]" src={AIimagelogo} alt="aiimage" />
          </div> 
          <p className="pb-[15px]">
            Zimzee AI is about to step into the spotlight and we are buzzing
            with anticipation!
          </p>
          <h5>Stay tuned for the updates!</h5>
        </div>
        <div>
          <h6 className="pb-[51px]">
            Unleash your creativity with Zimzee AI, whether you're starting from
            scratch or feeling inspired - We've got your back!
          </h6>
          <div class="mb-[50px]">
            <div><img className="aiImg-section" src={AIimage1} alt="aiimage" /></div>
            <div className="aidesc-content">
              <div className="text-center mb-[10px] creatingproj-content">Create a new Project</div>
              <ul>
                <li>
                  Finish your projects faster and more efficient than ever
                  before.
                </li>
                <li>
                  With our modeled templates and pathways, inspired by the best
                  marketers and copywriters in the business, you can now
                  effortlessly craft ads, webpage copy, ad copy, images,
                  summaries, CTAs, VSL scripts, and even write a book!
                </li>
                <li>
                  Simply choose a template or pathway, fill in the blanks, and
                  let Zimzee AI do the rest.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div><img className="aiImg-section" src={AIimage2} alt="aiimage" /></div>
            <div className="aidesc-content">
              <div className="mb-[10px] creatingproj-content">
                Choose cards your are inspired by
              </div>
              <ul>
                <li>
                  Zimzee AI will generate personalized unique versions inspired by your favorite examples.
                </li>
                <li>
                  Select your favorite inspiration and let Zimzee AI take care of the rest.Share your creations with your team members, designers, ad creators and copywriters to enhance further or use them as-is for that instant impact.
                </li>
                <li>
                  Your vision, effortlessly brought to life.
                </li>
              </ul>
            </div>
          </div>{" "}
          <div style={{paddingBottom: "30px"}}>
            <div className="aiImg-section">
              <img className="align-middle" src={AIimage3} alt="aiimage" />
            </div>
            <div className="aidesc-content">
              <div className="text-left creatingproj-content mb-[10px]">
                It's not just about creation; it's about collaboration
              </div>
              <ul>
                <li>
                  Capture, share, and permanently store your inspiration in one place.
                </li>
                <li>
                  Effortlessly revisit past gems, with our automatic e-mail reminders and stay on top of trends, never running out of fresh ideas.
                </li>
                <li>
                  Zimzee AI is here to make your creative journey irresistibly seamless – because your success deserves nothing less.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 
export default AIScreen;
