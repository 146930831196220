import React from "react";
import './Invoices.scss'
import InvoicePDF from "../../../assets/images/invoicePdf.svg";
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

function Invoices(props) {
  const navigate = useNavigate();
  console.log(props.invoiceList,"invoiceList");
  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }

  const handleInvoicePdf = (e,invoice_pdf) => {
      e.stopPropagation();
      window.location.href = invoice_pdf;
  }

  const handleDetailInvoice = (invoiceId) => {
      navigate(`/app/invoiceSummary/${invoiceId}`);
  }

  return(
    <div className="invoices-section">
      <div className="invoicesdesktop-section">
        <div className="flex">
          <div className="invoicenameamnt-content">Amount</div>
          <div className="invoiceamount-content">Invoice no</div>
          <div className="invoiceamntcustomer-content">Customer</div>
          <div className="invoiceamntplan-content">Plan</div>
          <div className="invoicepay-content">Payment method</div>
          <div className="invoiceamount-content">Payment date</div>
          <div className="invoicepdf-detail"></div>
        </div> 
      </div>
      <hr/>
      <div className="invoicesdesktop-section">
        {props?.invoiceList?.map((item) => (
            <>
            <div className="flex" style={{cursor:"pointer"}}>
              <div className="invoicename-content">${item.total/100}</div>
              <div className="invoicedetail-content">{item.number}</div>
              <div className="invoicedetailemail-content">{item.customer_email}</div>
              <div className="invoicedetailmon-content">{item.product.name} - Monthly</div>
              <div className="invoicedetailpay-content">{item.paymentMethod.card.brand} •••• •••• •••• {item.paymentMethod.card.last4}</div>
              <div className="invoicedetail-content">{periodEnd(item.created)}</div>
              <div className="invoicepdf-detail"><img src={InvoicePDF} alt="invoicePDF" onClick={(e) => handleInvoicePdf(e,item.invoice_pdf)} /></div>
            </div> 
            <hr />
            </>
        ))}
      </div>
      
      <div className="invoicesmbl-section">
        {props?.invoiceList?.map((item) => (
            <>
            <div className="invoicembl-bgcontent" style={{cursor:"pointer"}}>
              <div className="flex">
                <div className="invoicedetail-content">{item.number}</div>
                <div className="invoicepdf-detail"><img src={InvoicePDF} alt="invoicePDF" onClick={(e) => handleInvoicePdf(e,item.invoice_pdf)} /></div>
              </div>
              <div className="flex">
                <div className="invoicename-content">${item.total/100}</div><div className="invoicedetailmon-content">{item.product.name} - Monthly</div>
              </div>
              <div className="invoicedetailemail-content">{item.customer_email}</div>
              <div className="invoicedetailpay-content">{item.paymentMethod.card.brand} •••• •••• •••• {item.paymentMethod.card.last4}</div>
              <div className="invoicedetail-content">{periodEnd(item.created)}</div>
            </div> 
            <hr />
            </>
        ))}
      </div>
    </div>
  );
}
export default Invoices; 