import { React, useEffect, useState } from "react";
import "./PurchaseSummary.scss";
// import { React, useEffect, useState } from "react";
import axios from "axios";

import { HashLink as Link } from "react-router-hash-link";
import EditPlan from "../../../assets/icons/editplan.svg";

function PurchaseSummary(props) {
  const [toggle, setToggle] = useState(false);
  // const [promoCode, setPromoCode] = useState('');
  const [couponError, setCouponError] = useState(null);
  const [couponData, setCouponData] = useState(null)
  const [totalPrice, setTotalPrice] = useState(props.planPrice / 100)
  
  useEffect(() => {
    setTotalPrice(props.planPrice / 100);
  }, [props.planPrice]);


  const addPromoCode = (e) => {
    e.stopPropagation();
    setToggle(!toggle)
  }

  const applyPromoCode = (e) => {
    e.stopPropagation();
    const token = localStorage.getItem("token");
    const fetchCouponData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/coupon/${props.promoCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        );


        /// we'll update the 

        if (response?.data?.data?.coupon?.valid && response?.data?.statusCode === 200) {
          setCouponError(null)
          setCouponData(response?.data?.data?.coupon)
          // here we'ill update the subscription to add promo their
          try {
            // const subscriptionRes = await axios.patch(
            //   `${process.env.REACT_APP_API}subscriptions/update`,
            //   {
            //     mainPlanDetails: {
            //       priceId: localStorage.getItem("subscriptionpriceId"),
            //       subscriptionId: localStorage.getItem("subscriptionId"),
            //       planId: localStorage.getItem("subscriptionplanId"),
            //       couponId: props.promoCode

            //     },
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //     },
            //   }
            // );

            // calculate the price
            const calculatedPrice = Number(((props.planPrice / 100 * response?.data?.data?.coupon?.percent_off) / 100).toFixed(2))

            setTotalPrice((props.planPrice / 100) - calculatedPrice)
          } catch (error) {
            console.log(error, "logging error while updating subscriptoio")
          }



        }
      } catch (error) {

        console.error("Error fetching data:", error?.response?.data?.message?.error?.message);
        setCouponError(error?.response?.data?.message?.error?.message)
        // setCouponError(err)
      }
    };
    if (token) {
      fetchCouponData();
    }

  } 


  return (
    <div className="purchasesummary-section">
      <div className="confirmrenew-content">Purchase Summary</div>
      <div className="flex renewmonth-content">
        <div className="renewheading-content">
          Monthly plan, monthly billing
        </div>
        <div className="renewamount-content">${props.planPrice / 100}</div>
      </div>
      <div className="billing-content">
        ${props.planPrice / 100} / member / month * 1 member <br />
        billed monthly
      </div>
      <div className="flex">
        {/* <div>
          <div className="teamplan-content">Total team members slot</div>
          <div className="teamslot-content">Add/Remove the team members.</div>
          <div className="additional-content">$20 each additional user</div>
        </div> */}
        <div className="flex"></div>
      </div>
      <hr />
      <div className="flex subtotal-section">
        <div className="totalplan-content">Subtotal</div>
        <div className="renewamount-content">${props.planPrice / 100}</div>
      </div>
      {/* <div className="flex totalteam-section">
        <div className="totalplan-content">Add additional team members</div>
        <div className="renewamount-content">$0</div>
      </div>
      <div className="flex tax-section">
        <div className="totalplan-content">Tax if applicable</div>
        <div className="renewamount-content">-</div>
      </div> */}
      <hr />

      {
        !couponData ?
          (
            <div className=" py-[16px]">
              <button className="promotion-code" type="button" onClick={(e) => addPromoCode(e)}>
                Add promotion code
              </button>
              {toggle && (
                <div className="flex items-center">
                  <div className="w-[59%]  py-[10px] pr-[10px]">
                    <input
                      className="card-inputfield  px-[14px] py-[10px]"
                      type="text"
                      placeholder="Enter code here"
                      value={props.promoCode}
                      onChange={(e) => props.setPromoCode(e.target.value)}
                    />
                  </div>
                  <div className="w-[13%]">
                    <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={(e) => applyPromoCode(e)}>
                      <span className="saveupdatebutton-context apply">Apply</span>
                    </button>

                  </div>
                  {

                    couponError ? <div>{couponError}</div> : ""
                  }
                </div>
              )}
            </div>
          ) : (
            <div className="flex tax-section">
              <div className="totalplan-content">Discount</div>
              <div className="renewamount-content">{couponData?.percent_off}%</div>
            </div>
          )
      }


      <hr />
      <div className="flex today-section">
        <div className="totalplan-content">Total for today</div>
        <div className="renewamount-content">${totalPrice}</div>
      </div>
      <div className="flex monthdue-content">
        {/* <div className="duemonth-content">
          Due on Oct 31 '23 then every month
        </div> */}
      </div>
      <div className="termsagree-content">
        By clicking agree and subscribe you are agreeing to their
        <span className="condition-content"> terms and conditions</span>
      </div>
      <div className="flex buttonplan-content">
        <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="submit" disabled={props.disabled}>
          <span className="saveupdatebutton-context">{props.disabled ? 'Loading....' : 'Agree and Subscribe'}</span>
        </button>
        {props.errorMessage && <div>{props.errorMessage}</div>}
        {/* <div className="cancelplancard-content">Cancel</div> */}
      </div>
    </div>
  );
}
export default PurchaseSummary;
