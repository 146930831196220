import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./UpgradePlan.scss";
import "../UserPricing/UserPricing.scss";
import "../UserPricing/YearlyPlan/YearlyPlan.scss"
import YearlyPlan from "./YearlyPlan/YearlyPlan";
import MonthlyPlan from "./MonthlyPlan/MonthlyPlan";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Planarrow from "../../../assets/icons/Big arrow.svg";
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateBilling() {
    const navigate = useNavigate();
    const [priceListY, setPriceListY] = useState([]);
    const [priceListM, setPriceListM] = useState([]);
    const[productsList,setProductList] = useState([]);
    const [planActive, setPlanActive] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTag, setSelectedTag] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedLast, setSelectedLast] = useState("");
    const [planName, setPlanName] = useState("");
    // const [couponCode, setCouponCode] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        const findObjectById = (array, id) => {
          return array.find(obj => obj.id === id);
        };
    
        const findObjectByProductId = (array, id) => {
          return array.find(obj => obj.plan.product.id === id);
        };
    
        const findObjectBytype = (array, subscriptionType) => {
          return array.find(obj => obj.subscriptionType === subscriptionType);
        };
    
        const fetchPlans = async () => {
          setIsLoading(true);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API}plans/list`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the request header
                },
              }
            ); // Replace this with the actual API endpoint
            // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
            if(response){
              let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
              console.log(productList,"productList");
              setPlanName(productList.name);
              setProductList(productList);
              let pricelistY = findObjectBytype(productList.priceList,'year');
              let pricelistM = findObjectBytype(productList.priceList,'month');
              setPriceListY(pricelistY);
              setPriceListM(pricelistM);
            }
            setIsLoading(false);
            
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        const fetchActiveSubscription = async () => {
          setIsLoading(true);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the request header
                },
              }
            ); // Replace this with the actual API endpoint
            if(response.data.data.subscriptions){
              let productList = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
              console.log(productList,"productList subscription id");
              // if(productList?.discount){
              //   if(productList?.discount?.coupon){
              //     setCouponCode(productList.discount.coupon.id);
              //   }
              // }
              setProductList(productList);
              // console.log(productList.items.data[0].plan.interval,"productList");
              setPlanActive(productList.items.data[0].plan.interval);
              // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
              // setPlanActive()
              // response.data.data.subscriptions[0].items.data        
            }
    
            if(response.data.data.paymentMethods){
              const paymentDefault = response.data.data.paymentMethods.find(item => item?.isDefault);
              // console.log(paymentDefault,"paymentDefault");
              if(paymentDefault){
                setSelectedTag(paymentDefault.id);
                setSelectedBrand(paymentDefault.card.brand);
                setSelectedLast(paymentDefault.card.last4);
              }else{
                setSelectedTag(response.data.data.paymentMethods[0].id);
                setSelectedBrand(response.data.data.paymentMethods[0].card.brand);
                setSelectedLast(response.data.data.paymentMethods[0].card.last4);
              }
    
            }
            // console.log(response.data.data.subscriptions,"response");
            setIsLoading(false);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        if (token) {
          fetchPlans();
          fetchActiveSubscription();
        }
      }, []);

      const handleRenew = async(price,plan,priceId,subscriptionId) => {
        console.log(price,plan,priceId,subscriptionId,"priceplan");
        setIsLoading(true);
          const renewPriceId = priceId;
          const token = localStorage.getItem("token");
          try{
            let planObj = { mainPlanDetails: {priceId: renewPriceId, subscriptionId: subscriptionId, planName : planName, planId: `${process.env.REACT_APP_MAIN_PLAN_ID}`},paymentMethod:selectedTag   };
            // if(couponCode){
            //   planObj = { mainPlanDetails: {priceId: renewPriceId, subscriptionId: subscriptionId, planName : planName, planId: `${process.env.REACT_APP_MAIN_PLAN_ID}`, couponId: couponCode},paymentMethod:selectedTag   };
            // }
              const response = await axios.patch(
                `${process.env.REACT_APP_API}subscriptions/update`,
                planObj,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log(response,"response");
              localStorage.removeItem('renewPrice');
              localStorage.removeItem('renewPlan');
              localStorage.removeItem('renewPriceId');
              setIsLoading(false);
              toastify("success", response.data.message);
              setTimeout(function(){ 
                navigate('/app/mySubscription');
              }, 2000);

          } catch (error) {
            console.error("Error fetching data:", error);
            toastify("error", error.response.data.data.message );
          }
      }

    return (
      <>
      {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <div className="userpricing-plans"> 
        <div className="upgrade-content">Upgrade your plan</div>
        <Tabs defaultIndex={1}> 
            <div style={{display: "flex"}}>
            <TabList> 
            <Tab>Monthly</Tab>
            <Tab>Yearly</Tab>
            </TabList>
            <div className="save-plan">
            <div className="sec-sectio">
            <h6>Save up to 33%</h6>
            <img src={Planarrow} alt="chrome-icon" />
            </div>
        </div>
            </div>
            
            <TabPanel>
                {
                    planActive ?
                    <MonthlyPlan priceListM = {priceListM} planActive={planActive} handleRenew={handleRenew} productsList={productsList}/> : ""
                }
            </TabPanel>
            <TabPanel>
                {
                  planActive ?
                    <YearlyPlan priceListY = {priceListY} planActive={planActive} handleRenew={handleRenew} productsList={productsList}/> : ""
                }
            </TabPanel>
        </Tabs>
        <ToastContainer/>
        </div>
        )}
      </>
    );
}
export default UpdateBilling;
 