import { useEffect, useState } from 'react';
import axios from 'axios';
import './verify.scss';
import Intercom from '../components/intercom/Intercom';
function MailVerify() {
  const user = {
    firstName: null,
    email: null,
    createdAt: Math.floor(Date.now() / 1000),
  };
useEffect(()=>{
    console.log(user,"user on login")
        Intercom(user)
      },[user])
    const pathslug = window.location.pathname
    .split("/")
    .filter(Boolean)
    .at(-1);

    console.log(pathslug);

    

    useEffect(() => {
        const data = {
            "token": pathslug,
        }

        axios
          .post(`${process.env.REACT_APP_API}users/email-verify`, data)
          .then((response) => {
            console.log(response);
          });
    }, []);

    return (
        <div className="min-h-[100vh] flex justify-center items-center text-center">
        <div className="wrapperAlert">
          <div className="contentAlert">

            <div className="topHalf">
              <p className="flex justify-center">
                <svg viewBox="0 0 512 512" width="100" title="check-circle">
                    <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                </svg>
              </p>
              <h1>Congratulations</h1>

            <ul className="bg-bubbles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            </div>

            <div className="bottomHalf">

              <p>Your Account is verified. Please click on the below button to sign in.</p>

              <button className="bg-[#E77000] px-20 rounded-full text-white pt-3 pb-2">Sign In</button>

            </div>

          </div>       
        </div>
      </div>
    );
}

export default MailVerify;
