import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import './Tags.scss';
import { useEffect } from "react";
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateTags({setPopup,parent,bucketUpdate,bucketOperation,bucketNameText,changeTxt,bucketUpdateId}){
   
    const createBucket = async (name) => {
       const userData = JSON.parse(localStorage.getItem('userData'));
       const token = localStorage.getItem('token');
        try {
          const response = await axios.post('https://prdapi.zimzee.com/api/tags/add', { name,userId:userData._id,parentId:parent },
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          toastify("success", response.data.message);
          setPopup(false);
          bucketUpdate();
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.message );
        }
      };

      const updateCreateBucket = async (id,name) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
         try {
           const response = await axios.patch('https://prdapi.zimzee.com/api/tags/update-tag', {tagId:id,sourceId:null,destinationId:null,name:name },
           {
             headers: {
                 Authorization: `Bearer ${token}`,
               }
           }
           );
           console.log(response);
           toastify("success", response.data.message);
           setPopup(false);
           bucketUpdate();
         } catch (error) {
           console.error(error);
           toastify("error", error.response.data.message );
         }
       };
       
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(bucketUpdateId,"bucketUpdateId");
        const name = event.target.name.value; // Get the name value from the form
        if(bucketOperation == 'Add'){
            await createBucket(name);
        }else if(bucketOperation == 'subbucket'){
            await createBucket(name);
        }else{
            console.log(bucketUpdateId,"bucketNameText");
            console.log(name,"name params");
            await updateCreateBucket(bucketUpdateId,name);
        }
         
        // setPopup(false);
        // bucketUpdate();
      };

    function closePopup(){
        setPopup(false);
    }
    
    return(
        <div className="bucketCreationPopuptags relative !z-[999]" onClick={closePopup}>
            <form className="addBucket relative w-[85%] md:w-[500px]" onSubmit={handleSubmit} onClick={(e)=>{e.stopPropagation()}}>
                <span onClick={closePopup} className="absolute right-0 top-0 w-6 h-6 bg-[#000] m-4 flex justify-center items-center rounded-full">
                    <FontAwesomeIcon className="cursor-pointer text-white text-[14px]" icon={faClose} />
                </span>
                <h2 className="createpopup-content" style={{textAlign:"left"}}>{
                  (bucketOperation == "Update")? "Rename" : (bucketOperation == "subbucket")? "Create sub": (bucketOperation == "Update Sub")? "Edit Sub" :"Create new"
                } tag</h2>
                <hr/>
                <div className="tagsdesc-content">{(bucketOperation == "Update")? 'Are you sure you want to rename the tag? This tag will be renamed on all the content.' : 'Tags let you easily find, organize and browse your content.'}</div>
                <input className="flex items-center rounded-3xl border-0 menusubitems-content createdtagtext-content" type="text" name="name" placeholder="Tag Name" onChange={changeTxt} value={bucketNameText} />
                <div className="flex justify-end">
                  <div className="canceltag-content cursor-pointer" onClick={closePopup}>Cancel</div>
                  <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Create</span>
                  </button>
                </div> 
                {/* <button type="submit">{(bucketOperation == "subbucket")? "Cancel": (bucketOperation == "Update Sub")? "Update":bucketOperation}</button> */}
            </form>
        </div>
    )
} 

export default CreateTags;