import { React, useEffect, useState } from "react";
import axios from "axios";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import RenewPlan from "../Plans/PurchaseSummary.js";
import { toastify } from '../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SetupForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [priceListY, setPriceListY] = useState([]);
  const [priceListM, setPriceListM] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState(localStorage.getItem('subscriptionType'));
  const [planPrice, setPlanPrice] = useState(props.planPrice);
  
  useEffect(() => {
    const token = localStorage.getItem("token");

    const findObjectById = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const findObjectBytype = (array, subscriptionType) => {
      return array.find(obj => obj.subscriptionType === subscriptionType);
    };

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        console.log(localStorage.getItem('subscriptionType'),"subscriptionType");
        if(response){
          let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          let pricelistY = findObjectBytype(productList.priceList,'year');
          let pricelistM = findObjectBytype(productList.priceList,'month');
          setPriceListY(pricelistY);
          setPriceListM(pricelistM);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchPlans();
    }
  }, []);

  const handlePlanSelect = (e,planId,priceId,type,planPrice) => {
    e.stopPropagation();
    console.log(type,planId,priceId,planPrice,"plan details");
    localStorage.setItem('subscriptionType',type);
    localStorage.setItem('subscriptionplanId',planId);
    localStorage.setItem('subscriptionpriceId',priceId);
    localStorage.setItem('planPrice',planPrice);
    setSubscriptionType(type);
    setPlanPrice(planPrice);
  }

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }

  
  const handleSubmit = async (event) => {
    // event.preventDefault();
    // console.log(promoCode,"promoCode parent");
    // return;
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    // setIsLoading(true);
    console.log(event.target.name.value, "name target");
    const token = localStorage.getItem("token");

    try {
      if(event.target.name.value == '' || event.target.email.value == '' || event.target.phone.value == ''){
        toastify("error", "Please provide billing details" );
        return;
      }

      
      // console.log(response);
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoading(true);

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      let subscriptionObj = { priceId:localStorage.getItem("subscriptionpriceId"),planId:localStorage.getItem("subscriptionplanId")}
      if(promoCode){
        subscriptionObj = { priceId:localStorage.getItem("subscriptionpriceId"),planId:localStorage.getItem("subscriptionplanId"), couponId: promoCode}
      }
      const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/create`, subscriptionObj,
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );

      if(response){
        let clientSecret = response.data.data.clientSecret;
        
        try {
          const response = await axios.patch(
            `${process.env.REACT_APP_API}customer/update-customer`,
            { name: event.target.name.value, email: event.target.email.value, phone: event.target.phone.value },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log(response);
          if(clientSecret){

              const { error } = await stripe.confirmPayment({
                  //`Elements` instance that was used to create the Payment Element
                  elements,
                  clientSecret: clientSecret,
                  confirmParams: {
                    return_url: window.location.origin+'/app/myProfile',
                  }
              });

              

              if (error) {
                  console.log(error)
                  // This point is only reached if there's an immediate error when
                  // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                  // handleError(error);
                  if (error.type === "card_error" || error.type === "validation_error") {
                    setErrorMessage(error.message);
                  } else {
                    setErrorMessage("An unexpected error occured.");
                  }
              } else {
                  localStorage.removeItem('subscriptionpriceId');
                  localStorage.removeItem('subscriptionplanId');
                  localStorage.removeItem('planPrice');
                  localStorage.removeItem('subscriptionType');
                  // Your customer is redirected to your `return_url`. For some payment
                  // methods like iDEAL, your customer is redirected to an intermediate
                  // site first to authorize the payment, then redirected to the `return_url`.
              }
          }
          // toastify("success", response.data.data.clientSecret);
        } catch (error) {
          console.error(error);
          toastify("error", error.response.data.data.message );
        }
      }
      
      // const { error } = await stripe.confirmSetup({
      //       elements,
      //       clientSecret: props.clientSecret,
      //       confirmParams: {
      //           return_url: window.location.href,
      //       },
      // });
      // const { error } = await stripe.confirmPayment({
      //   elements,
      //   confirmParams: {
      //     // Make sure to change this to your payment completion page
      //     return_url: `${window.location.href}`,
      //   },
      // });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      

      // setLoading(false);
      // toastify("success", response.data.message);
      // cardUpdate();
    } catch (error) {
      console.error(error);
      // toastify("error", error.response.data.message);
    }



    // Create the SetupIntent and obtain clientSecret
    // const res = await fetch("http://localhost:5000/api/subscriptions/attach-paymentMethod", {
    //     method: "GET",
    //     headers: {
    //         "Authorization": `Bearer ${token}`,
    //         "Content-Type": "application/json", // You may need to set the content type based on your API requirements
    //     },
    // });

    // const data = await res.json();
    // console.log(data.data)
    // Confirm the SetupIntent using the details collected by the Payment Element
    // const { error } = await stripe.confirmSetup({
    //     elements,
    //     clientSecret: props.clientSecret,
    //     confirmParams: {
    //         return_url: 'https://example.com/complete',
    //     },
    // });

    // if (error) {
    //     // This point is only reached if there's an immediate error when
    //     // confirming the setup. Show the error to your customer (for example, payment details incomplete)
    //     handleError(error);
    // } else {
    //     // Your customer is redirected to your `return_url`. For some payment
    //     // methods like iDEAL, your customer is redirected to an intermediate
    //     // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };

  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <form onSubmit={handleSubmit}>
      <div class="flex flex-row flex-wrap">

        <div class="w-full sm:w-1/3 md:w-3/6">

          <h4>Payment</h4>
          <div className="pb-[30px]">
            <h6>Plans</h6>
            <div className="flex flex-row flex-wrap pt-[13px]">
              <div className="w-full sm:w-1/3 md:w-3/6 pr-[10px]">
                <button className={`plan-btn  focus:border-[#fff] ${subscriptionType == 'monthly' ? 'plan-btn-active' : ''}`} onClick={(e) =>handlePlanSelect(e,`${process.env.REACT_APP_MAIN_PLAN_ID}`,priceListM.id,'monthly',priceListM.amount)} type="button">
                  Monthly
                </button>
              </div>
              <div className="w-full sm:w-1/3 md:w-3/6 pl-[10px]">
                <button className={`plan-btn  focus:border-[#fff] ${subscriptionType !== 'monthly' ? 'plan-btn-active' : ''}`} onClick={(e) =>handlePlanSelect(e,`${process.env.REACT_APP_MAIN_PLAN_ID}`,priceListY.id,'yearly',priceListY.amount)} type="button">
                  Yearly 33% off
                </button>
              </div>
            </div>
          </div>
          <div className="pb-[30px]">
            <h6 className="pb-[15px]">Add payment method</h6>
            {/* <form onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button disabled={!stripe}>Submit</button>
                  </form> */}
            {/* <Elements stripe={stripePromise} options={options}> */}
            {/* <form onSubmit={handleSubmit}> */}
            <PaymentElement />
            {/* <button>Submit</button> */}
            {/* </form>
                  </Elements> */}
          </div>
          {/* <div>
            <div className="flex">
              <input
                className="inputfocus-content"
                type="checkbox"
                name="payment"
              />
              <div className="default-payment ml-2">
                Use as default payment method
              </div>
            </div>
          </div> */}
          <h6 className="pt-[30px] pb-[15px]">Billing Information</h6>
          <div className="billing-fields">
            <div className="payment-section pb-[30px]">
              <input
                className="card-inputfield  px-[14px] py-[10px]"
                type="text"
                name="name"
                placeholder="Name"
              />
            </div>
            <div className="flex flex-row">
              <div className="payment-section pr-[10px]  md:w-1/2">
                <input
                  className="card-inputfield  px-[14px] py-[10px]"
                  type="text"
                  name="email"
                  placeholder="Email"
                />
              </div>
              <div className="payment-section pl-[10px]  md:w-1/2">
                <input
                  className="card-inputfield  px-[14px] py-[10px]"
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>

        </div>
        <div class="w-full sm:w-1/3 md:w-3/6">
          <RenewPlan disabled={!stripe || loading } errorMessage={errorMessage} planPrice={planPrice} promoCode={promoCode} setPromoCode={setPromoCode} />
        </div>

      </div>
      <ToastContainer/>
    </form>
    )}
    </>
  );
}