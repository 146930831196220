import React, { useEffect } from 'react';
import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";

function VerifyTokenExpiry(){
    const navigate = useNavigate();
    useEffect(() => {
        // Check the user's login status (you might want to use your own logic)
        // const isUserLoggedIn = /* your logic to check if the user is logged in */;
        const isUserLoggedIn = localStorage.getItem('userData') ? true : false;
        // Redirect if the user is already logged in
        if (isUserLoggedIn) {
          navigate('/app/dashboard'); // Redirect to your main app page
        }else{
            localStorage.removeItem('settingsToken')
        }
      }, []);
    return(
        <div className="loginHolder min-h-[100vh] flex items-center bg-[#F3F4F6]">
        <div className="formHolder bg-white md:w-1/2 lg:w-2/5 max-w-lg mx-auto p-8 rounded-lg shadow text-center">
            <h3 className="text-xl font-bold text-[24px]">Invitation expired</h3>
            <p>Your invitation Link has expired</p>
            <div className="logo flex justify-center my-8">
                <img src={logo} />
            </div>
            <a href="/" type="button" className="button bg-[#FF6600] text-white py-2 px-5 font-bold rounded-[5px] mt-4">Login</a>
        </div>
    </div>
    )
}

export default VerifyTokenExpiry;