import { React, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import './InvoiceSummary.scss'
import { HashLink as Link } from 'react-router-hash-link';
import EditPlan from '../../../../assets/icons/editplan.svg';
 
function InvoiceSummary() { 
  const invoiceId = useParams();
  console.log(invoiceId, "bucketId");
  const [invoiceList, setInvoiceList] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/single-invoice/${invoiceId.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        console.log(response);
        setInvoiceList(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    if (token) {
      fetchInvoices();
    }
  }, []);
  
  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }
  return(
    <div className="invoicesummary-section">
      <div className="plandetail-content"><a href="/app/myProfile">Invoice</a><span className="plan-content"> / <a href={window.location.href}>Summary</a></span></div>
      <div className="confirmrenew-content">Summary</div>
      <div className="flex">
        <div className="summaryplan-content">Plan</div>
        <div className="summarydetail-content">{invoiceList?.lines?.data[0]?.plan?.interval == "month" ? "Monthly" : "Yearly"}</div>
      </div>
      <div className="flex">
        <div className="summaryplan-content">Invoice No.</div>
        <div className="summarydetail-content">{invoiceList?.number}</div>
      </div>
      <div className="confirmitem-content">Items</div>
      <div className="summarydate-content">{periodEnd(invoiceList?.period_start)} - {periodEnd(invoiceList?.period_end)}</div>
       <div className="flex subtotal-section">
          <div className="totalplan-content">Subtotal</div>
          <div className="renewamount-content">${invoiceList?.total/100}</div>
       </div>
       <hr />
       <div className="flex tax-section">
          <div className="totalplan-content due-section">Total due</div>
          <div className="renewamount-content due-section">${invoiceList?.amount_due/100}</div>
       </div>
       <hr />
        <div className="flex today-section">
          <div className="totalplan-content">Amount paid</div>
          <div className="renewamount-content">${invoiceList?.amount_paid/100}</div>
        </div>
        <div className="flex">
          <div className="totalplan-content">Amount remaining</div>
          <div className="renewamount-content">${invoiceList?.amount_remaining}</div>
        </div>
        <div className="flex buttonplan-content">
          <a className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" href={invoiceList?.invoice_pdf}>
            <span className="saveupdatebutton-context">Download Invoice</span>
          </a>
        </div>
    </div>
  );
}
export default InvoiceSummary; 