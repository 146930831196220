import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./VisaPlan.scss";
import VisaIcon from "../../../../assets/icons/visaplan.svg";
import AddPayment from "../AddPayment/AddPayment";

function VisaPlan() {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const team = urlSearchParams.get('team');
  const [visible, setVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] =useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedLast, setSelectedLast] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // console.log(response.data.data.subscriptions,"response");
        if(response.data.data.paymentMethods.data){
          // console.log(response.data.data.paymentMethods.data,"response.data.data.paymentMethods.data");
          setPaymentMethod(response.data.data.paymentMethods.data);
          setSelectedTag(response.data.data.paymentMethods[0].id);
          setSelectedBrand(response.data.data.paymentMethods[0].card.brand);
          setSelectedLast(response.data.data.paymentMethods[0].card.last4);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchPlans();
    }
  }, []);

  const handleRadioChange = (event,brand,last) => {
    if(event.target.value == 'addPayment'){
      navigate(`/app/profileAddpayment`)
    }
    setSelectedTag(event.target.value);
    setSelectedBrand(brand);
    setSelectedLast(last);
  };

  const handPayment = () => {
    if(selectedTag != "addPayment" && !team){
      navigate(`/app/renewPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}`)
    }else if(team){
      navigate(`/app/teamMembersPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}`)
    }
      // (selectedTag != "addPayment") ? navigate(`/app/upgradePlan?payment=${selectedTag}`) : "";
  }

  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
      <div className="visaplan-section">
        <div className="plandetail-content">
          <a href="/app/myProfile">Plan details</a> / <a href={team? `/app/teamMembersPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}` : `/app/renewPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}`}>Update</a> / <a href={team? `/app/teamMembersPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}` : `/app/renewPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}`}>Confirm</a>{" "}
          <span className="plan-content"> / <a href={window.location.href}>Payment method</a></span>
        </div>
        <div className="visapayment-content">Select a payment method</div>
        <div className="selectpayment-content">
          Select a payment method to use with your Monthly plan.
        </div>

        {paymentMethod.map((item,index) => (
            <div className="flex tagradio-content visadetail-section">
            <div className="tagline-section flex">
              <input
                type="radio"
                name="selectedtag"
                className="radiobutton-content"
                value={item.id}
                onClick={() => setVisible(false)}
                onChange={(e) => handleRadioChange(e,item.card.brand,item.card.last4)}
                defaultChecked={index === 0 ? true : false}
              />
               
              <label className="visaplan-content flex" for="html">
                <img src={VisaIcon} style={{ marginRight: "8px" }} alt="VisaIcon" />
                {capitalizeFirstLetter(item.card.brand)} •••• {item.card.last4}
              </label>
            </div>
            <div className="visaexpire-content">Expires on {item.card.exp_month}/{item.card.exp_year}</div>
          </div>
          ))}
        

        <div className="flex  mb-[25px]">
          <input
            type="radio"
            name="selectedtag"
            className="radiobutton-content"
            value="addPayment"
            onChange={handleRadioChange}
            onClick={() => setVisible(true)}
          />
          <label className="addpayment-section" for="html">
            Add payment method
          </label>
        </div>
        {visible && (
          <div>
            <AddPayment />
          </div>
        )}
        <div className="termsagree-content">
          You can review important information for plans on their{" "}
          <span className="condition-content">
            Terms of Service and <br /> Privacy Policy pages.
          </span>
        </div>
        <div className="flex buttonplan-content">
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={handPayment}>
            <span className="saveupdatebutton-context">Update</span>
          </button>
          <div className="cancelplancard-content" style={{cursor:"pointer"}}>Cancel</div>
        </div>
      </div>
    )}
    </>
  );
}
export default VisaPlan;
