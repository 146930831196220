import React from "react";
import './InvoiceDetails.scss'
import { HashLink as Link } from 'react-router-hash-link';

function InvoiceDetails(props) {
  let userData =JSON.parse(localStorage.getItem('userData'));
  const complementry = userData.isComplementary;
  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }
  return(
    <div className="invoicedetails-section">
      <div className="invoice-content">Invoice</div>
      {
        !complementry ?
        <div className="flex">
          <div className="amountmonth-content">${props?.priceActive/100} per {props?.planActive}</div>
          <div className="currentplan-content">Current plan</div>
        </div> : ""
      }
      {
        !complementry ?
        <div className="planrenew-content">Your plan renews on {periodEnd(props?.currentPeriodEnd)}</div> : ""
      }
      
      <div className="flex">
        <div className="invoiceheading-section monthlyinvoice-section">Purchase</div>
        <div className="invoiceheading-section numberinvoice-section">Invoice No.</div>
        <div className="invoiceheading-section detailsinvoice-section">Payment date</div>
        <div className="invoiceheading-section detailsinvoice-section">Payment method</div>
        <div className="invoiceheading-section dollarsinvoice-section">Total amount</div>
      </div>
      <hr/>
      {props?.invoiceList?.map((item) => (
            <Link to={`/app/invoiceSummary/${item.id}`}>
              <div className="flex">
                <div className="invoicedetail-content monthlyinvoice-section">{item.product.name}</div>
                <div className="invoicedetail-content numberinvoice-section">{item.number}</div>
                <div className="invoicedetail-content detailsinvoice-section">{periodEnd(item.created)}</div>
                <div className="invoicedetail-content detailsinvoice-section">{item.paymentMethod.card.brand} •••• •••• •••• {item.paymentMethod.card.last4}</div>
                <div className="priceinvoice-section dollarsinvoice-section">${item.total/100}</div>
              </div>
            </Link>
        ))}
      
      {/* <div className="flex">
        <div className="invoicedetail-content monthlyinvoice-section">Monthly</div>
        <div className="invoicedetail-content numberinvoice-section">No.BCEA9861-0001</div>
        <div className="invoicedetail-content detailsinvoice-section">Dec 4, 2021 @ 4:30 PM</div>
        <div className="invoicedetail-content detailsinvoice-section">Visa •••• •••• •••• 4242</div>
        <div className="priceinvoice-section dollarsinvoice-section">$49</div>
      </div>
      <div className="flex">
        <div className="invoicedetail-content monthlyinvoice-section">Team members</div>
        <div className="invoicedetail-content numberinvoice-section">No.BCEA9861-0001</div>
        <div className="invoicedetail-content detailsinvoice-section">Dec 4, 2021 @ 4:30 PM</div>
        <div className="invoicedetail-content detailsinvoice-section">Visa •••• •••• •••• 4242</div>
        <div className="priceinvoice-section dollarsinvoice-section">$20</div>
      </div> */}
    </div>
  );  
} 
export default InvoiceDetails; 