import React, { useEffect, useState, useRef } from "react";

const Tags = ({ listTags, isShare = true }) => {
  const [tags, setTags] = useState(listTags);
  const [visibleTags, setVisibleTags] = useState([]);
  const [hiddenTags, setHiddenTags] = useState([]);
  const tagsContainerRef = useRef(null);
  const [negateNumber] = useState(isShare ? 40 : 60);
  useEffect(() => {
    const calculateTags = () => {
      const tagsContainerWidth = tagsContainerRef.current.offsetWidth;

      // add red background to tagsContainer
      // tagsContainerRef.current.style.background = "red";
      const visibleTagsArray = [];
      const hiddenTagsArray = [];

      let remainingWidth = tagsContainerWidth - negateNumber;

      tags.forEach((item) => {
        const span = document.createElement("span");
        span.innerText = item.name;
        span.className =
          "tagsnamefile-textcontent tagsnamefile-truncated py-1 px-2 mr-[6px] my-1 rounded-2xl";
        document.body.appendChild(span);

        const spanWidth = span.offsetWidth;

        if (spanWidth <= remainingWidth) {
          visibleTagsArray.push(item);
          remainingWidth -= spanWidth;
        } else {
          hiddenTagsArray.push(item);
        }

        span.remove();
      });

      setVisibleTags(visibleTagsArray);
      setHiddenTags(hiddenTagsArray);
    };

    calculateTags(); // Initial calculation

    // Recalculate on resize
    const handleResize = () => {
      calculateTags();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tags]);

  return (
    <div
      className="cardTags cardTags-content flex py-1"
      style={{
        width: isShare ? "100%" : "76%",
        flex: isShare ? "1" : '"0 0 226px"',
        maxWidth: isShare ? "100%" : "226px",
      }}
      ref={tagsContainerRef}
    >
      {visibleTags?.length > 0 ? (
        <>
          {visibleTags.map((item, index) => (
            <span
              key={item.name}
              className="tagsnamefile-textcontent tagsnamefile-truncated py-1 px-2 mr-[6px] my-1 rounded-2xl"
            >
              {item.name}
            </span>
          ))}
          {hiddenTags.length > 0 && (
            <span className="tagsnamefile-textcontent tagsnamefile-truncated py-1 px-2 mr-[6px] my-1 rounded-2xl">
              +{hiddenTags.length}
            </span>
          )}
        </>
      ) : (
        <span className="tagsnamefile-textcontent py-1 px-2 mr-[6px] my-1 rounded-2xl">
          No Tags Assigned
        </span>
      )}
    </div>
  );
};

export default Tags;
