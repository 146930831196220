import { React, useEffect, useState } from "react";
import axios from "axios";
import './BillingInfo.scss'
import { HashLink as Link } from 'react-router-hash-link';
import VisaPlan from '../../../../assets/icons/visaplan.svg'; 
import PlanDismiss from '../../../../assets/icons/plandismiss.svg';
import DismissButton from "../../../../assets/icons/dismissButton.svg";
import ProfileUser from "../../../../assets/images/profileuser.svg"
import EditPlan from "../../../../assets/icons/editplan.svg";
import { toastify } from '../../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function BillingInfo(props) {
  const navigate = useNavigate();
  const [invitepopup, setInvitePopup] = useState(false);
  const [cancelpopup, setCancelPopup] = useState(false);
  const[name,setName]=useState('')
  const[email,setEmail]=useState('')
  const[phone,setPhone]=useState('')
  const[city,setCity]=useState('')
  const[state,setState]=useState('')
  const[country,setCountry]=useState('')
  const[zip,setZip]=useState('')
  const[addressline1,setAddressLine1]=useState('')
  const[addressline2,setAddressLine2]=useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [countPurchase, setPurchaseCount] = useState(0)
  const [teamMember, setTeamMember] = useState(0);
  // const [teamMemberPrice, setTeamMemberPrice] = useState(100);

  useEffect(() => {
    console.log(props.teamMember,"props.teamMember");
    const token = localStorage.getItem("token");
    const fetchCustomerInfo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}customer/get-customer-info`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          setEmail(response.data.data.email);
          setName(response.data.data.name);
          setPhone(response.data.data.phone);
          if(response.data.data.address){
            setCity(response.data.data.address.city);
            setState(response.data.data.address.state);
            setZip(response.data.data.address.postal_code);
            setAddressLine1(response.data.data.address.line1);
            setAddressLine2(response.data.data.address.line2);
            setCountry(response.data.data.address.country);
          }
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchCustomerInfo();
    }
  }, []);

  const handleName=(e)=>{
    setName(e.target.value)
  }

  const handleEmail=(e)=>{
    setEmail(e.target.value)
  }

  const handlePhone=(e)=>{
    setPhone(e.target.value)
  }

  const handleAddress1=(e)=>{
    setAddressLine1(e.target.value)
  }

  const handleAddress2=(e)=>{
    setAddressLine2(e.target.value)
  }

  const handleCity=(e)=>{
    setCity(e.target.value)
  }

  const handleState=(e)=>{
    setState(e.target.value)
  }

  const handleZip=(e)=>{
    setZip(e.target.value)
  }

  const handleCountry=(e)=>{
    setCountry(e.target.value)
  }

  const updateBilling = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
      try {
        if(name== '' || email == '' || city == '' || country == '' || addressline1 == '' || zip == '' || state == ''){
          setIsLoading(false);
          toastify("error", "Please provide billing details" );
          return;
        }
        const response = await axios.patch(`${process.env.REACT_APP_API}customer/update-customer`,{
            name: name,
            email:email,
            phone:phone,
            address:{
              city: city,
              country: country,
              line1:addressline1,
              line2:addressline2,
              postal_code: zip,
              state: state
            }
        },
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
        );
        console.log(response);
        setIsLoading(false);
        toastify("success", response.data.message);
        // setTimeout(function(){ 
        //   navigate('/app/myProfile');
        //  }, 2000);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }

  const handleCancelSubscription = async() => {
    const token = localStorage.getItem('token');
    // setIsLoading(true);
    try {
      // props.planActive == 'year' ? 
      const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/cancel-subscription`,{},
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );

      setCancelPopup(false);
      // setIsLoading(false);
      toastify("success", response.data.message);
      console.log(response);
      // setTimeout(function(){ 
      //   navigate('/app/myProfile');
      //  }, 2000);
    
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }

  const deleteUserAccount = async () => {
      const token = localStorage.getItem('token');
      try {
            const response = await axios.delete(`${process.env.REACT_APP_API}users/delete-userAccount`,
              {
                headers: {
                    Authorization: `Bearer ${token}`,
                  }
              }
          );
          console.log(response);
          toastify("success", response.data.message);
          setCancelPopup(false);
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
          localStorage.removeItem('settingsToken')
          localStorage.removeItem('cardFilter');
          localStorage.removeItem('bucketFilter');
          localStorage.removeItem('bucketView');
          navigate('/');
      //  setIsLoading(false);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }

  let userData =JSON.parse(localStorage.getItem('userData'));
  const complementry = userData.isComplementary;
  console.log(props.paymentMethod,"props.paymentMethod)");
  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }

  const handleTeamMemberSubscription = async () => {
    if(count && props.teamMember){
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if(props.selectedTag == ""){
        setIsLoading(false);
        setCount(0);
        toastify("error", "Please Add payment Method First");
        return;
      }
      let planObj = { teamMemberDetails: {priceId: props.teamMember, quantity: count, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`, planName: 'Team Member Seat'},paymentMethod:props.selectedTag  }
      console.log(planObj);
      try{
        const response = await axios.patch(
          `${process.env.REACT_APP_API}subscriptions/update`,
          planObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response,"response");
        setIsLoading(false);
        setCount(0);
        closeInvitePopup();
        props.handleDeattachRefresh();
        setTimeout(function(){ 
          toastify("success", response.data.message);
        }, 2000);
      } catch (error) {
        setIsLoading(false);
        setCount(0);
        console.error("Error fetching data:", error);
        toastify("error", error?.response?.data?.message);
      }
    }else{
      toastify("error", "Price Id and quantity not exist" );
    }
  }
  
  const handleDeattachPayment = async(id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/deattach-paymentMethod`,{"paymentMethod":id},
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      toastify("success", response.data.message);
      console.log(response);
      props.handleDeattachRefresh();
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
  const handleIncrement = () => {
    let countI = count + 1;
    // let price = 20;
    // console.log(price,Number(renewPrice),Number(countI * 20));
    let team = Number(countI * 20);
    setCount(count + 1);
    setTeamMember(team);
    // setRenewPrice(price);
  };

  const handleDecrement = () => {
    if (count + countPurchase > 0) {
      let countI = count - 1;
      // let price = 20;
      // console.log(price,Number(renewPrice),Number(countI * 20));
      let team = Number(countI * 20);
      setCount(count - 1);
      setTeamMember(team);
      // setRenewPrice(price);
    }
  };

  const handleInvitePopup = () => {
    setInvitePopup(true);
  }

  const closeInvitePopup = () => {
    setInvitePopup(false);
  }

  const handleCancelPopup = () => {
    setCancelPopup(true);
  }

  const closeCancelPopup = () => {
    setCancelPopup(false);
  }

  return(
    <>
     {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <>
      <div className="billing-section">
        <div className="billingdesk-sec">
          <div className="flex">
            <div className="subhead-content">Plan Name</div>
            <div className="subhead-content">Billing Cycle</div>
            <div className="subhead-content">Billing Amount</div>
            <div className="subhead-content">Seats</div>
          </div>
        </div>
        <div className="subdetail-section">
          <div className="flex">
            <div className="subdetail-content">{props.productName}</div>
            <div className="subdetail-content">{props.planActive == 'year' ?  'Yearly' : complementry ? 'Lifetime Promo' : 'Monthly'}</div>
            <div className="subdetail-content">${props.priceActive/100}</div>
            <div className="subadd-content" onClick={handleInvitePopup} >{props.purchaseTeamMembers ? props.purchaseTeamMembers : 0}<span className="addseat-content">+Add additional seats</span></div>
          </div>
        </div>
        <div className="billingmbl-sec">
          <div>
            <div className="flex">
              <div className="subhead-content">Plan Name</div>
              <div className="subhead-content">Billing Cycle</div>
            </div>
            <div className="flex">
              <div className="subdetail-content">{props.productName}</div>
              <div className="subdetail-content">{props.planActive == 'year' ?  'Yearly' : complementry ? 'Lifetime Promo' : 'Monthly'}</div>
            </div> 
          </div>
          <div>
            <div className="flex">
              <div className="subhead-content">Billing Amount</div>
              <div className="subhead-content">Seats</div>
            </div>
            <div className="flex">
              <div className="subdetail-content">${props.priceActive/100}</div>
              <div className="subadd-content" onClick={handleInvitePopup} >{props.purchaseTeamMembers ? props.purchaseTeamMembers : 0}<span className="addseat-content">+Add additional seats</span></div>
            </div>
          </div>
        </div>
        {
          !complementry?
          <div className="autorenew-content">Your plan auto-renews on {periodEnd(props.currentPeriodEnd)}</div> : ""
        }
        
        {
          !complementry?
          <div className="flex submitbill-content">
          {
            props.planActive !== 'year' ?
            <Link to="/app/updateBillingplan">
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                <span className="saveupdatebutton-context">Upgrade</span>
              </button>
            </Link> : ""
          }
          <div className="cancelsub-content" onClick={handleCancelPopup} style={{paddingLeft:  props.planActive == 'year' ? "0px" : "35px"}}>Cancel the subscription</div>
        </div>  : ""
        }
       
        <hr />
        <div className="paybill-content">Payment method</div>
        {props.paymentMethod.map((item) => (
          <div className="flex plancard-content">
            <div className="flex visadetail-content"><img src={VisaPlan} style={{marginRight: "8px"}} alt="VisaPlan" />{capitalizeFirstLetter(item.card.brand)} •••• •••• •••• {item.card.last4} {item?.isDefault? <div className="defaultplan-content">Default</div> : ""}</div>
            <div className="expire-content">Expires on {item.card.exp_month}/{item.card.exp_year}</div>
            <div><img src={PlanDismiss} className="plandismiss-content" alt="planDismiss"   onClick={() => handleDeattachPayment(item.id)}/></div>
          </div>
          ))}
        
        <div className="paymethod-content"><Link to="../../app/profileAddpayment">+ Add payment method</Link></div>
        <hr />
        <div className="billinfo-section">
          <div className="billinfo-content flex"><span>Billing Information</span>
            {/* <Link to="../../app/updateBilling" style={{ marginLeft: "20px" }}>
                <div className="flex updateplan-content">
                  <img src={EditPlan} alt="EditPlan" />
                </div>
              </Link> */} 
          </div>
            <div className="flex" style={{marginBottom: "20px"}}>
              <input className="paymentsec-inputfield" type="text" name="name" value={name} onChange={handleName} placeholder="Name"/>
              <input className="paymentcvc-inputfield" type="text" name="email" value={email} onChange={handleEmail} placeholder="email"/>
            </div>
            {/* <input className="paymentcard-inputfield" type="text" name="country" value={country} placeholder="Country"/> */}
            <div className="flex" style={{marginBottom: "20px"}}>
            <input className="paymentsec-inputfield" type="text" name="address" value={addressline1} onChange={handleAddress1} placeholder="Address"/>
              <input className="paymentcvc-inputfield" type="text" name="phone" placeholder="Phone" value={phone} onChange={handlePhone} />
            </div>
            <div className="flex" style={{marginBottom: "20px"}}>
              <input className="paymentsec-inputfield" type="text" name="country" placeholder="Country" value={country} onChange={handleCountry}/>
              <input className="paymentcvc-inputfield" type="text" name="state" placeholder="State" value={state} onChange={handleState}/>
            </div>
            <div className="flex" style={{marginBottom: "20px"}}>
              <input className="paymentsec-inputfield" type="text" name="city" placeholder="City" value={city} onChange={handleCity} />
              <input className="paymentcvc-inputfield" type="text" name="ZIP" placeholder="ZIP" value={zip} onChange={handleZip}/>
            </div>
            
            <div className="flex planupdate-section">
                <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={updateBilling}>
                  <span className="saveplanbutton-context">Save</span>
                </button>
                <div className="cancelupdatecard-content"><a href="/app/mySubscription">Cancel</a></div>
              </div>
        </div>
        {invitepopup &&
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeInvitePopup}>
            <div className="subinviteupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Add seats</div>
                <div className="dismissSubfiles-content" onClick={closeInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/> 
              <div className="currentstatus-section">
                <div className="flex profilebill-content"><img style={{marginRight: "10px"}} src={ProfileUser} alt="ProfileUser" />Current Seats: {props.purchaseTeamMembers ? props.purchaseTeamMembers : 0} <span style={{marginLeft: "10px"}} className="seatsbill-content">+{count > 0 ? count : '0'} seat(s)</span></div>
                <div className="addbillseat-content">Add seats</div>
                <div className="flex teammember-section">
                  <input type="text" placeholder="0" className="focusteamcount-content" value={count > 0 ? count : '0'} />
                  <button className="decrementbutton-content" onClick={handleDecrement}>-</button>
                  <button className="incrementbutton-content" onClick={handleIncrement}>+</button>
                </div>
                <div className="seatno-content">$20 per seat</div>
              </div>
              <div className="totalcharges-content">Total charges</div>
              <hr />
              <div className="flex seatsec-content">
                <div className="seatprice-content">Seats pricing</div>
                <div className="seatsmon-content">{count > 0 ? count : '0'} Seat(s) * $20/month</div>
              </div>
              <div className="flex pricesection-content">
                <div className="pricebill-content">Due now</div>
                <div className="pricebill-content">${teamMember}/month</div>
              </div>
              <div className="flex">
                <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={handleTeamMemberSubscription}>
                  <span className="payupdatebutton-context">Pay</span>
                </button>
                <div className="cancelbill-content">Cancel</div>
              </div>
            </div>
          </div> 
        }
        {cancelpopup &&
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeCancelPopup}>
            <div className="subcancelupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Cancel your subscription</div>
                <div className="dismissSubfiles-content" onClick={closeCancelPopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <div>
                <div className="plancancel-content">{props.planActive == 'year' ? `Your plan will be canceled, along with any team member's seats.Closing your account will permanently remove your data and content. Are you sure you want to delete your account? This action cannot be undone.` : `Your plan will be canceled, along with any team member's seats, and remain available until the end of your billing cycle on ${periodEnd(props.currentPeriodEnd)}.`}</div>
                {props.planActive !== 'year' ? <div className="plancancel-content">Please note, closing your account will permanently remove your data and content. Are you sure you want to delete your account? This action cannot be undone.</div> : ``}
                <div className="flex justify-end cancellastfile-section">
                  <div className="cancelbillinfo-content" onClick={closeCancelPopup}>Cancel</div>
                  <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end"  onClick={() => props.planActive == 'year'? deleteUserAccount() : handleCancelSubscription()}>
                    <span className="savebutton-context">Confirm</span>
                  </button>
              </div>
              </div>
            </div>
          </div> 
        }
      </div>
      <ToastContainer />
    </>
    )}
    </>
  );
}
export default BillingInfo; 