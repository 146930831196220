import { React } from "react";
import "../../UserPricing/UserPricing.scss";
import "../../UserPricing/YearlyPlan/YearlyPlan.scss";
import chromeIcon from "../../../../assets/icons/chrome-svgrepo-com.svg";
import tickIcon from "../../../../assets/icons/Tick.svg";
import Facebook from "../../../../assets/icons/facebook-svgrepo-com (5).svg";
import Instagram from "../../../../assets/icons/instagram-2-1-logo-svgrepo-com (1).svg";
import Tiktok from "../../../../assets/icons/tiktok-svgrepo-com (1).svg";
import Youtube from "../../../../assets/icons/youTube-plan.svg";
import Xtwitter from "../../../../assets/icons/twitter-x-seeklogo.com-4.svg";

function MonthlyPlan(props) {
  return (
    <div className="plans">
      <div>
        <div className="">
          <h4>&#36;{props.priceListM.amount/100}/month</h4>
          <h6>1 seat included as part of the plan</h6>
          <div className="additionaluser-content">(+ $20 for each additional user)</div>
        </div>
      </div>
      <div className="flex">
        <div className="plan-details firstplandetails-content">
        <h5>Key features</h5>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Powerful Chrome extension</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={chromeIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>
            Save ads while browsing in one click
            </p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Save - Facebook, Instagram, TikTok, X, YouTube.</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Permanent saves</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Public and Private share stats</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Auto landing page screenshots when saving ads</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Auto Embed videos over 10 min</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Unlimited share links for Buckets, Cards, Files, Bookmarks</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>
              Unlimited Buckets, Cards, Regional screenshots, Screenshots, Post
              saves, Text and Save, Bookmarks, Tags
            </p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        </div>
        <div className="plan-details">
        <h5>Personalisation</h5>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90"> 
            <p>Focus Bucket</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Custom Personalized Buckets</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Reminder Email w/ Customize frequency/reminders.</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Dark and light view</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <h5>Upcoming Features</h5>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Kindle book & highlights, Focus Kindle Books</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            {/* <img src={tickIcon} alt="chrome-icon" /> */}
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Brand Filters</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            {/* <img src={tickIcon} alt="chrome-icon" /> */}
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Collaborative comments with emojis</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            {/* <img src={tickIcon} alt="chrome-icon" /> */}
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1  width-90">
            <p>Image Markup</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            {/* <img src={tickIcon} alt="chrome-icon" /> */}
          </div>
        </div>
        </div>
      </div>
      <div className="socialinte-content">Social integrations</div>
      <div className="flex socialmedia-icons">
          <img src={Facebook} alt="socialmedia" />
          <img src={Instagram} alt="socialmedia" />
          <img src={Tiktok} alt="socialmedia" />
          <img src={Youtube} alt="socialmedia" />
          <img src={Xtwitter} alt="socialmedia" />
      </div>
      <div className="yearlysubscribe-content">
        <button className="subscribe-btn">{props.planActive == 'month' ? 'Subscribed' : 'Continue'}</button>
      </div>
    </div>
  );
}
export default MonthlyPlan;
