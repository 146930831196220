import {React,Fragment,useState} from "react";
import Masonry from 'react-smart-masonry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faNoteSticky, faPencil,faTrash,faFolder,faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from '@headlessui/react';
import SingleCard from "./singleCard";
import SingleBucket from "../SingleBucket";
import axios from "axios";
import { useEffect } from "react"; 
import { useParams } from "react-router-dom";
import moment from "moment";
// import {ReactComponent as CalenderIcon} from "../../assets/icons/calendarIcon.svg";
import compactCardIcon from "../../assets/icons/gridcard.svg";
import { useOutletContext } from "react-router-dom";
import CreateBucket from "../CreateBucket";
import EditIcon from '../../assets/icons/edit.svg';
import DeleteIcon from '../../assets/icons/delete.svg';
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import CalenderIcon from '../../assets/icons/calendar.svg';
import FolderBucket from '../../assets/icons/folderbucket.svg';
import FileBucket from '../../assets/icons/fileBucket.svg';
import ThreeDots from '../../assets/icons/threeDots.svg';
import Arrow from '../../assets/icons/arrow.svg';
import ListView from '../../assets/icons/listView.svg';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareCard from "./shareCard";
import BlackDismiss from '../../assets/icons/dismissButton.svg'
import MoveModal from "./includes/MoveModal";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };

function StarredCard(){
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [subBucket,setSubBucket] = useState([]);
  const [cardList,setCardList] = useState([]);
  const [openMove, setOpenMove] = useState(false);
  const [moveModal,setMoveModal]=useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [popup,setPopup] = useState(false);
  const [bucketCreated,setBucketCreated] = useState(false);
  const [cardCreated,setCardCreated] = useState(false);
  const [bucketOperation,setBucketOperation] = useState('Add');
  const [selectedItems, setSelectedItems] = useState([]);
  let [isOpens, setIsOpen] = useState(false);
  const [BucketSharePopup,setBucketSharePopup]=useState(false)
  const [shareBucketId,setShareBucketId]=useState('')
  let [isOpenss, setIsOpens] = useState(false);
  const [isOpen] = useOutletContext();
  const [buckets, setBuckets] = useState([]);
  const [appTheme,setAppTheme]=useState("")
  const bucketId = useParams();
  console.log(bucketId,"bucketId");
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);
  function closeModal() {
    setIsOpen(false)
  }

  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }
    const filterDropDown = [
        { name: 'Created Date', href: '#' },
        { name: 'Recent Activity', href: '#' },
        { name: 'Ascending Order', href: '#' },
        { name: 'Descending Order', href: '#' },
        // { name: 'Manual', href: '#' },
      ]

      let cardFilterv = '';
      if (!localStorage.getItem("cardFilter")) {
        localStorage.setItem('cardFilter','Created_Date');
        cardFilterv = 'Created_Date';
      }else{
        cardFilterv = localStorage.getItem("cardFilter");
      }
      console.log(cardFilterv,"cardFilterv");
      const [cardFilterDefault,setCardFilterDefault] = useState(cardFilterv);

      useEffect(() => {
        // Function to fetch data from the API and update the state
        const token = localStorage.getItem('token');
        const fetchBuckets = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API}buckets/user-buckets`,{
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }); // Replace this with the actual API endpoint
            setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        if (token) {
          fetchBuckets();
        }
      }, []);
      const MoveCard = async () => {
        setMoveModal(true)
        setOpenMove(true);
      };
     
      const closeMove = () => {
        // setIsBucketOpen(false);
        setSelectedItems([])
        setMoveModal(false)
        setOpenMove(false);
        // setselectedBucketId("");
        // setSearchBucket("");
      }
      useEffect(()=>{
        // api url
        const fetchData = async () => {
         const token = localStorage.getItem('token');
         setIsLoadings(true);
          axios.post(`${process.env.REACT_APP_API}cards/recent-swiped`, {"page": currPage,"limit": 8},{
              headers: {
              Authorization: `Bearer ${token}`,
            }
            })
            .then(function (response) {
              setIsLoading(false);
                console.log(response.data.data, "<<<");
                if (!response.data.data.length) {
                  setLastList(true);
                  setIsLoadings(false);
                  return;
                }
                setPrevPage(currPage);
                // setUserList([...userList, ...response.data.data]);
                setCardList([...cardList, ...response.data.data]);
                // setTotalCards(response.data.data.totalCards);
                setIsLoadings(false);
              // reorder(response.data.data.slice(0,response.data.data.length),4);
            })
          .catch(function (error) {
            console.log(error);
          });
        }
       
        const handleScroll = () => {
          const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
          console.log(scrollTop, clientHeight, scrollHeight, "scrollTop, clientHeight, scrollHeight window")
          console.log(parseInt(scrollTop), parseInt(clientHeight), parseInt(scrollHeight), "scrollTop, clientHeight, scrollHeight window")
          if (parseInt(scrollTop) + parseInt(clientHeight) === parseInt(scrollHeight)) {
            setCurrPage(currPage + 1);
          }
        };

        if (!lastList && prevPage !== currPage) {
          fetchData();
          // console.log(listInnerRef,"listInnerRef");
        }
         
        window.addEventListener("scroll", handleScroll);

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };

     
     },[bucketCreated, cardCreated, currPage, lastList, prevPage, cardList, isLoadings])


     const reorder = (arr, columns) =>{
      // READ HERE
      // this is the magic
      // re-order the array so the "cards" read left-right
      // cols === CSS column-count value
      
      const cols = columns;
      const out = [];
      let col = 0;
      while(col < cols) {
          for(let i = 0; i < arr.length; i += cols) {
              let _val = arr[i + col];
              if (_val !== undefined)
                  out.push(_val);
          }
          col++;
      }
      console.log(out,"out");
      setCardList(out);
  }
      // const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
      const [filtersText, setFiltersText] = useState((cardFilterv == 'Created_Date') ? filterDropDown[0].name : (
        cardFilterv == 'Descending_Order' ?  filterDropDown[3].name : (
          cardFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
            cardFilterv == 'Recent_Activity' ? filterDropDown[1].name : ""
          )
        )
      ));
      
      const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
          setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
        } else {
          setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item !== value)
          );
        }
      };

      console.log('Selected Items:', selectedItems);
      const deleteCard = async () => {
        setIsOpens(true);
      };

       const handleDeleteCard = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        try {
          const response = await axios.delete('https://prdapi.zimzee.com/api/cards/delete',
          {
            data: {deletedIds:selectedItems},
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          console.log(response);
          setIsLoading(false);
          toastify("success", response.data.message);
          setSelectedItems([]);
          closeCardModal();
          cardUpdate();
        } catch (error) {
          console.error(error);
          closeCardModal();
          setIsLoading(false);
          toastify("error", error.response.data.message );
        }
     }

      const handleFilterText = (text) => {
          // setFiltersText(text);
          console.log(text,"handlefiltertext");
          setIsLoading(true);
          setFiltersText(text);
          const filterValues = (text == 'Created Date') ? "Created_Date" : (
            text == 'Descending Order' ?  "Descending_Order" : (
              text == 'Ascending Order' ? "Ascending_Order" : (
                text == 'Recent Activity' ? "Recent_Activity" : ""
              )
            )
          );
          localStorage.setItem('cardFilter',filterValues);
          setCardFilterDefault(filterValues);
          const token = localStorage.getItem('token');

          axios.post(`${process.env.REACT_APP_API}cards/recent-swiped`,{"filterValue":filterValues},{
              headers: {
              Authorization: `Bearer ${token}`,
            }
            })
            .then(function (response) {
              setIsLoading(false);
              setCardList(response.data.data);
              // setBucketList(response.data.data.bucketList);
              // setTotalBuckets(response.data.data.total);
              console.log(cardList,"cardList Changess for deployment changes");
            })
          .catch(function (error) {
            console.log(error);
          });

        }
    const bucketNav = [
      { icon: faTrash, name: "Select All", href: "#" },
      { icon: faTrash, name: "Unselect All", href: "#" },  
      // {icon:faPencil, name: 'Edit Name', href: '#' },
        // {icon:faNoteSticky, name: 'Create New Card', href: '#' },
        // {icon:faTrash, name: 'Delete', href: '#' },
        // {icon:faFolder, name: 'Customize New Card', href: '#' },
      ]

      const handleAllCardClick = (text) => {
        if (text == "Select All") {
          var checkboxes = document.querySelectorAll(
            '.cardheight-content input[type="checkbox"]'
          );
          var checkedValues = []; // Array to store the values of checked checkboxes
    
          checkboxes.forEach(function (checkbox) {
            if (!checkbox.checked) {
              var checked = true; // Replace with your desired checked state
              checkbox.checked = checked;
              if (checkbox.checked) {
                checkedValues.push(checkbox.value);
                setSelectedItems((prevSelectedItems) => [
                  ...prevSelectedItems,
                  checkbox.value,
                ]);
              }
            } else {
              var checked = false; // Replace with your desired checked state
              checkbox.checked = checked;
              setSelectedItems([]);
            }
          });
        } else if (text == "Unselect All") {
          var checkboxes = document.querySelectorAll(
            '.cardheight-content input[type="checkbox"]'
          );
          var checkedValues = []; // Array to store the values of checked checkboxes
    
          checkboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
              var checked = false; // Replace with your desired checked state
              checkbox.checked = checked;
              setSelectedItems([]);
            }
          });
        }
      };

      const createBucket = async (name) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
         try {
           const response = await axios.post('https://prdapi.zimzee.com/api/buckets/add/', { name,userId:userData._id,parentId:bucketId.id },
           {
             headers: {
                 Authorization: `Bearer ${token}`,
               }
           }
           );
           console.log(response);
         } catch (error) {
           console.error(error);
         }
       };
       const createSubBucket = async (event) => {
          event.preventDefault();
          const name = event.target.name.value; // Get the name value from the form
          await createBucket(name); 
          setPopup(false);
        };
        const closeSharePop=()=>{
          console.log("close share pop")
          setBucketSharePopup(false)
        }
        const handleSharePop=(id)=>{
          console.log(id,"share card id")
          setShareBucketId(id)
          setBucketSharePopup(true)
        }
      function createSub(){
        // setPopup(false);
        setBucketOperation('subbucket');
        setPopup(true)
      }

      function closePopup(){
          setPopup(false);
      }

      function cardUpdate(){
        // setBucketCreated(true);
        setLastList(false);
        setCurrPage(1);
        setPrevPage(0);
        setCardList([]);
        setCardCreated(!cardCreated);
      }
      
      function bucketUpdate(){
        // setBucketCreated(true);
        setBucketCreated(!bucketCreated);
      }
    
      function getUrl(type){
        if(type === 'list'){
          return `/app/buckets/${bucketId.id}`
        }else{
          return `/app/cardlist/${bucketId.id}`
        }
        
     } 
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return (
      <>
        {
          isLoading ? (
            <div className='App-loading'>
              <div className='App-logo' title='Zimzee' />
            </div>
            )
        :  
        (
          <div className="subBuckets-whole listingCards">
            <div className="cards">

            <div className="cards-filter cardsspacing-content flex justify-between">
                    <div className="flex items-center">
                        <div className="card-count flex items-center">
                            <h4 className="cardssubBuckets-content">Cards</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{cardList?.length}</span>
                        </div>
                        {BucketSharePopup &&
             <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeSharePop}>
             <div className="subinviteupload-section" onClick={(e)=>e.stopPropagation()}>
               <div className="teaminvitesection flex">
                 <div className="teammembers-content">Share</div>
                 <div className="dismissSubfiles-content" onClick={closeSharePop}><img src={BlackDismiss} alt="dismissButton" /></div>
               </div>
             <ShareCard shareBucketId={shareBucketId} closeSharePop={closeSharePop}  />
             </div>
             </div>
            
             }
                        <Menu as="div" className="relative px-2">
                            <Menu.Button
                                className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 "
                            >
                                {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                                <img src={ThreeDots} alt="threeDots" />
                            </Menu.Button>
                            <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {bucketNav.map((item) => (
                                        <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <a
                                            href={item.href}
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block py-2 px-4 text-sm text-gray-700'
                                            )}
                                            onClick={() => {
                                              handleAllCardClick(item.name);
                                            }}
                                            >
                                                <span className="pr-2"><FontAwesomeIcon className="text-[#797979]" icon={item.icon} /></span>
                                            <span className="text-black">{item.name}</span>
                                            </a>
                                        )}
                                        </Menu.Item>
                                    ))}
                                    </Menu.Items>
                                </Transition>
                        </Menu>
                        {selectedItems.length > 0 ?
                <img
                src={appTheme==="light"?DeleteIcon:LightDelete}
                alt="deleteIcon"
                className="px-3"
                onClick={() => deleteCard()}
              />
              :
              <img
              src={appTheme==="light"?LightDelete:DeleteIcon}
              alt="deleteIcon"
              className="px-3"
              // onClick={() => deleteCard()}
            />
              }
                        <Transition appear show={isOpenss} as={Fragment}>
                          <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                              <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-out duration-300"
                                  enterFrom="opacity-0 scale-95"
                                  enterTo="opacity-100 scale-100" 
                                  leave="ease-in duration-200"
                                  leaveFrom="opacity-100 scale-100"
                                  leaveTo="opacity-0 scale-95"
                                >
                                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                    <Dialog.Title
                                      as="h3"
                                      className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                      Delete
                                    </Dialog.Title>
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-500">
                                        Are you sure you want to delete?
                                      </p>
                                    </div>

                                    <div className="mt-4">
                                      <button style={{background:'#FF6600',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => handleDeleteCard()}
                                      >
                                        Yes
                                      </button>
                                      <button style={{background:'#000',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                                        type="button"
                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeCardModal}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </Dialog.Panel>
                                </Transition.Child>
                              </div>
                            </div>
                          </Dialog>
                        </Transition>
                        {/* <FontAwesomeIcon className="transform rotate-45 px-3" icon={faArrowUp } /> */}
                        <img src={Arrow} alt="arrow" className="px-3" onClick={MoveCard}/>
                    </div>
                    <div className="filterHolder flex items-center justify-end">
                        {/* <div className="filter display-content"> 
                            <a className="viewGrid" href={getUrl('list')}>                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                            </a>
                            <a className="viewGrid" href={getUrl('grid')}>                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a>
                        </div> */}
                        
                        {/* <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                                  >
                                  <strong className="createdtext-content pr-4">{filtersText}</strong>
                                  <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                              </a>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                              {filterDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (

                                    <a
                                      onClick={()=>{handleFilterText(item.name)}}
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu> */}
                    </div>
                </div>
            </div>
            {/* Single Card */}
            <Masonry breakpoints={breakpoints}
                columns={{ mobile: 2, tablet: 3, desktop: 4 }}
                gap={{ mobile: 20, tablet: 15, desktop: 15 }}>
                  {cardList?.map((card)=>{
                    // console.log(card);
                    const sharedWithObjects = card?.shared
                    ? card.shared
                      .filter(shares => shares.sharedWith != null)
                      .map(shares => shares.sharedWith)
                    : [];
                    return <SingleCard key={card._id} handleSharePop={handleSharePop}  sharedBy={[]}
                    sharedUsers={sharedWithObjects}   starred={card.starred} buckets={buckets} cardUpdate={cardUpdate} cardId={card._id} cardType={card.cardType} sharedLength ={card.shared.length} title={card.title} content ={card.content} note={card.note} tags={card.tags} updated={card.updatedAt} postDetail={card.postDetail? card.postDetail : ""} handleCheckboxChange={handleCheckboxChange} />
                  })}
             </Masonry>
             {isLoadings && (
                <Masonry breakpoints={breakpoints}
                columns={{ mobile: 2, tablet: 3, desktop: 4 }}
                gap={{ mobile: 20, tablet: 15, desktop: 15 }}>
                    <div className="cardHolder rounded-[14px] overflow-hidden break-inside" style={{height:"330px"}}>
                      <div className="cardheight-content">
                            <div className="flex justify-between w-full px-3 pt-3">
                              <div className="inputCheck">
                                  <input type="checkbox"/>
                              </div>
                              <div className="flex">
                                <Skeleton />
                              </div>
                          </div>
                          <div className="cardHeader flex items-center pb-1 px-3">
                              <div className="logo w-[30px] rounded">
                                    <Skeleton />
                              </div>
                              <div className="Cardtitle w-3/4 pl-3">
                              <Skeleton />
                              </div>
                              <div className="iconsRight flex items-center">
                              <Skeleton />
                              </div>
                          </div>
                          <p></p>
                          <div className="cardContent cardListContent w-full">
                          <Skeleton />
                            </div>
                        </div>
                        <div className="px-3">
                        <Skeleton />
                          </div>
                          <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                          <Skeleton />
                            </div>
                            <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                            <Skeleton />
                              </div>
                      </div>
                      <div className="cardHolder rounded-[14px] overflow-hidden break-inside" style={{height:"330px"}}>
                        <div className="cardheight-content">
                              <div className="flex justify-between w-full px-3 pt-3">
                                <div className="inputCheck">
                                    <input type="checkbox"/>
                                </div>
                                <div className="flex">
                                  <Skeleton />
                                </div>
                            </div>
                            <div className="cardHeader flex items-center pb-1 px-3">
                                <div className="logo w-[30px] rounded">
                                      <Skeleton />
                                </div>
                                <div className="Cardtitle w-3/4 pl-3">
                                <Skeleton />
                                </div>
                                <div className="iconsRight flex items-center">
                                <Skeleton />
                                </div>
                            </div>
                            <p></p>
                            <div className="cardContent cardListContent w-full">
                            <Skeleton />
                              </div>
                          </div>
                        <div className="px-3">
                        <Skeleton />
                          </div>
                          <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                          <Skeleton />
                            </div>
                            <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                            <Skeleton />
                              </div>
                      </div>
                      <div className="cardHolder rounded-[14px] overflow-hidden break-inside" style={{height:"330px"}}>
                      <div className="cardheight-content">
                            <div className="flex justify-between w-full px-3 pt-3">
                              <div className="inputCheck">
                                  <input type="checkbox"/>
                              </div>
                              <div className="flex">
                                <Skeleton />
                              </div>
                          </div>
                          <div className="cardHeader flex items-center pb-1 px-3">
                              <div className="logo w-[30px] rounded">
                                    <Skeleton />
                              </div>
                              <div className="Cardtitle w-3/4 pl-3">
                              <Skeleton />
                              </div>
                              <div className="iconsRight flex items-center">
                              <Skeleton />
                              </div>
                          </div>
                          <p></p>
                          <div className="cardContent cardListContent w-full">
                          <Skeleton />
                            </div>
                        </div>
                        <div className="px-3">
                        <Skeleton />
                          </div>
                          <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                          <Skeleton />
                            </div>
                            <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                            <Skeleton />
                              </div>
                      </div>
                      <div className="cardHolder rounded-[14px] overflow-hidden break-inside" style={{height:"330px"}}>
                      <div className="cardheight-content">
                            <div className="flex justify-between w-full px-3 pt-3">
                              <div className="inputCheck">
                                  <input type="checkbox"/>
                              </div>
                              <div className="flex">
                                <Skeleton />
                              </div>
                          </div>
                          <div className="cardHeader flex items-center pb-1 px-3">
                              <div className="logo w-[30px] rounded">
                                    <Skeleton />
                              </div>
                              <div className="Cardtitle w-3/4 pl-3">
                              <Skeleton />
                              </div>
                              <div className="iconsRight flex items-center">
                              <Skeleton />
                              </div>
                          </div>
                          <p></p>
                          <div className="cardContent cardListContent w-full">
                          <Skeleton />
                            </div>
                        </div>
                        <div className="px-3">
                        <Skeleton />
                          </div>
                          <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                          <Skeleton />
                            </div>
                            <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                            <Skeleton />
                              </div>
                      </div>
                  </Masonry>
              )}
          </div>
        )
    }
    {popup === true ? <CreateBucket bucketUpdate={bucketUpdate} bucketOperation={bucketOperation} parent={bucketId.id} setPopup = {setPopup} /> : null}
    {moveModal?<MoveModal openMove={openMove} closeModal={closeMove} selectedItems={selectedItems} cardUpdate={cardUpdate} buckets={buckets}/>:null}
    
    <ToastContainer />
    </>
    )
}

export default StarredCard;