import { React, Fragment, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./bookmarklist.scss";
import magnifier from "../../assets/icons/magnifyGlass.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
import EditIcon from "../../assets/icons/edit.svg";
import Notes from "../../assets/icons/Clipboard.svg";
import PurpleLink from "../../assets/icons/purpleLink.svg";
import Star from "../../assets/icons/Star.svg";
import parse from "html-react-parser";
import Tag from "../../assets/icons/tag2Icon.svg";
import Share from "../../assets/icons/Share 1.svg";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import resizeIcon from "../../assets/icons/resizeIcon.svg";
import Profile3 from "../../assets/icons/profile3.png";
import copyIcon from "../../assets/icons/copyIcon.svg";
import linkIcon from "../../assets/icons/link_icon.svg";
import Shareicon from "../../assets/icons/gotourl.svg";
import BookmarksIcon from "../../assets/icons/BookmarksList.svg";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faCheck,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import shareIcon from "../../assets/icons/sharing_icon.svg";
import CardImage from "../../assets/images/cardDetailImage.png";
import Bookmark1 from "../../assets/images/Bookmarks - Image 1.png";
import Union from "../../assets/icons/Union-19.svg";
import SubStar from "../../assets/icons/subStar.svg";
import Bookmark2 from "../../assets/images/Bookmarks - Image 4.png";
import Bookmark3 from "../../assets/images/Bookmarks - Image 5.png";
import Bookmark4 from "../../assets/images/Bookmarks - Image 6.png";
import Bookmark5 from "../../assets/images/Bookmarks - Imgae 2.png";
import { Menu, Dialog, Transition } from "@headlessui/react";
import BookmarkImage from "../../assets/images/Bookmarks - Big image.png";
import BookmarksShared from "../../assets/icons/bookmarksShared.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import linksIcon from "../../assets/icons/linksicon.svg";
import Bookmark from "../../assets/icons/Bookmarks - 1.svg";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListView from "../../assets/icons/listView.svg";
import compactCardIcon from "../../assets/icons/compactCardIcon.svg";
import moment from "moment";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";

function CardList({ shared, bookmarkCards, cardsRefresh, handleSharePop }) {
  const [isActive, setIsActive] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  let [isOpenss, setIsOpens] = useState(false);
  const [searchBucket, setSearchBucket] = useState("");
  const [titleState, setTitleState] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [cardId, setCardId] = useState("");
  const [cardTitle, setCardTitle] = useState("");
  const navigate = useNavigate();
  const handleStarClick = (cardId, star) => {
    console.log(star, "handle star click star");
    console.log(cardId, "handle star click star");
    updateCard(cardId, star == true ? false : true);
  };
  const bucketNav = [
    { icon: faTrash, name: "Delete", href: "javascript:void(0)" },
    { icon: faPencil, name: "Edit Name", href: "javascript:void(0)" },
  ];
  const handleCardShare = (id) => {
    console.log("card share click");
    handleSharePop(id);
  };
  const handleCardMenu = (name, title, id) => {
    setCardId(id);
    if (name === "Delete") {
      setIsOpens(true);
    }
    if (name === "Edit Name") {
      setSearchBucket(title);
      setOpenEdit(true);
    }
  };
  const handleCardLink = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://prdapi.zimzee.com/api/cards/public-url",
        {
          itemId: id,
          isPublic: true,
          itemType: "Card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.data) {
        // Create a temporary input element to copy the link to clipboard
        const tempInput = document.createElement("input");
        tempInput.value = response?.data?.data;

        // Append the input element to the DOM
        document.body.appendChild(tempInput);

        // Select the input element's text and copy it to clipboard
        tempInput.select();
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can show a message to indicate that the link has been copied
        console.log(response.data.message);
        toastify("success", response.data.message);
      }
    } catch (error) {
      console.error(error?.response?.data?.message, "error msg");
      toastify("error", error?.response?.data?.message);
    }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const closeEditModal = () => {
    setOpenEdit(false);
  };
  const handleBucketInputChange = (event) => {
    setSearchBucket(event.target.value);
  };
  const handleOnChangeTitle = (e) => {
    setCardTitle(e.target.value);
  };
  const handleEditTitle = (title) => {
    setTitleState(false);
    setCardTitle(title);
    setTimeout(() => {
      document.getElementsByClassName("carddetailfile-content")[0].focus();
    }, 100);
  };
  const handleCardTitle = (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        { cardId: id, title: cardTitle },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      cardsRefresh();
      setTitleState(true);
    } catch (error) {
      console.error(error);
    }
  };
  function closeCardModal() {
    setIsOpens(false);
  }
  const handleUpdateCardMenu = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        {
          cardId: cardId,
          title: searchBucket,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        setOpenEdit(false);
        cardsRefresh();
      }

      // cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };
  const handleDeleteCard = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://prdapi.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: cardId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardsRefresh();
      closeCardModal();
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };

  const updateCard = async (cardId, star) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        { cardId: cardId, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      cardsRefresh();
    } catch (error) {
      console.error(error);
    }
  };
  const handleNotesMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleNotesMouseLeave = () => {
    setTooltipVisible(false);
  };
  const handleIframeUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="cardList Bookmarklist dark:bg-[#051534]">
      <div className="row">
        <Tabs>
          <TabList>
            <div className="search-tab flex item-center ">
              <div className="w-full">
                <label
                  htmlFor="search"
                  className="dark:border-white-700 sr-only"
                >
                  Search
                </label>
                <div className="relative">
                  <input
                    id="search"
                    name="search"
                    className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                    placeholder="Search"
                    type="search"
                  />
                  <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center pr-2 ">
                    <img
                      className="magnifier"
                      src={magnifier}
                      alt="magnifying-glass"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-scroll ">
              {bookmarkCards &&
                bookmarkCards?.map((card, index) => {
                  return (
                    <Tab key={index}>
                      <div className="card-img">
                        <img
                          className="card-imge"
                          src={
                            card?.content?.imageUrl[0]?.url
                              ? card?.content?.imageUrl[0]?.url
                              : BookmarksIcon
                          }
                          alt="editicon"
                        />
                      </div>
                      <div
                        className="card-txt-content"
                        style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                      >
                        <p className="card-text">{card?.title}</p>
                        <p className="card-link">
                          {card?.content?.sourceUrl?.split("?")[0]}{" "}
                        </p>
                      </div>
                      <Menu as="div" className="relative ml-[auto] my-[auto]">
                        <Menu.Button className="relative inline-flex items-center text-sm font-semibold text-gray-900 bookmarksingledot-content">
                          {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                          <img
                            src={ThreeDots}
                            alt="threeDots"
                            className="tab-menu menuthree-icon"
                            style={{ paddingTop: "5px" }}
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="bookmarklist-menu absolute right-0 z-10  w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {bucketNav.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                    onClick={() =>
                                      handleCardMenu(
                                        item.name,
                                        card?.title,
                                        card?._id
                                      )
                                    }
                                  >
                                    <span className="pr-2">
                                      <FontAwesomeIcon
                                        className="text-[#797979]"
                                        icon={item.icon}
                                      />
                                    </span>
                                    <span className="text-black">
                                      {item.name}
                                    </span>
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      {/* <img
                        className="tab-menu menuthree-icon"
                        src={ThreeDots}
                        alt="menu"
                      /> */}
                    </Tab>
                  );
                })}
            </div>
          </TabList>

          {bookmarkCards &&
            bookmarkCards?.map((card, index) => (
              <TabPanel key={index}>
                <div className="panel-content"> 
                  <div className="flex mb-3">
                    {titleState ? (
                      // <h5 className="buckettitle-textcontent">{card?.title}</h5>
                      <div className="tooltip">
                      <span className="tooltip-text bucketshrename">
                        <p className="block text-backgroundshare">
                          {card?.title}
                        </p>
                      </span>
                      <h6 className="buckettitle-textcontent">
                        {card?.title}
                      </h6>
                    </div>
                    ) : (
                      <>
                        <input
                          type="text"
                          value={cardTitle}
                          onChange={handleOnChangeTitle}
                          className="carddetailfile-content"
                          style={{
                            fontSize: "18px",
                            outline: "none",
                            border: "none",
                            padding: "0 10px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        />
                      </>
                    )}
                    {/* <h5>{card?.title}</h5> */}
                    {titleState ? (
                      <img
                        className="edit-cardname"
                        src={EditIcon}
                        alt="editIcon"
                        onClick={() => handleEditTitle(card?.title)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        icon={faCheck}
                        onClick={() => handleCardTitle(card?._id)}
                        color="gray"
                      />
                    )}
                    {/* <img
                      className="edit-cardname"
                      src={EditIcon}
                      alt="editicon"
                    /> */}
                    <img
                      onClick={() => navigate(`/app/card-detail/${card?._id}`)}
                      className="resizeicons-imgcontent ml-[40px]"
                      src={resizeIcon}
                      style={{ width: "20px" }}
                    />
                    <div className="cardmenu-icons flex">
                      <div className="bookmark-tooltip-container m-auto">
                        {card?.note?.length != 0 ? (
                          <img
                            className="tooltip-icon bookmarkiconsingle-content clipicon-content"
                            src={clipboardNotes}
                            alt="clipboardNotes"
                            style={{ width: "20px", paddingTop: "5px" }}
                            onMouseEnter={handleNotesMouseEnter}
                            onMouseLeave={handleNotesMouseLeave}
                          />
                        ) : (
                          <img
                            className="tooltip-icon bookmarkiconsingle-content clipicon-content"
                            src={Notes}
                            alt="clipboardNotes"
                            style={{ width: "20px", paddingTop: "5px" }}
                          />
                        )}
                        {isTooltipVisible && card?.note && (
                          <div className="bookmark-tooltip">
                            {parse(card?.note)}
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() =>
                          handleStarClick(card?._id, card?.starred)
                        }
                      >
                        {" "}
                        {card?.starred === true ? (
                          <img
                            src={Union}
                            alt="subStar"
                            style={{ width: "20px" }}
                          />
                        ) : (
                          <img
                            className="star-img"
                            src={SubStar}
                            alt="subStar"
                          />
                        )}
                      </button>
                      {/* <img className="edit-cardname" src={Tag} alt="editicon" /> */}
                      <img
                        className="edit-cardname"
                        src={Share}
                        alt="editicon"
                        onClick={() => handleCardShare(card?._id)}
                      />
                    </div>
                  </div>
                  <div className="flex mt-[10px] items-center">
                    <a
                      href={card?.content?.sourceUrl?.includes("youtube") 
                        ? card?.content.sourceUrl 
                        : card?.content?.sourceUrl?.split("?")[0]}
                      target="_blank"
                    >
                      {" "}
                      <p className="last-updated flex update-link">
                        {" "}
                        <img
                          className="link-imge"
                          src={PurpleLink}
                          alt="editicon"
                        />
                        {card?.content?.sourceUrl?.includes("youtube") 
                        ? card?.content.sourceUrl 
                        : card?.content?.sourceUrl?.split("?")[0]}
                      </p>
                    </a>
                    <br />
                    <p className="last-updated flex ml-[40px]">
                      {" "}
                      <img
                        className="link-imge"
                        src={Bookmark}
                        alt="editicon"
                      />
                      {moment(card.createdAt).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                    <div className="ml-auto sharedAction flex items-center">
                      {/* <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl public-share">
                        <p>Publicly Shared</p>
                        <span className="bg-white  rounded-[50%] p-1 ml-2">
                          <img src={copyIcon} alt="copy-icon" />
                        </span>
                      </div> */}
                      <div className="w-6 mx-4 ">
                        <img
                          className="darkicon-bc"
                          src={linkIcon}
                          onClick={() => handleCardLink(card?._id)}
                          alt="link-icon"
                        />
                      </div>
                      <div>
                        {/* <img
                          className="darkicon-bc"
                          src={shareIcon}
                          alt="shareIcon"
                        /> */}
                        <div className="shareCount col-span-2 ml-3">
                          <div className="profile-imgcontent ">
                            {card?.shared
                              ? card.shared
                                  .filter((shares) => shares.sharedWith != null)
                                  .map((shares) => shares.sharedWith)
                                  ?.slice(0, 3)
                                  .map((user, index) => (
                                    <div className="tooltip">
                                      <span className="tooltip-text">
                                        {/* <p>Share By &nbsp;{sharedBy}</p> */}
                                        <p>
                                          {" "}
                                          Share with : &nbsp;
                                          {card.shared
                                            .filter(
                                              (shares) =>
                                                shares.sharedWith != null
                                            )
                                            .map((shares) => shares.sharedWith)
                                            .map((user, index) => (
                                              <span
                                                key={index}
                                                style={{
                                                  display: "inline-flex",
                                                }}
                                              >
                                                {user?.fullName}{" "}
                                                {index <
                                                card?.shared?.filter(
                                                  (shares) =>
                                                    shares.sharedWith != null
                                                )?.length -
                                                  1
                                                  ? ","
                                                  : ""}
                                              </span>
                                            ))}{" "}
                                        </p>
                                      </span>
                                      <>
                                        {user?.imageUrl ? (
                                          <img
                                            src={user?.imageUrl}
                                            alt="profile1"
                                          />
                                        ) : (
                                          <Avatar
                                            name={`${user.fullName}`}
                                            size="25"
                                            round={true}
                                            className="profile-avatar"
                                          />
                                        )}
                                      </>
                                    </div>
                                  ))
                              : null}
                            {card?.shared?.filter(
                              (shares) => shares.sharedWith != null
                            )?.length > 3 && (
                              <div className="plus-share"> +</div>
                            )}
                            {/* <img src={Profile1} alt="profile1" />
                            <img src={Profile2} alt="profile2" />
                            <img src={Profile3} alt="profile3" /> */}
                            <br />
                          </div>
                          <p className="share-content dark:text-[#d3d3d3]">
                            {
                              card?.shared?.filter(
                                (shares) => shares.sharedWith != null
                              )?.length
                            }{" "}
                            Share(s)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-cardshare  flex mb--16">
                    {card?.tags.map((tag) => {
                      return (
                        <span className="tag-menu tagsname-textcontent bg-[#f7f8df]">
                          {tag.name}
                        </span>
                      );
                    })}

                    {/* <div className="shareCount col-span-2 ml-7">
                      <div className="profile-imgcontent">
                        <img src={Profile1} alt="profile1" />
                        <img src={Profile2} alt="profile2" />
                        <img src={Profile3} alt="profile3" />
                        <br />
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">
                        {shared?.length} Share(s)
                      </p>
                    </div> */}
                  </div>
                  {/* {card?.content?.html != "test" ? (
                    <p className="card-content">{card?.content?.html}</p>
                  ) : (
                    ""
                  )} */}

                  <iframe
                    className="frame-img"
                    src={card?.content?.sourceUrl}
                    style={{ width: "100%", height: "750px" }}
                    onClick={() => handleIframeUrl(card?.content?.sourceUrl)}
                  ></iframe>
                </div>
              </TabPanel>
            ))}
        </Tabs>
        <Transition appear show={isOpenss} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[9999]"
            onClose={closeCardModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete?
                      </p>
                    </div>

                    <div className="mt-4">
                      <button
                        style={{
                          background: "#FF6600",
                          width: "80px",
                          color: "#fff",
                          padding: "10px",
                          borderRadius: "50px",
                        }}
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleDeleteCard}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          background: "#000",
                          width: "80px",
                          color: "#fff",
                          padding: "10px",
                          borderRadius: "50px",
                        }}
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeCardModal}
                      >
                        No
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={openEdit} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[9999]"
            onClose={closeEditModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="test w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Name
                    </Dialog.Title>
                    <div
                      style={{ display: "block" }}
                      className="custom-list-dropdown"
                    >
                      <div className="position-relative">
                        <input
                          type="text"
                          placeholder="Edit Bookmark Name"
                          value={searchBucket}
                          onChange={handleBucketInputChange}
                        />
                      </div>
                      <button
                        style={{
                          filter:
                            "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                        }}
                        className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                        onClick={() => handleUpdateCardMenu(cardId)}
                      >
                        <span className="savebutton-context">Update</span>
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <ToastContainer />
    </div>
  );
}
export default CardList;
