import { React, Fragment, useState, useEffect, useRef } from "react";
import "../Bucket.scss";
import { Menu, Transition } from "@headlessui/react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import Avatar from "react-avatar";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Profile2 from "../../assets/icons/profile2.png";
import "./sharebucket.scss";
import DismissButton from "../../assets/icons/dismissButton.svg";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
function ShareCard({ closeSharePop, shareBucketId, cardsRefresh }) {
  const tagsInputRef = useRef("");
  const navigate = useNavigate();
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredOption, setHoveredOption] = useState(null);
  const [tags, setTags] = useState([]);
  const [invitepopup, setInvitePopup] = useState(false);
  const [email, setEmail] = useState([]);
  const [peopleAccess, setPeopleAccess] = useState([]);
  const [owner, setOwner] = useState({});
  const [permission, setPermission] = useState("Editor");
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [filtersText, setFiltersText] = useState(false);
  const [inputValueSet, setInputValueSet] = useState("");
  const [bucketRefresh, setBucketRefresh] = useState(false);
  const [AccessUpdate, setAccessUpdate] = useState(false);
  const filterDropDown = [
    { name: "Viewer", href: `javascript:void(0);` },
    { name: "Editor", href: `javascript:void(0);` },
    { name: "Remove Access", href: `javascript:void(0);` },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_API}team/peopleAccess-withItem`,
        {
          itemId: shareBucketId,
          itemType: "Card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response?.data?.data?.name, "tags list");
        setPeopleAccess(response?.data?.data?.membersWithAccess?.shared);
        setOwner(response?.data?.data?.owner);
        setAccessUpdate(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [AccessUpdate]);
  useEffect(() => {
    // api url

    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API}team/owner-team-members`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setBucketRefresh(false);
        console.log(response?.data?.data?.name, "tags list");
        setTagOptions(response?.data?.data?.teamMembers);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [bucketRefresh]);
  const closePop = () => {
    closeSharePop();
  };
  useEffect(() => {
    console.log(email, "list of emails");
    console.log(tags, "list of tags");
    console.log(permission, "permission given");
  }, [email, permission, tags]);
  useEffect(() => {
    if (tagOptions && tags.length == []) {
      var elements = document.getElementsByClassName("react-tagsinput-input");
      for (var i = 0; i < elements.length; i++) {
        elements[i].setAttribute("placeholder", "Add Team Members");
      }
    }
  }, [tagOptions, tags]);
  const changePermission = async (id, shareBucketId, permission) => {
    const token = localStorage.getItem("token");
    // const selectedPermission = permission || selectedPermissions[userId]; // Replace 'userId' with the actual user's ID
    if (permission === "Remove Access") {
      await axios
        .post(
          `https://prdapi.zimzee.com/api/team/revoke-item-access`,
          { temMemberId: id, itemId: shareBucketId },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          console.log(response?.data?.data?.name, "tags list");
          setBucketRefresh(true);
          cardsRefresh();
          toastify("success", response.data.message);
          setTagOptions(response?.data?.data?.teamMembers);
          setAccessUpdate(true);
        })
        .catch(function (error) {
          console.log(error);
          toastify("error", error?.response?.data?.message);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API}team/update-item-access`,
          {
            temMemberId: id,
            itemId: shareBucketId,
            permissions: permission,
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          console.log(response?.data?.data?.name, "tags list");
          setBucketRefresh(true);
          cardsRefresh();
          toastify("success", response.data.message);
          setTagOptions(response?.data?.data?.teamMembers);
        })
        .catch(function (error) {
          console.log(error);
          toastify("error", error?.response?.data?.message);
        });
    }
  };
  const handleAddMember = () => {
    // navigate("/app/settings?tab=teamMembers");
    navigate("/app/manageTeam");
  };
  const handleBucketShare = async () => {
    if (tags.length != 0) {
      const token = localStorage.getItem("token");

      await axios
        .post(
          `${process.env.REACT_APP_API}team/shareItem-withTeamMember`,
          {
            userEmails: email,
            itemId: shareBucketId,
            permissions: permission,
            itemType: "Card",
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          // closePop()

          console.log(response?.data?.data?.name, "tags list");
          toastify("success", response.data.message);
          setTagOptions(response?.data?.data?.teamMembers);
          cardsRefresh();
          setBucketRefresh(true);
          setAccessUpdate(true);
          setTags([]);
        })
        .catch(function (error) {
          console.log(error);
          toastify("error", error?.response?.data?.message);
        });
    } else {
      toastify("error", "Please select a team member");
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const newTag = selectedOption?.email;
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
        const newEmail = selectedOption?.email;
        setEmail([...email, newEmail]);
        handleTagsChange([...tags, newTag]);
        handleEmailChange([...email, newEmail]);
        handleBlur();
      }
    }
  };
  const closeInvitePopup = () => {
    setInvitePopup(false);
  };
  const handleBlur = () => {
    setIsTagOpen(false);
  };
  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };
  const handleEmailChange = (newTags) => {
    setEmail(newTags);
  };
  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };
  const handleFocus = (e) => {
    e.stopPropagation();

    tagsInputRef.current.focus();
    setIsTagOpen(true);
    console.log("on focus is woring", isTagOpen);
  };
  const handleFilterText = (text) => {
    setFiltersText(text);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div
      className="filesuploadpopup relative !z-[999]"
      style={{ height: "100%" }}
      onClick={closeSharePop}
    >
      <div
        className="subinviteupload-section"
        onClick={(e) => {
          console.log("parent clicked");
          setIsTagOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="teaminvitesection flex">
          <div className="teammembers-content">Share</div>
          <div className="dismissSubfiles-content" onClick={closeSharePop}>
            <img src={BlackDismiss} alt="dismissButton" />
          </div>
        </div>
        <hr />

        {tagOptions && tagOptions.length > 0 ? (
          <>
            <div className="teambuckets-content">
              Select the team members to share this card
            </div>
            <div className="flex items-baseline">
              <div className="pos-sharede">
                <div className="firstteambucket-content">
                  <p className="createdsubtext-content">
                    <div
                      className="flex teampopupbucket-content teampopupbucket-content-cursor"
                      onClick={handleFocus}
                    >
                      <div>
                        <TagsInput
                          ref={tagsInputRef}
                          type="text"
                          placeholder="Add Team members"
                          className=""
                          value={tags}
                          onChange={handleTagsChange}
                          inputValue={inputValueSet}
                          onChangeInput={(e) => setInputValueSet(e)}
                        />
                      </div>
                      {/* <div className=""><FontAwesomeIcon style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} onClick={handleFocus}/></div> */}
                    </div>
                  </p>
                </div>
                {isTagOpen && (
                  <div className="pos-dropdown">
                    <nav>
                      <div className="tab-scrolls tagscard">
                        <ul
                          className="dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tagOptions
                            .filter((option) => {
                              const inputValue = inputValueSet
                                .toLowerCase()
                                .trim();
                              const [searchFirstName, searchLastName] =
                                inputValue.split(" ");

                              return (
                                (searchFirstName &&
                                  option.firstName
                                    .toLowerCase()
                                    .startsWith(searchFirstName)) ||
                                (searchLastName &&
                                  option.lastName
                                    .toLowerCase()
                                    .startsWith(searchLastName)) ||
                                (inputValue.length === 1 &&
                                  (option.firstName
                                    .toLowerCase()
                                    .startsWith(inputValue) ||
                                    option.lastName
                                      .toLowerCase()
                                      .startsWith(inputValue))) ||
                                option.email.toLowerCase().includes(inputValue)
                              );
                            })
                            .map((option) => (
                              <li
                                key={option.email}
                                onMouseEnter={() => handleMouseEnter(option)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <div onClick={() => handleSelectChange(option)}>
                                  <div className="teamowner-content">
                                    {option.firstName} {option?.lastName}
                                  </div>
                                  <div className="teamemail-content">
                                    {option.email}
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </nav>
                  </div>
                )}
              </div>
              <div className="">
                <strong className="createdsubtext-content pr-4">
                  <div>
                    {/* <input 
                        type="text"
                        placeholder="Edit"
                        className=""
                        
                      /> */}
                    {tags.length > 0 ? (
                      <div className="flex teampopupbucket-content editor-dropdown">
                        <select
                          onChange={(e) => {
                            setPermission(e.target.value);
                          }}
                        >
                          <option value={"Editor"}>Editor</option>
                          <option value={"Viewer"}>Viewer</option>
                        </select>
                      </div>
                    ) : null}
                    {/* {isTagOpen && (
                      <div>
                        <nav>
                          <div className="tab-scrolls tagscard">
                            <ul
                              className="dropdown"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {tagOptions
                                .filter((option) => {
                                  const inputValue = inputValueSet
                                    .toLowerCase()
                                    .trim();
                                  const [searchFirstName, searchLastName] =
                                    inputValue.split(" ");

                                  return (
                                    (searchFirstName &&
                                      option.firstName
                                        .toLowerCase()
                                        .startsWith(searchFirstName)) ||
                                    (searchLastName &&
                                      option.lastName
                                        .toLowerCase()
                                        .startsWith(searchLastName)) ||
                                    (inputValue.length === 1 &&
                                      (option.firstName
                                        .toLowerCase()
                                        .startsWith(inputValue) ||
                                        option.lastName
                                          .toLowerCase()
                                          .startsWith(inputValue))) ||
                                    option.email
                                      .toLowerCase()
                                      .includes(inputValue)
                                  );
                                })
                                .map((option) => (
                                  <li
                                    key={option.email}
                                    onMouseEnter={() =>
                                      handleMouseEnter(option)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <div
                                      onClick={() => handleSelectChange(option)}
                                    >
                                      <div className="teamowner-content">
                                        {option.firstName} {option?.lastName}
                                      </div>
                                      <div className="teamemail-content">
                                        {option.email}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </nav>
                      </div>
                    )} */}
                  </div>
                </strong>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex">
              <div className="teambuckets-content">
                Add team members to share this card
              </div>
              <button
                style={{
                  filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                }}
                className="buttonpadding-content sharebucketbutton-content text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
                onClick={handleAddMember}
              >
                <FontAwesomeIcon
                  className="pl-[23px] pr-[17.73px]"
                  icon={fontIcons.faPlus}
                />
                <span className="createbutton-context">Add</span>
              </button>
            </div>
          </>
        )}
        {/* <div className="flex"> */}
        {/* <input className="w-full flex items-center rounded-3xl border-0 menusubitems-content teaminput-content" type="text" value="Add Team members"/> */}
        {/* <TagsInput value={tags} onChange={handleTagsChange}  /> */}
        {/* <FontAwesomeIcon className="fileicon-content" style={{  }} icon={faChevronDown} onClick={handleFocus}/> */}
        {/* </div> */}
        <div></div>
        <div className="">
          {/* <strong className="createdsubtext-content pr-4">  
                <div className="flex teampopupbucket-content">
                  <div>
                    <TagsInput
                      type="text"
                      placeholder="Add Team members"
                      className=""
                      value={tags} 
                      // onChange={handleTagsChange} 
                    />
                  </div>
                  <div className=""><FontAwesomeIcon style={{ fontSize: '12px', color: '#707070' }} icon={faChevronDown} onClick={handleFocus}/></div>
                </div>
              </strong>  */}
        </div>

        {/* for second screen*/}

        {/* </div> */}

        <div className="teamaccess-content">People with access</div>
        <div className="share-content">
          <div className="flex justify-between">
            <div className="flex share-details">
              <div>
              {owner?.imageUrl ? (
                    <img
                      className="profileteams-imgcontent"
                      src={owner?.imageUrl}
                      alt="Profile1"
                    />
                  ) : (
                    <Avatar
                      name={`${owner?.firstName} ${owner?.lastName}`}
                      size="55"
                      round={true}
                    />
                  )}
                {/* <img
                  className="profileteams-imgcontent"
                  src={Profile2}
                  alt="Profile1"
                /> */}
              </div>
              <div>
                <div className="teamowner-content">
                  {owner?.firstName} {owner?.lastName} (you)
                </div>
                <div className="teamemail-content">{owner?.email}</div>
              </div>
            </div>
            <div className="shareowner-content">Owner</div>
          </div>
          <div className="sharetop-section share-details">
            {peopleAccess.map((people) => {
              const personId = people?.sharedWith?._id;
              return (
                <>
                  {/* <div className="flex justify-between"> */}
                  {people?.sharedWith ? (
                    <div className="flex mt-[5px]">
                    <div className="flex items-center">
                        <div>
                        {people?.sharedWith?.imageUrl ? (
                              <img
                                className="profileteams-imgcontent"
                                src={people?.sharedWith?.imageUrl}
                                alt="Profile1"
                              />
                            ) : (
                              <Avatar
                                name={`${people?.sharedWith?.firstName} ${people?.sharedWith?.lastName}`}
                                size="50"
                                round={true}
                                className="mr-[12px]"
                              />
                            )}
                        </div>
                        <div>
                          <div className="teamowner-content">
                            {people?.sharedWith?.firstName}{" "}
                            {people?.sharedWith?.lastName}{" "}
                          </div>
                          <div className="teamemail-content">
                            {people?.sharedWith?.email}
                          </div>
                        </div>
                      </div>

                      <div className="justify-end shareowner-content">
                        <Menu
                          as="div"
                          className="relative desktop-section flex-shrink-0"
                        >
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                              <div
                                href="#"
                                className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                              >
                                <strong className="createdtext-content pr-4">
                                  {selectedPermissions[personId] ||
                                    people.permissions}
                                </strong>
                                <FontAwesomeIcon
                                  style={{ fontSize: "12px", color: "#707070" }}
                                  icon={faChevronDown}
                                />
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 dropdownshareteam-content origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {filterDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      onClick={() => {
                                        handleFilterText(item.name);
                                        setSelectedPermissions({
                                          ...selectedPermissions,
                                          [personId]: item.name, // Update selected permission for this person
                                        });

                                        // Call changePermission function here with the updated permission
                                        changePermission(
                                          people?.sharedWith?._id,
                                          shareBucketId,
                                          item.name
                                        );
                                      }}
                                      href={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-[13px] text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* </div> */}
                </>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end lastfile-section">
          <button
            className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end"
            onClick={handleBucketShare}
          >
            <span className="teamsavebutton-context">Share</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShareCard;
