import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './PaymentScreen.scss'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import SetupForm from './SetupForm';
import "react-toastify/dist/ReactToastify.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_INTENT);


function PaymentScreen() {
  const navigate = useNavigate();
  const planPrice = localStorage.getItem('planPrice');
  useEffect(() => {
    if(!localStorage.getItem('subscriptionplanId')){
      navigate('/app/userPricing');
    }
  }, []);
  const appearance = {
    labels: 'floating',
  
    // other configurations such as `theme`, `variables` and `rules`...
  }
  const options = {
    mode: 'subscription',
    amount: Number(localStorage.getItem('planPrice')*100),
    currency: 'usd',
    paymentMethodTypes: ['card'],
    // paymentMethodTypes: ['card', 'us_bank_account',],
    appearance: {/*...*/ },
  };

  return (
      <Elements stripe={stripePromise} options={options}>
          <SetupForm planPrice={planPrice}/>
      </Elements>
  );
}
export default PaymentScreen;
