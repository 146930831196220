import React from "react";
import './EmailCampaign.scss'
import Masonry from 'react-smart-masonry';
import darklogo from "../../assets/icons/logo-dark-theme.svg";
import logo from "../../assets/images/facebooklogo.png";
import Cardimage from "../../assets/images/cardImage.png";
import Graph from '../../assets/images/graph.png';
import Kindleimage from "../../assets/images/Kindle - Image 1.png";
import Tick from "../../assets/icons/Kindle - Tick.svg";
import amazonkindle from"../../assets/images/Amazon kindle svg logo.svg";
import Facebook from "../../assets/icons/emailfacebook.svg";
import Twitter from "../../assets/icons/emailtwitter.svg";
import Instagram from "../../assets/icons/emailinstagram.svg";

const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };  

function EmailCampaign() {
  return(
    <div className="emailcampaign-section">  
      <div className="emailtop-section">
        <div className="emaillogo-content"><img className="block emaillogo-content" src={darklogo} alt="Zimzee-Logo"/></div>
        <div className="emailsubcribe-content">Favorite, tag, delete, share, and more</div>
        <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
          <span className="emailbuckets-context">Go To Buckets</span>
        </button> 
        <div className="emaildate-content">Aug 5, 2023</div>
        <div className="emailfocus-content">Focus Bucket Highlights</div>
        <div className="emailtagswhole-section">
          <div className="emailtags-section">
            <Masonry breakpoints={breakpoints}
              columns={{ mobile: 2, tablet: 3, desktop: 4 }}
              gap={{ mobile: 20, tablet: 15, desktop: 20 }}
              style={{display: "flex"}}
            >
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">Save the internet with one click - Zimzee</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <img src={Cardimage} alt="cardImage-content"/>
                </div>
                <div className="emailread-content">Read More</div>
              </div>
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">What is a Designer Fashion?</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <div className="emailfashion-content">Fashion designers hold a special place in our world. Their talent and vision play a big role in how people look, and also contribute to the cultural and social environment.</div>
                </div>
                <div className="emailread-content">Read More</div>
              </div>
            </Masonry>
          </div>
        </div>
        
        <div className="emailweek-content">Weekly Progress</div>
        <div className="emailpreview-content">You have reviewed 11 more card(s) this week than last week</div>
        <img className="graph-imgcontent" src={Graph} alt="graph" />
        <div className="emailweek-content">Today's Highlights</div>
        <div className="emailtagswhole-section">
          <div className="emailtags-section">
            <Masonry breakpoints={breakpoints}
              columns={{ mobile: 1, tablet: 2, desktop: 2 }}
              gap={{ mobile: 20, tablet: 15, desktop: 20 }}
              style={{display: "flex"}}
            >
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">Save the internet with one click - Zimzee</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <img src={Cardimage} alt="cardImage-content"/>
                </div>
                <div className="emailread-content">Read More</div>
              </div>
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">What is a Designer Fashion?</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <div className="emailfashion-content">Fashion designers hold a special place in our world. Their talent and vision play a big role in how people look, and also contribute to the cultural and social environment.</div>
                </div>
                <div className="emailread-content">Read More</div>
              </div>
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">Diversified career options for Fashion Design</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <div className="emailfashion-content">A flagship program of NIFT, the Fashion Design program has played an influential role in the growth of the Indian fashion industry. In the 3 decades of its existence the graduates of the department have had remarkable presence in the Indian as well as global fashion scenarios.</div>
                </div>
                <div className="emailread-content">Read More</div>
              </div>
              <div className="cardHolders rounded-[14px] overflow-hidden break-inside">
                <div className="cardHeader flex items-center pt-2 pb-2 px-3">
                  <div className="logo w-[40px]">
                    <img src={logo} alt="card-logo" /> 
                  </div>
                  <div className="Cardtitle w-3/4 pl-3">
                    <h3 className="emailsocial-textcontent">Save the internet with one click - Zimzee</h3>
                  </div>
                </div>
                <div className="cardContent w-full">
                  <img src={Cardimage} alt="cardImage-content"/>
                </div>
                <div className="emailfashion-content">Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</div>
                <div className="emailread-content">Read More</div>
              </div>
            </Masonry> 
          </div> 
        </div>
        <div className="emailkindle-description">
          <div className="emailkindle-content">Today's Kindle Highlights</div>
          <div className="kindleemailwhole-section">
            <div className="kindle-detail">
              <div className="kindle-card firstkindleemail-card">
                <h6>The ART of Fashion Journal by Eila Mell</h6>
                <div className="flex">
                  <div className="mt-auto">
                <span>Highlights</span>
                </div>
                <div className="ml-auto">
                <img src={amazonkindle} alt="Kindleimage"/>
                </div>
                </div>
                <img className="my-[5px] kindle-image" src={Kindleimage} alt="Kindleimage"/>
                <div className="flex w-full">
                  <div className="kindle-box bg-green w-[50%]">
                  <img className="kindle-tick" src={Tick} alt="Tick" />
                  </div>
                  <div className="kindle-box ml-[2px] bg-afc604 w-[50%]">
                  <img className="kindle-tick" src={Tick} alt="Tick" />

                  </div>

                </div>
              </div>
              <div className="kindle-card">
                <h6>The ART of Fashion Journal by Eila Mell</h6>
                <div className="flex">
                  <div className="mt-auto">
                <span>Highlights</span>
                </div>
                <div className="ml-auto">
                <img src={amazonkindle} alt="Kindleimage"/>
                </div>
                </div>
                <img className="my-[5px] kindle-image" src={Kindleimage} alt="Kindleimage"/>
                <div className="flex w-full">
                  <div className="kindle-box bg-green w-[50%]">
                  <img className="kindle-tick" src={Tick} alt="Tick" />
                  </div>
                  <div className="kindle-box ml-[2px] bg-afc604 w-[50%]">
                  <img className="kindle-tick" src={Tick} alt="Tick" />

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="emailfollow-section">
          <div className="emailfollow-content">Follow as and stay updated!</div>
          <div className="emailiconswhole-section">
            <div className="emailicons-section">
              <img className="mr-3" src={Facebook} alt="facebook" />
              <img className="mr-3" src={Instagram} alt="instagram" />
              <img src={Twitter} alt="twitter" />
            </div>
          </div>
        </div>
        <div className="footer-section">
          <div className="emailweb-content">www.zimzee.com</div>
          <div className="emaillocation-content">442 5th Avenue, Suite #2001, New York, NY 10018 | Phone: +1.646.580.4526</div>
          <div className="emailchange-content">Change your email Preferences | Unsubscribe</div>
        </div>
      </div>
    </div> 
  ); 
}
export default EmailCampaign;