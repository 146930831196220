import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./CancelPlan.scss";
import DismissButton from "../../../../assets/icons/dismissButton.svg";
import { toastify } from '../../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CancelPlan() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [renewPrice, setRenewPrice] = useState(0);
  const [planInterval, setPlan] = useState(0);
  const [currentPeriodEnd, setPeriodEnd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const closeSharePop = () => {
    console.log("close share pop");
    setIsVisible(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };

    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          if(productList){
            console.log(productList,"productList");
            setPlan(productList.plan.interval);
            setRenewPrice(productList.plan.amount/100);
            setPeriodEnd(productList.current_period_end);
            // setSubscriptionUpdateId(productList.id);
          }       
        }
        // console.log(response.data.data.subscriptions,"response");
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchActiveSubscription();
    }
  }, []);

  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }


  const handleCancelSubscription = async() => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/cancel-subscription`,{},
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );

      setIsVisible(false);
      setIsLoading(false);
      toastify("success", response.data.message);
      console.log(response);
      setTimeout(function(){ 
        navigate('/app/myProfile');
       }, 2000);
    
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
  

  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="page-border">
      <div className="Plan-checkout w-[50%]">
        <div className="pagination-checkout">
          <p>
            <span><a href="/app/myProfile">Plan details</a></span> / <a href="/app/cancelPlan">Cancel</a>
          </p>
        </div>
        <div>
          <h4>Cancel the subscription</h4>
          <h6 className="pb-[14px]">Monthly plan</h6>
          <div className="current-plandetails flex mb-[20px]">
            <div className="currentplan-price">${renewPrice} per {planInterval == 'year' ?  'year' : 'month'}</div>
            <div className="currentplan">Current Plan</div>
          </div>
          <div className="plan-summary">
            <p className="pb-[20px]">
              Your plan will be canceled along with the team member's purchase
              but is still available until the end of your billing period on
              &nbsp;{periodEnd(currentPeriodEnd)}.
            </p>
            <p className="pb-[30px]">
              If you change your mind, you can renew your subscription.
            </p>
            <p className="cancel-summary">
              By cancelling your plan, you agree to {planInterval == 'year' ?  'yearly' : 'monthly'} plan's
              <span className="underline"> Terms and Conditions</span>
            </p>
          </div>
          <div className="mt-[30px] flex buttonplan-content">
            <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
              <span
                onClick={() => setIsVisible(!isVisible)}
                className="cancelsubscription-btn"
              >
                Cancel the subscription
              </span>
            </button>
            <div className="cancel-subscription"><a href="/app/myProfile">Cancel</a></div>
          </div>
          {isVisible && (
            <div
              className="filesuploadpopup relative !z-[999]"
              style={{ height: "100%" }}
              onClick={closeSharePop}
            >
              <div
                className=" cancelsubscription-popup"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="teaminvitesection flex">
                  <div className="Subscription-head">
                    Subscription has been cancelled
                  </div>
                  <div className="dismissSubfiles-content">
                    <img src={DismissButton} alt="dismissButton" onClick={() => setIsVisible(!isVisible)}/>
                  </div>
                </div>
                <hr />
                <div className="cancel-summary">
                  <p className="mt-[30px] mb-[30px]">
                    We would love to hear your feedback, tell us why you
                    cancelled this plan
                  </p>
                </div>
                <div className="cancel-reasons">
                  <div className="cancelreason-section flex">
                    <input
                      type="radio"
                      name="selectedtag"
                      className="radiobutton-content"
                      value=""
                      checked="selectedType"
                    />
                    <label
                      className="visaplan-content flex pl-[10px]"
                      for="html"
                    >
                      I no longer need it
                    </label>
                  </div>
                  <div className="pt-[17px] cancelreason-section flex">
                    <input
                      type="radio"
                      name="selectedtag"
                      className="radiobutton-content"
                      value=""
                    />
                    <label
                      className="visaplan-content flex pl-[10px]"
                      for="html"
                    >
                      I need more features
                    </label>
                  </div>
                  <div className="pt-[17px] cancelreason-section flex">
                    <input
                      type="radio"
                      name="selectedtag"
                      className="radiobutton-content"
                      value=""
                    />
                    <label
                      className="visaplan-content flex pl-[10px]"
                      for="html"
                    >
                      I found an alternative
                    </label>
                  </div>
                  <div className="pt-[17px] cancelreason-section flex">
                    <input
                      type="radio"
                      name="selectedtag"
                      className="radiobutton-content"
                      value=""
                    />
                    <label
                      className="visaplan-content flex pl-[10px]"
                      for="html"
                    >
                      It's too expensive
                    </label>
                  </div>
                  <div className="pt-[17px] cancelreason-section flex">
                    <input
                      type="radio"
                      name="selectedtag"
                      className="radiobutton-content"
                      value=""
                    />
                    <label
                      className="visaplan-content flex pl-[10px]"
                      for="html"
                    >
                      Other reason
                    </label>
                  </div>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    placeholder="Any additional feedback?"
                  ></textarea>
                  <div className="mt-[30px] flex buttonplan-content justify-end">
                    <div className="nothanks-btn">No thanks</div>
                    <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                      <span
                        onClick={() => handleCancelSubscription()}
                        className="cancelsubscription-btn submit-btn"
                      >
                        Submit
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    )}
    </>
  );
}
export default CancelPlan;
