import React from "react";
import './ManageTeam.scss'
import InviteTeam from "../../../assets/images/manageTeam.png";

function ManageTeam() {
  return(
    <div className="manage-section">
      <div className="manageteam-content">Manage teams</div>
      <div className="flex">
        <div className="manageseats-content">Utilized seats</div>
        <div className="manageseats-content">Available seats</div>
      </div>
      <div className="flex">
        <div className="managecount-content">0</div>
        <div className="managecount-content">0 <span className="manageadd-content">+Add seats</span></div>
      </div>
      <img className="manageinvite-imgcontent" src={InviteTeam} alt="inviteTeam" />
      <div className="manageassign-content">Invite, Assign & Collaborate</div>
      <div className="manageactive-content">All your Invited and Active team members seats and their updates will be list here</div>
      <button className="buttonpadding-content managebutton-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" >
        <span className="savebutton-context">Invite a member</span>
      </button>
    </div>
  );
}
export default ManageTeam; 