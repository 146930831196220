
// import Icon Images
import DashboardIcon from "../assets/icons/dashboardIcon.svg";
import allCardIcon from "../assets/icons/allCardIcon.svg";
import bucketIcon from "../assets/icons/bucketIcon.svg";
import projectIcon from "../assets/icons/projectIcon.svg";
import editorIcon from "../assets/icons/editorIcon.svg";
import galleryIcon from "../assets/icons/galleryIcon.svg";
import historyIcon from "../assets/icons/historyIcon.svg";
import kindleIcon from "../assets/icons/kindleIcon.svg";
import notesIcon from "../assets/icons/subClipboard.svg";
import shareIcon from "../assets/icons/shareIcon.svg";
import shareIcon2 from "../assets/icons/shareIcon2.svg";
import starIcon from "../assets/icons/starIcon.svg";
import tagIcon from "../assets/icons/tagIcon.svg";
import videosIcon from "../assets/icons/videosIcon.svg";
import filesIcon from "../assets/icons/filesIcon.svg";
import gridIcon from "../assets/icons/grid.svg";
import gridMultiIcon from "../assets/icons/gridMulti.svg";
import navFiles from "../assets/icons/navFiles.svg";
import bookmarksIcon from "../assets/icons/bookmarksIcon.svg";
import projectsIcon from "../assets/icons/projectsIcon.svg";

export default {
    DashboardIcon,
  allCardIcon,
  projectIcon,
  bucketIcon,
  editorIcon,
  galleryIcon,
  historyIcon,
  kindleIcon,
  notesIcon,
  shareIcon,
  shareIcon2,
  starIcon,
  tagIcon,
  videosIcon,
  filesIcon,
  gridIcon,
  gridMultiIcon,
  navFiles,
  bookmarksIcon,
  projectsIcon
  }