import {React,Fragment,useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './ProjectsList.scss';
import magnifier from "../../../assets/icons/magnifyGlass.svg";
import ThreeDots from "../../../assets/icons/threeDots.svg";
import EditIcon from "../../../assets/icons/Projects - Edit.svg";
import Notes from "../../../assets/icons/Clipboard.svg";
import Star from "../../../assets/icons/Star.svg";
import Tag from "../../../assets/icons/tag2Icon.svg"; 
import Share from "../../../assets/icons/Projects - Arrow.svg";
import PdfDownload from '../../../assets/icons/Pdf download.svg';
import ProjectImage from '../../../assets/icons/Project - Image.png'
import ProjectDelete from '../../../assets/icons/Projects - Delete.svg'
import ListView from '../../../assets/icons/listView.svg';
import compactCardIcon from "../../../assets/icons/Grid-view.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faChevronDown, faCamera} from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from '@headlessui/react';

function ProjectList(shared) {
   const filterDropDown = [
      { name: 'Created Date', href: '#' },
      { name: 'Recent Activity', href: '#' },
      { name: 'Ascending Order', href: '#' },
      { name: 'Descending Order', href: '#' },
      { name: 'Manual', href: '#' },
    ]

   const [filtersText, setFiltersText] = useState(filterDropDown[0].name);

   const handleFilterText = (text) => {
       setFiltersText(text);
     }
            
     function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }
         
return(

<div className="ProjectList dark:bg-[#051534]">
<div className="bucketFeature col-span-2 mb-4">
                          <div className="flex items-center">
                              <h2 className="text-xl mybuckets-content dark:text-[#fff]">My Projects</h2>
                              <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}}  className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                              <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={faPlus} />
                                  <span className="createbutton-context">Create Project</span>
                                  </button> 
                          </div>
                      </div>
                      <hr className="my-[16px]" />
                      <div className="flex">
                      <div className="inline-align">
                      <div className="flex edit-menu pr-5">
            <img className="dark-color mr-[5px]" src={Share} alt="pdficon" /> <span>Move To</span>
          </div>
          <div className="flex edit-menu">
            <img className="dark-color mr-[5px]" src={ProjectDelete} alt="pdficon" /> <span>Delete</span>
          </div>
          </div>
          <div className="ml-auto">
          <div className="ml-[auto] flex items-center justify-end">
                        <div className="filter display-content"> 
                            <a href="#" >                            
                             <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                            </a>
                            <a href="gridProject">                            
                             <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                            </a>
                        </div>
                        
                        <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                                  >
                                  <strong className="createdtext-content pr-4">{filtersText}</strong>
                                  <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                              </a>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          
                              {filterDropDown.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (

                                    <a
                                      onClick={()=>{handleFilterText(item.name)}}
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                    </div>
          </div>
          </div>
   
<div className="row mt-[34px]">
   <Tabs>
      <TabList>
         <div className="search-tab flex item-center ">
            <div className="w-full">
               <label  htmlFor="search" className="dark:border-white-700 sr-only">
               Search
               </label>
               <div className="relative">
                  <input
                     id="search"
                     name="search"
                     className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                     placeholder="Search"
                     type="search"
                     />
                  <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center pr-2 ">
                     <img className="magnifier" src={magnifier} alt="magnifying-glass" />
                  </div>
               </div>
            </div>
         </div>
         <div className="tab-scroll ">
         <Tab>
            <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-24"/>
               <label for="check-24" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Save the internet with one click - Zimzee Bucket</p>
            <img className="tab-menu menuthree-icon" src={ThreeDots} alt="menu"/>

         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-25"/>
               <label for="check-25" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Summer 2022 Look Book
Project</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-26"/>
               <label for="check-26" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Summer 2022 Look Book
Project</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-27"/>
               <label for="check-27" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p className="dark:text-[#ffffff]">Summer 2022 Look Book Project</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
         <Tab>
         <div class="checkbox-wrapper-24">
               <input type="checkbox" id="check-28"/>
               <label for="check-28" >
                  <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
               </label>
            </div>
            <p>Summer 2022 Look Book Project</p>
            <img className="tab-menu" src={ThreeDots} alt="menu"/>
         </Tab>
    
         </div>
      </TabList>

      <TabPanel>
      <div className="panel-content">
          <div className="flex mb-3">
          <h5><span className="project-nav">My Projects</span> / Save the internet with one click - Zimzee</h5>

          </div>
      
            <div className="row flex">
            <div className="last-updated">
          <p>Created on Jan 5, 2022 @ 4:30 PM</p>
          <p>Last updated on Jan 5, 2022 @ 4:30 PM</p>
          </div>
          <div className="ml-auto flex projectedit-menu">
          <div className="flex edit-menu pr-5">
            <img className="dark-color" src={PdfDownload} alt="pdficon" /> <span>PDF Download</span>
          </div>
          <div className="flex edit-menu pr-5">
            <img src={EditIcon} alt="pdficon" /> <span>Edit Project</span>
          </div>
          <div className="flex edit-menu pr-5">
            <img className="dark-color" src={Share} alt="pdficon" /> <span>Move To</span>
          </div>
          <div className="flex edit-menu">
            <img className="dark-color" src={ProjectDelete} alt="pdficon" /> <span>Delete</span>
          </div>
          </div>
          <div>

          </div>
          </div>
      <hr className="my-2" />
      <h5 className="mt-4 mb-3"> Summer 2022 Collection</h5>

          <p className="card-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
          <img className="card-detailimg test" src={ProjectImage} alt="cardimage" />
         </div>
      </TabPanel>
      <TabPanel>
      
      </TabPanel>
       
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
   </Tabs>
   </div>
</div>

)
}
export default ProjectList;