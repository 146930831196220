import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./RenewPlan.scss";
import { HashLink as Link } from "react-router-hash-link";
import EditPlan from "../../../../assets/icons/editplan.svg";
import { toastify } from '../../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RenewPlan() {
  const navigate = useNavigate();
  // let renewPrice = localStorage.getItem('renewPrice');
  const [count, setCount] = useState(0);
  const [teamMember, setTeamMember] = useState(0);
  const [renewPrice, setRenewPrice] = useState(localStorage.getItem('renewPrice'));
  const [subscriptionUpdateId, setSubscriptionUpdateId] = useState("");
  const [teamSubscriptionUpdateId, setTeamSubscriptionUpdateId] = useState("");
  const [renewSubscription , setRenewSubscription] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const payment = urlSearchParams.get('payment');
  const selectedBrand = urlSearchParams.get('selectedBrand');
  const selectedLast = urlSearchParams.get('selectedLast');
  const [teamPrice, setTeamPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [planActive, setPlanActive] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };

    const findObjectById = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          if(productList){
            console.log(productList,"productList");
            let renew = productList?.plan?.interval == "month" ? 'Upgrade' : 'Downgrade';
            setRenewSubscription(renew);
            setSubscriptionUpdateId(productList.id);
          }
          let teamMembers = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          if(teamMembers){
            console.log(teamMembers,"teamMember");
            console.log(teamMembers.quantity,"teamMember quantity");
            setCount(teamMembers.quantity);
            // let team = Number(teamMembers.quantity * 20);
            // let price = Number(localStorage.getItem('renewPrice')) + team;
            let price = Number(localStorage.getItem('renewPrice'));
            // setTeamMember(team);
            setRenewPrice(price);
            setTeamSubscriptionUpdateId(teamMembers.id);
          }
          // console.log(productList.items.data[0].plan.interval,"productList");
          // setPlanActive(productList.items.data[0].plan.interval);
          // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
          // setPlanActive()
          // response.data.data.subscriptions[0].items.data        
        }
        // console.log(response.data.data.subscriptions,"response");
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          console.log(productList,"productList team member");
          setTeamPrice(productList.priceList[0].id);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchPlans();
      fetchActiveSubscription();
    }
  }, []);

  const handleIncrement = () => {
    let countI = count + 1;
    let price = Number(renewPrice) + 20;
    // console.log(price,Number(renewPrice),Number(countI * 20));
    let team = Number(countI * 20);
    setCount(count + 1);
    setTeamMember(team);
    // setRenewPrice(price);
  };

  const handleDecrement = () => {
    if (count > 0) {
      let countI = count - 1;
      let price = Number(renewPrice) - 20;
      // console.log(price,Number(renewPrice),Number(countI * 20));
      let team = Number(countI * 20);
      setCount(count - 1);
      setTeamMember(team);
      // setRenewPrice(price);
    }
  };
  
  const handleConfirmUpgrade = async() => {
    setIsLoading(true);
    const renewPriceId = localStorage.getItem("renewPriceId");
    const token = localStorage.getItem("token");
    try{
      let planObj = { mainPlanDetails: {priceId: renewPriceId, subscriptionId: subscriptionUpdateId, planId: `${process.env.REACT_APP_MAIN_PLAN_ID}`},paymentMethod:payment   };
      if(teamPrice && count){
        let teamPlanObj = { mainPlanDetails: {priceId: renewPriceId, subscriptionId: subscriptionUpdateId, planId: `${process.env.REACT_APP_MAIN_PLAN_ID}`},paymentMethod:payment, teamMemberDetails: {priceId: teamPrice, quantity: count, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`} }
        if(teamSubscriptionUpdateId){
          teamPlanObj = { mainPlanDetails: {priceId: renewPriceId, subscriptionId: subscriptionUpdateId, planId: `${process.env.REACT_APP_MAIN_PLAN_ID}`},paymentMethod:payment, teamMemberDetails: {priceId: teamPrice, subscriptionId: teamSubscriptionUpdateId, quantity: count, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`} }
        }
        planObj = teamPlanObj;
      }
        const response = await axios.patch(
          `${process.env.REACT_APP_API}subscriptions/update`,
          planObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response,"response");
        localStorage.removeItem('renewPrice');
        localStorage.removeItem('renewPlan');
        localStorage.removeItem('renewPriceId');
        setIsLoading(false);
        toastify("success", response.data.message);
        setTimeout(function(){ 
          navigate('/app/myProfile');
         }, 2000);

    } catch (error) {
      console.error("Error fetching data:", error);
      toastify("error", error.response.data.data.message );
    }

  }

  return (
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="renewplan-section">
      <div className="plandetail-content">
        <a href="/app/myProfile">Plan details</a> / <a href="/app/upgradePlan">{renewSubscription}</a> <span className="plan-content"> / <a href={window.location.href}>Confirm</a></span>
      </div>
      <div className="confirmrenew-content">Confirm your updates</div>
      <div className="flex renewmonth-content">
        <div className="renewheading-content">
          {renewSubscription == 'Upgrade'?
              'Yearly plan' : 'Monthly plan'
          }
          {/* Monthly plan, monthly billing */}
        </div>
        <div className="renewamount-content">${Number(localStorage.getItem('renewPrice'))}</div>
      </div>
      <div className="billing-content">
      {renewSubscription == 'Upgrade'?
              '$33.25/month billed annually (Save ~$189) 1 users included' : '$49/month billed monthly 1 users included'
          }
        {/* ${Number(localStorage.getItem('renewPrice'))} / member / month * 1 member <br />
        billed monthly */}
      </div>
      {/* <div className="flex">
        <div className="totalmembers-section">
          <div className="teamplan-content">Additional team members slots</div>
          <div className="teamslot-content">Add/Remove team members.</div>
          <div className="additional-content">$20 each additional user</div>
        </div>
        <div className="flex teammember-section">
          <button className="decrementbutton-content" onClick={handleDecrement}>-</button>
          <input
            type="text"
            placeholder="2"
            className="focusteamcount-content"
            value={count}
          />
          <button className="incrementbutton-content" onClick={handleIncrement}>+</button>
        </div>
      </div> */}
      <hr />
      {/* <div className="flex subtotal-section">
        <div className="totalplan-content">Total team members</div>
        <div className="renewamount-content">${teamMember}</div>
      </div> */}
      <div className="flex totalteam-section">
        <div className="totalplan-content">Tax if applicable</div>
        <div className="renewamount-content">-</div>
      </div>
      <div className="flex tax-section">
        <div className="totalplan-content">Subtotal</div>
        <div className="renewamount-content">${renewPrice}</div>
      </div>
      <hr />

      <div className="flex today-section">
        <div className="totalplan-content">Total for today</div>
        <div className="renewamount-content">${renewPrice}</div>
      </div>
      <div className="flex monthdue-content">
        <div className="duemonth-content">
          Due on Oct 31 '23 then every month
        </div>
        <div className="flex">
          <Link to="/app/visaPlan">
            <div className="visano-content">{selectedBrand} •••• {selectedLast}</div>
          </Link>
          <img src={EditPlan} style={{ marginLeft: "8px" }} alt="EditPlan" />
        </div>
      </div>
      <div className="termsagree-content">
        By clicking agree and subscribe you are agreeing to their{" "}
        <span className="condition-content">
          terms and <br />
          conditions
        </span>
      </div>
      <div className="flex buttonplan-content">
        <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" onClick={handleConfirmUpgrade}>
          <span className="saveupdatebutton-context">Confirm</span>
        </button>
        <div className="cancelplancard-content"><a href="/app/myProfile">Cancel</a></div>
      </div>
      <ToastContainer />
    </div>
    )}
    </>
  );
}
export default RenewPlan;
