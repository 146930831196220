import {React, Fragment, useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider, CssBaseline } from "@mui/material";
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
// import {faPlus, faChevronDown, faCamera} from "@fortawesome/free-solid-svg-icons";
import {faFolder as fasFaFolder} from '@fortawesome/free-regular-svg-icons';
import { Menu, Dialog, Transition } from '@headlessui/react'
import SingleBucket from "../shades/SingleBucket";
import BucketDragPreview from "../shades/BucketDragPreview";
import CreateBucket from "../shades/CreateBucket";
import axios from 'axios';
import moment  from "moment";
import './../shades/compact.scss' 
import BucketIcon from '../assets/images/bucket.svg';
import { useOutletContext } from "react-router-dom";
import Compacticongrey from '../assets/icons/Compact icon - black.svg';
import NormaliconBlack from '../assets/icons/normalicon - black.svg';
import SortMbl from '../assets/icons/sortmbl.svg';
import { toastify } from '../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  DndProvider
} from "@minoru/react-dnd-treeview";
import { CustomDragPreview } from "../components/Tags/CustomDragPreview";
import { theme } from "../components/Tags/theme";
import { Placeholder } from "../components/Tags/Placeholder";
import styles from "../components/Tags/App.module.css";
import styless from "../components/Tags/CustomNode.module.css";
import zIndex from "@mui/material/styles/zIndex";
import CustomizePopup from '../shades/cards/popupfinal.js'
import BlackDismiss from '../assets/icons/dismissButton.svg'
import DismissButton from "../assets/icons/orangedismiss.svg";
import ShareBucket from "./cards/ShareBucket";

function GridBucket() {
const [bucketList,setBucketList] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [totalBuckets,setTotalBuckets] = useState(0);
const [bucketCreated,setBucketCreated] = useState(false);
// const [bucketRectangle,setRectangle] = useState(false);
const [bucketOperation,setBucketOperation] = useState('Add');
const [bucketNameText,setBucketNameText] = useState('');
const [bucketUpdateId,setBucketUpdateId] = useState(0);
const [filepopup, setFilePopup] = useState(false);
const [Custompopup,setCustomPopup] = useState(false);
const [selectedBucketName,setSelectedBucketName]=useState('');
const [selectedBucketId,setSelectedBucketId]=useState('');
const [treeData, setTreeData] = useState([]);
const [activeItem, setActiveItem] = useState(null);
const [BucketSharePopup,setBucketSharePopup]=useState(false)
const [shareBucketId,setShareBucketId]=useState('')
let [isOpens, setIsOpen] = useState(false);
let [isDropOpens, setIsDropOpen] = useState(false);
const [isTagOpen, setIsTagOpen] = useState(false);
const [sdragSourceId, setdragSourceId] = useState('');
const [sdropTargetId, setdropTargetId] = useState('');
const [sdragSource, setdragSource] = useState('');
const [sdropTarget, setdropTarget] = useState('');
const [treeTempData, setTempTreeData] = useState([]);
const [apiCalled, setApiCalled] = useState(false);
const[openCopyLink, setOpenCopyLink] = useState(false);
const [publicUrl, setPublicUrl] = useState("");
const [iOS, setiOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
useEffect(()=>{
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tab= urlSearchParams.get('tab');
  const tokenId = urlSearchParams.get('tokenId');
  const token = localStorage.getItem('token');
  const userID=JSON.parse(localStorage.getItem('userData'))
  const setting=localStorage.getItem('settingsToken')
  if(!apiCalled && tokenId || setting){
    try {
      const response = axios.post('https://prdapi.zimzee.com/api/users/token-verify',
      {
     token:tokenId ? tokenId :setting
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      
      console.log(response);
      if(response && tokenId || setting && userID){
        try {
          const response = axios.post('https://prdapi.zimzee.com/api/team/accept-invitation',
          {
         tokenId:tokenId ? tokenId :setting,
         recipientUserId:userID._id,
          },
          {
            headers: {
                Authorization: `Bearer ${token}`,
              }
          }
          );
          toastify("success", response.data.message);
          if(response){
            localStorage.removeItem('settingsToken')
            setApiCalled(true)
          }
          
          console.log(response);
         
        } catch (error) {
          console.log(error)
          

          // console.error(error?.response?.data?.message,"error msg");
          toastify("error", error?.response?.data?.message);
        }
      }

     
    } catch (error) {
      console.log(error)
      // console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
},[])
  const handleFocus = () => {
    setIsTagOpen(!isTagOpen);
  };
const [isOpen] = useOutletContext();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
 const filterDropDown = [
    { name: 'Created Date', href: '#' },
    { name: 'Recent Activity', href: '#' },
    { name: 'Ascending Order', href: '#' },
    { name: 'Descending Order', href: '#' },
    // { name: 'Manual', href: '#' },
  ]
  let bucketFilterv = '';
  if (!localStorage.getItem("bucketFilter")) {
    localStorage.setItem('bucketFilter','Created_Date');
    bucketFilterv = 'Created_Date';
  }else{
    bucketFilterv = localStorage.getItem("bucketFilter");
  }
  console.log(bucketFilterv,"bucketFilterv");
  const [bucketFilterDefault,setBucketFilterDefault] = useState(bucketFilterv);
  let bucketViewv = 'compact';
  if (!localStorage.getItem("bucketView")){
    localStorage.setItem('bucketView','compact');
    bucketViewv = 'compact';
  }else{
    bucketViewv = localStorage.getItem("bucketView");
  }
  const [bucketView,setBucketView] = useState(bucketViewv);
  // const bucketData = [
  //   {bucketName: 'Fashion Trends 2022',bucketTag: 'Sketch Bucket',bucketIcon:faCamera, subBucket: 'true'},
  //   {bucketName: 'Andy Birthday Party Celebration',bucketTag: 'Plan',bucketIcon:faCamera, subBucket: 'false'},
  //   {bucketName: 'Cooking Receipes',bucketTag: '',bucketIcon:faCamera, subBucket: 'true'}
  // ]
  function closeModal() {
    setIsOpen(false)
  }
  function closeDropModal() {
    setIsDropOpen(false)
  }
  const handleSharePop=(id)=>{
    setShareBucketId(id)
    setBucketSharePopup(true)
  }
  const closeSharePop=()=>{
    console.log("close share pop")
    setBucketSharePopup(false)
  }

  /**
 * Copy a string to clipboard
 * @param  {String} string         The string to be copied to clipboard
 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
 * @see https://stackoverflow.com/a/53951634/938822
 */
  function copyToClipboard(string) {
    let textarea;
    let result;

    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      // const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      const copyHotkey = '⌘C';
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  }

  const bucketPublicShare = (url) => {
    if(iOS){
      setOpenCopyLink(true);
      setPublicUrl(url);
      // if(copyToClipboard(url)){
      //   toastify("success", "Public link is copied to clipboard");
      // }else{
      //   toastify("error", "Unable to write to clipboard. :-(" );
      // }
      // var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob([url], { type: "text/plain" }) )})];
      // navigator.clipboard.write(data).then(function() {
      //   // console.log("Copied to clipboard successfully!");
      //   toastify("success", "Public link is copied to clipboard");
      // }, function() {
      //   // console.error("Unable to write to clipboard. :-(");
      //   toastify("error", "Unable to write to clipboard. :-(" );
      // });
    }else{
      const tempInput = document.createElement("input");
      tempInput.value = url;

      // Append the input element to the DOM
      document.body.appendChild(tempInput);

      // Select the input element's text and copy it to clipboard
      tempInput.select();
      document.execCommand("copy");

      // Remove the temporary input element
      document.body.removeChild(tempInput);
      
      toastify("success", "Public link is copied to clipboard");
    }
      
  }
  const handleCopyText = (url) => {
    var emailLink = document.querySelector('.publicUrlInput');
    console.log(emailLink,"copyEmailBtn"); 
    emailLink.select();
    var range = document.createRange();  
    range.selectNode(emailLink);  
    window.getSelection().addRange(range);  

    try {  
      // Now that we've selected the anchor text, execute the copy command  
      var successful = document.execCommand('copy');  
      var msg = successful ? 'successful' : 'unsuccessful';  
      console.log('Copy email command was ' + msg);  
      toastify("success", "Public link is copied to clipboard");
    } catch(err) {  
      console.log('Oops, unable to copy');  
      toastify("success", 'Oops, unable to copy');
    }  

    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported  
    window.getSelection().removeAllRanges();  
    setOpenCopyLink(false);
    // setTimeout(() => {
    //   bucketUpdate();
    // }, 1000);
}
const closeCopyLinkModal = () => {
  setOpenCopyLink(false);
}
  const handleCustomPopup = (name,id) => {
    setSelectedBucketName(name)
    setSelectedBucketId(id)
    setCustomPopup(true);
  }
  const closeCustomPopup=()=>{
    setCustomPopup(false)
  }
  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log('Child clicked');
};

  function closeFilePopup() {
    setCustomPopup(false);
  }

  function openModal() {
    setIsOpen(true)
  }
useEffect(()=>{
   // api url
   const apiUrl = process.env.base_url;

    const token = localStorage.getItem('token');
  
  // axios.post(`${apiUrl}/buckets/list`).then((response) => {console.log(response.data)
  //   }); 
    axios.post(`${process.env.REACT_APP_API}buckets/list`, {
      limit:100,
      page:1,
      filterValue:bucketFilterDefault
    },{
      headers: {
       Authorization: `Bearer ${token}`,
     }
    })
    .then(function (response) {
      setIsLoading(false);
      if(response.data.data.bucketList && response.data.data.bucketList.length){
        const flattenedData = flattenObject(response.data.data.bucketList);
        console.log(flattenedData,"flattenedData");
        setTreeData(flattenedData);
      }
      setBucketList(response.data.data.bucketList);
      setTotalBuckets(response.data.data.total);
      console.log(bucketList);
    })
    .catch(function (error) {
      console.log(error);
    });

},[bucketCreated])


const flattenObject = (data, parentId = 0) => {
  const flattened = [];
  
  data.forEach(item => {
    console.log(item,"item foreach");
    const newItem = {
      id: item._id,
      parent: parentId,
      droppable: true, //item.parent
      droppables: item.subBuckets ? item.subBuckets.length > 0 : false,
      name: item.name,
      text: item.name,
      cards: item.totalCards,
      subBuckets: item.subBuckets,
      pinned : item.pinned,
      isFocused : item.isFocused ? item.isFocused : false,
      shared: item.shared,
      bucketDesignId: item.bucketDesignId,
      default: item.default,
      parentDisable: item.subBuckets ? item.subBuckets.length : 0,
      isChild: false //item.parent
    };

    flattened.push(newItem);

    if(item.subBuckets){
      if (item.subBuckets.length > 0) {
        const children = flattenObject(item.subBuckets, item._id);
        flattened.push(...children);
      }
    }
    
  });

  return flattened;
};

// (bucketFilterv == 'Created_Date') ? filterDropDown[0].name : (
//   bucketFilterv == 'Descending_Order' ?  filterDropDown[3].name : (
//     bucketFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
//       text == 'Recent_Activity' ? filterDropDown[1].name : ""
//     )
//   )
//   );
  const [filtersText, setFiltersText] = useState((bucketFilterv == 'Created_Date') ? filterDropDown[0].name : (
    bucketFilterv == 'Descending_Order' ?  filterDropDown[3].name : (
      bucketFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
        bucketFilterv == 'Recent_Activity' ? filterDropDown[1].name : ""
      )
    )
    ));
  // const [bucketDetails,setBucketDetails] = useState(bucketData);
  // console.log(bucketDetails);
  const [popup,setPopup] = useState(false);

  const handlePopup = () =>{
    setBucketOperation("Add");
    setBucketNameText("");
    setBucketCreated(false);
    setPopup(true);
    setTimeout(() => {
      document.getElementsByName('name')[0].focus();
    }, 100);
  }
  // const handleSquare = () =>{
  //   setRectangle(false);
  //   setSquare(true);
  // }

  const handleRectangle = () =>{
    // setSquare(false);
    // setRectangle(true);
    localStorage.setItem('bucketView','compact');
    setBucketView('compact');
  }
  const handleSquare = () =>{
    // setRectangle(false);
    localStorage.setItem('bucketView','grid');
    setBucketView('grid');
  }
  const handleFilterText = (text) => {
    console.log(text,"handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues = (text == 'Created Date') ? "Created_Date" : (
      text == 'Descending Order' ?  "Descending_Order" : (
        text == 'Ascending Order' ? "Ascending_Order" : (
          text == 'Recent Activity' ? "Recent_Activity" : ""
        )
      )
      );
    localStorage.setItem('bucketFilter',filterValues);
    setBucketFilterDefault(filterValues);  
    const token = localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API}buckets/list`, {
        limit:100,
        page:1,
        filterValue:filterValues
      },{
        headers: {
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        setIsLoading(false);
        if(response.data.data.bucketList && response.data.data.bucketList.length){
          const flattenedData = flattenObject(response.data.data.bucketList);
          console.log(flattenedData,"flattenedData");
          setTreeData(flattenedData);
        }
        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        console.log(bucketList);
      })
    .catch(function (error) {
      console.log(error);
    });
  }


  function bucketUpdate(){
     setBucketCreated(!bucketCreated);
  }

  function changeTxt(event){
    setBucketNameText(event.target.value);                
  }

  const handleDeleteClick = (id) => {
    setIsOpen(true);
    console.log(id);
    setBucketUpdateId(id);
  }


  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}buckets/delete/${id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
      );
     console.log(response,"response");
    //  toastify("sucess", response.message );
      toastify("success", response.data.message);
      axios.post(`${process.env.REACT_APP_API}buckets/list`, {
        limit:100,
        page:1,
        filterValue:bucketFilterDefault
      },{
        headers: {
        Authorization: `Bearer ${token}`,
      }
      })
      .then(function (response) {
        setIsLoading(false);
        if(response.data.data.bucketList && response.data.data.bucketList.length){
          const flattenedData = flattenObject(response.data.data.bucketList);
          console.log(flattenedData,"flattenedData");
          setTreeData(flattenedData);
        }
        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        console.log(bucketList);
      })
    .catch(function (error) {
      console.log(error);
    });
      // setIsLoading(false);
    //  setBucketNameText(response.data.data.name); 
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message );
    }
    setIsOpen(false);
  }

  const updateBucket = async (bucketId,pin,action) => {
    if(action != 'pinn'){
      const focusItems = bucketList.filter(item => item.isFocused);
      console.log(focusItems);
      const selectedItem = bucketList.find(item => item._id === bucketId);
      console.log(selectedItem,"selectedItem");
      if (!selectedItem.isFocused && focusItems.length >= 2) {
        // User cannot select more than two focus items
        // alert("You can't select more than two focus items!");
        toastify("error", "A maximum of two focus buckets is allowed");
        return;
      }
      // return;
    }
    setIsLoading(true);
    let bucketAction = (action == 'pinn') ? {bucketId:bucketId,pinned:pin} : {bucketId:bucketId,isFocused:pin}
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
     try {
       const response = await axios.patch(`${process.env.REACT_APP_API}buckets/update`, bucketAction,
       {
         headers: {
             Authorization: `Bearer ${token}`,
           }
       }
       );
       console.log(response);
      //  toastify("success", response.data.message);
       bucketUpdate();
      //  setIsLoading(false);
     } catch (error) {
       console.error(error);
       toastify("error", error.response.data.message );
     }
   };

  const handleClick = async (id) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}buckets/single/${id}`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
      );
     console.log(response.data.data.name,"response");
     setBucketNameText(response.data.data.name); 
     console.log("heeloo bucket anem");
     setTimeout(() => {
      document.getElementsByName('name')[0].focus();
    }, 100);
    } catch (error) {
      console.error(error);
    }
    console.log('Button clicked!');
    console.log(id);
    setPopup(true);
    setBucketOperation("Update");
    setBucketUpdateId(id);

  };
  !bucketView ?
   console.log("not exist view")
   :
   console.log(bucketView,"bucketView test")
  ;


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const updateCreateBucket = async (id,destination) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
     try {
       const response = await axios.patch(`${process.env.REACT_APP_API}buckets/update`, {bucketId:id,dragIntoBucket: true,parentId:(destination == 0) ? null :destination },
       {
         headers: {
             Authorization: `Bearer ${token}`,
           }
       }
       );
       console.log(response);
      //  toastify("success", response.data.message);
       bucketUpdate();
     } catch (error) {
       console.error(error);
       toastify("error", error.response.data.message );
       bucketUpdate();
     }
   };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;
    console.log(source,destination);
    setBucketList(reorder(bucketList, source.index, destination.index));
    // setList(reorder(list, source.index, destination.index));
  };

  const handleDrop = (
    newTree,
    { dragSourceId, dropTargetId, dragSource, dropTarget }
  ) => {
    // Do something
    setdragSourceId(dragSourceId); 
    setdropTargetId(dropTargetId); 
    setdragSource(dragSource); 
    setdropTarget(dropTarget);
    setTempTreeData(newTree);
    setIsDropOpen(true);
    // if(dragSource.parentDisable == 0){
      
    // }else{
      // toastify("error", "Bucket having sub buckets cannot be moved to other bucket" );
    // }
   
  };


  const handleDropConfirm = (treeTempData, dragSourceId, dropTargetId, dragSource, dropTarget) => {
      console.log(dragSourceId, dropTargetId, dragSource, dropTarget, "dragSourceId, dropTargetId");
      updateCreateBucket(dragSourceId,dropTargetId);
      setTreeData(treeTempData);
      setIsDropOpen(false);
  }

    return(
      <>

        {
          isLoading ? (
            <div className='App-loading'>
              <div className='App-logo' title='Zimzee' /> 
            </div>
            )
        :  
        ( 
        <div className={bucketView == 'compact' ? 'regular' : 'CompactView-wholesection' }>
          <div className="bucketDashboard">
          <div className="compactdesktop-section">
            <div className="bucketHeader">
                <div className="grid grid-cols-3"> 
                    <div className="bucketFeature col-span-2">
                            <div className="flex items-center">
                                <h2 className="text-xl mybuckets-content dark:text-[#fff]">My Buckets</h2>
                                <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} onClick={handlePopup} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                                    <span className="createbutton-context">Create New</span>
                                    </button> 
                            </div>
                            <div className="totalbucket-content"><img className="bucket-imgcontent" src={BucketIcon} alt="bucketIcon"/>{totalBuckets} Buckets in Total</div>        
                        </div>
                        <div className="filterHolder flex items-center justify-end">
                              <img onClick={handleRectangle} className="icon-imgcontent compact-view w-5" src={Compacticongrey} alt="compacticonGrey" />
                            
                              <img onClick={handleSquare} className="icon-imgcontent regular-view w-5" src={NormaliconBlack} alt="normaliconBlack" />
                            <span className="sorttext-content">Sort By</span>
                            <Menu as="div" className="relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                                      <a
                                        href="#"
                                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                                      >
                                      <strong className="createdtext-content pr-4">{filtersText}</strong>
                                      <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={fontIcons.faChevronDown} />
                                      
                                  </a>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="filter-dropdown absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              
                                  {filterDropDown.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (

                                        <a
                                          onClick={()=>{handleFilterText(item.name)}}
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                        </div>
                  </div>
            </div>
          </div>
          <div className="compactmbl-section">
            <div className="bucketHeader">
                <div className="grid grid-cols-2"> 
                    <div className="bucketFeature col-span-2">
                            <div className="flex items-center">
                                <h2 className="text-xl mybuckets-content dark:text-[#fff]">My Buckets</h2>
                                <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} onClick={handlePopup} className="buttonpadding-content mx-4 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                                  <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                                    <span className="createbutton-context">Create New</span>
                                </button> 
                                <div>
                                <Menu as="div" className="sortmbl-content relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                                      <a
                                        href="#"
                                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                                      >
                                      
                                      <img className="sortimg-content" src={SortMbl} alt="SortMbl" />
                                      
                                  </a>
                                </Menu.Button>  
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              
                                  {filterDropDown.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (

                                        <a
                                          onClick={()=>{handleFilterText(item.name)}}
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                                </Menu>
                                </div>
                            </div>
                            <div className="totalbucket-content"><img className="bucket-imgcontent" src={BucketIcon} alt="bucketIcon"/>{totalBuckets} Buckets in Total</div>        
                        </div>
                  </div>
            </div>
          </div>

          {
            bucketList.length != 0 ? 
            <div className="mybucketswhole-content">
              <div className={`BucketListHolder-content`}>
              {/* <div className="fixed inset-0 flex items-center justify-center">
                <button
                  type="button"
                  onClick={openModal}
                  className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  Open dialog
                </button>
            </div> */}
            
              <Transition appear show={isOpens} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100" 
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Delete
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete?
                            </p>
                          </div>

                          <div className="mt-4">
                            <button style={{background:'#FF6600',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={() => handleButtonDelete(bucketUpdateId)}
                            >
                              Yes
                            </button>
                            <button style={{background:'#000',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                              type="button"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              No
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
              {/* bucket drag and drop warning alert */}
              <Transition appear show={isDropOpens} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDropModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100" 
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Move
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                            Are you sure? This action cannot be undone.
                            </p>
                          </div>

                          <div className="mt-4">
                            <button style={{background:'#FF6600',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={() => handleDropConfirm(treeTempData,sdragSourceId, sdropTargetId, sdragSource, sdropTarget)}
                            >
                              Yes
                            </button>
                            <button style={{background:'#000',width:"80px",color:"#fff",padding:"10px",borderRadius:"50px"}}
                              type="button"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeDropModal}
                            >
                              No
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
              {/* bucket drag and drop warning alert */}
             {BucketSharePopup &&
             
             <ShareBucket shareBucketId={shareBucketId} closeSharePop={closeSharePop} bucketUpdate={bucketUpdate} bucketPublicShare={bucketPublicShare}  />
            
            
             }
              {Custompopup &&
              <div className="filesbucketuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeCustomPopup}>
                <div className="subfilesbucketupload-section" onClick={handleChildClick}>
                  <div onClick={closeFilePopup}><img className="dismissSubfiles-content" src={DismissButton} alt="dismissButton" /></div>
                  <CustomizePopup selectedBucketName={selectedBucketName} selectedBucketId={selectedBucketId} closeCustomPopup={closeCustomPopup} bucketUpdate={bucketUpdate} />
                </div>
              </div>
            }
            <div className="compactdesktop-section">
            <div className="app">
                <ThemeProvider theme={theme}>
                  <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                  <Tree
                      tree={treeData}
                      rootId={0}
                      onDrop={handleDrop}
                      render={(bucket, { depth, isOpen, onToggle }) => (
                        <div className={styles.root}>
                          <div className={styles.container}>
                            <SingleBucket bucketId={bucket.id} bucketView={bucketView} sharedUsers={bucket?.shared} bucketUpdate={bucketUpdate} handleSharePop={handleSharePop} handleCustomPopup={handleCustomPopup} updateBuckets={updateBucket} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket.name} bucketCard={bucket.cards} subBucket={bucket.subBuckets} pined={bucket.pinned} focus={bucket.isFocused ? bucket.isFocused : false} shared={bucket.shared} sharedBy={[]} bucketDesign={bucket.bucketDesignId} defaults={bucket.default ? bucket.default : ""}/>
                          </div>
                          
                        </div>
                      )}
                      dragPreviewRender={(monitorProps) => (
                        <div className="relative">
                            <BucketDragPreview bucketId={monitorProps.item.id} bucketUpdate={bucketUpdate} handleSharePop={handleSharePop} handleCustomPopup={handleCustomPopup} updateBuckets={updateBucket} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={monitorProps.item.name} bucketCard={monitorProps.item.cards} subBucket={monitorProps.item.subBuckets} pined={monitorProps.item.pinned} focus={monitorProps.item.isFocused ? monitorProps.item.isFocused : false} shared={monitorProps.item.shared} bucketDesign={monitorProps.item.bucketDesignId} defaults={monitorProps.item.default ? monitorProps.item.default : ""}/>
                        </div>
                        // <CustomDragPreview monitorProps={monitorProps.item} />
                      )}
                      classes={{
                        root: styles.treeRoot,
                        draggingSource: styles.draggingSource,
                        dropTarget: styles.dropTarget
                      }}
                      sort={false}
                      insertDroppableFirst={false}
                      canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                        if (dragSource?.parent === dropTargetId) {
                          return true;
                        }else if(dragSource.parentDisable > 0){
                          // return false;
                        }
                      }}
                      dropTargetOffset={5}
                      placeholderRender={(node, { depth }) => (
                        <Placeholder node={node} depth={depth} />
                      )}
                  />
                  </DndProvider>
                </ThemeProvider>
              </div>
            </div>
              
              <div className="compactmbl-section">
                  <div className="compactmbl-bucket">
                  {treeData?.map((bucket) => (
                    bucket?.id?
                      <SingleBucket bucketId={bucket.id} bucketView={bucketView} sharedUsers={bucket?.shared} bucketUpdate={bucketUpdate} handleSharePop={handleSharePop} handleCustomPopup={handleCustomPopup} updateBuckets={updateBucket} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket.name} bucketCard={bucket.cards} subBucket={bucket.subBuckets} pined={bucket.pinned} focus={bucket.isFocused ? bucket.isFocused : false} shared={bucket.shared} sharedBy={[]} bucketDesign={bucket.bucketDesignId} defaults={bucket.default ? bucket.default : ""}/> : ""
                    ))}
                  </div>
              </div>
              {/* <p>split two libs</p> */}
                {/* { bucketList?.length?
                 <DragDropContext onDragEnd={handleDragEnd}>
                 <Droppable droppableId="droppable">
                   {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps}>
                {bucketList.map((bucket, index)=>(
                  <Draggable
                  key={bucket._id}
                  index={index}
                  draggableId={bucket._id}
                  
                >
                  {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        className="dragsection-content"  
                        >
                      <SingleBucket bucketId={bucket._id} bucketUpdate={bucketUpdate} handleCustomPopup={handleCustomPopup} handleSharePop={handleSharePop} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket.name} bucketCard={bucket.cards} subBucket={bucket.subBuckets} pined={bucket.pinned} shared={bucket.shared} bucketDesign={bucket.bucketDesignId} defaults={bucket.default ? bucket.default : ""}/>
                        </div>
                         )}
                  </Draggable>
                  ))} 
                  </div>
                )}
              </Droppable>
                 </DragDropContext>
                : ""} */}

              </div>
            </div>
            : null
          }
          {popup === true ? <CreateBucket bucketUpdate={bucketUpdate} changeTxt={changeTxt} bucketOperation={bucketOperation} bucketNameText={bucketNameText} bucketUpdateId={bucketUpdateId} parent= {null} setPopup = {setPopup} /> : null}
      </div>
      <Transition appear show={openCopyLink} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyLinkModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy and Paste this URL
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="URL"
                              value={publicUrl}
                              className="publicUrlInput"
                              // onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeCopyLinkModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleCopyText(publicUrl)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
      <ToastContainer />
      </div>
        )
        }

      </>
      
    )
}

export default GridBucket;