import React from "react";
import logo from "../../assets/icons/logo.png";
import axios from 'axios';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Intercom from "../intercom/Intercom";
import { useEffect } from "react";
function ForgotPassword(){
    const [resendEmail, setResendEmail] = React.useState(false);
    const [Email, setEmail] = React.useState("");
    const user = {
        firstName: null,
        email: null,
        createdAt: Math.floor(Date.now() / 1000),
      };
    useEffect(()=>{
        console.log(user,"user on login")
            Intercom(user)
          },[user])
    function changeTxt(event){
        setEmail(event.target.value);                
    }

    const handleSubmit = (e) => {
        // /setLoading(true);
		e.preventDefault();
		const data = {
			email: e.target.email.value
		}
        axios
        .post(`${process.env.REACT_APP_API}users/password-forget`, data)
        .then((response) => {
            console.log(response.data.message);
            toastify("success", response.data.message );
            setEmail("");
            setResendEmail(true)
        }).catch( (err) => {
            console.log(err.response.data.message);
            toastify("error", err.response.data.message );
        });
      };
      
    return(
        <div className="loginHolder bg-[#F3F4F6] min-h-[100vh] flex items-center text-center">
        <div className="formHolder bg-white md:w-1/2 lg:w-2/5 mx-auto p-8 rounded-lg shadow">
            <div className="logo flex justify-center">
                <img src={logo} />
            </div>
            <h3 className="text-xl mt-5 font-bold">Forgot Password</h3>
            <p className="mb-3 mt-1">To reset your password,enter the email address that you use to login to Zimzee.</p>
            <form onSubmit={handleSubmit} className="loginForm flex flex-col">
                <input style={{borderColor:"#D9D9D9 !important", borderWidth: '1px'}} className="bg-white my-3 p-3 rounded-md" type="email" name="email" onChange={changeTxt} value={Email} placeholder="Your Email" />
                <button type="submit" className="button bg-[#FF6600] text-white py-2 rounded-[5px] mt-4">Send Email</button>
                {(resendEmail)? (
                        <a className="mt-5 text-[#000]" href="/" style={{textDecoration: "underline"}}>Return To Login</a>
                    ) : ("")}
            </form>
          
        </div>
        <ToastContainer />
    </div>
    )
}

export default ForgotPassword;