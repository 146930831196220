import React from "react";
import './BillingInfo.scss'
import { HashLink as Link } from 'react-router-hash-link';
import Profile1 from '../../../../assets/icons/profile1.png';
import Profile2 from '../../../../assets/icons/profile2.png';
import Profile3 from '../../../../assets/icons/profile3.png';
import VisaPlan from '../../../../assets/icons/visaplan.svg'; 
import EditPlan from '../../../../assets/icons/editplan.svg';
import BankIcon from '../../../../assets/icons/bankIcon.svg';
import PlanDismiss from '../../../../assets/icons/plandismiss.svg';
 
function BillingInfo() { 
  return(
    <div className="billinginfo-section">
      <div className="plandetails-content">Plan Details</div> 
      <div className="monthlyplan-content">Monthly plan</div>
      <div className="flex">
        <div className="amountmonth-content">$49 per month</div>
        <div className="currentplan-content">Current plan</div>
      </div>
      <div className="planrenew-content">Your plan renews on October 31, 2023</div>
      <div className="flex profileplan-section">
        <div className="profile-imgcontent">
          <img src={Profile1} alt="profile1" />
          <img src={Profile2} alt="profile2" /> 
          <img src={Profile3} alt="profile3" />
          <img src={Profile2} alt="profile2" /> 
          <img src={Profile3} alt="profile3" />
        </div>
        <div className="purchasedplan-content">Total team members purchased: <span className="countplan-content"> 5</span></div>
        <Link to="/app/teamMembersPlan">
          <div className="planteam-content">Add team members</div>
        </Link>
      </div>  
      <div className="flex planupdate-section">
        <Link to="/app/upgradePlan">
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
            <span className="saveplanbutton-context">Update</span>
          </button>
        </Link>
        <div className="cancelfilecard-content">Cancel the subscription</div>
      </div>
      <hr/>
      <div className="paymentdetails-content">Payment method</div>
      <div className="flex billing-content">
        <div><img src={BankIcon} style={{marginRight: "16px"}} alt="bankIcon" /></div>
        <div className="stripetest-content">STRIPE TEST BANK •••• 6789</div>
        <div className="defaultplan-content">Default</div>
        <div><img src={PlanDismiss} className="plandismiss-content" alt="planDismiss" /></div>
      </div>
      <div className="flex plancard-content">
        <div className="flex visadetail-content"><img src={VisaPlan} style={{marginRight: "8px"}} alt="VisaPlan" />Visa •••• •••• •••• 4242</div>
        <div className="expire-content">Expires on 03/2025</div>
        <div className="visacarddot-content">•••</div>
      </div>
      <div className="addpayment-content">+ Add payment method</div> 
      <div className="billingdetails-content">Billing Information</div>
      <div className="flex billingaddress-content">
        <div className="billinginfo-content">Billing address</div>
        <div className="billingaddr-content">Mr John Smith. 132, My Street, Kingston, <br/>New York 12401.</div>
      </div>
      <Link to="/app/updateBilling">
        <div className="flex updateplan-content"><img src={EditPlan} style={{marginRight: "8px"}} alt="EditPlan" />Update information</div>
      </Link>
    </div>
  ); 
}
export default BillingInfo;  