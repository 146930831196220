import React from "react";
import "./MonthlyPlan.scss";
import { HashLink as Link } from "react-router-hash-link";
import Profile1 from "../../../../assets/icons/profile1.png";
import Profile2 from "../../../../assets/icons/profile2.png";
import Profile3 from "../../../../assets/icons/profile3.png";
import VisaPlan from "../../../../assets/icons/visaplan.svg";
import EditPlan from "../../../../assets/icons/editplan.svg";

function MonthlyPlan(props) {
  let userData =JSON.parse(localStorage.getItem('userData'));
  const complementry = userData.isComplementary;
  console.log(props.paymentMethod,"props.paymentMethod)");
  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function periodEnd(current_period_end){
    var date = new Date(current_period_end*1000);
    console.log(date,"date");
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    // console.log(formattedDate);
    return formattedDate;
  }
  
  // periodEnd(props.currentPeriodEnd);
  return (
    <div className="monthlyplan-section">
      <div className="plandetails-content">Plan Details</div>
      <div className="monthlyplan-content">{props.planActive == 'year' ?  'Yearly' : complementry ? 'Lifetime Promo' : 'Monthly'} plan</div>
      {
        !complementry ?
        <div className="flex">
          <div className="amountmonth-content">${props.priceActive/100} per {props.planActive}</div>
          <div className="currentplan-content">Current plan</div>
        </div> : ""
      }

      {
        !complementry ?
        <div className="planrenew-content">
          Your plan renews on {periodEnd(props.currentPeriodEnd)}
        </div> : ""
      }
      
      <div className="flex profileplan-section">
        <div className="profile-imgcontent">
          <img src={Profile1} alt="profile1" />
          <img src={Profile2} alt="profile2" />
          <img src={Profile3} alt="profile3" />
          <img src={Profile2} alt="profile2" />
          <img src={Profile3} alt="profile3" />
        </div>
        <div className="purchasedplan-content">
          Total team members purchased:{" "}
          <span className="countplan-content"> {props.teamMember ? props.teamMember.quantity : 0}</span>
        </div>
        {/* /app/teamMembersPlan */}
        <Link to={`/app/teamMembersPlan?payment=${props.selectedTag}&&selectedBrand=${props.selectedBrand}&&selectedLast=${props.selectedLast}`}>
          <div className="planteam-content">Add/Remove the team members</div>
        </Link>
      </div>
      {
        !complementry ?
        <div className="flex planupdate-section">
        <Link to="/app/upgradePlan">
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
            <span className="saveplanbutton-context">Change Plan</span>
          </button>
        </Link>
        <Link to="../../app/cancelPlan">
          <div className="cancelfilecard-content">Cancel the subscription</div>
        </Link>
      </div> : ""
      }
      
      <hr />
      <div className="paymentdetails-content">Payment method</div>
        {props.paymentMethod.map((item) => (
            <div className="flex plancard-content">
                <div className="flex visadetail-content">
                  <img src={VisaPlan} style={{ marginRight: "8px" }} alt="VisaPlan" />
                  {capitalizeFirstLetter(item.card.brand)} •••• •••• •••• {item.card.last4}
                </div>
                <div className="defaultplan-content">Default</div>
                <div className="expire-content">Expires on {item.card.exp_month}/{item.card.exp_year}</div>
            </div>
        ))}
      {/* {
        props.paymentMethod.map((payment) => { */}
          
        {/* })
      } */}
     
      <Link to="../../app/profileAddpayment">
        <div className="addpayment-content">+ Add payment method</div>
      </Link>
        
      <div className="billingdetails-content">Billing Information</div>
    <Link to="../../app/updateBilling">
      <div className="flex updateplan-content">
        <img src={EditPlan} style={{ marginRight: "8px" }} alt="EditPlan" />
        Update information
      </div>
      </Link>
    </div>
    
  );
}
export default MonthlyPlan;
