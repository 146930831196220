import {React,Fragment,useState} from "react";
import './EditProject.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import magnifier from "../../../assets/icons/magnifyGlass.svg";
import ProjectImage from '../../../assets/icons/Project - Image.png';
import ProjectSwipe from '../../../assets/images/projectSwipe.svg';
import SummerImage from '../../../assets/images/summerImage.png';
import EditIcon from '../../../assets/images/editProject.svg';
import { Menu, Transition } from '@headlessui/react';

function EditProject() {
   const filterDropDown = [
      { name: 'Created Date', href: '#' },
      { name: 'Recent Activity', href: '#' },
      { name: 'Ascending Order', href: '#' }, 
      { name: 'Descending Order', href: '#' },
      { name: 'Manual', href: '#' },
    ]
  
    const [setFiltersText] = useState(filterDropDown[0].name);
  
    const handleFilterText = (text) => {
        setFiltersText(text);
      }
             
      function classNames(...classes) {
       return classes.filter(Boolean).join(' ')
     }
     
  return(
   <div className="editProject-wholesection">
      <div className="bucketHeader">
        <div className="flex items-center ">
          <div className="bucketFeature">
            <div className="flex items-center">
              <p className="text-xl editproject-content dark:text-[#fff]">Project Name</p>
            </div>
          </div>
          <div className="summertitle-textcontent"><span className="editIcon-content"><img src={EditIcon} alt="EditIcon" /></span>Summer 2022 Look Book Project</div>
        </div>        
      </div> 
      <hr/>
      <div  className="flex topsection-content">
        <div className="selectcards-content">Select the Cards</div>
        <div className="clickspace-content"></div>
        <div className="projectWorkspace-content">Project Workspace</div>
      </div>
      <div className="row mt-[24px] editproject-spacecontent">
         <Tabs>
            <TabList>
               <div className="search-tab flex item-center ">
                  <div className="w-full">
                  <Menu as="div" className="relative flex-shrink-0">
                     <div className="flex items-center menuitems-content"> 
                        <div><strong className="createdtext-content pr-4">Select Bucket / Sub Bucket</strong></div>
                        <div><FontAwesomeIcon className="iconimg-content" style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} /></div>
                     </div>
                  <Transition
                     as={Fragment}
                     enter="transition ease-out duration-100"
                     enterFrom="transform opacity-0 scale-95"
                     enterTo="transform opacity-100 scale-100"
                     leave="transition ease-in duration-75"
                     leaveFrom="transform opacity-100 scale-100"
                     leaveTo="transform opacity-0 scale-95"
                  >
                     <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                        {filterDropDown.map((item) => (
                        <Menu.Item key={item.name}>
                           {({ active }) => (
                              <a
                              onClick={()=>{handleFilterText(item.name)}}
                              href={item.href}
                              className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block py-2 px-4 text-sm text-gray-700'
                              )}
                              >
                              {item.name}
                              </a>
                           )}
                        </Menu.Item>
                        ))}
                     </Menu.Items>
                  </Transition>
                  </Menu>
                     <label  htmlFor="search" className="dark:border-white-700 sr-only">
                     Search
                     </label>
                     <div className="relative">
                        <input
                           id="search"
                           name="search"
                           className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                           placeholder="Search"
                           type="search"
                           />
                        <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center pr-2 ">
                           <img className="magnifier" src={magnifier} alt="magnifying-glass" />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="tab-scroll ">
                  <Tab>
                     <div class="checkbox-wrapper-24">
                        <input type="checkbox" id="check-24"/>
                        <label for="check-24" >
                           <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
                        </label>
                     </div>
                     <div>
                        <p>One Click Save Anything…</p>
                        <p className="showcase-content">Showcase your collections in the most attractive way!</p>
                     </div>
                  </Tab>
                  <Tab>
                     <div class="checkbox-wrapper-24">
                        <input type="checkbox" id="check-24"/>
                        <label for="check-24" >
                           <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
                        </label>
                     </div>
                     <div>
                        <p>Summer 2022 Look Book…</p>
                        <img className="summerImage-content" src={SummerImage} alt="summerImage" />
                        <p className="showcase-content">Showcase your collections in the most attractive way!</p>
                     </div>
                  </Tab>
                  <Tab>
                     <div class="checkbox-wrapper-24">
                        <input type="checkbox" id="check-24"/>
                        <label for="check-24" >
                           <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
                        </label>
                     </div>
                     <div>
                        <p>One Click Save Anything…</p>
                        <p className="showcase-content">Showcase your collections in the most attractive way!</p>
                     </div>
                  </Tab>
                  <Tab>
                     <div class="checkbox-wrapper-24">
                        <input type="checkbox" id="check-24"/>
                        <label for="check-24" >
                           <svg viewBox="0,0,50,50"><path d="M5 30 L 20 45 L 45 5"></path></svg>
                        </label>
                     </div>
                     <div>
                        <p>Summer 2022 Look Book…</p>
                        <img className="summerImage-content" src={SummerImage} alt="summerImage" />
                        <p className="showcase-content">Showcase your collections in the most attractive way!</p>
                     </div>
                  </Tab> 
               </div>
            </TabList> 
            <div className="saved-content">
               <img className="projectswipe-content" src={ProjectSwipe} alt="projectSwipe" />
               <div className="saved-section">
                  <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Saved It</span>
                  </button> 
               </div>
               <div className="workspace-content">Click Save It to add to the workspace</div>
            </div>
            <TabPanel>
               <div className="panel-content">
                  <h5 className="mb-3"> Summer 2022 Collection</h5>
                  <p className="card-content">Showcase your collections in the most attractive way! Make your own stunning fashion lookbook and publish it online or download it for print.</p>
                  <img className="card-detailimg test" src={ProjectImage} alt="cardimage" />
               </div>
               <div className="flex bgeditproject-content">
                  <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Save</span>
                  </button>
                  <div className="canceltext-content">Cancel</div> 
               </div>
            </TabPanel>
            <TabPanel>
               <div className="panel-content">
                  <h5 className="mb-3"> Summer 2022 Collection</h5>
                  <p className="card-content">Showcase your collections in the most attractive way! Make your own stunning fashion lookbook and publish it online or download it for print.</p>
                  <img className="card-detailimg test" src={ProjectImage} alt="cardimage" />
               </div>
               <div className="flex bgeditproject-content">
                  <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Save</span>
                  </button>
                  <div className="canceltext-content">Cancel</div> 
               </div>
            </TabPanel>
            <TabPanel>
               <div className="panel-content">
                  <h5 className="mb-3"> Summer 2022 Collection</h5>
                  <p className="card-content">Showcase your collections in the most attractive way! Make your own stunning fashion lookbook and publish it online or download it for print.</p>
                  <img className="card-detailimg test" src={ProjectImage} alt="cardimage" />
               </div>
               <div className="flex bgeditproject-content">
                  <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Save</span>
                  </button>
                  <div className="canceltext-content">Cancel</div> 
               </div>
            </TabPanel>
            <TabPanel>
               <div className="panel-content">
                  <h5 className="mb-3"> Summer 2022 Collection</h5>
                  <p className="card-content">Showcase your collections in the most attractive way! Make your own stunning fashion lookbook and publish it online or download it for print.</p>
                  <img className="card-detailimg test" src={ProjectImage} alt="cardimage" />
               </div>
               <div className="flex bgeditproject-content">
                  <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white">
                    <span className="savebutton-context">Save</span>
                  </button>
                  <div className="canceltext-content">Cancel</div> 
               </div>
            </TabPanel> 
         </Tabs>
      </div>
   </div>
  );  
}

export default EditProject;