import React from "react";
import "./ProjectsPreview.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import TrashIcon from "../../../assets/icons/Delete - Line icon.svg";
import PDFIcon from "../../../assets/images/Projects/pdfIcon.svg";
import EditIcon from "../../../assets/images/Projects/editIcon.svg";
import PreviewImg from "../../../assets/images/Projects/previewImg.png";

function ProjectPreview() {
  return (
    <div className="projectpreview-wholesection">
      <div className="bucketHeader">
        <div className="grid grid-cols-3">
          <div className="bucketFeature col-span-2">
            <div className="flex items-center">
              <p className="text-xl myproject-content dark:text-[#fff]">
                My Projects
                <span className="projecttitle-textcontent">
                  {" "}
                  / Summer 2022 Look Book Project
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cards-filter flex justify-between mb-3">
        <div className="flex items-center justify-end">
          <div className="createddate-content">
            Created on Jan 5, 2022 @ 4:30 PM
            <br />
            Last updated on Jan 22, 2022 @ 8:00 PM
          </div>
        </div>
        <div className="projectsicon-imgcontent flex items-center">
          <div className="flex actionsHolder">
            <div className="m-auto">
              <div href="#" className="flex items-center">
                <img className="w-6 trash-icon" src={PDFIcon} alt="pdfIcon" />
                <span className="actionicon-content pl-3">PDF Download</span>
              </div>
            </div>
            <div>
              <a href="/app/editProject">
                <div href="#" className="flex items-center pl-[24px]">
                  <img
                    className="w-6 trash-icon"
                    src={EditIcon}
                    alt="editIcon"
                  />
                  <span className="actionicon-content pl-3">Edit Project</span>
                </div>
              </a>
            </div>
            <div>
              <div href="#" className="flex items-center pl-[24px]">
                <FontAwesomeIcon
                  className="transform rotate-45"
                  icon={faArrowUp}
                />
                <span className="actionicon-content pl-3">Move to</span>
              </div>
            </div>
            <div>
              <div href="#" className="flex items-center pl-[24px]">
                <img
                  className="w-6 trash-icon"
                  src={TrashIcon}
                  alt="trashIcon"
                />
                <span className="actionicon-content pl-3">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="summerwhole-section">
        <div className="project-textcontent">Summer 2022 Collection</div>
        <div className="projectdesc-content">
          Showcase your collections in the most attractive way! Make your own
          stunning fashion lookbook and publish it online or download it for
          print.
        </div>
        <img src={PreviewImg} alt="previewImg" />
      </div>
    </div>
  );
}

export default ProjectPreview;
