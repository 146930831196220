import { React, Fragment, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Masonry from "react-smart-masonry";
import "../../PublicShare/Shared/Shared.scss";
import "../../../shades/Bucket.scss";
import "./PublicBucket.scss";
import TopBarPublic from "../TopbarPublic/TopbarPublic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import SingleCard from "../../../shades/cards/singleCard";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import DeleteIcon from "../../../assets/icons/DarkGrey-Delete.svg";
import LightDelete from "../../../assets/icons/Grey_Delete.svg";
import ThreeDots from "../../../assets/icons/threeDots.svg";
import Arrow from "../../../assets/icons/arrow.svg";
import SortMbl from "../../../assets/icons/sortmbl.svg";
import Profile1 from "../../../assets/icons/profile1.png";
import Profile2 from "../../../assets/icons/profile2.png";
import Profile3 from "../../../assets/icons/profile3.png";
import Union from "../../../assets/icons/Union-19.svg";
import clipboardNotes from "../../../assets/icons/8725602_clipboard_notes_icon.svg";
import SubTag from "../../../assets/icons/subTag.svg";
import SubShare from "../../../assets/icons/subShare.svg";
import resizeIcon from "../../../assets/icons/resizeIcon.svg";
import logo from "../../../assets/images/facebooklogo.png";
import Cardimage from "../../../assets/images/cardImage.png";
import { toastify } from "../../Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ShareCard from "../../../shades/cards/shareCard";
import BlackDismiss from "../../../assets/icons/dismissButton.svg";
import MoveModal from "../../../shades/cards/includes/MoveModal";
const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };

function PublicBucket() {
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [moveModal,setMoveModal]=useState(false)
  // const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [subBucket, setSubBucket] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const [bucketCreated, setBucketCreated] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [cardCreated, setCardCreated] = useState(false);
  const [bucketOperation, setBucketOperation] = useState("Add");
  const [selectedItems, setSelectedItems] = useState([]);
  let [isOpens, setIsOpen] = useState(false);
  let [isOpenss, setIsOpens] = useState(false);
  // const [isOpen] = useOutletContext();
  const [buckets, setBuckets] = useState([]);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [clickedCardId, setClickedCardId] = useState(null);
  const [totalCards, setTotalCards] = useState(0);
  const [BucketSharePopup, setBucketSharePopup] = useState(false);
  const [shareBucketId, setShareBucketId] = useState("");
  const [appTheme,setAppTheme]=useState("")
  const [forceUpdate, setForceUpdate] = useState(false);
  const bucketId = useParams();
  // console.log(bucketId, "bucketId");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    console.log(urlSearchParams, "urllllllllllllllllllllllll");
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);

  function closeModal() {
    setIsOpen(false);
  }

  function closeCardModal() {
    setIsOpens(false);
  }
  const closeSharePop = () => {
    console.log("close share pop");
    setBucketSharePopup(false);
  };
  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareBucketId(id);
    setBucketSharePopup(true);
  };
  const filterDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
    // { name: 'Manual', href: '#' },
  ];

  let cardFilterv = "";
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem("cardFilter", "Created_Date");
    cardFilterv = "Created_Date";
  } else {
    cardFilterv = localStorage.getItem("cardFilter");
  }
  console.log(cardFilterv, "cardFilterv");
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // api url
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      setIsLoadings(true);
      await axios
        .post(
          `${process.env.REACT_APP_API}buckets/public-shared-bucket`,
          { filterValue: cardFilterDefault, bucketId:bucketId.id , page: currPage, limit: 8 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setIsLoading(false);
          console.log(response.data.data, "<<<");
          if (!response.data.data.cardsList.length) {
            setLastList(true);
            setIsLoadings(false);
            return;
          }
          setPrevPage(currPage);
          // setUserList([...userList, ...response.data.data]);
          setCardList([...cardList, ...response.data.data.cardsList]);
          setTotalCards(response.data.data.totalCards);
          setIsLoadings(false);
          setSubBucket(response.data.data);
          console.log(response.data.data.sharedItems,"sharedItems")
          // setCardList(response.data.data);
          // setBucketList(response.data.data.bucketList);
          // setTotalBuckets(response.data.data.total);
          // console.log(response.data.data,"cardList before sorting");
          // reorder(response.data.data.slice(0,response.data.data.length),4);
        })
        .catch(function (error) {
          console.log(error);
          toastify("error", error.response.data.message);
          setTimeout(() => {
            navigate(`/expiry`);
          },1000);
        });
    };


    const getBrowserId = () => {
      // Implement your logic to generate or retrieve a unique browser ID
      // For example, you might use a combination of user agent and some randomization
      const userAgent = navigator.userAgent;
      console.log(userAgent, "userAgent");
      const randomPart = Math.random().toString(36).substring(2, 15);
      const browserId = `${userAgent}_${randomPart}`;

      return browserId;
    };

    const StarredCard = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API}cards/updateSharedCard-engagements`,
          { cardId: bucketId.id, views: 1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    const browserId = getBrowserId();

    if (
      !localStorage.getItem("browserId") &&
      !localStorage.getItem("sharedCardId")
    ) {
      localStorage.setItem("browserId", browserId);
      localStorage.setItem("sharedBucketId", bucketId.id);
      // const token = localStorage.getItem("token");
      StarredCard();
    } else {
      const sharedBucketId = localStorage.getItem("sharedBucketId");
      if (bucketId.id !== sharedBucketId) {
        localStorage.setItem("sharedBucketId", bucketId.id);
        StarredCard();
      }
    }

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      console.log(
        scrollTop,
        clientHeight,
        scrollHeight,
        "scrollTop, clientHeight, scrollHeight window"
      );
      console.log(
        parseInt(scrollTop),
        parseInt(clientHeight),
        parseInt(scrollHeight),
        "scrollTop, clientHeight, scrollHeight window"
      );
      if (
        parseInt(scrollTop) + parseInt(clientHeight) ===
        parseInt(scrollHeight)
      ) {
        setCurrPage(currPage + 1);
      }
    };

    if (!lastList && prevPage !== currPage) {
      fetchData();
      // console.log(listInnerRef,"listInnerRef");
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    bucketCreated,
    cardCreated,
    currPage,
    lastList,
    prevPage,
    cardList,
    isLoadings,
  ]);
  const handleCardClick = (cardId) => {
    setClickedCardId(cardId);
  };
  useEffect(() => {
    console.log(clickedCardId, "card iddddddddddddddddd");
  }, [clickedCardId]);

  const onScroll = () => {
    console.log(listInnerRef, "listInnerRef onScroll");
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log(
        scrollTop,
        scrollHeight,
        clientHeight,
        "scrollTop, scrollHeight, clientHeight"
      );
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const reorder = (arr, columns) => {
    // READ HERE
    // this is the magic
    // re-order the array so the "cards" read left-right
    // cols === CSS column-count value

    const cols = columns;
    const out = [];
    let col = 0;
    while (col < cols) {
      for (let i = 0; i < arr.length; i += cols) {
        let _val = arr[i + col];
        if (_val !== undefined) out.push(_val);
      }
      col++;
    }
    console.log(out, "out after sorting");
    setCardList(out);
  };
  // const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
  const [filtersText, setFiltersText] = useState(
    cardFilterv == "Created_Date"
      ? filterDropDown[0].name
      : cardFilterv == "Descending_Order"
      ? filterDropDown[3].name
      : cardFilterv == "Ascending_Order"
      ? filterDropDown[2].name
      : cardFilterv == "Recent_Activity"
      ? filterDropDown[1].name
      : ""
  );

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  console.log("Selected Items:", selectedItems);
  const MoveCard = async () => {
    setMoveModal(true)
    setOpenMove(true);
  };
 
  const closeMove = () => {
    // setIsBucketOpen(false);
    // setSelectedItems("")
    setSelectedItems([])
    setMoveModal(false)
    setOpenMove(false);
    // setselectedBucketId("");
    // setSearchBucket("");
  };
  const deleteCard = async () => {
    setIsOpens(true);
  };

  const handleDeleteCard = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://prdapi.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: selectedItems },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      setSelectedItems([]);
      closeCardModal();
      cardUpdate();
    } catch (error) {
      console.error(error);
      closeCardModal();
      setIsLoading(false);
      toastify("error", error.response.data.message);
    }
  };

  const handleFilterText = async (text) => {
    // setFiltersText(text);
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues =
      text == "Created Date"
        ? "Created_Date"
        : text == "Descending Order"
        ? "Descending_Order"
        : text == "Ascending Order"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : "";
    localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    const token = localStorage.getItem("token");
    cardUpdate();
  };
  const bucketNav = [
    { icon: faTrash, name: "Select All", href: "#" },
    { icon: faTrash, name: "Unselect All", href: "#" },
    // { icon: faPencil, name: "Edit Name", href: "#" },
    // { icon: faNoteSticky, name: "Create New Card", href: "#" },
    // { icon: faTrash, name: "Delete", href: "#" },
    // { icon: faFolder, name: "Customize New Card", href: "#" },
  ];


  const handleAllCardClick = (text) => {
    if (text == "Select All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (!checkbox.checked) {
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItems((prevSelectedItems) => [
              ...prevSelectedItems,
              checkbox.value,
            ]);
          }
        } else {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    } else if (text == "Unselect All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    }
  };

  const handleCardClickRequest = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}cards/updateSharedCard-engagements`,
        { cardId: bucketId.id, clicks: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleSignup = async () => {
    window.open("/signup", "_blank");
  };

  const createBucket = async (name) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://prdapi.zimzee.com/api/buckets/add/",
        { name, userId: userData._id, parentId: bucketId.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const createSubBucket = async (event) => {
    event.preventDefault();
    const name = event.target.name.value; // Get the name value from the form
    await createBucket(name);
    setPopup(false);
  };

  function createSub() {
    // setPopup(false);
    setBucketOperation("subbucket");
    setPopup(true);
  }

  function closePopup() {
    setPopup(false);
  }

  function cardUpdate() {
    // setBucketCreated(true);
    setLastList(false);
    setCurrPage(1);
    setPrevPage(0);
    setCardList([]);
    setCardCreated(!cardCreated);
  }

  function bucketUpdate() {
    // setBucketCreated(true);
    setBucketCreated(!bucketCreated);
  }

  function getUrl(type) {
    if (type === "list") {
      return `/app/buckets/${bucketId.id}`;
    } else {
      return `/app/cardlist/${bucketId.id}`;
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <TopBarPublic />
      <div className="publicshare-section" onClick={handleCardClickRequest}>
        <div className="flex publicspace-content">
          <div className="publicdesc-content">
          {subBucket?.sharedItems
                    ? subBucket?.sharedItems[0]?.sharedBy?.firstName
                    : ""}{" "}
                  {subBucket?.sharedItems
                    ? subBucket?.sharedItems[0]?.sharedBy?.lastName
                    : ""} shared this bucket using Zimzee.com
          </div>
          <div className="buttonpublic-content">
            <button
              style={{
                filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
              }}
              onClick={handleSignup}
              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
            >
              <span className="savebutton-context">Sign Up</span>
            </button>
          </div>
        </div>
        <div className="publictag-content">
        <div className="subBuckets-whole listingCards">
          {/* Single Card */}
          <div className="mansorydesktop-section">
            <Masonry
              breakpoints={breakpoints}
              columns={{ mobile: 1, tablet: 3, desktop: 4 }}
              gap={{ mobile: 20, tablet: 15, desktop: 15 }}
              style={{ display: "flex" }}
            >
              {/* <div 
        onScroll={onScroll}
        ref={listInnerRef}
        // style={{ height: "100vh", overflowY: "auto" }}
      > */}
              {cardList?.map((card) => {
                // console.log(card);
                const sharedWithObjects = card?.shared
                ? card.shared
                  .filter(shares => shares.sharedWith != null)
                  .map(shares => shares.sharedWith)
                : [];
                return (
                  <SingleCard
                    key={card._id}
                    handleSharePop={handleSharePop}
                    starred={card.starred}
                    buckets={buckets}
                    cardUpdate={cardUpdate}
                    cardId={card._id}
                    cardType={card.cardType}
                    sharedBy={[]}
                    sharedUsers={sharedWithObjects}
                    sharedLength={card.shared.length}
                    title={card.title}
                    content={card.content}
                    note={card.note}
                    tags={card.tags}
                    postDetail={card.postDetail ? card.postDetail : ""}
                    updated={card.updatedAt}
                    handleCheckboxChange={handleCheckboxChange}
                    handleCardClick={handleCardClick}
                    PublicBucket={true}
                  />
                );
              })}
              {/* </div> */}
            </Masonry>
          </div>
          {isLoadings && (
            <Masonry
              breakpoints={breakpoints}
              columns={{ mobile: 2, tablet: 3, desktop: 4 }}
              gap={{ mobile: 20, tablet: 15, desktop: 15 }}
            >
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
              <div
                className="cardHolder rounded-[14px] overflow-hidden break-inside"
                style={{ height: "330px" }}
              >
                <div className="cardheight-content">
                  <div className="flex justify-between w-full px-3 pt-3">
                    <div className="inputCheck">
                      <input type="checkbox" />
                    </div>
                    <div className="flex">
                      <Skeleton />
                    </div>
                  </div>
                  <div className="cardHeader flex items-center pb-1 px-3">
                    <div className="logo w-[30px] rounded">
                      <Skeleton />
                    </div>
                    <div className="Cardtitle w-3/4 pl-3">
                      <Skeleton />
                    </div>
                    <div className="iconsRight flex items-center">
                      <Skeleton />
                    </div>
                  </div>
                  <p></p>
                  <div className="cardContent cardListContent w-full">
                    <Skeleton />
                  </div>
                </div>
                <div className="px-3">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
                <div className="lastsection-subicon flex justify-between bg-[#F8F8F8] py-3 px-5 mt-1">
                  <Skeleton />
                </div>
              </div>
            </Masonry>
          )}
        </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default PublicBucket;
