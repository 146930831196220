/*global chrome*/
// AuthRedirect.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ActiveSubscription = ({ children }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null); // State to hold fetched user data

  useEffect(() => {
    let localUserData = JSON.parse(localStorage.getItem('userData'));
    const urlSearchParams = new URLSearchParams(window.location.search);
    
    if (urlSearchParams.get('payment_intent')) {
      localUserData.isActiveSubscription = true;
      localStorage.setItem('userData', JSON.stringify(localUserData));
    }

    const fetchUserAuth = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        console.log(response, 'Me response for authentication');

        if (!response?.data?.data?.status) {
          clearLocalStorageData();
          sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
          navigate('/');
        }

        if (response?.data?.data) {
          setUserData(response.data.data); // Save fetched data to state
          if (!response.data.data.isActiveSubscription && !urlSearchParams.get('payment_intent') && response.data.data.status) {
            if (!response.data.data.isComplementary && !response.data.data.isTeamMember) {
              navigate('/app/userPricing');
            }
          }
        }
      } catch (error) {
        console.error(error);
        clearLocalStorageData();
        sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
        navigate('/');
      }
    };

    const clearLocalStorageData = () => {
      const keysToRemove = ['userData', 'token', 'settingsToken', 'cardFilter', 'bucketFilter', 'bucketView', 'sharecardFilter', 'subscriptionpriceId', 'subscriptionType', 'renewPlan', 'renewPrice', 'renewPriceId', 'planPrice', 'subscriptionplanId', 'sharedClickCardId', 'sharedCardId', 'cardFilter', 'browserId'];
      keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    const sendTokenToChromeExtension = ({ extensionId, removejwt }) => {
      try {
        if (chrome && chrome?.runtime) {
          chrome.runtime.sendMessage(extensionId, { removejwt }, response => {
            // Handle response if needed
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserAuth();
  }, [navigate]);

  // Pass userData to children components using React.cloneElement
  return <>{React.cloneElement(children, { userData })}</>;
};

export default ActiveSubscription;
