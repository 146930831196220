import React from "react";
import './Settings.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RemainderEmail from "./RemainderEmail/RemainderEmail";
import TeamMembers from "./TeamMembers/TeamMembers";
import { useEffect,useState } from "react";
import axios from "axios";
import { toastify } from '../Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const [apiCalled, setApiCalled] = useState(false);
  useEffect(()=>{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tab= urlSearchParams.get('tab');
    if(tab){
      setActiveTab(1)
    }
    const tokenId = urlSearchParams.get('tokenId');
    const token = localStorage.getItem('token');
    const userID=JSON.parse(localStorage.getItem('userData'))
    const setting=localStorage.getItem('settingsToken')
    if(!apiCalled && tokenId || setting){
      try {
        const response = axios.post('https://prdapi.zimzee.com/api/users/token-verify',
        {
       token:tokenId ? tokenId :setting
        },
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
        );
        
        console.log(response);
        // if(response && tokenId || setting && userID){
        //   try {
        //     const response = axios.post('https://prdapi.zimzee.com/api/team/accept-invitation',
        //     {
        //    tokenId:tokenId ? tokenId :setting,
        //    recipientUserId:userID._id,
        //     },
        //     {
        //       headers: {
        //           Authorization: `Bearer ${token}`,
        //         }
        //     }
        //     );
        //     toastify("success", response.data.message);
        //     setActiveTab(1);
        //     setApiCalled(true)
        //     console.log(response);
           
        //   } catch (error) {
        //     console.log(error)
        //     setActiveTab(1);

        //     // console.error(error?.response?.data?.message,"error msg");
        //     toastify("error", error?.response?.data?.message);
        //   }
        // }

       
      } catch (error) {
        console.log(error)
        // console.error(error?.response?.data?.message,"error msg");
        toastify("error", error?.response?.data?.message);
      }
    }
  },[])
  return(
    <div className="settings-section">
      <div className="settings-content">Settings</div>
      <div className="settings-tab">
        <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
            <TabList>
            <Tab>Reminder email</Tab> 
            {/* <Tab>Team Members</Tab>  */}
            </TabList>
            <TabPanel>
              <RemainderEmail />
            </TabPanel>
            <TabPanel>
              <TeamMembers />
            </TabPanel>
        </Tabs> 
      </div>
    </div>
  );
}
export default Settings;