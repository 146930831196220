import React, { useEffect } from 'react';
import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";

function Logout(){
    const navigate = useNavigate();
    useEffect(() => {
        // Check the user's login status (you might want to use your own logic)
        // const isUserLoggedIn = /* your logic to check if the user is logged in */;
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('settingsToken')
        localStorage.removeItem('cardFilter');
        localStorage.removeItem('bucketFilter');
        localStorage.removeItem('bucketView');
        localStorage.removeItem('sharecardFilter');
        localStorage.removeItem('subscriptionpriceId');
        localStorage.removeItem('subscriptionType');
        localStorage.removeItem('renewPlan');
        localStorage.removeItem('renewPrice');
        localStorage.removeItem('renewPriceId');
        localStorage.removeItem('planPrice');
        localStorage.removeItem('subscriptionplanId');
        localStorage.removeItem('sharedClickCardId');
        localStorage.removeItem('sharedCardId');
        localStorage.removeItem('cardFilter');
        localStorage.removeItem('browserId');
        navigate('/');
      }, []);
    return(
        <div className="loginHolder min-h-[100vh] flex items-center bg-[#F3F4F6]">
        <div className="formHolder bg-white md:w-1/2 lg:w-2/5 max-w-lg mx-auto p-8 rounded-lg shadow text-center">
            <h3 className="text-xl font-bold text-[24px]">You have signed out of Zimzee</h3>
            <div className="logo flex justify-center my-8">
                <img src={logo} />
            </div>
            <a href="/" type="button" className="button bg-[#FF6600] text-white py-2 px-5 font-bold rounded-[5px] mt-4">Login Again</a>
        </div>
    </div>
    )
}

export default Logout;