import { React } from "react";
import "../../../PricingPlans/Plans/PlanCheckout.scss";
import "./AddPayment.scss"; 
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import magnifier from "../../../../assets/icons/magnifyGlass.svg";
import Visacards from "../../../../assets/icons/Visa Cards.svg";
import Visacardsdark from "../../../../assets/icons/Visa card-dark mode.svg";
import Bankcardsdark from "../../../../assets/icons/bank-dark mode (1).svg";
import Bank from "../../../../assets/icons/bank-grey.svg";
import Cardtab from "../../../../assets/icons/card-grey.svg";
import Bankorange from "../../../../assets/icons/bank-orange.svg";
import Cardorange from "../../../../assets/icons/card-orange.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import TagsInput from "react-tagsinput";

function AddPayment() {
  return (
    <div className="pricing-checkout add-payment">
      <div className="plan-billing">
        <div class="flex flex-row flex-wrap">
          <div class="w-full sm:w-1/3 md:w-3/6">
            <div className="pb-[30px]">
              <Tabs>
                <TabList>
                  <Tab>
                    <img className="inactive-icon" src={Cardtab} alt="bank" />
                    <img
                      className="dark-inactive-icon"
                      src={Visacardsdark}
                      alt="bank"
                    />
                    <img className="active-icon" src={Cardorange} alt="bank" />
                    Card
                  </Tab>
                  <Tab>
                    <img className="inactive-icon" src={Bank} alt="bank" />
                    <img
                      className="dark-inactive-icon"
                      src={Bankcardsdark}
                      alt="bank"
                    />
                    <img className="active-icon" src={Bankorange} alt="bank" />
                    US bank account
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="flex items-center rounded-3xl border-0 input-icon mb-[30px]">
                    <div className="w-full">
                      <div className="flex">
                        <input
                          className="card-inputfield p-[10px]"
                          type="text"
                          placeholder="Card number"
                        />
                        <img
                          className="magnifier fileicon-content m-[auto] pr-[14px]"
                          src={Visacards}
                          alt="magnifying-glass"
                        />
                      </div> 

                      {/* <button onClick={handleAddFromDropdown}>Add Tag from Dropdown</button> */}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="payment-section pr-[10px]  md:w-1/2">
                      <input
                        className="card-inputfield p-[10px]"
                        type="text"
                        placeholder="Expiration date"
                        required
                      />
                    </div>
                    <div className="payment-section pl-[10px]  md:w-1/2">
                      <input
                        className="card-inputfield p-[10px]"
                        type="text"
                        placeholder="CVC"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center rounded-3xl border-0 mt-[30px] input-icon">
                      <div className="w-full">
                        <div className="flex">
                          <input
                            className="card-inputfield p-[10px]"
                            type="text"
                            placeholder="Country"
                          />
                          <FontAwesomeIcon
                            className="fileicon-content m-[auto] pr-[12px]"
                            style={{}}
                            icon={faChevronDown}
                          />
                        </div>

                        {/* <button onClick={handleAddFromDropdown}>Add Tag from Dropdown</button> */}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="payment-section pb-[30px]">
                    <input
                      className="card-inputfield p-[10px]"
                      type="text"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="payment-section pb-[30px]">
                    <input
                      className="card-inputfield p-[10px]"
                      type="text"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="flex items-center rounded-3xl border-0 input-icon">
                    <div className="w-full">
                      <div className="flex">
                        <input
                          className="card-inputfield p-[10px]"
                          type="text"
                          placeholder="Bank Account"
                        />
                        <img
                          className="magnifier fileicon-content m-[auto] pr-[12px]"
                          src={magnifier}
                          alt="magnifying-glass"
                        />
                      </div>

                      {/* <button onClick={handleAddFromDropdown}>Add Tag from Dropdown</button> */}
                    </div>
                  </div>
                  <div>
                    <div className="bank-options grid grid-cols-3 gap-4 pt-[30px]">
                      <div>Test Institution</div>
                      <div>Test OAuth Institution</div>
                      <div>Ownership Account</div>
                      <div>Invalid Payment Accounts</div>
                      <div>Down Bank (unscheduled)</div>
                      <div>Down Bank (unknown error)</div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
            <div className="acknowledgement">
              <p>
                By providing your card information, you allow Monthly plan to
                charge your card for future payments in accordance with their
                <span className="underline"> terms and conditions</span>
              </p>
            </div>
            <div className="pb-[30px]">
              <div className="flex">
                <input
                  className="inputfocus-content"
                  type="checkbox"
                  name="payment"
                />
                <div className="default-payment ml-2">
                  Use as default payment method
                </div>
              </div>
            </div>
            {/* <div className="acknowledgement">
              <p>
                You can review important information for plans on their
                <span className="underline">
                  Terms of Service and Privacy Policy pages
                </span>
              </p>
            </div> */}
          </div>
        </div>
        {/* <div className="flex">
          <button
            style={{
              filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
            }}
            className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
          >
            <span className="savebutton-context">Update</span>
          </button>
          <div className="cancelfilecard-content">Cancel</div>
        </div> */}
      </div>
    </div>
  );
}
export default AddPayment;
