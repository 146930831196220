import {React,useState, useEffect} from "react";
import axios from 'axios';
import './ManageInvite.scss'
import Profile1 from "../../../assets/icons/profile1.png";
import DeleteIcon from "../../../assets/icons/DarkGrey-Delete.svg";
import DismissButton from "../../../assets/icons/dismissButton.svg";
import ProfileUser from "../../../assets/images/profileuser.svg"
import InviteTeam from "../../../assets/images/manageTeam.png";
import { toastify } from '../../Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Avatar from 'react-avatar';
import '../MySubscription/BillingInfo/BillingInfo.scss'

function ManageInvite() {
  const [cancelpopup, setCancelPopup] = useState(false);
  const [unassignpopup, setUnassignPopup] = useState(false);
  const [removeInvitepopup, setRemoveInvitePopup] = useState(false);
  const [MemberRefresh,setMemberRefresh]=useState(false);
  const[teamMembers,setTeamMembers]=useState([]);
  const[invitedTeamMembers,setInvitedTeamMembers]=useState([]);
  const[memberId,setMemberId]=useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [invitepopup, setInvitePopup] = useState(false);
  const [inviteSeatpopup, setInviteSeatPopup] = useState(false);
  const [email,setEmail]=useState('')
  const [count, setCount] = useState(0)
  const [countPurchase, setPurchaseCount] = useState(0)
  const [teamMember, setTeamMember] = useState(0);
  const [purchaseTeamMembers, setPurchaseTeamMembers] = useState("");
  const [utilizedSeats, setUtilizedSeats] = useState(0);
  const [availableSeats, setAvailableSeats] = useState(0);
  const [invitedId, setInvitedId] = useState('');
  const [teamMemberPriceId, setTeamMemberPriceId] = useState("");
  const [planActive, setPlanActive] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [teamMemberPlan, setTeamMemberPlan] = useState("");
  const [availableSeatsList, setAvailableSeatsList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const[seatId, setSeatId] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    let userData =JSON.parse(localStorage.getItem('userData'));
    if(userData.isTeamMember && !userData.isActiveSubscription){
      window.history.go(-1);
    }
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}team/owner-team-members`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response?.data?.data?.teamMembers);
        setUtilizedSeats(response?.data?.data?.utilizedSeats);
        setAvailableSeats(response?.data?.data?.availableSeats);
        setMemberRefresh(false)
        console.log(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchInvitedTeamMember = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API}team/invited-members-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // setTeamMembers(response?.data?.data?.teamMembers)
        setInvitedTeamMembers(response?.data?.data);
        console.log(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    const fetchTotalSeats = async () => {
        setIsLoading(true);
        try{
          const response = await axios.get(
            `${process.env.REACT_APP_API}subscriptions/toatal-seates`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }
          ); // Replace this with the actual API endpoint
          console.log(response,"fetchTotalSeats");
          if(response.data.data.availableSeats){
            if(response.data.data.availableSeats.length){
              setAvailableSeatsList(response.data.data.availableSeats);
            }
          }
        }  catch (error) {
          console.error("Error fetching data:", error);
        }
    }


    fetchTotalSeats();
    
    fetchData(); // Call the async function immediately
    fetchInvitedTeamMember();
    // The empty dependency array means this effect runs once after initial render.
  }, [MemberRefresh]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // console.log(response,"response");
        if(response){
          let productList = findObjectByProductId(response?.data?.data?.productList,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          // console.log(productList,"productList team member");
          if(productList){
            setTeamMemberPlan(productList.name);
            setTeamMemberPriceId(productList.priceList[0].id);
          }
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint

        if(response.data.data.userDetails){
          if(response.data.data.userDetails.isComplementary){
            setPlanActive('Lifetime Promo');
          }
        }
        // console.log(response.data.data.subscriptions,"response");
        if(response.data.data.paymentMethods){
          console.log(response.data.data.paymentMethods,"response.data.data.paymentMethods.data");
          // setPaymentMethod(response.data.data.paymentMethods);
          const paymentDefault = response.data.data.paymentMethods.find(item => item?.isDefault);
          // console.log(paymentDefault,"paymentDefault");
          if(paymentDefault){
            setSelectedTag(paymentDefault.id);
          }else{
            setSelectedTag(response.data.data.paymentMethods[0].id);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchTotalSeats = async () => {
      setIsLoading(true);
      try{
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/toatal-seates`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        console.log(response,"fetchTotalSeats");
      }  catch (error) {
        console.error("Error fetching data:", error);
      }
  }


    fetchTotalSeats();
    fetchPlans();
    fetchActiveSubscription();

  }, []);

  const handleSendInvite=async (userEmail = '')=>{
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}team/send-team-invite`,
      {
      email:userEmail? userEmail : email
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      setEmail('');
      setIsLoading(false);
      
      closeInvitePopup()
      console.log(response);
      setMemberRefresh(true);
      // setMemberRefresh(!invitepopup)
      setTimeout(() => {
        toastify("success", response.data.message);
      }, 2000);
    } catch (error) {
      console.log(error,"consoleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      // console.error(error?.response?.data?.message,"error msg");
      setIsLoading(false);
      setEmail('');
      closeInvitePopup();
      setTimeout(() => {
        toastify("error", error?.response?.data?.message);
      }, 2000);
    }
  }

  const handleDeleteMember=async(isDeleted = false)=> {
    const token = localStorage.getItem('token');
    let delTeamMember = isDeleted ? {teamMemberId:memberId,isDeleted:true} : {teamMemberId:memberId}
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}team/revoke-member-fromTeam`,delTeamMember,
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
  
      isDeleted ? closeCancelPopup() : closeUnassignPopup();
      toastify("success", response.data.message);
      // setMemberRefresh(true)
      console.log(response);
      setTimeout(() => {
        setMemberRefresh(true);
      }, 2000);
     
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }

  

  const handleDeleteSeat=async()=> {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}subscriptions/cancel-subscriptionById`,{subscriptionId:seatId},
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      closeDeletePopup();
      toastify("success", response.data.message);
      // setMemberRefresh(true)
      console.log(response);
      setTimeout(() => {
        setMemberRefresh(true);
      }, 2000);
     
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }


  const handleInvitedMember = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}team/delete-invitation/${invitedId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response");
      toastify("success", response.data.message);
      closeRemoveInvitePopup();
      setTimeout(() => {
        setMemberRefresh(true);
      }, 2000);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
      closeRemoveInvitePopup();
    }
  }

  
  const handleDeletePopup = (id) => {
    setSeatId(id);
    setDeletePopup(true);
  }

  const closeDeletePopup = () => {
    setDeletePopup(false);
  }

  const handleCancelPopup = (id) => {
    setMemberId(id);
    setCancelPopup(true);
  }
  

  const handleUnassignPopup = (id) => {
    setMemberId(id);
    setUnassignPopup(true);
  }

  const handleRemoveInvitePopup = (id) => {
    setInvitedId(id);
    setRemoveInvitePopup(true);
  }

  const closeCancelPopup = () => {
    setCancelPopup(false);
  }

  const handleInvitePopup = () => {
    setInvitePopup(true);
  }

  const closeInvitePopup = () => {
    setInvitePopup(false);
  }

  const handleSeatInvitePopup = () => {
    setInviteSeatPopup(true);
  }
  const closeSeatInvitePopup = () => {
    setInviteSeatPopup(false);
  }

  const closeUnassignPopup = () => {
    setUnassignPopup(false);
  }

  const closeRemoveInvitePopup = () => {
    setRemoveInvitePopup(false);
  }

  const handleIncrement = () => {
    let countI = count + 1;
    // let price = 20;
    // console.log(price,Number(renewPrice),Number(countI * 20));
    let team = Number(countI * 20);
    setCount(count + 1);
    setTeamMember(team);
    // setRenewPrice(price);
  };

  const handleDecrement = () => {
    if (count + countPurchase > 0) {
      let countI = count - 1;
      // let price = 20;
      // console.log(price,Number(renewPrice),Number(countI * 20));
      let team = Number(countI * 20);
      setCount(count - 1);
      setTeamMember(team);
      // setRenewPrice(price);
    }
  };

  const handleTeamMemberSubscription = async () => {
    if(count && teamMemberPriceId){
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if(selectedTag == ""){
        setIsLoading(false);
        setCount(0);
        toastify("error", "Please Add payment Method First");
        return;
      }
      let planObj = { teamMemberDetails: {priceId: teamMemberPriceId, quantity: count, planId: `${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`, planName: teamMemberPlan},paymentMethod:selectedTag  }
      console.log(planObj);
      try{
        const response = await axios.patch(
          `${process.env.REACT_APP_API}subscriptions/update`,
          planObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response,"response");
        setCount(0);
        closeSeatInvitePopup();
        // props.handleDeattachRefresh();
        setMemberRefresh(true);
        setTimeout(() => {
          toastify("success", response.data.message);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
        setCount(0);
        toastify("error", error?.response?.data?.message);
      }
    }else{
      toastify("error", "Price Id and quantity not exist" );
    }
  }
  return(
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
      <>
      <div className="manage-section">
        <div className="flex">
          <div className="manageteam-content">Manage teams</div>
          <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={handleInvitePopup}>
            <span className="savebutton-context">Invite a member</span>
          </button>
          </div>
        <div className="flex seatssec-content">
          <div className="manageseats-content">Utilized seats</div>
          <div className="manageseats-content">Available seats</div>
        </div>
        <div className="flex countdetails-content">
          <div className="managecount-content">{utilizedSeats}</div>
          <div className="managecount-content">{availableSeats} <span className="manageadd-content" style={{cursor:"pointer"}} onClick={handleSeatInvitePopup}>+Add seats</span></div>
        </div>
        
        {
          invitedTeamMembers.length || teamMembers.length ?
          <>
          <div className="invitemem-content">Available Seats</div>
          <div className="manageinvite-desktop">
            <div className="flex">
              <div className="headingfirstinvite-content">Seat</div>
              <div className="headingdatefrstinvite-content">Status</div>
              {/* <div className="headingfirstinvite-content">Created At</div> */}
            </div>
          </div>
          <hr />

          <div className="manageinvite-desktop">
          {availableSeatsList && availableSeatsList?.map((item) => (
              <>
              <div className="flex managdetail-content">
              {/* <div className="submanagedetail-content flex"><img style={{marginRight: "6px"}} src={Profile1} alt="Profile1" />Nicky Kalliongis</div> */}
                <div className="submanagedetail-content">{item?.quantity}</div>
                <div className="subinvitefrstdetail-content">{item?.occupiedTeamMemberId? 'Assign' : 'Unassign'}</div>
                {/* <div className="submanagedetail-content">{moment(item?.createdAt).format('MMM D, YYYY @ hh:mm A')}</div> */}
                {/* <div className="datedetail-content"  onClick={() => handleSendInvite(item?.invitedUserEmail)}>Resend invite</div> */}
                {
                  !item?.occupiedTeamMemberId? 
                  <div className="managedelete-imgcontent"><img src={DeleteIcon} alt="deleteIcon" onClick={() => handleDeletePopup(item.subscriptionId)} /></div> : ""
                }
                
              </div>
              <hr />
              </>
            ))}
          </div>

          <div className="manageinvite-mbl">
          {availableSeatsList && availableSeatsList?.map((item) => (
              <>
              <div className="flex justify-between managdetail-content">
                  <div className="submanagedetail-content">{item?.quantity}</div>
                  <div className="subinvitefrstdetail-content">{item?.occupiedTeamMemberId? 'Assign' : 'Unassign'}</div>
                  <div><img src={DeleteIcon} alt="deleteIcon" onClick={() => handleDeletePopup(item.subscriptionId)} /></div>
              </div>
              <hr />
              </>
              ))}
            </div>

          <div className="invitemem-content manageactive-section">Invited members</div>
          <div className="manageinvite-desktop">
            <div className="flex">
              <div className="headingfirstinvite-content">Email</div>
              <div className="headingdatefrstinvite-content">Invited on</div>
              <div className="headingfirstinvite-content">Invited valid</div>
            </div>
          </div>
          <hr />

          <div className="manageinvite-desktop">
            {invitedTeamMembers && invitedTeamMembers?.map((item) => (
              <>
              <div className="flex managdetail-content">
              {/* <div className="submanagedetail-content flex"><img style={{marginRight: "6px"}} src={Profile1} alt="Profile1" />Nicky Kalliongis</div> */}
                <div className="submanagedetail-content">{item?.invitedUserEmail}</div>
                <div className="subinvitefrstdetail-content">{moment(item?.createdAt).format('MMM D, YYYY @ hh:mm A')}</div>
                <div className="submanagedetail-content">{moment(item?.createdAt).format('MMM D')} - {item?.validUpTo ? moment(item?.validUpTo).format('MMM D') : '--'}</div>
                <div className="datedetail-content"  onClick={() => handleSendInvite(item?.invitedUserEmail)}>Resend invite</div>
                <div><img src={DeleteIcon} alt="deleteIcon" onClick={() => handleRemoveInvitePopup(item?._id)} /></div>
              </div>
              <hr />
              </>
            ))}
          </div>
          
            <div className="manageinvite-mbl">
              {invitedTeamMembers && invitedTeamMembers?.map((item) => (
              <>
              <div className="flex justify-between managdetail-content">
                <div style={{width: "100%"}}>
                  <div className="submanagedetail-content">{item?.invitedUserEmail}</div>
                  <div className="subinvitefrstdetail-content">Invited on: {moment(item?.createdAt).format('MMM D, YYYY @ hh:mm A')}</div>
                  <div className="submanagedetail-content">Invited valid: {moment(item?.createdAt).format('MMM D')} - {item?.validUpTo ? moment(item?.validUpTo).format('MMM D') : '--'}</div>
                  <div className="datedetail-content"  onClick={() => handleSendInvite(item?.invitedUserEmail)}>Resend invite</div>
                </div>
                <div><img style={{width: "30px"}} src={DeleteIcon} alt="deleteIcon" onClick={() => handleRemoveInvitePopup(item?._id)} /></div>
              </div>
              <hr />
              </>
              ))}
            </div>
            




            <div className="invitemem-content manageactive-section">Active members</div>
            <div className="manageinvite-desktop">
              <div className="flex">
                <div className="headingnameactive-content">Name</div>
                <div className="headingemailactive-content">Email</div>
                <div className="headingdateactive-content">Joined on</div>
                <div className="headingactive-content">Shared buckets/cards</div>
              </div>
            </div>
            
              <hr />
              <div className="manageinvite-desktop">
                {
                teamMembers && teamMembers?.map((member) => (
                  <>
                  <div className="flex managdetail-content">
                    <div className="subactivenamedetail-content flex">
                    {member?.imageUrl ?<img className="profilecard-imgcontent" src={member?.imageUrl} alt="Profile1" />:
                      <div>
                        <Avatar name={`${member?.firstName} ${member?.lastName}`} size="50" style={{marginRight: "8px"}} round={true} />
                      </div>
                      }
                    {`${member?.firstName} ${member?.lastName}`}</div>
                    <div className="subactiveemaildetail-content">{`${member?.email}`}</div>
                    <div className="subinvitedetail-content">{moment(member?.inviteDate).format('MMM D, YYYY @ hh:mm A')}</div>
                    <div className="subcountdetail-content">{member?.totalSharedItems}</div>
                    <div className="datedetail-content" onClick={() => handleUnassignPopup(member._id)}>Unassign user</div>
                    <div className="managedelete-imgcontent"><img src={DeleteIcon} alt="deleteIcon" onClick={() => handleCancelPopup(member._id)} /></div>
                  </div>
                  <hr />
                  </>
                )) 
                }
              </div>
              
              <div className="manageinvite-mbl">
              {
                teamMembers && teamMembers?.map((member) => (
                  <>
                  <div className="flex managdetail-content"> 
                    <div style={{width: "100%"}}>
                      <div className="subactiveemaildetail-content">{`${member?.firstName} ${member?.lastName}`}</div>
                      <div className="subactiveemaildetail-content">{`${member?.email}`}</div>
                      <div className="subinvitedetail-content">Joined on {moment(member?.inviteDate).format('MMM D, YYYY')}</div>
                      <div className="subcountdetail-content">Shared buckets/cards {member?.totalSharedItems}</div>
                      <div className="datedetail-content" onClick={() => handleUnassignPopup(member._id)}>Unassign user</div>
                    </div>
                    <div><img style={{width: "30px"}} src={DeleteIcon} alt="deleteIcon" onClick={() => handleCancelPopup(member._id)} /></div>
                  </div>
                  <hr />
                  </>
                ))  
              }
              </div>
              


            </> : 
            <>
              <img className="manageinvite-imgcontent" src={InviteTeam} alt="inviteTeam" />
              <div className="manageassign-content">Invite, Assign & Collaborate</div>
              <div className="manageactive-content">All your Invited and Active team members seats and their updates will be list here</div>
              <button className="buttonpadding-content managebutton-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" type="button" onClick={handleInvitePopup}>
                <span className="savebutton-context">Invite a member</span>
              </button>
            </>
            
        }
        

        {
          removeInvitepopup && 
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeRemoveInvitePopup}>
            <div className="subcancelupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Remove Invitation</div>
                <div className="dismissSubfiles-content" onClick={closeRemoveInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <div>
                <div className="plancancel-content">Are you sure to delete?</div>
                {/* <div className="assigncancel-content">You'll be able to enjoy your team member seats and assign them until then.</div> */}
                <div className="flex justify-end cancellastfile-section">
                  <div className="cancelbillinfo-content" style={{cursor:"pointer"}} onClick={closeRemoveInvitePopup}>No</div>
                  <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" type="button" onClick={() => handleInvitedMember()}>
                    <span className="savemanagebutton-context">Yes</span>
                  </button>
              </div>
              </div>
            </div>
          </div>
        }

        {
          unassignpopup && 
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeUnassignPopup}>
            <div className="subcancelupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Unassign User</div>
                <div className="dismissSubfiles-content" onClick={closeUnassignPopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <div>
                <div className="plancancel-content">Unassigning a user will remove their access from all buckets and cards shared with them.</div>
                <div className="assigncancel-content">Note: The user seat will still be available to assign to another user until you cancel the seat</div>
                <div className="flex justify-end cancellastfile-section">
                  <div className="cancelbillinfo-content" style={{cursor:"pointer"}} onClick={closeUnassignPopup}>Cancel</div>
                  <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" type="button" onClick={() => handleDeleteMember(false)}>
                    <span className="savemanagebutton-context">Confirm</span>
                  </button>
              </div>
              </div>
            </div>
          </div> 
        }
        
        {cancelpopup &&
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeCancelPopup}>
            <div className="subcancelupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Delete Seat</div>
                <div className="dismissSubfiles-content" onClick={closeCancelPopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <div>
                <div className="plancancel-content">Deleting this seat will delete the user as well, and will remove their access from all buckets and cards shared with them.</div>
                {/* <div className="assigncancel-content">You'll be able to enjoy your team member seats and assign them until then.</div> */}
                <div className="flex justify-end cancellastfile-section">
                  <div className="cancelbillinfo-content" style={{cursor:"pointer"}} onClick={closeCancelPopup}>Cancel</div>
                  <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" type="button" onClick={() => handleDeleteMember(true)}>
                    <span className="savemanagebutton-context">Confirm</span>
                  </button>
              </div>
              </div>
            </div>
          </div> 
        }

      {deletePopup &&
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeDeletePopup}>
            <div className="subcancelupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Delete Seat</div>
                <div className="dismissSubfiles-content" onClick={closeDeletePopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <div>
                <div className="plancancel-content">Deleting this seat.</div>
                {/* <div className="assigncancel-content">You'll be able to enjoy your team member seats and assign them until then.</div> */}
                <div className="flex justify-end cancellastfile-section">
                  <div className="cancelbillinfo-content" style={{cursor:"pointer"}} onClick={closeDeletePopup}>Cancel</div>
                  <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" type="button" onClick={() => handleDeleteSeat()}>
                    <span className="savemanagebutton-context">Yes</span>
                  </button>
              </div>
              </div>
            </div>
          </div> 
        }

        {invitepopup &&
          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeInvitePopup}>
            <div className="subinviteupload-section" onClick={(e)=>e.stopPropagation()}>
              <div className="teaminvitesection flex">
                <div className="teammembers-content">Invite people</div>
                <div className="dismissSubfiles-content" onClick={closeInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
              </div>
              <hr/>
              <input className="w-full flex items-center rounded-3xl border-0 menusubitems-content teaminput-content" type="text" placeholder="Email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
              {/* <div className="flex justify-between">
                <div className="flex linkteam-content"><img className="teamlink-imgcontent" src={LinkIcon} alt="LinkIcon" />Invite with link</div>
                <div className="unshare-content" style={{cursor:"pointer"}}>Create link</div>
              </div>
              <div className="joinlink-content">Anyone with the link can join in Zimzee</div> */}
              <div className="flex justify-end lastfile-section">
                <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" onClick={() => handleSendInvite()}>
                  <span className="savebutton-context">Send Invite</span>
                </button>
              </div>
            </div>
          </div> 
        }
        {inviteSeatpopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeSeatInvitePopup}>
          <div className="subinviteupload-section" onClick={(e)=>e.stopPropagation()}>
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Add seats</div>
              <div className="dismissSubfiles-content" onClick={closeSeatInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/> 
            <div className="currentstatus-section">
              <div className="flex profilebill-content"><img style={{marginRight: "10px"}} src={ProfileUser} alt="ProfileUser" />Current Seats: {availableSeats ? availableSeats : 0} <span style={{marginLeft: "10px"}} className="seatsbill-content">+{count > 0 ? count : '0'} seat(s)</span></div>
              <div className="addbillseat-content">Add seats</div>
              <div className="flex teammember-section">
                <input type="text" placeholder="0" className="focusteamcount-content" value={count > 0 ? count : '0'} />
                <button className="decrementbutton-content" onClick={handleDecrement}>-</button>
                <button className="incrementbutton-content" onClick={handleIncrement}>+</button>
              </div>
              <div className="seatno-content">$20 per seat</div>
            </div>
            <div className="totalcharges-content">Total charges</div>
            <hr />
            <div className="flex seatsec-content">
              <div className="seatprice-content">Seats pricing</div>
              <div className="seatsmon-content">{count > 0 ? count : '0'} Seat(s) * $20/month</div>
            </div>
            <div className="flex pricesection-content">
              <div className="pricebill-content">Due now</div>
              <div className="pricebill-content">${teamMember}/month</div>
            </div>
            <div className="flex">
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={handleTeamMemberSubscription}>
                <span className="payupdatebutton-context">Pay</span>
              </button>
              <div className="cancelbill-content">Cancel</div>
            </div>
          </div>
        </div> 
      }
      </div>
      <ToastContainer />
      </>
    )}
   </>
  );
}
export default ManageInvite; 