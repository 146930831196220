import React, { useEffect } from 'react';
import logo from "../../assets/icons/logo.png";


function NotFound(){
    return(
        <div className="loginHolder min-h-[100vh] flex items-center bg-[#F3F4F6]">
        <div className="formHolder bg-white md:w-1/2 lg:w-2/5 max-w-lg mx-auto p-8 rounded-lg shadow text-center">
            <h3 className="text-xl font-bold text-[24px]">Not Found</h3>
            <p>Page not Found</p>
            <div className="logo flex justify-center my-8">
                <img src={logo} />
            </div>
            <div className="flex">
              <a class="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" href="https://app.zimzee.com/" style={{margin:"0 auto"}}><span class="savebutton-context">Go to home</span></a>
            </div>
            {/* <a href="/" type="button" className="button bg-[#FF6600] text-white py-2 px-5 font-bold rounded-[5px] mt-4">Login</a> */}
        </div>
    </div>
    )
}

export default NotFound;