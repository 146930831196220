import { React, Fragment, useState } from "react";
import "./ProjectsGrid.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import { faArrowUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import TrashIcon from "../../../assets/icons/Delete - Line icon.svg";
import ResizeIcon from "../../../assets/icons/resizeIcon.svg";
import ProjectImg1 from "../../../assets/images/Projects/projectImg1.png";
import ProjectImg2 from "../../../assets/images/Projects/projectImg2.png";
import ProjectImg3 from "../../../assets/images/Projects/projectImg3.png";
import ListView from "../../../assets/icons/listView.svg";
import compactCardIcon from "../../../assets/icons/Grid-view.svg";
import { Menu, Transition } from "@headlessui/react";

function GridProject() {
  const filterDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
    { name: "Manual", href: "#" },
  ];

  const [filtersText, setFiltersText] = useState(filterDropDown[0].name);

  const handleFilterText = (text) => {
    setFiltersText(text);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="projectgrid-wholesection">
      <div className="bucketHeader">
        <div className="grid grid-cols-3">
          <div className="bucketFeature col-span-2">
            <div className="flex items-center">
              <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                My Projects
              </h2>
              <button
                style={{
                  filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                }}
                className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
              >
                <FontAwesomeIcon
                  className="pl-[23px] pr-[17.73px]"
                  icon={fontIcons.faPlus}
                />
                <span className="createbutton-context">Create Project</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex mt-[18px]">
        <div className="cards-filter flex justify-between mb-5">
          <div className="flex items-center">
            <div className="flex actionsHolder">
              <div className="m-auto">
                <div href="#" className="flex items-center">
                  <FontAwesomeIcon
                    className="transform rotate-45"
                    icon={faArrowUp}
                  />
                  <span className="actionicon-content pl-3">Move to</span>
                </div>
              </div>
              <div>
                <div href="#" className="flex items-center pl-[24px]">
                  <img
                    className="w-6 trash-icon"
                    src={TrashIcon}
                    alt="trashIcon"
                  />
                  <span className="actionicon-content pl-3">Delete</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-auto">
          <div className="ml-[auto] flex items-center justify-end">
            <div className="filter display-content">
              <a href="projectlist">
                <img
                  className="mt-1 listcard-imgcontent"
                  src={ListView}
                  alt="listView"
                />
              </a>
              <a href="#">
                <img
                  className="mt-1 compact-imgcontent"
                  src={compactCardIcon}
                  style={{ color: "#000000" }}
                  alt="compactIcon"
                />
              </a>
            </div>
            <span className="sorttext-content mx-3 dark:text-[#acacac]">
              Sort By
            </span>
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full focus:outline-none ">
                  <a
                    href="#"
                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                  >
                    <strong className="createdtext-content pr-4">
                      {filtersText}
                    </strong>
                    <FontAwesomeIcon
                      style={{ fontSize: "12px", color: "#707070" }}
                      icon={faChevronDown}
                    />
                  </a>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filterDropDown.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            handleFilterText(item.name);
                          }}
                          href={item.href}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block py-2 px-4 text-sm text-gray-700"
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="md:masonry-2-col lg:masonry-4-col mt-6">
        <a href="/app/projectPreview">
          <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
            <div className="flex justify-between w-full px-3 pt-3 pb-1">
              <div className="inputCheck">
                <input type="checkbox" />
              </div>
            </div>
            <div className="cardHeader flex items-center pb-2 px-3">
              <div className="Cardtitle w-3/4">
                <h3 className="social-textcontent">
                  Summer 2022 Look Book Project
                </h3>
              </div>
              <div
                className="iconsRight flex 
            "
              >
                <img className="resizeicon" src={ResizeIcon} alt="resizeicon" />
                <FontAwesomeIcon
                  className="text-[#707070] text-[22px] ml-4 mr-1"
                  icon={faEllipsisVertical}
                />
              </div>
            </div>
            <div className="cardContent w-full">
              <img className="w-full" src={ProjectImg1} alt="projectImg1" />
            </div>
            <div className="px-3">
              <div className="cardtext-content">
                Showcase your collections in the most attractive way! Make your
                own stunning fashion lookbook and publish it online or download
                it for print.
              </div>
              <div className="createddate-content">
                Created on Jan 5, 2022 @ 4:30 PM
              </div>
              <div className="createddate-content pb-2">
                Last updated on Jan 22, 2022 @ 8:00 PM
              </div>
            </div>
          </div>
        </a>
        <a href="/app/projectPreview">
          <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
            <div className="flex justify-between w-full px-3 pt-3 pb-1">
              <div className="inputCheck">
                <input type="checkbox" />
              </div>
            </div>
            <div className="cardHeader flex items-center pb-2 px-3">
              <div className="Cardtitle w-3/4">
                <h3 className="social-textcontent">Save. Organise. Remember</h3>
              </div>
              <div className="iconsRight flex iconsalign-content">
                <img className="resizeicon" src={ResizeIcon} alt="resizeicon" />
                <FontAwesomeIcon
                  className="text-[#707070] text-[22px] ml-4 mr-1"
                  icon={faEllipsisVertical}
                />
              </div>
            </div>
            <div className="px-3">
              <div className="headingproject-content">
                Remember what you read
              </div>
              <div className="cardtext-content">
                Collect recurring payments and automate your subscription
                billing logic - from checkout to reconciliation. Adjust price
                structures, product bundles, discount management, and recovery
                processes to maximise revenue.
              </div>
              <div className="createddate-content">
                Created on Jan 5, 2022 @ 4:30 PM
              </div>
              <div className="createddate-content pb-2">
                Last updated on Jan 22, 2022 @ 8:00 PM
              </div>
            </div>
          </div>
        </a>
        <a href="/app/projectPreview">
          <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
            <div className="flex justify-between w-full px-3 pt-3 pb-1">
              <div className="inputCheck">
                <input type="checkbox" />
              </div>
            </div>
            <div className="cardHeader flex items-center pb-2 px-3">
              <div className="Cardtitle w-3/4">
                <h3 className="social-textcontent">
                  Save the internet with one click - Zimzee
                </h3>
              </div>
              <div className="iconsRight flex iconsalign-content">
                <img className="resizeicon" src={ResizeIcon} alt="resizeicon" />
                <FontAwesomeIcon
                  className="text-[#707070] text-[22px] ml-4 mr-1"
                  icon={faEllipsisVertical}
                />
              </div>
            </div>
            <div className="cardContent w-full">
              <img className="w-full" src={ProjectImg2} alt="projectImg1" />
            </div>
            <div className="px-3">
              <div className="createddate-content createalign-content">
                Created on Jan 5, 2022 @ 4:30 PM
              </div>
              <div className="createddate-content pb-2">
                Last updated on Jan 22, 2022 @ 8:00 PM
              </div>
            </div>
          </div>
        </a>
        <a href="/app/projectPreview">
          <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
            <div className="flex justify-between w-full px-3 pt-3 pb-1">
              <div className="inputCheck">
                <input type="checkbox" />
              </div>
            </div>
            <div className="cardHeader flex items-center pb-2 px-3">
              <div className="Cardtitle w-3/4">
                <h3 className="social-textcontent">
                  Save the internet with one click - Zimzee
                </h3>
              </div>
              <div className="iconsRight flex iconsalign-content">
                <img className="resizeicon" src={ResizeIcon} alt="resizeicon" />
                <FontAwesomeIcon
                  className="text-[#707070] text-[22px] ml-4 mr-1"
                  icon={faEllipsisVertical}
                />
              </div>
            </div>
            <div className="cardContent w-full">
              <img className="w-full" src={ProjectImg3} alt="projectImg1" />
            </div>
            <div className="px-3">
              <div className="cardtext-content">
                Adjust price structures, product bundles, discount management,
                and recovery processes to maximise revenue. Adjust price
                structures, product bundles, discount management, and recovery
                processes to maximise revenue.
              </div>
              <div className="createddate-content">
                Created on Jan 5, 2022 @ 4:30 PM
              </div>
              <div className="createddate-content pb-2">
                Last updated on Jan 22, 2022 @ 8:00 PM
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default GridProject;
