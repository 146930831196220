import React, { useState } from 'react';
import axios from "axios";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { toastify } from '../../../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import RenewPlan from "../Plans/PurchaseSummary.js";

export default function SetupForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);

    const handleError = (error) => {
        setLoading(false);
        setErrorMessage(error.message);
        toastify("error", error?.message);
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        console.log(event.target.name.value,"name target");
        const token = localStorage.getItem("token");
          if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }
        

        const { error } = await stripe.confirmSetup({
              elements,
              clientSecret: props.clientSecret,
              confirmParams: {
                  return_url: window.location.origin+'/app/mySubscription',
              },
        });
        // const { error } = await stripe.confirmPayment({
        //     elements,
        //     confirmParams: {
        //       // Make sure to change this to your payment completion page
        //       return_url: `${window.location.href}`,
        //     },
        //   });
      
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (error.type === "card_error" || error.type === "validation_error") {
            setErrorMessage(error.message);
            toastify("error", error?.message);
          } else {
            setErrorMessage("An unexpected error occured.");
            toastify("error", "An unexpected error occured.");
          }
      
          setLoading(false);
          // toastify("success", response.data.message);
          // cardUpdate();
        
        

        
        // Create the SetupIntent and obtain clientSecret
        // const res = await fetch("http://localhost:5000/api/subscriptions/attach-paymentMethod", {
        //     method: "GET",
        //     headers: {
        //         "Authorization": `Bearer ${token}`,
        //         "Content-Type": "application/json", // You may need to set the content type based on your API requirements
        //     },
        // });

        // const data = await res.json();
        // console.log(data.data)
        // Confirm the SetupIntent using the details collected by the Payment Element
        // const { error } = await stripe.confirmSetup({
        //     elements,
        //     clientSecret: props.clientSecret,
        //     confirmParams: {
        //         return_url: 'https://example.com/complete',
        //     },
        // });

        // if (error) {
        //     // This point is only reached if there's an immediate error when
        //     // confirming the setup. Show the error to your customer (for example, payment details incomplete)
        //     handleError(error);
        // } else {
        //     // Your customer is redirected to your `return_url`. For some payment
        //     // methods like iDEAL, your customer is redirected to an intermediate
        //     // site first to authorize the payment, then redirected to the `return_url`.
        // }
    };

    return (
         <>
            <div className="plan-billing">
                <form onSubmit={handleSubmit}>
                <div class="flex flex-row flex-wrap">
                <div class="w-full sm:w-1/3 md:w-3/6">
                    <div className="pb-[30px]">
                    <PaymentElement />
                    {/* <Tabs>
                        <TabList>
                        <Tab>
                            <img className="inactive-icon" src={Cardtab} alt="bank" />
                            <img
                            className="dark-inactive-icon"
                            src={Visacardsdark}
                            alt="bank"
                            />
                            <img className="active-icon" src={Cardorange} alt="bank" />
                            Card
                        </Tab>
                        <Tab>
                            <img className="inactive-icon" src={Bank} alt="bank" />
                            <img
                            className="dark-inactive-icon"
                            src={Bankcardsdark}
                            alt="bank"
                            />
                            <img className="active-icon" src={Bankorange} alt="bank" />
                            US bank account
                        </Tab>
                        </TabList>
                        <TabPanel>
                        <div className="flex items-center rounded-3xl border-0 input-icon mb-[30px]">
                            <div className="w-full">
                            <div className="flex">
                                <input
                                className="card-inputfield p-[10px]"
                                type="text"
                                placeholder="Card number"
                                />
                                <img
                                className="magnifier fileicon-content m-[auto] pr-[14px]"
                                src={Visacards}
                                alt="magnifying-glass"
                                />
                            </div>

                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="payment-section pr-[10px]  md:w-1/2">
                            <input
                                className="card-inputfield p-[10px]"
                                type="text"
                                placeholder="Expiration date"
                                required
                            />
                            </div>
                            <div className="payment-section pl-[10px]  md:w-1/2">
                            <input
                                className="card-inputfield p-[10px]"
                                type="text"
                                placeholder="CVC"
                                required
                            />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center rounded-3xl border-0 mt-[30px] input-icon">
                            <div className="w-full">
                                <div className="flex">
                                <input
                                    className="card-inputfield p-[10px]"
                                    type="text"
                                    placeholder="Country"
                                />
                                <FontAwesomeIcon
                                    className="fileicon-content m-[auto] pr-[12px]"
                                    style={{}}
                                    icon={faChevronDown}
                                />
                                </div>

                            </div>
                            </div>
                        </div>
                        </TabPanel>
                        <TabPanel>
                        <div className="payment-section pb-[30px]">
                            <input
                            className="card-inputfield p-[10px]"
                            type="text"
                            placeholder="Email"
                            required
                            />
                        </div>
                        <div className="payment-section pb-[30px]">
                            <input
                            className="card-inputfield p-[10px]"
                            type="text"
                            placeholder="Full Name"
                            required
                            />
                        </div>
                        <div className="flex items-center rounded-3xl border-0 input-icon">
                            <div className="w-full">
                            <div className="flex">
                                <input
                                className="card-inputfield p-[10px]"
                                type="text"
                                placeholder="Bank Account"
                                />
                                <img
                                className="magnifier fileicon-content m-[auto] pr-[12px]"
                                src={magnifier}
                                alt="magnifying-glass"
                                />
                            </div>

                            </div>
                        </div>
                        <div>
                            <div className="bank-options grid grid-cols-3 gap-4 pt-[30px]">
                            <div>Test Institution</div>
                            <div>Test OAuth Institution</div>
                            <div>Ownership Account</div>
                            <div>Invalid Payment Accounts</div>
                            <div>Down Bank (unscheduled)</div>
                            <div>Down Bank (unknown error)</div>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs> */}
                    </div>
                    {/* <div className="acknowledgement">
                    <p>
                        By providing your card information, you allow Monthly plan to
                        charge your card for future payments in accordance with their
                        <span className="underline"> terms and conditions</span>
                    </p>
                    </div> */}
                    <div className="pb-[30px]">
                    {/* <div className="flex">
                        <input
                        className="inputfocus-content"
                        type="checkbox"
                        name="payment"
                        />
                        <div className="default-payment ml-2">
                        Use as default payment method
                        </div>
                    </div> */}
                    </div>
                    <div className="acknowledgement">
                    <p>
                        You can review important information for plans on their
                        <span className="underline">
                        Terms of Service and Privacy Policy pages
                        </span>
                    </p>
                    </div>
                </div>
                </div>
                <div className="flex">
                <button
                    style={{
                    filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                    }}
                    className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                >
                    <span className="savebutton-context">{loading? "Loading......" : "Add"}</span>
                </button>
                <div className="cancelfilecard-content"><a href="/app/myProfile">Cancel</a></div>
                {/* {errorMessage && <div>{errorMessage}</div>} */}
                </div>
                </form>
        </div>
        <ToastContainer />
        </>
    );
}