import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './UpdateBilling.scss'
import "../../../PricingPlans/Plans/PlanCheckout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import PlanDismiss from '../../../../assets/icons/plandismiss.svg';
import { toastify } from '../../../Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
function UpdateBilling() { 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const[name,setName]=useState('')
  const[email,setEmail]=useState('')
  const[phone,setPhone]=useState('')
  const[city,setCity]=useState('')
  const[state,setState]=useState('')
  const[country,setCountry]=useState('')
  const[zip,setZip]=useState('')
  const[addressline1,setAddressLine1]=useState('')
  const[addressline2,setAddressLine2]=useState('')

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchCustomerInfo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}customer/get-customer-info`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          setEmail(response.data.data.email);
          setName(response.data.data.name);
          setPhone(response.data.data.phone);
          if(response.data.data.address){
            setCity(response.data.data.address.city);
            setState(response.data.data.address.state);
            setZip(response.data.data.address.postal_code);
            setAddressLine1(response.data.data.address.line1);
            setAddressLine2(response.data.data.address.line2);
            setCountry(response.data.data.address.country);
          }
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchCustomerInfo();
    }
  }, []);

  const handleName=(e)=>{
    setName(e.target.value)
  }

  const handleEmail=(e)=>{
    setEmail(e.target.value)
  }

  const handlePhone=(e)=>{
    setPhone(e.target.value)
  }

  const handleAddress1=(e)=>{
    setAddressLine1(e.target.value)
  }

  const handleAddress2=(e)=>{
    setAddressLine2(e.target.value)
  }

  const handleCity=(e)=>{
    setCity(e.target.value)
  }

  const handleState=(e)=>{
    setState(e.target.value)
  }

  const handleZip=(e)=>{
    setZip(e.target.value)
  }

  const handleCountry=(e)=>{
    setCountry(e.target.value)
  }

  const updateBilling = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
      try {
        if(name== '' || email == '' || phone == '' || city == '' || country == '' || addressline1 == '' || addressline2 == '' || zip == '' || state == ''){
          setIsLoading(false);
          toastify("error", "Please provide billing details" );
          return;
        }
        const response = await axios.patch(`${process.env.REACT_APP_API}customer/update-customer`,{
            name: name,
            email:email,
            phone:phone,
            address:{
              city: city,
              country: country,
              line1:addressline1,
              line2:addressline2,
              postal_code: zip,
              state: state
            }
        },
        {
          headers: {
              Authorization: `Bearer ${token}`,
            }
        }
        );
        console.log(response);
        setIsLoading(false);
        toastify("success", response.data.message);
        // setTimeout(function(){ 
        //   navigate('/app/myProfile');
        //  }, 2000);
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message );
      }
  }

  return(
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="updatebilling-section pricing-checkout">
      <div className="plandetail-content"><a href="/app/myProfile">Plan details</a><span className="plan-content"> / <a href="/app/updateBilling">Billing</a></span></div>
      <div className="confirmrenew-content">Billing Information</div>
      <div className="plan-billing">
        <div class="flex flex-row flex-wrap">
          <div class="w-full sm:w-1/3 md:w-3/6">
          <div className="payment-section pb-[30px]">
                <input className="card-inputfield p-[10px]" type="text" placeholder="Name" value={name} onChange={handleName} required/>
            </div>
            <div className="payment-section pb-[30px]">
                <input className="card-inputfield p-[10px]" type="text" placeholder="Email" value={email} onChange={handleEmail} required/>
            </div>
            <div className="payment-section pb-[30px]">
                <input className="card-inputfield p-[10px]" type="text" placeholder="Phone" value={phone} onChange={handlePhone} required/>
            </div>
            {/* <div className="flex items-center rounded-3xl border-0 input-icon">
              <div className="w-full">
                <div className="flex">
                    <input className="card-inputfield p-[10px]" type="text" placeholder="Address"/>
                    <FontAwesomeIcon className="fileicon-content m-[auto] pr-[12px]" style={{}} icon={faChevronDown} />
                </div>
              </div>
            </div> */}
            <div className="payment-section pt-[30px] pb-[30px]">
                <input className="card-inputfield p-[10px]" type="text" placeholder="Address Line 1" onChange={handleAddress1} value={addressline1} required/>
            </div>
            <div className="payment-section pb-[30px]">
                <input className="card-inputfield p-[10px]" type="text" placeholder="Address Line 2" onChange={handleAddress2} value={addressline2} required/>
            </div>
            <div className="flex flex-row pb-[30px]">
              <div className="payment-section pr-[10px]  md:w-1/2">
                <input className="card-inputfield p-[10px]" type="text" placeholder="City" onChange={handleCity} value={city} required/>
              </div>
              <div className="payment-section pl-[10px]  md:w-1/2">
               <div className="flex items-center rounded-3xl border-0 input-icon">
                <div className="w-full">
                  <div className="flex">
                    <input className="card-inputfield p-[10px]" type="text" placeholder="State" value={state} onChange={handleState}/>
                    <FontAwesomeIcon className="fileicon-content m-[auto] pr-[12px]" style={{}} icon={faChevronDown} />
                  </div>
                </div> 
                </div>
              </div>
            </div>
            <div className="flex flex-row pb-[30px]">
                <div className="payment-section pr-[10px]  md:w-1/2">
                    <input className="card-inputfield p-[10px]" type="text" placeholder="Country" value={country} onChange={handleCountry} required/>
                </div>
                <div className="payment-section pl-[10px]  md:w-1/2">
                    <input className="card-inputfield p-[10px]" type="text" placeholder="ZIP" value={zip} onChange={handleZip} required/>
                </div>
            </div>
            
            {/* <div className="flex flex-row">
              <div className="payment-section pr-[10px]  md:w-1/2">
               <div className="flex items-center rounded-3xl border-0 input-icon">
                <div className="w-full">
                  <div className="flex">
                    <input className="card-inputfield p-[10px]" type="text" placeholder="Tax ID type"/>
                    <FontAwesomeIcon className="fileicon-content m-[auto] pr-[12px]" style={{}} icon={faChevronDown} />
                  </div>
                </div>
               </div>
              </div>
              <div className="flex payment-section md:w-1/2">
                <input className="card-inputfield p-[10px]" type="text" required/>
                <img src={PlanDismiss} style={{ width: "22px", marginLeft: "10px"}} alt="planDismiss" />
              </div>
            </div> */}
            {/* <div className="addid-content">+ Add another ID</div> */}
            <div className="flex planupdate-section">
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white" type="button" onClick={updateBilling}>
                <span className="saveplanbutton-context">Save</span>
              </button>
              <div className="cancelupdatecard-content"><a href="/app/myProfile">Cancel</a></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
    )}
    </>
  );
}
export default UpdateBilling;  