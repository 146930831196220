import { React, Fragment, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dialog, Transition } from "@headlessui/react";
import resizeIcon from "../../assets/icons/resizeIcon.svg";
import logo from "../../assets/images/facebooklogo.png";
import parse from "html-react-parser";
import moment from "moment";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import Avatar from "react-avatar";
import Union from "../../assets/icons/Union-19.svg";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";
import FbIcon from "../../assets/images/fbnewlogo.svg";
import twitterlogo from "../../assets/images/twitternewlogo.svg";
import defaultUser from "../../assets/images/default-user.png";
import linkIcon from "../../assets/icons/linksicon.svg";
import ZimzeeIcon from "../../assets/icons/logoSymbol.svg";
import youtubeIcon from "../../assets/images/youtube.svg";
import tiktokIcon from "../../assets/images/download-tiktok.png";
import instagramIcon from "../../assets/images/instagram-icon-png.png";
import videoThumnail from "../../assets/images/video-thumbnail.jpg";
import axios from "axios";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import FullScreen from "../../assets/icons/FullScreen.svg";
import RegionalScreen from "../../assets/icons/RegionalScreenshot.svg";
import Highlight from "../../assets/icons/HighlightText.svg";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ShareCard from "./shareCard";

const addSpaceIfNeeded = (text) => {
  // Split the text into words based on spaces
  const words = text.split(' ');

  // Check if there are any words longer than 12 characters without spaces
  for (let i = 0; i < words.length; i++) {
    if (!/\s/.test(words[i]) && words[i].length > 12) {
      const middleIndex = Math.floor(words[i].length / 2);
      words[i] = words[i].slice(0, middleIndex) + ' ' + words[i].slice(middleIndex);
    }
  }

  // Check if there are more than 25 words
  if (words.length > 25) {
    for (let i = 0; i < words.length; i++) {
      // Add a space in the middle of any word without spaces
      if (!/\s/.test(words[i])) {
        const middleIndex = Math.floor(words[i].length / 2);
        words[i] = words[i].slice(0, middleIndex) + ' ' + words[i].slice(middleIndex);
      }
    }
  }

  return words.join(' ');
};


const VideoEmbed = ({ url }) => {
  const [dimensions, setDimensions] = useState({ height: null, width: null });
  useEffect(() => {
    const urlObj = new URL(url);
    const height = urlObj.searchParams.get('height');
    const width = urlObj.searchParams.get('width');

    if (height && width) {
      const heightValue = parseInt(height);
      const widthValue = parseInt(width);

      // Check if height is between 50% and 60% of width
      if (heightValue >= 0.95 * widthValue && heightValue <= 1.05 * widthValue) {
        setDimensions({ height: 300, width: widthValue });
      }else if (heightValue >= 0.5 * widthValue && heightValue <= 0.6 * widthValue) {
        setDimensions({ height: 'auto', width: "100% "});
      } else {
        setDimensions({ height: 300, width: "100%" });
      }
    }
  }, [url]);
  return (
    <>
    {dimensions.height && dimensions.width ? (
         <iframe
         src={url}
         width={dimensions.width}
         height={dimensions.height}
         className="facebook-embed-iframe"
       ></iframe>
      ) : (
        <p>Loading iframe dimensions...</p>
      )}
     
    </>
  );

}

function SingleCard({
  title,
  content,
  note,
  tags,
  updated,
  sharedLength,
  cardId,
  starred,
  cardType,
  handleCheckboxChange,
  cardUpdate,
  buckets,
  postDetail,
  handleSharePop,
  SharedToken,
  PermissionState,
  shared,
  sharedBy,
  sharedUsers,
  PublicBucket = false
}) {
  const [isActive, setIsActive] = useState(starred);
  const [isActiveVideo, setIsActiveVideo] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  // const [SelectedCard,setSelectedCard]=useState([])
  let [isOpenss, setIsOpens] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openMove, setOpenMove] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [optionSelect, setOptionSelect] = useState("");
  const [selectedBucketId, setselectedBucketId] = useState("");
  const [searchBucket, setSearchBucket] = useState("");
  const [bucketId, setBucketId] = useState("");
  const [isBucketOpen, setIsBucketOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [selectedBucket, setSelectedBucket] = useState("");
  const [downloadImg, setdownloadImg] = useState(false);
  const [permissionState, setPermissionState] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const[openCopyLink, setOpenCopyLink] = useState(false);
  const [EditFile, setEditFile] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const navigate = useNavigate();
  const [iOS, setiOS] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  // const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
  useEffect(() => {
    console.log(sharedUsers, "sharedUsers");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const matchingBucket =
      sharedUsers && sharedUsers.find((user) => user?._id === userData?._id);
    if (matchingBucket) {
      // Set the permission state to the user's permissions
      setPermissionState(matchingBucket.permissions);
    }
    const facebookBaseUrl = 'https://l.facebook.com/l.php?u=';
    // Function to process anchor tags
    const processAnchors = () => {
      document.querySelectorAll('.second-html a').forEach(anchor => {
        const href = anchor.getAttribute('href');
        // console.log(href,"fddsfds");
        // Check if the href is not null, doesn't start with "http", "//", and doesn't contain "#"
        if (href && !href.startsWith('http') && !href.startsWith('//') && href !== '#') {
          // Prepend the base URL
          anchor.href = facebookBaseUrl + href;
        }
      });
    };
    if(cardType == "facebook" || cardType == "Facebook"){
      processAnchors();
    }
  }, []);

  // window.scrollTo(0, 0);
  // useEffect(()=>{
  //     let cardContent = document.querySelectorAll('.cardContent > div[dir="auto"]');
  //     // console.log(cardContent,"card");
  //     var valueContent;
  //     cardContent.forEach(function(value,i){
  //         valueContent = value.textContent;
  //         // console.log(valueContent);
  //     });
  //     // console.log(valueContent);
  // },[])
  let StyleDiv = "";

  if (content?.html) {
    const tempElementContent = document.createElement("div");
    tempElementContent.innerHTML = content.html;
    let cardContent = tempElementContent.querySelector(
      "div.x6s0dn4.x1jx94hy.x78zum5.xdt5ytf.x6ikm8r.x10wlt62.x1n2onr6.xh8yej3"
    );
  }
  // const handleClick = () => {
  //   handleCardClick(cardId); // Pass the cardId to the parent component's function
  // };
  const closeDuplicateModal = () => {
    setOpenDuplicate(false);
  };

  const closeCopyLinkModal = () => {
    setOpenCopyLink(false);
  }

  const handleMouseEnter = (option) => {
    console.log(option, "hovered option.......");
    setHoveredOption(option);
  };

  const handleCardShare = () => {
    console.log("card share click");
    handleSharePop(cardId);
  };
  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleFileInput = (e) => {
    setEditFile(e.target.value);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const handleBucketId = (e) => {
    setBucketId(e.target.value);
  };
  const handleBucketChange = (bucket) => {
    const Selectbucket = bucket._id;
    setSelectedBucket(Selectbucket);
    const newBucket = bucket.name;
    setSearchBucket(newBucket);
    setIsBucketOpen(false);
  };
  const handleSubBucketSelectChange = (subbucket) => {
    const newBucket = subbucket._id;
    setSelectedBucket(newBucket);
    setSearchBucket(subbucket.name);
    setIsBucketOpen(false);
    // setHoveredOption(hoveredOption);
    // handleTagsChange([...tags, newTag]);
    // handleBlur()
  };
  const updateCard = async (cardId, star) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        { cardId: cardId, starred: star },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleCheckboxChanges = (event) => {
    handleCheckboxChange(event);
  };
  const handleBucketOpen = () => {
    setIsBucketOpen(!isBucketOpen);
  };

  const handleBucketClear = () => {
    setSearchBucket("");
  };

  const filteredBuckets = buckets?.filter((bucket) => {
    const bucketNameMatches = bucket.name.toLowerCase().includes(searchBucket);
    const subBucketMatches = bucket.subBuckets?.some((subBucket) =>
      subBucket.name.toLowerCase().includes(searchBucket)
    );
    return bucketNameMatches || subBucketMatches;
  });

  const handleStarClick = async (cardId) => {
    setIsActive(!isActive);
    await updateCard(cardId, !isActive);
  };
  const handleBucketInputChange = (event) => {
    const inputText = event.target.value;
    setSearchBucket(inputText.toLowerCase());
  };
  useEffect(() => {
    console.log(PermissionState, "userpermision");
  }, [PermissionState]);
  const handleCardLink = async () => {
    const token = localStorage.getItem("token");
    if(!PublicBucket){
      try {
        const response = await axios.post(
          "https://prdapi.zimzee.com/api/cards/public-url",
          {
            itemId: cardId,
            isPublic: true,
            itemType: "Card",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.data) {
          if(iOS){
            setOpenCopyLink(true);
            setPublicUrl(response?.data?.data);
            // var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob([response?.data?.data], { type: "text/plain" }) )})];
            // navigator.clipboard.write(data).then(function() {
            //   // console.log("Copied to clipboard successfully!");
            //   toastify("success", "Public link is copied to clipboard");
            // }, function() {
            //   // console.error("Unable to write to clipboard. :-(");
            //   toastify("error", "Unable to write to clipboard. :-(" );
            // });
          }else{

            const tempInput = document.createElement("input");
            tempInput.value = response?.data?.data;

            // Append the input element to the DOM
            document.body.appendChild(tempInput);

            // Select the input element's text and copy it to clipboard
            tempInput.select();
            document.execCommand("copy");

            // Remove the temporary input element
            document.body.removeChild(tempInput);
            
            toastify("success", response.data.message);
            // Create a temporary input element to copy the link to clipboard
            // const tempInput = document.createElement("a");
            // // tempInput.value = response?.data?.data;
            // tempInput.textContent = response?.data?.data;
            // // Append the input element to the DOM
            // document.body.appendChild(tempInput);
            // tempInput.classList.add("your-class-name");
            // var emailLink = document.querySelector('.your-class-name');
            // console.log(emailLink,"copyEmailBtn"); 
            // var range = document.createRange();  
            // range.selectNode(emailLink);  
            // window.getSelection().addRange(range);  

            // try {  
            //   // Now that we've selected the anchor text, execute the copy command  
            //   var successful = document.execCommand('copy');  
            //   var msg = successful ? 'successful' : 'unsuccessful';  
            //   console.log('Copy email command was ' + msg);  
            //   toastify("success", response.data.message);
            //   setTimeout(() => {
            //     // Remove the temporary input element
            //     document.body.removeChild(tempInput);
            //   }, 1000);
            // } catch(err) {  
            //   console.log('Oops, unable to copy');  
            //   toastify("success", 'Oops, unable to copy');
            // }
          }
        }
      } catch (error) {
        console.error(error?.response?.data?.message, "error msg");
        toastify("error", error?.response?.data?.message);
      }
    }else{
      const tempInput = document.createElement("input");
      tempInput.value = 'https://share.zimzee.com/public/card/'+cardId;
      // Append the input element to the DOM
      document.body.appendChild(tempInput);
      // Select the input element's text and copy it to clipboard
      tempInput.select();
      document.execCommand("copy");
      // Remove the temporary input element
      document.body.removeChild(tempInput);
      toastify("success", "Public link is copied to clipboard");
    }
    
  };

  const handleCopyText = (url) => {
          var emailLink = document.querySelector('.publicUrlInput');
          console.log(emailLink,"copyEmailBtn"); 
          emailLink.select();
          var range = document.createRange();  
          range.selectNode(emailLink);  
          window.getSelection().addRange(range);  

          try {  
            // Now that we've selected the anchor text, execute the copy command  
            var successful = document.execCommand('copy');  
            var msg = successful ? 'successful' : 'unsuccessful';  
            console.log('Copy email command was ' + msg);  
            toastify("success", "Public link is copied to clipboard");
          } catch(err) {  
            console.log('Oops, unable to copy');  
            toastify("success", 'Oops, unable to copy');
          }  

          // Remove the selections - NOTE: Should use
          // removeRange(range) when it is supported  
          window.getSelection().removeAllRanges();
          setOpenCopyLink(false);  
  }
  const handlePlayVideo = async (event, cardId) => {
    event.preventDefault();
    if (isPlaying && cardId == isActiveVideo) {
      document
        .querySelector(".video-content-inner-" + cardId + " video")
        .pause();
    } else {
      setIsPlaying(false);
      // const videoContainers = document.querySelectorAll('.video-content-inner video');
      // videoContainers.forEach(function (videoContainer) {
      //     videoContainer.removeAttribute('controls');
      //     videoContainer.pause();
      //     // const videoLink = videoContainer.querySelector('.video-link');
      //     // const video = videoContainer.querySelector('.custom-video');
      //     // const playButton = videoContainer.querySelector('.play-button');
      //     // const pauseButton = videoContainer.querySelector('.pause-button');

      //     // // Add a click event listener to the play button
      //     // playButton.addEventListener('click', function (event) {
      //     //   event.preventDefault();
      //     //   video.play();
      //     // });

      //     // // Add a click event listener to the pause button
      //     // pauseButton.addEventListener('click', function (event) {
      //     //   event.preventDefault();
      //     //   video.pause();
      //     // });
      //   });
      // document.querySelector('.video-content-inner-'+cardId+' video').play();
      // document.querySelector('.video-content-inner video').removeAttribute('controls');
      // document.querySelector('.video-content-inner video').pause();
      // document.querySelector('.video-content-inner video').currentTime = 0;
      document
        .querySelector(".video-content-inner-" + cardId + " video")
        .play();
    }
    setIsActiveVideo(cardId);
    setIsPlaying(!isPlaying);
    // event.stopPropagation();

    // handlePlayVideo(cardId);
  };

  const bucketNav = [
    { icon: faPencil, name: "Edit Name", href: "#" },
    { icon: faNoteSticky, name: "Move To", href: "#" },
    { icon: faNoteSticky, name: "Copy To", href: "#" },
    { icon: faFolder, name: "Duplicate", href: "#" },

    { icon: faTrash, name: "Delete", href: "" },
  ];
  const handleAction = (item) => {
    if (item.name === "Delete") {
      setIsOpens(true);
      setOptionSelect("");
    }
    if (item.name === "Duplicate") {
      setOpenDuplicate(true);
      setOptionSelect("Duplicate");
      setBucketId("");
    }
    if (item.name === "Move To") {
      setOpenMove(true);
      setOptionSelect("MoveTo");
    }
    if (item.name === "Copy To") {
      setOpenCopy(true);
      setOptionSelect("CopyTo");
    }
    if (item.name === "Edit Name") {
      setOpenEdit(true);
      setEditFile(title);
      console.log(openEdit, "item.name --- dsf");
      // navigate(`/app/CardEdit/${cardId}`);
    }
  };
  const closeMoveModal = () => {
    setIsBucketOpen(false);
    setOpenMove(false);
    setselectedBucketId("");
    setSearchBucket("");
  };
  const closeCopyModal = () => {
    setOpenCopy(false);
    setselectedBucketId("");
    setSearchBucket("");
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function closeCardModal() {
    setIsOpens(false);
  }
  const handleDeleteCard = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://prdapi.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: Id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      cardUpdate();
      closeCardModal();
    } catch (error) {
      console.error(error);
      closeCardModal();
      toastify("error", error.response.data.message);
    }
  };
  const handleUpdateCardMenuName = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        {
          cardId: Id,
          title: EditFile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        closeEditModal();
        cardUpdate();
      }
      // cardUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const createMarkup = (htmlString) => {
    const replacedHtml = htmlString
      .replace(/<\/p>/g, "<br>")
      .replace(/<p>/g, "");
    return { __html: replacedHtml };
  };
  const handleUpdateCardMenu = async (Id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/cards/update",
        {
          optionType: optionSelect,
          destinationBucketID: selectedBucket,
          cardId: Id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      toastify("success", response.data.message);
      if (response) {
        cardUpdate();
        setOpenCopy(false);
      }

      setSelectedBucket("");
      // cardUpdate();
    } catch (error) {
      console.error(error);
      setSelectedBucket("");
      toastify("error", error.response.data.message);
    }
  };
  let videoIframe = "";
  if (
    content?.imageUrl.length > 0 &&
    content?.imageUrl[0].urlType == "Video" &&
    content?.html &&
    content?.imageUrl[0].url
  ) {
    videoIframe = content?.html;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = videoIframe;

    // const anchorTag = document.createElement('a');
    // const divElementWithDirAuto = tempElement.querySelector('div[dir="auto"]');
    // if (divElementWithDirAuto) {
    //     // Append the div element as a child of the anchor tag
    //     const newChild = anchorTag.appendChild(divElementWithDirAuto);
    //     console.log(newChild);

    //     // Get the actual container where you want to inject the anchor tag
    //     // const container = document.getElementById('container');

    //     // Append the anchor tag to the container
    //     // container.appendChild(anchorTag);
    //     // divElementWithDirAuto.parentNode.replaceChild(newChild, divElementWithDirAuto);
    //   }
    // tempElement.find('iframe').attr('')
    const iframes = tempElement.querySelector("iframe");
    if (iframes) {
      const videoElement = document.createElement("video");
      videoElement.controls =
        isPlaying && cardId == isActiveVideo ? true : false;
      // videoElement.autoplay = true;

      const sourceElement = document.createElement("source");
      sourceElement.src = content?.imageUrl[0].url;
      sourceElement.type = "video/mp4";

      videoElement.appendChild(sourceElement);
      const playIcon = document.createElement("div");
      playIcon.className = "play-icon";
      playIcon.innerHTML = "&#9658;";

      // Wrap the video element with a container div
      const videoContainer = document.createElement("div");
      videoContainer.className = "video-container";
      videoContainer.appendChild(videoElement);
      // videoContainer.appendChild(playIcon);

      // Replace the iframe with the video element
      iframes.parentNode.replaceChild(videoContainer, iframes);
    }

    // Get the updated HTML content from the temporary container
    const updatedHtml = tempElement.innerHTML;
    // console.log(updatedHtml,"updatedHtml");
    videoIframe = updatedHtml;
  }
  return (
    <div>
      <div className="">
        <div className="cardHolder rounded-[14px]  break-inside">
          {(content?.imageUrl.length > 0 &&
            content?.imageUrl[0].urlType !== "Video") ||
          (content?.imageUrl.length > 0 &&
            content.html &&
            content?.imageUrl[0].urlType !== "Video") ||
          (content?.html && content?.imageUrl.length == 0) ? (
            <>
              <div className="cardheight-content">
                <div
                  className={`flex justify-between w-full px-3 pt-3 ${
                    SharedToken ? "pointer-events-none" : ""
                  }`}
                >
                  {SharedToken ? (
                    <>
                      {" "}
                      <div className="inputCheck">
                        <input
                          type="checkbox"
                          value={cardId}
                          // onChange={handleCheckboxChanges}
                        />
                      </div>
                      <div className="flex">
                        <img
                          className="cardiconlink-imgcontent"
                          src={linkIcon}
                          alt="link-icon"
                          // onClick={handleCardLink}
                          style={{
                            width: "20px",
                            height: "18px",
                            marginRight: "12px",
                          }}
                        />
                        <FontAwesomeIcon
                          className="color-secondary"
                          icon={faBell}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="inputCheck">
                        <input
                          type="checkbox"
                          value={cardId}
                          onChange={handleCheckboxChanges}
                        />
                      </div>
                      <div className="flex">
                        <img
                          className="cardiconlink-imgcontent"
                          src={linkIcon}
                          alt="link-icon"
                          onClick={handleCardLink}
                          style={{
                            width: "20px",
                            height: "18px",
                            marginRight: "12px",
                          }}
                        />
                        <FontAwesomeIcon
                          className="color-secondary"
                          icon={faBell}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="cardHeader flex items-center pb-1 px-3 justify-between">
                  <div className="flex">
                    <a
                      href={
                        SharedToken && PermissionState
                          ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                          : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                      }
                    >
                      <div className="logo cardlisticonw-content w-[30px] rounded">
                        {/* {(subBucket?.cardType)? (cardType == 'twitter' ?  twitterlogo : cardType == 'facebook' ? FbIcon : defaultUser):("")} */}
                        {logo ? (
                          <img
                            className="border-rounded"
                            // style={{ maxWidth: "30px" }}
                            src={
                              cardType
                                ? cardType == "twitter"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : twitterlogo
                                  : cardType == "facebook" ||
                                    cardType == "Facebook"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : FbIcon
                                  : cardType == "screenshoot"
                                  ? ZimzeeIcon
                                  : cardType == "fullScreen"
                                  ? FullScreen
                                  : cardType == "partScreen"
                                  ? RegionalScreen
                                  : cardType == "highlightText"
                                  ? Highlight
                                  : cardType == "youtube"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : youtubeIcon
                                  : cardType == "TikTok"
                                  ? tiktokIcon
                                  : cardType == "instagram"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : instagramIcon
                                  : defaultUser
                                : ""
                            }
                            alt="card-logo"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </a>
                    <a
                      href={
                        SharedToken && PermissionState
                          ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                          : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                      }
                    >
                      <div className="Cardtitle pl-3">
                        <h3 className="social-textcontent">{cardType !== "partScreen" ||  cardType !== "fullScreen" || cardType !== "highlightText" ? addSpaceIfNeeded(title) : title}</h3>
                      </div>
                    </a>
                  </div>

                  <div className="iconsRight flex items-center">
                    <a
                      href={
                        SharedToken && PermissionState
                          ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                          : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                      }
                      style={{ width: "20px", height: "20px" }}
                    >
                      <img className="resizeicon-imgcontent" src={resizeIcon} />
                    </a>

                    <Menu
                      as="div"
                      className={`relative space-left ${
                        SharedToken ? "pointer-events-none" : ""
                      }`}
                    >
                      <Menu.Button className="relative inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                        {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                        {/* <img src={ThreeDots} alt="threeDots" /> */}
                        <FontAwesomeIcon
                          className="text-[#707070] text-[22px] ml-4 mr-1"
                          icon={faEllipsisVertical}
                        />
                      </Menu.Button>
                      {
                        !PublicBucket ?
                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute text-left right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {bucketNav.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={`javascript:void(0);`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                  onClick={() => handleAction(item)}
                                >
                                  <span className="pr-2">
                                    <FontAwesomeIcon
                                      className="text-[#797979]"
                                      icon={item.icon}
                                    />
                                  </span>
                                  <span className="text-black">
                                    {item.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition> : ""
                      }
                      
                    </Menu>
                    <Transition appear show={isOpenss} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeCardModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Delete
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to delete?
                                  </p>
                                </div>

                                <div className="mt-4">
                                  <button
                                    style={{
                                      background: "#FF6600",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => handleDeleteCard(cardId)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    style={{
                                      background: "#000",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={closeCardModal}
                                  >
                                    No
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                    {/* <Transition appear show={openDuplicate} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeDuplicateModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Duplicate
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to duplicate?
                                  </p>
                                </div>

                                <div className="mt-4">
                                  <button
                                    style={{
                                      background: "#FF6600",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => handleUpdateCardMenu(cardId)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    style={{
                                      background: "#000",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={closeDuplicateModal}
                                  >
                                    No
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                    {/* <Transition appear show={openMove} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeMoveModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Move To
                                </Dialog.Title>
                                <div
                                  style={{ display: "block" }}
                                  className="custom-list-dropdown"
                                >
                                  <div className="flex fliesbucket-inputcontent filesbottom-input">
                                    <input
                                      type="text"
                                      placeholder="Select a bucket"
                                      value={searchBucket}
                                      onChange={handleBucketInputChange}
                                    />
                                    <span className="dwondownIcon">
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "20px",
                                          color: "#707070",
                                          paddingTop: "8px",
                                        }}
                                        icon={faSearch}
                                        onClick={handleBucketOpen}
                                      />
                                    </span>
                                    <div className="tab-scrolls singlecard">
                                      <ul className="dropdown">
                                        {filteredBuckets?.map((option) => (
                                          <li key={option._id}>
                                            <p
                                              onClick={() =>
                                                handleBucketChange(option)
                                              }
                                            >
                                              {option.name}
                                              {option.subBuckets &&
                                              option.subBuckets.length > 0 ? (
                                                <FontAwesomeIcon
                                                  style={{
                                                    fontSize: "12px",
                                                    color: "#707070",
                                                    position: "absolute",
                                                    right: "17px",
                                                    top: "14px",
                                                  }}
                                                />
                                              ) : null}
                                            </p>
                                            {option.subBuckets &&
                                            option.subBuckets.length > 0 ? (
                                              <ul className="sub-dropdown">
                                                {option.subBuckets.map(
                                                  (subTag) => (
                                                    <li key={subTag._id}>
                                                      <p
                                                        onClick={() =>
                                                          handleSubBucketSelectChange(
                                                            subTag
                                                          )
                                                        }
                                                      >
                                                        -- {subTag.name}
                                                      </p>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            ) : null}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                  <button
                                    style={{
                                      filter:
                                        "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                                    }}
                                    className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                                    onClick={() => handleUpdateCardMenu(cardId)}
                                  >
                                    <span className="savebutton-context">
                                      Move
                                    </span>
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                    {/* <Transition appear show={openCopy} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeCopyModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Copy To
                                </Dialog.Title>
                                <div
                                  style={{ display: "block" }}
                                  className="custom-list-dropdown"
                                >
                                  <div className="fliesbucket-inputcontent filesbottom-input">
                                    <input
                                      type="text"
                                      placeholder="Select a bucket"
                                      value={searchBucket}
                                      onChange={handleBucketInputChange}
                                    />
                                    <span className="dwondownIcon">
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "20px",
                                          color: "#707070",
                                          paddingTop: "8px",
                                        }}
                                        icon={faSearch}
                                        onClick={handleBucketOpen}
                                      />
                                    </span>
                                    <div className="tab-scrolls singlecard">
                                      <ul className="dropdown">
                                        {filteredBuckets?.map((option) => (
                                          <li key={option._id}>
                                            <p
                                              onClick={() =>
                                                handleBucketChange(option)
                                              }
                                            >
                                              {option.name}
                                            </p>
                                            {option.subBuckets &&
                                            option.subBuckets.length > 0 ? (
                                              <ul className="sub-dropdown">
                                                {option.subBuckets.map(
                                                  (subTag) => (
                                                    <li key={subTag._id}>
                                                      <p
                                                        onClick={() =>
                                                          handleSubBucketSelectChange(
                                                            subTag
                                                          )
                                                        }
                                                      >
                                                        -- {subTag.name}
                                                      </p>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            ) : null}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="flex justify-end">
                                    <hr />
                                    <div
                                      className="movetotop-content cancelsinglecard-content"
                                      style={{ cursor: "pointer" }}
                                      onClick={closeCopyModal}
                                    >
                                      Cancel
                                    </div>
                                    <button
                                      style={{
                                        filter:
                                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                                      }}
                                      className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                                      onClick={() =>
                                        handleUpdateCardMenu(cardId)
                                      }
                                    >
                                      <span className="savebutton-context">
                                        Copy
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                  </div>
                </div>
                <p></p>
                <div className="cardContent cardListContent w-full">
                  {/* {contentImage ?<img className="w-full" src={contentImage} />  : ""}  */}
                  {/* {content?.imageUrl?  <img className="w-full" src={content?.imageUrl} /> :  parse(content.html)} */}

                  {content ? (
                    content.imageUrl.length > 0 &&
                    content.imageUrl[0].urlType == "Image" &&
                    content.html ? (
                      <a
                        href={
                          SharedToken && PermissionState
                            ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                            : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                        }
                        className={
                          cardType == "highlightText"
                            ? "highlightTextItem"
                            : cardType == "twitter" ? "twitterItems": "otherItems"
                        }
                      >
                        <div
                          className={`cardHtml ${
                            cardType == "youtube" ? "youtube-container" : ""
                          }`}
                        >
                          {cardType ? (
                            cardType == "twitter" ? (
                              <div className="twitterItemsText">
                                {parse(content.html)}
                              </div>
                            ) : cardType == "facebook" ? (
                              content?.imageUrl[0].isAdLibrary ? (
                                <div className="ad-lib-first-html">
                                  {parse(content.html)}
                                </div>
                              ) : (
                                <div className="first-html">
                                  {parse(content.html)}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {content?.imageUrl.length > 0 &&
                          content?.imageUrl[0].urlType == "Image" &&
                          cardType !== "youtube" ? (
                            <a
                              href={
                                SharedToken && PermissionState
                                  ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                                  : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                              }
                            >
                              {" "}
                              <img
                                className="w-full"
                                src={content?.imageUrl[0].url}
                              />
                            </a>
                          ) : cardType == "youtube" ? (
                            <iframe
                              src={
                                `https://www.youtube.com/embed/` +
                                content?.sourceUrl.split("v=")[1].split("&")[0]
                              }
                              width="100%"
                              height="315"
                            ></iframe>
                          ) : (
                            ""
                          )}
                          {/* <img className="w-full" src={content?.imageUrl[0].url} /> */}
                          {cardType ? (
                            cardType == "twitter" ? (
                              ""
                            ) : cardType == "facebook" ? (
                              content?.imageUrl[0].isAdLibrary ? (
                                <>
                                  <div className="ad-lib-second-html">
                                    {parse(content.html)}
                                  </div>
                                </>
                              ) : (
                                <div className="second-html">
                                  {parse(content.html)}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </a>
                    ) : content?.imageUrl.length > 0 &&
                      content?.imageUrl[0].urlType == "Video" &&
                      content?.html == " " ? (
                      <div
                        className={`video video-content-inner video-content-inner-${cardId} ${
                          isPlaying ? "active" : ""
                        }`}
                      >
                        {parse(videoIframe)}
                        {isPlaying && cardId == isActiveVideo ? (
                          // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                          ""
                        ) : content?.imageUrl[0]?.url ? (
                          <>
                            <div
                              className="toggle-view-icon-outer"
                              onClick={(event) =>
                                handlePlayVideo(event, cardId)
                              }
                            >
                              <div className="toggle-view-icon">
                                {/* <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPlay}  style={{color:"#111111b0",fontSize:"40px"}}/> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : content?.imageUrl.length > 0 &&
                      content?.imageUrl[0].urlType == "Video" &&
                      content.html ? (
                      <div
                        className={`video video-content-inner video-content-inner-${cardId} ${
                          isPlaying ? "active" : ""
                        }`}
                      >
                        {parse(videoIframe)}
                        {isPlaying && cardId == isActiveVideo ? (
                          // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                          ""
                        ) : content?.imageUrl[0]?.url ? (
                          <>
                            <div
                              className="toggle-view-icon-outer"
                              onClick={(event) =>
                                handlePlayVideo(event, cardId)
                              }
                            >
                              <div className="toggle-view-icon">
                                {/* <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPlay}  style={{color:"#111111b0",fontSize:"40px"}}/> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : content.html ? (
                      <a
                        href={
                          SharedToken && PermissionState
                            ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                            : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                        }
                        className={
                          cardType == "highlightText"
                            ? "highlightTextItem"
                            : cardType == "twitter" ? "twitterItems": "otherItems"
                        }
                      >
                        <div className="twitterItemsText">
                          {parse(content.html)}
                        </div>
                      </a>
                    ) : content?.imageUrl.length > 0 ? (
                      <a
                        href={
                          SharedToken && PermissionState
                            ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                            : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                        }
                      >
                        {" "}
                        <img
                          className="w-full"
                          src={content?.imageUrl[0].url}
                        />
                      </a>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {/* {content?.html?  parse(content.html) : <img className="w-full" src={content?.imageUrl} />} */}
                </div>
              </div>
              <a
                href={
                  SharedToken && PermissionState
                    ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                    : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                }
              >
                <div className="px-3">
                  <div className="lastUpdate">
                    <p className="updatedtext-content py-1">
                      Last updated on{" "}
                      {moment(updated).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div>
                  <div className="flex items-center flex-wrap">
                    <div
                      className="cardTags cardTags-content flex flex-wrap py-1 "
                      style={{
                        width:
                          sharedUsers?.length === 1
                            ? "84%"
                            : sharedUsers?.length === 2
                            ? "84%"
                            : sharedUsers?.length === 3
                            ? "81%"
                            : sharedUsers?.length > 3
                            ? "74%"
                            : "100%",
                      }}
                    >
                      {tags.length > 0 ? (
                        tags.map((item) => {
                          return (
                            <span className="tagsname-textcontent py-1 px-3 mr-[6px] my-1 rounded-2xl">
                              {item.name}
                            </span>
                          );
                        })
                      ) : (
                        <span className="tagsname-textcontent py-1 px-3 mr-[6px] my-1 rounded-2xl">
                          No Tags Assigned
                        </span>
                      )}
                    </div>
                    <div
                      className="profile-displaycontent"
                      style={{
                        display: sharedUsers?.length > 0 ? "block" : "none",
                      }}
                    >
                      <div className="profile-imgcontent">
                        {SharedToken && sharedUsers ? (
                          <div className="tooltip">
                            <span className="tooltip-text">
                              <p>Share By : &nbsp;{sharedBy}</p>
                              <p>
                                {" "}
                                Share with :&nbsp;
                                {sharedUsers?.map((user, index) => (
                                  <span
                                    key={index}
                                    style={{ display: "inline-flex" }}
                                  >
                                    {user?.fullName}{" "}
                                    {index < sharedUsers?.length - 1 ? "," : ""}
                                  </span>
                                ))}{" "}
                              </p>
                            </span>
                            {sharedUsers?.slice(0, 3)?.map((user) => (
                              <>
                                {user?.imageUrl ? (
                                  <img src={user?.imageUrl} alt="profile1" />
                                ) : (
                                  <Avatar
                                    name={`${user.fullName}`}
                                    size="25"
                                    round={true}
                                    className="profile-avatar"
                                  />
                                )}
                              </>
                            ))}
                          </div>
                        ) : (
                          sharedUsers?.slice(0, 3)?.map((user) => (
                            <div className="tooltip">
                              <span className="tooltip-text">
                                {/* <p>Share By &nbsp;{sharedBy}</p> */}
                                <p>
                                  {" "}
                                  Share with : &nbsp;
                                  {sharedUsers?.map((user, index) => (
                                    <span
                                      key={index}
                                      style={{ display: "inline-flex" }}
                                    >
                                      {user?.fullName}{" "}
                                      {index < sharedUsers?.length - 1
                                        ? ","
                                        : ""}
                                    </span>
                                  ))}{" "}
                                </p>
                              </span>
                              <>
                                {user?.imageUrl ? (
                                  <img src={user?.imageUrl} alt="profile1" />
                                ) : (
                                  <Avatar
                                    name={`${user.fullName}`}
                                    size="25"
                                    round={true}
                                    className="profile-avatar"
                                  />
                                )}
                              </>
                            </div>
                          ))
                        )}

                        {sharedUsers?.length > 3 && (
                          <div className="plus-share">+ </div>
                        )}
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">
                        {sharedUsers?.length > 0
                          ? sharedUsers?.length + " Share(s)"
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </>
          ) : (
            <>
              <div className="cardheight-content">
                <div className="flex justify-between w-full px-3 pt-3">
                  <div className="inputCheck">
                    <input
                      type="checkbox"
                      value={cardId}
                      onChange={handleCheckboxChanges}
                    />
                  </div>
                  <div className="flex">
                    <img
                      className="cardiconlink-imgcontent"
                      src={linkIcon}
                      alt="link-icon"
                      onClick={handleCardLink}
                      style={{
                        width: "20px",
                        height: "18px",
                        marginRight: "12px",
                      }}
                    />
                    <FontAwesomeIcon
                      className="color-secondary"
                      icon={faBell}
                    />
                  </div>
                </div>

                <div className="cardHeader flex items-center pb-1 px-3 justify-between">
                  <div className="flex">
                    <a href={"/app/card-detail/" + cardId}>
                      <div className="logo w-[30px] rounded">
                        {/* {(subBucket?.cardType)? (cardType == 'twitter' ?  twitterlogo : cardType == 'facebook' ? FbIcon : defaultUser):("")} */}
                        {logo ? (
                          <img
                            className="border-rounded"
                            style={{ maxWidth: "30px" }}
                            src={
                              cardType
                                ? cardType == "twitter"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : twitterlogo
                                  : cardType == "facebook" ||
                                    cardType == "Facebook"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : FbIcon
                                  : cardType == "screenshoot"
                                  ? ZimzeeIcon
                                  : cardType == "fullScreen"
                                  ? FullScreen
                                  : cardType == "partScreen"
                                  ? RegionalScreen
                                  : cardType == "highlightText"
                                  ? Highlight
                                  : cardType == "youtube"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : youtubeIcon
                                  : cardType == "TikTok"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                      ? postDetail.imageUrl
                                      : tiktokIcon
                                    : tiktokIcon
                                  : cardType == "instagram"
                                  ? postDetail
                                    ? postDetail.imageUrl
                                    : instagramIcon
                                  : defaultUser
                                : ""
                            }
                            alt="card-logo"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </a>
                    <a
                      href={
                        SharedToken && PermissionState
                          ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                          : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                      }
                    >
                      <div className="Cardtitle pl-3">
                        <h3 className="social-textcontent">{cardType !== "partScreen" ||  cardType !== "fullScreen" || cardType !== "highlightText" ? addSpaceIfNeeded(title) : title}</h3>
                      </div>
                    </a>
                  </div>

                  <div className="iconsRight flex items-center">
                    <a
                      href={
                        SharedToken && PermissionState
                          ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                          : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                      }
                      style={{ width: "20px", height: "20px" }}
                    >
                      <img className="resizeicon-imgcontent" src={resizeIcon} />
                    </a>
                    <Menu
                      as="div"
                      className={`relative space-left ${
                        SharedToken ? "pointer-events-none" : ""
                      }`}
                    >
                      <Menu.Button className="relative inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                        {/* <FontAwesomeIcon className="text-lg text-[#707070]" icon={faEllipsisVertical} /> */}
                        {/* <img src={ThreeDots} alt="threeDots" /> */}
                        <FontAwesomeIcon
                          className="text-[#707070] text-[22px] ml-4 mr-1"
                          icon={faEllipsisVertical}
                        />
                      </Menu.Button>
                      {
                        !PublicBucket ?
                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute text-left right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {bucketNav.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={`javascript:void(0);`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                  onClick={() => handleAction(item)}
                                >
                                  <span className="pr-2">
                                    <FontAwesomeIcon
                                      className="text-[#797979]"
                                      icon={item.icon}
                                    />
                                  </span>
                                  <span className="text-black">
                                    {item.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition> : ""
                      }
                      
                    </Menu>
                    {/* <Transition appear show={isOpenss} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeCardModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Delete
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to delete?
                                  </p>
                                </div>

                                <div className="mt-4">
                                  <button
                                    style={{
                                      background: "#FF6600",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => handleDeleteCard(cardId)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    style={{
                                      background: "#000",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={closeCardModal}
                                  >
                                    No
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition>
                    <Transition appear show={openDuplicate} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeDuplicateModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Duplicate
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to duplicate?
                                  </p>
                                </div>

                                <div className="mt-4">
                                  <button
                                    style={{
                                      background: "#FF6600",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => handleUpdateCardMenu(cardId)}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    style={{
                                      background: "#000",
                                      width: "80px",
                                      color: "#fff",
                                      padding: "10px",
                                      borderRadius: "50px",
                                    }}
                                    type="button"
                                    className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={closeDuplicateModal}
                                  >
                                    No
                                  </button>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                    {/* <Transition appear show={openMove} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeMoveModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Move To
                                </Dialog.Title>
                                <div
                                  style={{ display: "block" }}
                                  className="custom-list-dropdown"
                                >
                                  <div className="fliesbucket-inputcontent filesbottom-input">
                                    <input
                                      type="text"
                                      placeholder="Select a bucket"
                                      value={searchBucket}
                                      onChange={handleBucketInputChange}
                                    />
                                    <span className="dwondownIcon">
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "20px",
                                          color: "#707070",
                                          paddingTop: "8px",
                                        }}
                                        icon={faSearch}
                                        onClick={handleBucketOpen}
                                      />
                                    </span>
                                    <div className="tab-scrolls singlecard">
                                      <ul className="dropdown">
                                        {filteredBuckets?.map((option) => (
                                          <li key={option._id}>
                                            <p
                                              onClick={() =>
                                                handleBucketChange(option)
                                              }
                                            >
                                              {option.name}
                                              {option.subBuckets &&
                                              option.subBuckets.length > 0 ? (
                                                <FontAwesomeIcon
                                                  style={{
                                                    fontSize: "12px",
                                                    color: "#707070",
                                                    position: "absolute",
                                                    right: "17px",
                                                    top: "14px",
                                                  }}
                                                />
                                              ) : null}
                                            </p>
                                            {option.subBuckets &&
                                            option.subBuckets.length > 0 ? (
                                              <ul className="sub-dropdown">
                                                {option.subBuckets.map(
                                                  (subTag) => (
                                                    <li key={subTag._id}>
                                                      <p
                                                        onClick={() =>
                                                          handleSubBucketSelectChange(
                                                            subTag
                                                          )
                                                        }
                                                      >
                                                        -- {subTag.name}
                                                      </p>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            ) : null}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="flex justify-end">
                                    <hr />
                                    <div
                                      className="movetotop-content cancelsinglecard-content"
                                      onClick={closeMoveModal}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Cancel
                                    </div>
                                    <button
                                      style={{
                                        filter:
                                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                                      }}
                                      className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                                      onClick={() =>
                                        handleUpdateCardMenu(cardId)
                                      }
                                    >
                                      <span className="savebutton-context">
                                        Move
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                    {/* <Transition appear show={openCopy} as={Fragment}>
                      <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={closeCopyModal}
                      >
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                          <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Copy To
                                </Dialog.Title>
                                <div
                                  style={{ display: "block" }}
                                  className="custom-list-dropdown"
                                >
                                  <div className="flex fliesbucket-inputcontent filesbottom-input">
                                    <input
                                      type="text"
                                      placeholder="Select a bucket"
                                      value={searchBucket}
                                      onChange={handleBucketInputChange}
                                    />
                                    <span className="dwondownIcon">
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "20px",
                                          color: "#707070",
                                          paddingTop: "8px",
                                        }}
                                        icon={faSearch}
                                        onClick={handleBucketOpen}
                                      />
                                    </span>
                                    <div className="tab-scrolls singlecard">
                                      <ul className="dropdown">
                                        {filteredBuckets?.map((option) => (
                                          <li key={option._id}>
                                            <p
                                              onClick={() =>
                                                handleBucketChange(option)
                                              }
                                            >
                                              {option.name}
                                            </p>
                                            {option.subBuckets &&
                                            option.subBuckets.length > 0 ? (
                                              <ul className="sub-dropdown">
                                                {option.subBuckets.map(
                                                  (subTag) => (
                                                    <li key={subTag._id}>
                                                      <p
                                                        onClick={() =>
                                                          handleSubBucketSelectChange(
                                                            subTag
                                                          )
                                                        }
                                                      >
                                                        -- {subTag.name}
                                                      </p>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            ) : null}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                

                                  <div className="flex justify-end">
                                    <hr />
                                    <div
                                      className="movetotop-content cancelsinglecard-content"
                                      style={{ cursor: "pointer" }}
                                      onClick={closeCopyModal}
                                    >
                                      Cancel
                                    </div>
                                    <button
                                      style={{
                                        filter:
                                          "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                                      }}
                                      className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                                      onClick={() =>
                                        handleUpdateCardMenu(cardId)
                                      }
                                    >
                                      <span className="savebutton-context">
                                        Copy
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition> */}
                  </div>
                </div>
                <p></p>

                <div className="cardContent cardListContent w-full">
                  {/* {contentImage ?<img className="w-full" src={contentImage} />  : ""}  */}
                  {/* {content?.imageUrl?  <img className="w-full" src={content?.imageUrl} /> :  parse(content.html)} */}

                  {content ? (
                    content.imageUrl.length > 0 &&
                    content.imageUrl[0].urlType == "Image" &&
                    content.html ? (
                      <a
                        href={
                          SharedToken && PermissionState
                            ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                            : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                        }
                      >
                        <div className="cardHtml">
                          {cardType ? (
                            cardType == "twitter" ? (
                              <div className="twitterItemsText">
                                {parse(content.html)}
                              </div>
                            ) : cardType == "facebook" ? (
                              content?.imageUrl[0].isAdLibrary ? (
                                <div className="ad-lib-first-html">
                                  {parse(content.html)}
                                </div>
                              ) : (
                                <div className="first-html">
                                  {parse(content.html)}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          <a
                            href={
                              SharedToken && PermissionState
                                ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                                : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                            }
                          >
                            {" "}
                            <img
                              className="w-full"
                              src={content?.imageUrl[0].url}
                            />
                          </a>
                          {cardType ? (
                            cardType == "twitter" ? (
                              ""
                            ) : cardType == "facebook" ? (
                              content?.imageUrl[0].isAdLibrary ? (
                                <>
                                  <div className="ad-lib-second-html">
                                    {parse(content.html)}
                                  </div>
                                </>
                              ) : (
                                <div className="second-html">
                                  {parse(content.html)}
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </a>
                    ) : content?.imageUrl.length > 0 &&
                      content?.imageUrl[0].urlType == "Video" &&
                      content?.html == " " ? (
                      <div
                        className={`video video-content-inner video-content-inner-${cardId} ${
                          isPlaying ? "active" : ""
                        }`}
                      >
                        <div className="first-html">{parse(content.html)}</div>

                        <div className="video-container">
                          {isPlaying ? (
                            <video controls>
                              <source src={content?.imageUrl[0].url}></source>
                            </video>
                          ) : (
                            <video poster={iOS && !content?.imageUrl[0]?.metaData?.thumbnail? videoThumnail : content?.imageUrl[0]?.metaData?.thumbnail? content?.imageUrl[0]?.metaData?.thumbnail:""} style={{width: "100%", height: "100%"}}>
                              <source src={content?.imageUrl[0].url}></source>
                            </video>
                          )}

                          {isPlaying && cardId == isActiveVideo ? (
                            // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                            ""
                          ) : content?.imageUrl[0]?.url ? (
                            <>
                              <div
                                className="toggle-view-icon-outer"
                                onClick={(event) =>
                                  handlePlayVideo(event, cardId)
                                }
                              >
                                <div className="toggle-view-icon">
                                  {/* <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPlay}  style={{color:"#111111b0",fontSize:"40px"}}/> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="second-html">{parse(content.html)}</div>
                        {/* {parse(videoIframe)} */}
                      </div>
                    ) : content?.imageUrl.length > 0 &&
                      content?.imageUrl[0].urlType == "Video" &&
                      content.html ? (
                      <div
                        className={`video video-content-inner video-content-inner-${cardId} ${
                          isPlaying ? "active" : ""
                        }`}
                      >
                        <a
                          href={
                            SharedToken && PermissionState
                              ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                              : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                          } className={cardType? cardType == "twitter" ? "social-card-content" : "other-card-content" : "other-card-content"}
                        >
                          {cardType ? (
                            cardType == "twitter" ? (
                              <div className="twitterItemsText">
                                {parse(content.html)}
                              </div>
                            ) : cardType == "facebook" ? (
                              content?.imageUrl[0].isAdLibrary ? (
                                <div className="ad-lib-first-html">
                                  {parse(content.html)}
                                </div>
                              ) : (
                                <div className="first-html">
                                  <a
                                    href={
                                      SharedToken && PermissionState
                                        ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                                        : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                                    }
                                  >
                                    {" "}
                                    {parse(content.html)}
                                  </a>
                                </div>
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </a>
                        {content?.imageUrl[0]?.url ? (
                          <div className="video-container">
                            {isPlaying ? (
                              <video controls>
                                <source src={content?.imageUrl[0].url}></source>
                              </video>
                            ) : (
                              <video poster={iOS && !content?.imageUrl[0]?.metaData?.thumbnail? videoThumnail : content?.imageUrl[0]?.metaData?.thumbnail? content?.imageUrl[0]?.metaData?.thumbnail:""} style={{width: "100%", height: "100%"}}>
                                <source src={content?.imageUrl[0].url}></source>
                              </video>
                            )}

                            {isPlaying && cardId == isActiveVideo ? (
                              // <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPause} style={{color:"#fff",fontSize:"40px"}}/>
                              ""
                            ) : content?.imageUrl[0]?.url ? (
                              <>
                                <div
                                  className="toggle-view-icon-outer"
                                  onClick={(event) =>
                                    handlePlayVideo(event, cardId)
                                  }
                                >
                                  <div className="toggle-view-icon">
                                    {/* <FontAwesomeIcon className="ml-[40px] text-[#989898]" icon={faPlay}  style={{color:"#111111b0",fontSize:"40px"}}/> */}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : cardType == "youtube" ? (
                          <div className="youtube-container">
                            <iframe
                              src={
                                `https://www.youtube.com/embed/` +
                                content?.sourceUrl.split("v=")[1].split("&")[0]
                              }
                              width="100%"
                              height="315"
                            ></iframe>
                          </div>
                        ) : cardType == "facebook" &&
                          content?.imageUrl[0]?.metaData ? (
                          <div className="youtube-container">
                            <VideoEmbed url={content?.imageUrl[0]?.metaData?.embedUrl} />
                          </div>
                        ) : (
                          ""
                        )}

                        {cardType == "facebook" ? (
                          content?.imageUrl[0].isAdLibrary ? (
                            <>
                              <div className="ad-lib-second-html">
                                {parse(content.html)}
                              </div>
                            </>
                          ) : (
                            <div className="second-html">
                              {parse(content.html)}
                            </div>
                          )
                        ) : (
                          ""
                        )}
                        {/* {parse(videoIframe)} */}
                      </div>
                    ) : content.html ? (
                      parse(content.html)
                    ) : content?.imageUrl.length > 0 ? (
                      <a
                        href={
                          SharedToken && PermissionState
                            ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                            : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                        }
                      >
                        {" "}
                        <img
                          className="w-full"
                          src={content?.imageUrl[0].url}
                        />
                      </a>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {/* {content?.html?  parse(content.html) : <img className="w-full" src={content?.imageUrl} />} */}
                </div>
              </div>
              <a
                href={
                  SharedToken && PermissionState
                    ? `/app/card-detail/${cardId}?shared=true&permission=${PermissionState}`
                    : !PublicBucket ?  `/app/card-detail/${cardId}` : `/public/card/${cardId}`
                }
              >
                <div className="px-3">
                  <div className="lastUpdate">
                    <p className="updatedtext-content py-1">
                      Last updated on{" "}
                      {moment(updated).format("MMM D, YYYY @ hh:mm A")}
                    </p>
                  </div>
                  <div className="flex items-center flex-wrap">
                    <div className="cardTags cardTags-content flex flex-wrap py-1">
                      {tags.length > 0 ? (
                        tags.map((item) => {
                          return (
                            <span className="tagsname-textcontent py-1 px-3 mr-[6px] my-1 rounded-2xl">
                              {item.name}
                            </span>
                          );
                        })
                      ) : (
                        <span className="tagsname-textcontent py-1 px-3 mr-[6px] my-1 rounded-2xl">
                          No Tags Assigned
                        </span>
                      )}
                    </div>
                    <div className="profile-displaycontent">
                      <div className="profile-imgcontent">
                        {SharedToken && sharedUsers ? (
                          <div className="tooltip">
                            <span className="tooltip-text">
                              <p>Share By : &nbsp;{sharedBy}</p>
                              <p>
                                {" "}
                                Share with :&nbsp;
                                {sharedUsers?.map((user, index) => (
                                  <span
                                    key={index}
                                    style={{ display: "inline-flex" }}
                                  >
                                    {user?.fullName}{" "}
                                    {index < sharedUsers?.length - 1 ? "," : ""}
                                  </span>
                                ))}{" "}
                              </p>
                            </span>
                            {sharedUsers?.slice(0, 3)?.map((user) => (
                              <>
                                {user?.imageUrl ? (
                                  <img src={user?.imageUrl} alt="profile1" />
                                ) : (
                                  <Avatar
                                    name={`${user.fullName}`}
                                    size="20"
                                    round={true}
                                  />
                                )}
                              </>
                            ))}
                          </div>
                        ) : (
                          sharedUsers?.slice(0, 3)?.map((user) => (
                            <div className="tooltip">
                              <span className="tooltip-text">
                                {/* <p>Share By &nbsp;{sharedBy}</p> */}
                                <p>
                                  {" "}
                                  Share with : &nbsp;
                                  {sharedUsers?.map((user, index) => (
                                    <span
                                      key={index}
                                      style={{ display: "inline-flex" }}
                                    >
                                      {user?.fullName}{" "}
                                      {index < sharedUsers?.length - 1
                                        ? ","
                                        : ""}
                                    </span>
                                  ))}{" "}
                                </p>
                              </span>
                              <>
                                {user?.imageUrl ? (
                                  <img src={user?.imageUrl} alt="profile1" />
                                ) : (
                                  <Avatar
                                    name={`${user.fullName}`}
                                    size="25"
                                    round={true}
                                    className="profile-avatar"
                                  />
                                )}
                              </>
                            </div>
                          ))
                        )}

                        {sharedUsers?.length > 3 && (
                          <div className="plus-share">+</div>
                        )}
                      </div>
                      <p className="share-content dark:text-[#d3d3d3]">
                        {sharedUsers?.length > 0
                          ? sharedUsers?.length + " Share(s)"
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </>
          )}

          <div className="lastsection-subiconcards flex justify-between py-3 px-5 mt-1">
            <a>
              {logo ? (
                <img
                  // className="border-rounded"
                  style={{ maxWidth: "20px" }}
                  src={
                    cardType
                      ? cardType == "twitter"
                        ? twitterlogo
                        : cardType == "facebook" || cardType == "Facebook"
                        ? FbIcon
                        : cardType == "screenshoot"
                        ? ZimzeeIcon
                        : cardType == "fullScreen"
                        ? FullScreen
                        : cardType == "partScreen"
                        ? RegionalScreen
                        : cardType == "highlightText"
                        ? Highlight
                        : cardType == "youtube"
                        ? youtubeIcon
                        : cardType == "TikTok"
                        ? tiktokIcon
                        : cardType == "instagram"
                        ? instagramIcon
                        : defaultUser
                      : ""
                  }
                  alt="card-logo"
                />
              ) : (
                ""
              )}
            </a>
            <a href={"javascript:void()"}>
              {" "}
              {note && /\S/.test(stripHtmlTags(note)) ? (
                <div className="tooltip">
                  <span className="tooltip-text">
                    <p dangerouslySetInnerHTML={createMarkup(note)} />
                    {/* <p>{note ? parse(note) : ""}</p> */}
                  </span>
                  <img
                    className="tooltip-icon"
                    src={clipboardNotes}
                    style={{ width: "20px" }}
                    alt="clipbaord"
                  />
                </div>
              ) : (
                // note?.length != 0 ? (
                //   <div className="tooltip">
                //     <span className="tooltip-text">
                //       <p>{note ? parse(note) : ""}</p>
                //     </span>
                //     <img
                //       className="tooltip-icon"
                //       src={clipboardNotes}
                //       style={{ width: "20px" }}
                //     />
                //   </div>
                <img src={ClipBoard} alt="card-note-image/>" />
              )}
            </a>
            {
              !PublicBucket?
              <button type="button" onClick={() => handleStarClick(cardId)}>
              {" "}
              {isActive ? (
                <img
                  src={Union}
                  alt="subStar"
                  style={{ width: "20px", marginBottom: "6px" }}
                />
              ) : (
                <img
                  src={SubStar}
                  alt="subStar"
                  style={{ width: "20px", marginBottom: "6px" }}
                />
              )}
            </button> :
             <button type="button">
             {" "}
             {isActive ? (
               <img
                 src={Union}
                 alt="subStar"
                 style={{ width: "20px", marginBottom: "6px" }}
               />
             ) : (
               <img
                 src={SubStar}
                 alt="subStar"
                 style={{ width: "20px", marginBottom: "6px" }}
               />
             )}
           </button>
            }
           
            {/* <a href="#">
              {" "}
              <img src={SubTag} alt="subTag" className="subtags" />
            </a> */}
            <a href={`javascript:void(0);`}>
              {" "}
              <img
                src={SubShare}
                alt="subShare"
                onClick={handleCardShare}
              />{" "}
            </a>
            <Transition appear show={isOpenss} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCardModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Delete
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete?
                          </p>
                        </div>

                        <div className="mt-4">
                          <button
                            style={{
                              background: "#FF6600",
                              width: "80px",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "50px",
                            }}
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => handleDeleteCard(cardId)}
                          >
                            Yes
                          </button>
                          <button
                            style={{
                              background: "#000",
                              width: "80px",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "50px",
                            }}
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={closeCardModal}
                          >
                            No
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openDuplicate} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeDuplicateModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Duplicate
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to duplicate?
                          </p>
                        </div>

                        <div className="mt-4">
                          <button
                            style={{
                              background: "#FF6600",
                              width: "80px",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "50px",
                            }}
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => handleUpdateCardMenu(cardId)}
                          >
                            Yes
                          </button>
                          <button
                            style={{
                              background: "#000",
                              width: "80px",
                              color: "#fff",
                              padding: "10px",
                              borderRadius: "50px",
                            }}
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={closeDuplicateModal}
                          >
                            No
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openMove} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeMoveModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Move To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="flex fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon test">
                              {searchBucket ? (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faClose}
                                  onClick={handleBucketClear}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "#707070",
                                    paddingTop: "8px",
                                  }}
                                  icon={faSearch}
                                  onClick={handleBucketOpen}
                                />
                              )}
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                      {option.subBuckets &&
                                      option.subBuckets.length > 0 ? (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "12px",
                                            color: "#707070",
                                            position: "absolute",
                                            right: "17px",
                                            top: "14px",
                                          }}
                                        />
                                      ) : null}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeMoveModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCardMenu(cardId)}
                            >
                              <span className="savebutton-context">Move</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openCopy} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy To
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="fliesbucket-inputcontent filesbottom-input">
                            <input
                              type="text"
                              placeholder="Select a bucket"
                              value={searchBucket}
                              onChange={handleBucketInputChange}
                            />
                            <span className="dwondownIcon">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "20px",
                                  color: "#707070",
                                  paddingTop: "8px",
                                }}
                                icon={faSearch}
                                onClick={handleBucketOpen}
                              />
                            </span>
                            <div className="tab-scrolls singlecard">
                              <ul className="dropdown">
                                {filteredBuckets?.map((option) => (
                                  <li key={option._id}>
                                    <p
                                      onClick={() => handleBucketChange(option)}
                                    >
                                      {option.name}
                                    </p>
                                    {option.subBuckets &&
                                    option.subBuckets.length > 0 ? (
                                      <ul className="sub-dropdown">
                                        {option.subBuckets.map((subTag) => (
                                          <li key={subTag._id}>
                                            <p
                                              onClick={() =>
                                                handleSubBucketSelectChange(
                                                  subTag
                                                )
                                              }
                                            >
                                              -- {subTag.name}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            <hr />
                            <div
                              className="movetotop-content cancelsinglecard-content"
                              style={{ cursor: "pointer" }}
                              onClick={closeCopyModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white movetotop-content"
                              onClick={() => handleUpdateCardMenu(cardId)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openEdit} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeEditModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit Name
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="Edit Name"
                              value={EditFile}
                              onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeEditModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleUpdateCardMenuName(cardId)}
                            >
                              <span className="savebutton-context">Update</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={openCopyLink} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={closeCopyLinkModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Copy and Paste this URL
                        </Dialog.Title>
                        <div
                          style={{ display: "block" }}
                          className="custom-list-dropdown"
                        >
                          <div className="position-relative">
                            <input
                              type="text"
                              placeholder="URL"
                              value={publicUrl}
                              className="publicUrlInput"
                              // onChange={handleFileInput}
                            />
                          </div>
                          <div className="flex justify-end">
                            <div
                              className="cancelfilecard-content"
                              onClick={closeCopyLinkModal}
                            >
                              Cancel
                            </div>
                            <button
                              style={{
                                filter:
                                  "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
                              }}
                              className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white"
                              onClick={() => handleCopyText(publicUrl)}
                            >
                              <span className="savebutton-context">Copy</span>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCard;
