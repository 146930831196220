import React from "react";
import logo from "../../assets/icons/logo.png";
import magicLinkIcon from "../../assets/icons/magicLinkIcon.svg";
import axios from 'axios';

function LoginMagicLink(){

    const handleSubmit = (e) => {
        // /setLoading(true);
		e.preventDefault();
		console.log(e.target.password.value);
		const pathslug = window.location.pathname
		.split("/")
		.filter(Boolean)
		.at(-1);
	  	console.log(pathslug);

		const data = {
			"token": pathslug,
			password: e.target.password.value
		}
		
        axios
          .post(`${process.env.REACT_APP_API}users/password-reset`, data)
          .then((response) => {
            console.log(response);
          });
      };
    return(
        <div className="loginHolder bg-[#F3F4F6] min-h-[100vh] flex items-center">
            <div className="formHolder bg-white md:w-1/2 lg:w-2/5 max-w-lg mx-auto my-5 p-8 rounded-lg shadow">
                <div className="logo">
                    <img src={logo} />
                </div>
                <h3 className="text-xl mt-7 mb-6 font-bold text-[24px]">Login with Magic Link</h3>
                <form className="loginForm flex flex-col" onSubmit={handleSubmit}>
                {/* style={{borderColor:"#D9D9D9 !important", borderWidth: '1px'}} */}
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="email" placeholder="Your Email" />
                    <button type="button" className="button bg-[#EE7900] text-white py-2 rounded-[5px] mt-4">Request magic link</button>
                </form>
                <p className="text-center pt-3 pb-5">A one click password-free login link will be emailed to you.</p>
                <div className="mt-4 text-center">
                    <hr className="-mb-3 border-[#394150]" />
                    <span className="inline-block bg-white text-center text-[#394150] px-3">OR</span>
                </div>
                <a href="/" type="button" className="flex justify-center py-3 mt-7" style={{border:"1px solid #D9D9D9", borderRadius: "50px",width:"100%"}}>
                    <img className="pr-3" src={magicLinkIcon} />
                    <span className="font-bold">Login with Password</span>
                </a>
                <h4 className="font-bold text-center mt-12">Don't have an account? <a className="text-[#5587e9]" href="#">Create account</a></h4>
            </div>
        </div>
    )
}

export default LoginMagicLink;