import React from "react";
import "./Dashboard.scss";
import "../WhatIShared/shared.scss";
import CanvasJSReact from "@canvasjs/react-charts";
import { Fragment, useState, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Slider from "react-slick";
// import moment from "moment"; 
import LeftArrow from "../../assets/icons/leftarrow.svg";
import RightArrow from "../../assets/icons/rightarrow.svg";
import "react-tabs/style/react-tabs.css";
import RecentBuckets from "./RecentBuckets/RecentBuckets";
import Avatar from 'react-avatar';
import RecentCards from "./RecentCards/RecentCards";
import RecentFiles from "./RecentFiles/RecentFiles";
import KindleHighlights from "./KindleHighlights/KindleHighlights";
import Profile1 from "../../assets/icons/dashboardprofile1.png";
import Profile2 from "../../assets/icons/dashboardprofile2.png";
import Profile3 from "../../assets/icons/dashboardprofile3.png";
import Profile4 from "../../assets/icons/dashboardprofile4.png";
import JackTeam from "../../assets/images/jackTeam.png";
import NickyTeam from "../../assets/images/nickyTeam.png";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TotalBuckets from "../../assets/images/totalBuckets.svg";
import TotalSub from "../../assets/images/totalSub.svg";
import TotalCards from "../../assets/images/totalCards.svg";
import TotalProjects from "../../assets/images/totalProjects.svg";
import TotalKindle from "../../assets/images/totalKindle.svg";
import TotalFiles from "../../assets/images/totalFiles.svg";
import TotalBookmarks from "../../assets/images/totalBookmarks.svg";
import Graph from "../../assets/images/graph.png";
import Donut from "../../assets/images/donutChart.png";
import { useEffect } from "react";
import axios from "axios";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format,
} from "date-fns";

// import Donut from '../../assets/images/donutsCharts.png';
// import Donut from '../../assets/images/donutCharts.png';
import { Menu, Transition } from "@headlessui/react";
import { TagCloud } from 'react-tagcloud'
import TagClouds, {renderData} from 'd3-tagcloud-for-react';
import RecentBookmarks from "./RecentBookmarks/RecentBookmarks";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const moment = require('moment-timezone');


function Dashboard() {
  const filterDropDown = [
    // { name: "Current Week" },
    { name: "Current Month"},
    { name: "Current Year" },
  ];
  const [splineGraphData, setSplineGraphData] = useState({});
  const [donutGraphData, setDonutGraphData] = useState({});
  const [recentActivites, setRecentActivites] = useState([]);
  const [statsData, setStatsData] = useState({});
  const [recentItems, setRecentItems] = useState([]);
  const [UpdateRecentCards, setUpdateRecentCards] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [FiltersText, setFiltersText] = useState(filterDropDown[1].name);
  const [dateRangeOption, setDateRangeOption] = useState("thisYear");
  const [weekRangeOption, setWeekRangeOption] = useState("Current_Year"); // Default to 'thisWeek'
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tagCloudData, setTagCloudData] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [TextYear,setTextYear]=useState('previous year')
  const [belowGraphText, setBelowGraphText] = useState("current year");
  const [currentSelection,setCurrentSelection]=useState('year')
  const [GraphBack,setGraphBack]=useState(false)
  const [testState, setTestState] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [formattedDatas, setFormattedDatas] = useState([]);

  // const data = [
  //   { value: 'JavaScript', count: 38 },
  //   { value: 'React', count: 30 },
  //   { value: 'Nodejs', count: 28 },
  //   { value: 'Express.js', count: 25 },
  //   { value: 'HTML5', count: 33 },
  //   { value: 'MongoDB', count: 18 },
  //   { value: 'CSS3', count: 20 },
  // ]

  const colorarray = [
    '#8c5fc4',
    '#61b8ff',
    '#f7d286',
    '#e86971',
    '#76bb7f',
    '#5bbeca',
  ]
  const data = [
    {
    label: 'React',
    fontSize: 10,
    opacity: 8,
    color:'blue'
    },
    {
    label: 'jQuery',
    fontSize: 9,
    opacity: 9,
    },
    {
    label: 'ECMAScript 6',
    fontSize: 4,
    opacity: 7,
    },
    {
    label: 'webpack',
    fontSize: 4,
    opacity: 4,
    },
    {
    label: 'Redux',
    fontSize: 4,
    opacity: 7,
    },
    {
    label: 'HTML5',
    fontSize: 9,
    opacity: 9,
    },
    {
    label: 'JSON APIs',
    fontSize: 9,
    opacity: 6,
    },
    {
    label: 'JavaScript',
    fontSize: 9,
    opacity: 8,
    },
    {
    label: 'CSS3',
    fontSize: 9,
    opacity: 9,
    },
    {
    label: 'PHP',
    fontSize: 8,
    opacity: 3,
    },
    {
    label: 'MySQL',
    fontSize: 8,
    opacity: 4,
    },
    {
    label: 'WordPress',
    fontSize: 9,
    opacity: 8,
    },
    {
    label: 'Github',
    fontSize: 6,
    opacity: 5,
    },
    {
    label: 'SASS',
    fontSize: 6,
    opacity: 6,
    },
    {
    label: 'Node.js',
    fontSize: 4,
    opacity: 4,
    },
    {
    label: 'JSX',
    fontSize: 8,
    opacity: 5,
    },
    {
    label: 'SEO',
    fontSize: 9,
    opacity: 8,
    },
    {
    label: 'Redux Saga',
    fontSize: 4,
    opacity: 6,
    },
    {
    label: 'Immutable',
    fontSize: 4,
    opacity: 5,
    },
    {
    label: 'CSS Javascript',
    fontSize: 4,
    opacity: 6,
    },
    {
    label: 'GraphQL',
    fontSize: 1,
    opacity: 4,
    },
  ]
// useEffect(()=>{
//   const chart = new CanvasJS.Chart("chartContainer", Donutoptions);
//   chart.render();
// },[])
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        let startDate, endDate;

        if (dateRangeOption === "thisWeek") {
          // Calculate fromDate and toDate for the current week
          const currentDate = new Date();
          startDate = startOfWeek(currentDate);
          endDate = endOfWeek(currentDate);
        } else if (dateRangeOption === "thisMonth") {
          // Calculate fromDate and toDate for the current month
          const currentDate = new Date();
          startDate = startOfMonth(currentDate);
          endDate = endOfMonth(currentDate);
        } else if (dateRangeOption === "thisYear") {
          // Calculate fromDate and toDate for the current year
          const currentDate = new Date();
          startDate = startOfYear(currentDate);
          endDate = endOfYear(currentDate);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_API}dashboard/graph-data`,
          {
            fromDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
            toDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
            durationType:weekRangeOption
          },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        console.log(response?.data?.data, "spline data");
        setSplineGraphData(response?.data?.data);
        // response?.data?.data?.currentDateCards?.map(function(dataPoint){
        //   // console.log(dataPoint.x);
        //   const dateWithoutTime = new Date(dataPoint.x);
        //   const dateWithZeroTime = dateWithoutTime.setUTCHours(0, 0, 0, 0);
          
        //   // const formattedDate = `${dateWithZeroTime.getUTCFullYear()}-${String(dateWithZeroTime.getUTCMonth() + 1).padStart(2, '0')}-${String(dateWithZeroTime.getUTCDate()).padStart(2, '0')}`;

        //   console.log(new Date(dateWithZeroTime),"dateWithoutTime");
        // });
        console.log(response?.data?.data, "Graph data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, [dateRangeOption]);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/buckets-chartData`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint

       const modifiedArray = Object.keys(response?.data?.data).reduce((result,key)=>{
        if(response?.data?.data[key] !=0 && response?.data?.data[key] != null){
          const obj =  {
              name: key === 'privateBuckets'?  "Private Buckets" : key === 'sharedBuckets'? "Shared Buckets" :key === 'bucketWithFiles'? "Bucket with Files" : 'Cards with Comments'  ,
              y: response?.data?.data[key],
              color: key === 'privateBuckets'?  "#ff844b" : key === 'sharedBuckets'? "#6b66ce" :key === 'bucketWithFiles'? "#ff606b" : '#3cc2e0'  ,
          }

           result = [...result, obj]
      
          }

          return result
        },[])
//           let finalArray = []
//       if(response?.data?.data['privateBuckets'] !=0 && response?.data?.data['privateBuckets'] != null){
//         finalArray = [...finalArray, {
          
//             name: "Private Buckets",
//             y: response?.data?.data['privateBuckets'],
//             // y: donutGraphData.privateBuckets>0 || donutGraphData.privateBuckets!=null ?donutGraphData.privateBuckets:null,
//             color: "#ff844b",
          
//         }]

        
//         console.log(finalArray,"loggs final array")
//       }
// console.log(modifiedArray,"final Array")
      setTestState(modifiedArray)
        setDonutGraphData(response?.data?.data);
        console.log(response?.data?.data, "donout graph data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/recent-activities`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint



        setRecentActivites(response?.data?.data);
        console.log(response?.data?.data, "donout graph data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/active-teamMembers`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setActiveMembers(response?.data?.data);
        console.log(response?.data?.data, "tags cloud data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/tags-cloud`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setTagCloudData(response?.data?.data);
        console.log(response?.data?.data?.tagsData,"tag cloud response");

        const convertedDatas = response?.data?.data?.tagsData.map(item => ({
          label: item.tagInfo.name,
          fontSize: item.count,
          // opacity: item.count,
        }));
        setFormattedDatas(convertedDatas);
        const convertedData = response?.data?.data?.tagsData.map(item => ({
          value: item.tagInfo.name,
          count: item.count,
        }));
        // convertedData.push( { value: 'JavaScript', count: 38 },
        // { value: 'React', count: 30 },
        // { value: 'Nodejs', count: 28 },
        // { value: 'Express.js', count: 25 },
        // { value: 'HTML5', count: 33 },
        // { value: 'MongoDB', count: 18 },
        // { value: 'CSS3', count: 20 });
        // Set the formatted data in the state
        setFormattedData(convertedData);
        console.log(response?.data?.data, "tags cloud data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/stats`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setStatsData(response?.data?.data);
        console.log(response?.data?.data, "statssss data"); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, []);
  useEffect(() => {
    // Function to fetch data from the API and update the state
    const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}dashboard/recent-items`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setRecentItems(response?.data?.data);
        setUpdateRecentCards(false);
        console.log(response?.data?.data, "recent Items Data data");
        setIsLoading(false); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, [UpdateRecentCards]);

let  options = {
  
  animationEnabled: true,
  backgroundColor: "transparent",
  theme: "light2",
  axisX: {
    labelFormatter: function (e) {
      return CanvasJS.formatDate( e.value, "DD MMM");
    },
    crosshair: {
      enabled: true,
      snapToDataPoint: true,
    },
    interval: dateRangeOption === "thisYear"? 5 : 3,
    intervalType: dateRangeOption === "thisYear"? "day" : "day",
    gridColor: "transparent",
  },
  axisY: {
    title: "Number of Cards",
    valueFormatString: "00",
    crosshair: {
      enabled: true,
      snapToDataPoint: true,
      labelFormatter: function (e) {
        return CanvasJS.formatNumber(e.value, "##0");
      },
    },
    gridColor: "#70707045",
    gridThickness: 1,
  },
  data: [
    {
      type: "splineArea",
      xValueFormatString: "DD MMM",
      yValueFormatString: "##0",
      lineColor: "#ff844b",
      lineThickness: 3,
      color: "#ffd2be",
      markerColor: "#ff844b",
      
      markerSize: 0,
      toolTipContent: "{x} <br>  Created Cards: {y}", // Customize the tooltip content as needed
    // dataPointss:[
    //     { x: new Date('2024, 02, 1'), y: 1}, 
    //     { x: new Date('2024, 02, 2'), y: 0},
		// 	  { x: new Date('2024, 02, 3'), y: 2}, 
    //     { x: new Date('2024, 02, 4'), y: 8}, 
    //     { x: new Date('2024, 02, 5'), y: 2}, 
    //     { x: new Date('2024, 02, 6'), y: 0}, 

		// 	  // { x: new Date('2024, 01, 6'), y: 3}, 
		// 	  // { x: new Date('2024, 01, 8'), y: 4}, 
		// 	  // { x: new Date('2024, 01, 11'), y: 5}, 
		// 	  // { x: new Date('2024, 01, 15'), y: 6}, 
		// 	  // { x: new Date('2024, 01, 19'), y: 7}, 


    // ],
    
    dataPoints: 
    // dateRangeOption === "thisWeek" ? generateRemainingDates(splineGraphData?.currentDateCards).map((dataPoint) => 
    // {
    //   // let dateWithoutTime = new Date(new Date(dataPoint.x).setUTCHours(0, 0, 0, 0))
    //   console.log(dataPoint,"dateWithoutTime dataPoint");
    //   const date = new Date(dataPoint.x);
    //   const year = date.getFullYear();
    //   const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
    //   const day = date.getDate();
    //   const formattedDate = `${year}, ${month < 10 ? '0' + month : month}, ${day < 10 ? '0' + day : day}`;
    //   console.log(formattedDate,dataPoint.y,"dateWithoutTime curr"); // Output: "2024, 02, 01"
    //   return {
    //     // x: new Date(moment(dataPoint.x).tz('America/New_York')),
    //     // x: new Date(new Date(dataPoint.x).setUTCHours(0, 0, 0, 0)),
    //     x: new Date(formattedDate),
    //     y: dataPoint.y? dataPoint.y : 0,
    //   }
    // }) : 
    splineGraphData?.currentDateCards?.map((dataPoint) => 
    {
      // let dateWithoutTime = new Date(new Date(dataPoint.x).setUTCHours(0, 0, 0, 0))
      console.log(dataPoint,"dateWithoutTime dataPoint");
      const date = new Date(dataPoint.x);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
      const day = date.getDate();
      const formattedDate = `${year}, ${month < 10 ? '0' + month : month}, ${day < 10 ? '0' + day : day}`;
      console.log(formattedDate,dataPoint.y,"dateWithoutTime curr"); // Output: "2024, 02, 01"
      return {
        // x: new Date(moment(dataPoint.x).tz('America/New_York')),
        // x: new Date(new Date(dataPoint.x).setUTCHours(0, 0, 0, 0)),
        x: new Date(formattedDate),
        y: dataPoint.y? dataPoint.y : 0,
      }
    }),
      // dataPoints: splineGraphData?.currentDateCards?.map((dataPoint) => (
      //   {
      //   // x: new Date(moment(dataPoint.x).tz('America/New_York')),
      //   // x: new Date(new Date(dataPoint.x).setUTCHours(0, 0, 0, 0)),
      //   x: new Date(dataPoint.x),
      //   y: dataPoint.y,
      // })),
    },
  ],
};


// Function to fill missing daily dates
function generateRemainingDates(data){
// const generateRemainingDates = (data) => {
  // Initialize a new array to store filled data
  const filledData = [];

  // Helper function to convert date string to date object
  const toDate = (dateString) => new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Find the start and end date of the current week
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to first day of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to last day of the week (Saturday)

  // Iterate over each day of the current week
  for (let d = new Date(startOfWeek); d <= endOfWeek; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString();
      const existingData = data?.find(obj => obj.x.slice(0, 10) === dateString.slice(0, 10));

      if (existingData) {
          filledData.push(existingData); // Add existing data if available
      } else {
          filledData.push({ x: dateString, y: 0 }); // Add missing data with y value 0
      }
  }

  return filledData;
};

// const filledData = generateRemainingDates(data);
// console.log(filledData);
// function generateRemainingDates(existingDates) {
//   // Extract date strings from the "x" property of each object
//   const existingDateStrings = existingDates?.map(obj => obj.x.split('T')[0]);

//   const today = new Date();
//   const currentDay = today.getDay(); 
//   const currentDate = today.getDate();
//   const startDate = new Date(today.setDate(currentDate - currentDay + 1));

//   const dates = [];
//   for (let i = 0; i < 7; i++) {
//       const nextDate = new Date(startDate);
//       nextDate.setDate(startDate.getDate() + i);
//       const isoDate = nextDate.toISOString().split('T')[0];
//       if (!existingDateStrings?.includes(isoDate)) dates.push(isoDate);
//   }

//   return dates;
// }

// Example usage
// const existingDates = ["2024-02-05", "2024-02-06"];
// const remainingDates = generateRemainingDates(existingDates);
// console.log([...existingDates, ...remainingDates]);
// options.data[0].dataPoints = options?.data[0]?.dataPoints.slice(0,2);
console.log(options?.data[0]?.dataPoints,"data[0]?.dataPoints");
// console.log(options?.data[0]?.dataPointss,"data[0]?.dataPointss");
  const DonutOptions = ("chartContainer",{
    animationEnabled: true,
    height:160,
    backgroundColor: "transparent",
    width:160,
    data: [
      {
        type: "doughnut",
        radius: "100%", 
        innerRadius: "65%",  //change the innerRadius here.
        backgroundColor: "transparent",
        indexLabelFontSize: 13,
        indexLabelFontWeight: 600,
        indexLabel: "{name}: {y}",
        indexLabelPlacement: "inside",
        yValueFormatString: "#,###",
        dataPoints:testState,
        indexLabel: 
         " {y} ",

         indexLabelFontColor: "white", 
      },
    ],
  });
  


  const RecentCardUpdate = () => {
    setUpdateRecentCards(true);
  };
  const handleFilterText = (text) => {
    setFiltersText(text);
    // if (text === "Current Week") {
    //   setDateRangeOption("thisWeek");
    //   setBelowGraphText("current week");
    //   setTextYear("previous week")
    //   setWeekRangeOption('Current_Week')
    //   setCurrentSelection('week')
    // } else
    
    if (text === "Current Month") {
      setDateRangeOption("thisMonth");
      setWeekRangeOption('Current_Month')
      setBelowGraphText("current month");
      setTextYear("previous month")
      setCurrentSelection('month')
    } else if (text === "Current Year") {
      setDateRangeOption("thisYear");
      setBelowGraphText("current year");
      setTextYear("previous year")
      setCurrentSelection('year')
      setWeekRangeOption('Current_Year')
    }
    // else{
    //   setDateRangeOption('manual')
    // }
    // if (text !== 'manual') {
    //   setFromDate('');
    //   setToDate('');
    // }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="offers-blur">
        <img
          src={RightArrow}
          alt="vector"
          id="next-arrow"
          className={className}
          style={{ ...style, background: "transparent" }}
          onClick={onClick}
        />
      </div>
    );
  }

  useEffect(() => {
    console.log(recentItems.cardsList, "recent cardssssssssssssssssss");
  }, [recentItems]);
  function SamplePreviousArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="offers-blur">
        <img
          src={LeftArrow}
          alt="vector"
          id="previous-arrow"
          className={className}
          style={{ ...style, background: "transparent" }}
          onClick={onClick}
        />
      </div>
    );
  }

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    arrows: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
      let ele = document.getElementById("previous-arrow-offers");
      if (ele) {
        if (index < 1) {
          ele.style.display = "none";
        } else {
          ele.style.display = "block";
        }
      }
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePreviousArrow />,
  };

  return (
    <>
      {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />{" "}
        </div>
      ) : (
        <>
          <div className="dashboard-wholesection">
          <ToastContainer/>
            <div className="bucketHeader">
              <div className="grid grid-cols-3">
                <div className="bucketFeature col-span-2">
                  <div className="flex items-center">
                    <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                      Dashboard
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="dashboard-section w-3/3 row">
              <div className="w-2/3 firstwhole-section">
                <div className="flex rowdashboard-content">
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalBuckets} alt="totalBuckets" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Buckets
                          </div>
                          <div className="bucketsnamembl-content">Total Buckets</div>
                          <div className="bucketnumber-content">
                            {statsData?.totalBuckets}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalSub} alt="totalSub" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Sub Buckets
                          </div>
                          <div className="bucketsnamembl-content">
                            Total Sub Buckets
                          </div>
                          <div className="bucketnumber-content">
                            {statsData?.totalSubBuckets}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalCards} alt="totalCards" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Cards
                          </div>
                          <div className="bucketsnamembl-content">Total Cards</div>
                          <div className="bucketnumber-content">
                            {statsData?.totalCards}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalFiles} alt="totalFiles" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Files
                          </div>
                          <div className="bucketsnamembl-content">Total Files</div>
                          <div className="bucketnumber-content">
                            {statsData?.totalFiles}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalBookmarks} alt="totalBookmarks" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Bookmarks
                          </div>
                          <div className="bucketsnamembl-content">
                          Total Bookmarks
                          </div>
                          <div className="bucketnumber-content"> {statsData?.totalBookMarks}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalProjects} alt="totalProjects" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                          Total AI Projects
                          </div>
                          <div className="bucketsnamembl-content">Total AI Projects</div>
                          <div className="bucketnumber-content">0</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid relative">
                    <div className="cardWhole-background cardWhole-background-kindle">
                      <div className="displaytext-content">
                        <div className="dashbucket-content">
                          <img src={TotalKindle} alt="totalKindle" />
                        </div>
                        <div className="content-section">
                          <div className="bucketsnamedesktop-content">
                            Total Kindle Highlights
                          </div>
                          <div className="bucketsnamembl-content">
                          Total Kindle Highlights
                          </div>
                          <div className="bucketnumber-content">0</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mb-[15px]">
                  <div className="cards-filter flex justify-between">
                    <div className="flex items-center">
                      <div className="flex actionsHolder">
                        <div href="#" className="flex items-center">
                          <div className="cardReviews-content">
                            Card Reviews
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="ml-[auto] flex items-center justify-end">
                      <span className="sorttext-content mx-3 dark:text-[#acacac]">
                        Sort By
                      </span>
                      <Menu as="div" className="relative flex-shrink-0">
                        <div>
                          <Menu.Button className="flex rounded-full focus:outline-none ">
                            <div
                              href="#"
                              className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                            >
                              <strong className="createdtext-content pr-4">
                                {FiltersText}
                              </strong>
                              <FontAwesomeIcon
                                style={{ fontSize: "12px", color: "#707070" }}
                                icon={faChevronDown}
                              />
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {filterDropDown.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                  style={{cursor:"pointer"}}
                                    onClick={() => {
                                      handleFilterText(item.name);
                                    }}
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
                {dateRangeOption === "manual" && (
                  <div>
                    <label htmlFor="fromDate">From Date:</label>
                    <input
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />

                    <label htmlFor="toDate">To Date:</label>
                    <input
                      type="date"
                      id="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                )}
                <hr />
                <div className="reviewed-content">
                  {(splineGraphData?.currnetDateTotalCards-splineGraphData?.previosDateTotalCards)>0? ` You have created ${splineGraphData?.currnetDateTotalCards-splineGraphData?.previosDateTotalCards} more card(s) this ${currentSelection} than
                 the ${TextYear}`:` You have created ${Math.abs(splineGraphData?.currnetDateTotalCards-splineGraphData?.previosDateTotalCards)} fewer card(s) this ${currentSelection} than the
                  ${TextYear}` }
                  
                </div>
                <CanvasJSChart
                  options={options}

                  /* onRef={ref => this.chart = ref} */
                />
                <div className="display-content dashboard-chart">
                  <div>
                    <div className="legend-content bggradient-section"></div>
                  </div>
                  <div>
                    <div className="breaktext-content">{belowGraphText}</div>
                  </div>
                </div>
                {/* <img className="graph-imgcontent" src={Graph} alt="graph" /> */}
               
              </div>
              <div className="w-1/3 ml-[10px] dashboard-width">
                <div className="recenttext-content">Recent Activities</div>
                <hr />
                {recentActivites.length > 0
                  ? recentActivites?.map((active) => {
                      return (
                        <div className="recentactivity-section">
                          <div className="displaywhole-content">
                            <div className="display-content flex items-center">
                            {active?.authorId?.imageUrl ?    <img
                              className="dashboardprofilerecent-imgcontent"
                              src={active?.authorId?.imageUrl}
                              alt="Profile1"

                            />: <Avatar name={`${active?.authorId?.firstName} ${active?.authorId?.lastName}`} size="50"round={true} />}
                             
                              <div className="sharedtext-content">
                              
                                  {active?.authorId?.firstName}{" "}
                             
                                {active?.operationType &&
                                active?.operationType === "Share"
                                  ? "shared a"
                                  : active?.operationType &&
                                  active?.operationType === "Update"?"added a":
                                  active?.operationType &&
                                  active?.operationType === "Add"?"added a":null
                                  }
                               
                                {" "} {(active?.itemType).toLowerCase()}
                                {" "}
                                {active?.operationType &&
                                active?.operationType === "Share"
                                  ? "with you"
                                  : active?.operationType &&
                                  active?.operationType === "Update"?"on a card":
                                  active?.operationType &&
                                  active?.operationType === "Add"?"to a bucket":null
                                  }
                                
                              </div>
                            </div>
                            <div className="datetextfirst-content flex items-center justify-end">
                              {moment(active?.createdAt).format("MMM D, YYYY")}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "No Recent Activity Found !"} 

                <div className="recentactivetext-content">
                  Most Active Team Members
                </div>
                <hr />
                <div className="flex teammembers-content flex-wrap">
                  {activeMembers.length > 0
                    ? activeMembers?.map((member) => {
                        return (
                          <div className="teamspace-content">
                            {member?.imageUrl ?    <img
                              className="dashboardprofile-imgcontent dashbaordicon-imgcontent"
                              src={member?.imageUrl}
                              alt="Profile1"

                            />: <Avatar name={`${member?.firstName} ${member?.lastName}`} size="30"round={true}  style={{margin: "0 auto", display: "flex" ,marginBottom: "10px"}}/>}
                         
                            <div className="activename-content">
                              {member?.firstName} {member?.lastName}
                            </div>
                            <div className="datetext-content">
                              {member?.numberOfCards} cards
                            </div>
                          </div>
                        );
                      })
                    : "No Records Found !"}
                </div>
                {/* <div className="flex secondteam-content teammembers-content">
            <div className="teamspace-content">
              <img className="dashboardprofile-imgcontent" src={Profile1} alt="Profile1" />
              <div className="activename-content">Jack</div>
              <div className="datetext-content">300 cards</div>
            </div>
            <div className="teamspace-content">
              <img className="dashboardprofile-imgcontent" src={Profile2} alt="Profile1" />
              <div className="activename-content">Ursa</div>
              <div className="datetext-content">280 cards</div>
            </div>
            <div className="teamspace-content">
              <img className="dashboardprofile-imgcontent" src={Profile3} alt="Profile1" />
              <div className="activename-content">Kate</div>
              <div className="datetext-content">300 cards</div>
            </div>
            <div className="teamspace-content">
              <img className="dashboardprofile-imgcontent" src={Profile4} alt="Profile1" />
              <div className="activename-content">John</div>
              <div className="datetext-content">250 cards</div>
            </div>
            
          </div> */}
              </div>
              
            </div>

            <div className="tabssectiondesktop-ontent recenttabs-content">
                  <Tabs>
                    <TabList>
                      <Tab>Recent Buckets</Tab>
                      <Tab>Recent Files</Tab>
                      <Tab>Recent Cards</Tab>
                      <Tab>Recent Kindle Highlights</Tab>
                      <Tab>Recent Bookmarks</Tab>
                    </TabList>
                    <TabPanel>
                      <RecentBuckets
                        RecentBucket={recentItems?.bucketList}
                        RecentCardUpdate={RecentCardUpdate}
                      />
                    </TabPanel>
                    <TabPanel>
                      <RecentFiles />
                    </TabPanel>
                    <TabPanel>
                      <RecentCards
                      />
                    </TabPanel>
                    <TabPanel>
                      {/* <KindleHighlights /> */}
                    </TabPanel>
                    <TabPanel><RecentBookmarks/></TabPanel>
                  </Tabs>
                </div>
                <div className="tabssectionmbl-ontent">
                  <Tabs>
                    <TabList>
                      {/* <Slider {...settings}> */}
                        <Tab>Recent Buckets</Tab>
                        <Tab>Recent Files</Tab>
                        <Tab>Recent Cards</Tab>
                        <Tab>Recent Kindle Highlights</Tab>
                        <Tab>Recent Bookmarks</Tab>
                      {/* </Slider> */}
                    </TabList>
                    <TabPanel>
                      <RecentBuckets RecentBucket={recentItems?.bucketList}
                        RecentCardUpdate={RecentCardUpdate}/>
                    </TabPanel>
                    <TabPanel>
                    <RecentFiles />
                    </TabPanel>
                    <TabPanel>
                      <RecentCards />
                    </TabPanel>
                    <TabPanel>
                      {/* <KindleHighlights /> */}
                    </TabPanel>
                    <TabPanel><RecentBookmarks/></TabPanel>
                  </Tabs>
                </div>
                <div className="row w-3/3 spacing-content recenttabs-content">
                  <div className="cloudsection-content">
                    <div className="cardReview-content cloudspace-content">
                      Tag Cloud
                    </div>
                    <hr />
                    {/* <TagCloud
                      minSize={8}
                      maxSize={35}
                      tags={formattedData}
                      // onClick={tag => alert(`'${tag.value}' was selected!`)}
                    /> */}

                  <TagClouds 
                          className='tag-cloud'
                          // rotate={() => Math.round(Math.random() * 180)}
                          // spiral={1}
                          style={{
                            fontFamily: 'sans-serif',
                            // fontSize: () => Math.round(Math.random() * 50) + 16,
                            fontSize: 30,
                            // color: () => randomColor(colorarray),
                            padding: 5,
                            width: '100%',
                            height: '215px',
                          }}
                          data={data}
                          colorarray={colorarray}
                        >
                          {renderData(formattedDatas)}
                          {/* <div
                            style={{
                              fontSize: 30,
                              fontWeight: 'bold',
                              opacity: 0.9,
                              padding: 12,
                              color: 'black'
                            }}
                          >
                            Black
                          </div> */}
                        </TagClouds>
                  
                  </div>
                  <div className="cardReview-content">
                    <div className="cardReview-content cloudspace-content">
                      Bucket Breakdown
                    </div>
                    <hr />
                    <div className="lastdashboard-content">
                      <div className="display-content">
                        <div className="donughnut-section" >
                       

                        <CanvasJSChart
                            options={DonutOptions}
                            
                          />
                   
                          
                  
                        </div>
                        <div className="legend-section">
                          <div className="displaylegend-content">
                            <div className="privatebuckets-content">
                              <div className="display-content">
                                <div>
                                  <div className="legend-content bggradient-section"></div>
                                </div>
                                <div>
                                  <div className="breaktext-content">
                                    Private Buckets
                                  </div>
                                  <div className="percentage-content">
                                  {donutGraphData.privateBuckets > 0 && donutGraphData.privateBuckets!=null ?`${donutGraphData.privateBuckets}` :"0"}
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="teambuckets-content">
                              <div className="display-content">
                                <div>
                                  <div className="legend-content teamgradient-section"></div>
                                </div>
                                <div>
                                  <div className="breaktext-content">
                                    Bucket with Files
                                  </div>
                                  <div className="percentage-content">
                                  {donutGraphData.bucketWithFiles >0 &&donutGraphData.bucketWithFiles!=null ?`${donutGraphData.bucketWithFiles}` :"0"} 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="displaylegend-content seconddashboard-section">
                            <div className="privatebuckets-content">
                              <div className="display-content">
                                <div>
                                  <div className="legend-content privategradient-section"></div>
                                </div>
                                <div>
                                  <div className="breaktext-content">
                                    Shared Buckets
                                  </div>
                                  <div className="percentage-content">
                                  {donutGraphData.sharedBuckets >0 &&donutGraphData.sharedBuckets!=null ?`${donutGraphData.sharedBuckets}` :"0"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="teambuckets-content">
                              <div className="display-content">
                                <div>
                                  <div className="legend-content teambuckgradient-section"></div>
                                </div>
                                <div>
                                  <div className="breaktext-content">
                                    Cards with Comments
                                  </div>
                                  <div className="percentage-content">{donutGraphData.totalCardsWithNotes >0 &&donutGraphData.totalCardsWithNotes!=null ?`${donutGraphData.totalCardsWithNotes}` :"0"}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
          </div>
        </>
      )}
    </>
  );
}
export default Dashboard;
