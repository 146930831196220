import { React } from "react";
import "./PricingPlans.scss";
import "./YearlyPlan.scss";

import chromeIcon from "../../../assets/icons/chrome-svgrepo-com.svg";
import tickIcon from "../../../assets/icons/Tick.svg";
import Facebook from "../../../assets/icons/facebook-svgrepo-com (5).svg";
import Instagram from "../../../assets/icons/instagram-2-1-logo-svgrepo-com (1).svg";
import Tiktok from "../../../assets/icons/tiktok-svgrepo-com (1).svg";
import Youtube from "../../../assets/icons/youTube-plan.svg";
import Xtwitter from "../../../assets/icons/twitter-x-seeklogo.com-4.svg";
import Planarrow from "../../../assets/icons/Big arrow.svg";

function YearlyPlan(props) {
  return (
    <div className="plans">
      <div className="flex flex-row flex-wrap">
        <div className="sm:w-1/1 width-75 ">
            <h4>$33.25/month</h4>
           <h6>
             Billed annually
            <span className="saveplan-text"> (Save ~$189)</span>
          </h6>
          <h6 className="pt-12">$20 each additional user</h6>
        </div>
        <div className="sm:w-1/1 width-25 btn-column">
          <button className="subscribe-btn" onClick={async() => props.createSubscription(`${process.env.REACT_APP_MAIN_PLAN_ID}`,props.priceListY.id,'yearly',props.priceListY.amount)}>Subscribe</button>
        </div>
      </div>

      <div className="plan-details">
        <h5>Included</h5>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Included team members</p>
          </div>
          <div className="sm:w-1/1 width-10 count-align">
            <p>1</p>
          </div>
        </div>
        <p className="sub-heading">Key features</p>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Powerful Chrome extension</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={chromeIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>
              Save ads while browsing in one click ( not just the ad library)
            </p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Save - Facebook, Instagram, TikTok, X, YouTube.</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Permanent saves</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Public and Private share stats</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Auto landing page screenshots when saving ads</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Auto Embed videos over 10 min</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Unlimited share links for Buckets, Cards, Files, Bookmarks</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>
              Unlimited Buckets, Cards, Regional screenshots, Screenshots, Post
              saves, Text and Save, Bookmarks, Tags
            </p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <p className="sub-heading">Personalisation</p>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Focus Bucket</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Custom Personalized Buckets</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Reminder Email w/ Customize frequency/reminders.</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap pt-12">
          <div className="sm:w-1/1 width-90">
            <p>Dark and light view</p>
          </div>
          <div className="sm:w-1/1 width-10 icon-align">
            <img src={tickIcon} alt="chrome-icon" />
          </div>
        </div>
        <p className="sub-heading">Social integrations</p>
        <div className="flex socialmedia-icons">
          <img src={Facebook} alt="socialmedia" />
          <img src={Instagram} alt="socialmedia" />
          <img src={Tiktok} alt="socialmedia" />
          <img src={Youtube} alt="socialmedia" />
          <img src={Xtwitter} alt="socialmedia" />
        </div>
        <hr />

        <div className="flex flex-row flex-wrap">
          <p className="pt-12">
            Kindle book & highlights, Focus Kindle books
            <span> (Coming soon),</span>
          </p>
          <p className="pt-12">
            Brand Filters
            <span> (Coming soon),</span>
          </p>
          <p className="pt-12">
            Collaborative comments with emojis
            <span> (Coming soon),</span>
          </p>
          <p className="pt-12">
            Image markup
            <span> (Coming soon)</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default YearlyPlan;
