import React, { useEffect, useState } from "react";
import imageGet from "../assets/images/Image 81.png";
import { TextEditor } from "text-editor-react";

function Extension(){
    const id = "textRichId";
    const [active,setActive] = useState('false');
    const [text,setText] = useState('Easy Build');
    const [arrayText,setArrayText] = useState([]);
    const listItems = ['Easily build','Double Your Coding','Build Responsive Real World','Easily build','Double Your Coding','Double Your Coding'] 
    function handleDropDown(){
        active === 'true' ? setActive('false') : setActive('true');
    }
    function onChange(e){
        // console.log(e.target.value);
        var x = e.target.value;
        if(x.includes(',')){
            let length = x.length;
            x=x.slice(0, length - 1);
            setArrayText([...arrayText , x]);
            e.target.value = "";
        }
    }
    function handleDelete(index){
    const newArray = [...arrayText];
    newArray.splice(index, 1);
    setArrayText(newArray);
    }

    function updateInput(item) {
        setText(item);
        setActive('false');
    }
    return(
        <div className="extensionContentHolder">
        <div className="headerflex">
            <div className="logo">
                <svg width="149" height="29" viewBox="0 0 149 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.8853 23.8302H37.5139L50.8144 7.09851V2.92432H31.3943V7.41675H44.1642L31.0405 23.8302V28.3227H50.8853V23.8302Z" fill="#0F0F0F"/>
                    <path d="M54.2104 9.50391V28.3227H59.1628V9.50391H54.2104Z" fill="#0F0F0F"/>
                    <path d="M89.8872 15.3053C89.8872 11.1665 87.0221 9.00879 83.3433 9.00879C80.3363 9.00879 78.957 11.0957 78.3201 12.0154C77.0468 9.99924 75.8087 9.00879 73.0849 9.00879C69.7598 9.00879 68.3802 11.025 67.7434 12.263H67.6728V9.50391H62.9326V28.3228H67.8851V16.8616C67.8851 14.81 68.628 13.1827 70.9627 13.1827C73.9339 13.1827 73.9339 15.4112 73.9339 17.0033V28.3228H78.8863V17.0385C78.8863 14.81 79.7707 13.1827 82.2466 13.1827C83.697 13.1827 84.935 14.1024 84.935 16.8971V28.3228H89.8872V15.3053Z" fill="#0F0F0F"/>
                    <path d="M108.741 13.5719V9.50391H93.4955V13.4657H102.48L92.8232 24.467V28.3227H109.095V24.2547H99.2259L108.741 13.5719Z" fill="#0F0F0F"/>
                    <path d="M123.528 22.8043C123.139 24.1485 121.724 24.8563 120.344 24.8563C116.347 24.8563 116.064 21.6725 115.923 20.2575H128.834V19.373C128.834 10.7775 123.705 9.00879 119.991 9.00879C111.748 9.00879 110.97 16.2604 110.97 18.5242C110.97 26.2711 115.038 28.8179 120.344 28.8179C123.563 28.8179 127.313 27.3322 128.622 22.8043H123.528ZM116.064 17.0385C116.347 14.5624 117.585 13.0764 119.92 13.0764C121.512 13.0764 123.457 13.9964 123.74 17.0385H116.064Z" fill="#0F0F0F"/>
                    <path d="M143.195 22.8043C142.806 24.1485 141.391 24.8563 140.012 24.8563C136.014 24.8563 135.731 21.6725 135.59 20.2575H148.501V19.373C148.501 10.7775 143.372 9.00879 139.658 9.00879C131.416 9.00879 130.638 16.2604 130.638 18.5242C130.638 26.2711 134.706 28.8179 140.012 28.8179C143.231 28.8179 146.98 27.3322 148.289 22.8043H143.195ZM135.731 17.0385C136.014 14.5624 137.252 13.0764 139.587 13.0764C141.179 13.0764 143.124 13.9964 143.407 17.0385H135.731Z" fill="#0F0F0F"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M56.6866 0.87793C58.3935 0.87793 59.7771 2.26157 59.7771 3.96832C59.7771 5.67535 58.3935 7.05881 56.6866 7.05881C54.9798 7.05881 53.5962 5.67535 53.5962 3.96832C53.5962 2.26157 54.9798 0.87793 56.6866 0.87793Z" fill="#FF6600"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.17088 26.6102C8.89044 28.1802 11.9473 28.6361 14.8135 28.1198C15.157 28.0579 15.3997 27.7595 15.3901 27.4104C15.3805 27.0613 15.1217 26.7765 14.7751 26.7336C14.6882 26.723 14.6012 26.711 14.5143 26.6978C14.2018 26.6727 13.8892 26.6325 13.5778 26.577C12.4782 26.3813 11.3929 25.9942 10.3694 25.4033C5.97096 22.8638 4.26219 17.4549 6.2426 12.9065C6.53177 12.2422 6.32347 11.4869 5.73449 11.0649C5.14572 10.6431 4.3632 10.6884 3.82737 11.1755C3.13676 11.8035 2.52981 12.5472 2.0381 13.399C-0.955875 18.5849 1.60312 23.9729 6.17088 26.6102Z" fill="#FF6600"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.17085 4.49429C3.45129 6.06426 1.5278 8.48385 0.542203 11.224C0.423963 11.5525 0.560972 11.9116 0.868146 12.078C1.17512 12.2444 1.55139 12.1629 1.7619 11.8838C1.8144 11.814 1.86834 11.7447 1.92325 11.676C2.10141 11.4177 2.29214 11.1673 2.49583 10.9254C3.21544 10.0708 4.09338 9.32449 5.11674 8.73366C9.51515 6.1943 15.0537 7.41915 18.0026 11.4081C18.4334 11.9907 19.1921 12.1878 19.852 11.8886C20.5114 11.5897 20.8633 10.8894 20.7094 10.1818C20.5112 9.26977 20.1699 8.3724 19.6782 7.5208C16.6844 2.33471 10.7386 1.85702 6.17085 4.49429Z" fill="#52CE00"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.3239 15.5521C25.3239 12.412 24.1903 9.5364 22.3099 7.31253C22.0846 7.04611 21.7052 6.98519 21.4078 7.16772C21.1098 7.35049 20.9924 7.71716 21.1288 8.03891C21.1629 8.11959 21.1959 8.20089 21.228 8.28277C21.3626 8.56573 21.4839 8.85653 21.5917 9.15396C21.972 10.2045 22.1795 11.3376 22.1795 12.5194C22.1795 17.5983 18.3495 21.7825 13.4204 22.3418C12.7007 22.4234 12.1505 22.9818 12.0793 23.7028C12.0086 24.4236 12.439 25.0785 13.129 25.299C14.018 25.583 14.9655 25.7366 15.9489 25.7366C21.9372 25.7366 25.3239 20.8263 25.3239 15.5521Z" fill="#2A7FFF"/>
                </svg>
            </div>
            <div className="closeIcon">        
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.0203" cy="12.8914" r="12.6833" fill="black"/>
                    <path d="M14.1046 13.0595L17.9984 9.16561C18.2518 8.91233 18.2518 8.50281 17.9984 8.24953C17.7451 7.99625 17.3356 7.99625 17.0823 8.24953L13.1884 12.1434L9.29462 8.24953C9.04122 7.99625 8.63181 7.99625 8.37853 8.24953C8.12513 8.50281 8.12513 8.91233 8.37853 9.16561L12.2723 13.0595L8.37853 16.9533C8.12513 17.2066 8.12513 17.6161 8.37853 17.8694C8.50475 17.9958 8.67072 18.0592 8.83657 18.0592C9.00242 18.0592 9.16827 17.9958 9.29462 17.8694L13.1884 13.9756L17.0823 17.8694C17.2087 17.9958 17.3745 18.0592 17.5404 18.0592C17.7062 18.0592 17.8721 17.9958 17.9984 17.8694C18.2518 17.6161 18.2518 17.2066 17.9984 16.9533L14.1046 13.0595Z" fill="white"/>
                </svg>
            </div>

        </div>
        <div className="userData">
            <div className="userContent">
                <p>Ganesh Kumar S</p>
                <p>ganesh@zimzee.com</p>
            </div>
            <div className="bellIcon">            
                <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 16.4135C8.10457 16.4135 9 15.518 9 14.4135H5C5 15.518 5.89543 16.4135 7 16.4135ZM7.995 1.51247C8.03307 1.13318 7.85219 0.765227 7.5286 0.563724C7.205 0.362222 6.795 0.362222 6.4714 0.563725C6.14781 0.765227 5.96693 1.13318 6.005 1.51247C3.67474 1.98645 2.0003 4.0355 2 6.41347C2 7.51147 1.5 12.4135 0 13.4135H14C12.5 12.4135 12 7.51147 12 6.41347C12 3.99347 10.28 1.97347 7.995 1.51247Z" fill="black"/>
                </svg>
            </div>
        </div>
        <div className="select-dropdown">
            <div className="select-dropdown__button">
                <input onClick={handleDropDown} type="text" href="#"  data-value="" value={text} readOnly={true} />
                <div className="iconHOlder">
                    <div className="arrow">
                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.25781 1.56201L7.64905 7.95325L14.0403 1.56201" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>                            
                    </div>
                </div>
            </div>
            <div className="add">                        
                <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7195 1.41537L12.971 1.39844C13.9095 1.39844 14.6852 2.09584 14.8079 3.0007L14.8248 3.25225V11.9033H23.4759C24.4145 11.9033 25.1901 12.6007 25.3129 13.5056L25.3297 13.7572C25.3297 14.6957 24.6322 15.4713 23.7276 15.594L23.4759 15.611H14.8248V24.2621C14.8248 25.2006 14.1274 25.9762 13.2226 26.0991L12.971 26.1159C12.0325 26.1159 11.2569 25.4183 11.1341 24.5137L11.1172 24.2621V15.611H2.46611C1.52759 15.611 0.751982 14.9136 0.629236 14.0087L0.612305 13.7572C0.612305 12.8186 1.30971 12.043 2.21456 11.9203L2.46611 11.9033H11.1172V3.25225C11.1172 2.31372 11.8146 1.53812 12.7195 1.41537Z" fill="white" stroke="white"/>
                </svg>
            </div>
            <ul className={`select-dropdown__list ${active === 'true' ? 'active' : ''}`}>
                {listItems.map((item,key) => {
                    return <li onClick={()=>updateInput(item)} key={key} value={item} className="select-dropdown__list-item">{item}</li>
                })}
                {/* <li data-value="1" className="select-dropdown__list-item">Easily build</li>
                <li data-value="2" className="select-dropdown__list-item">Double Your Coding</li>
                <li data-value="3" className="select-dropdown__list-item">Build Responsive Real World
                    <ul>
                        <li>School Notes</li>
                        <li>School Notes</li>
                        <li>School Notes</li>
                    </ul>
                </li>
                <li data-value="4" className="select-dropdown__list-item">Easily build</li>
                <li data-value="5" className="select-dropdown__list-item">Double Your Coding</li>
                <li data-value="6" className="select-dropdown__list-item">Build Responsive Real World</li> */}
                
            </ul>
        </div>
        <div className="tagsHolder">
           {/* <p>{text}</p> */}
            {arrayText !== '' 
            ? arrayText.map((arr,index) => {
              return  <p>{arr} <span onClick={()=>handleDelete(index)}>x</span></p>
            }) 
            : ''}
            <input type="text" onChange={onChange} id="tagCreator"/>
        </div>
        <p className="seperateLine">*Separate tags with (,) or give a space</p>
        <div className="extensionEditor">
            <TextEditor
                id={id}        //  required
            />
        </div>
        <div className="imageHolder">
            <img src={imageGet} />
        </div>
        <button className="addBucket">Add To Bucket</button>
    </div>
    )
}

export default Extension;