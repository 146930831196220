import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './PlanDetails.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MonthlyPlan from "./MonthlyPlan/MonthlyPlan";
import InvoiceDetails from "../Invoice/InvoiceDetails/InvoiceDetails";

function PlanDetails() {
  const navigate = useNavigate();
  const [priceActive, setPriceActive] = useState("");
  const [planActive, setPlanActive] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [paymentMethod, setPaymentMethod] =useState([]);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedLast, setSelectedLast] = useState("");

  useEffect(() => {
    const findObjectById = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };

    const token = localStorage.getItem("token");
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectById(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          if(productList){
            console.log(productList,"productList");
            console.log(productList.items.data[0].plan.interval,"productList");
            console.log(productList.current_period_end,"current_period_end");
            setCurrentPeriodEnd(productList.current_period_end);
            setPriceActive(productList.items.data[0].plan.amount);
            setPlanActive(productList.items.data[0].plan.interval);
          }

          let teamMember = findObjectById(response.data.data.subscriptions,`${process.env.REACT_APP_TEAM_MEMBER_PLAN_ID}`);
          if(teamMember){
            console.log(teamMember,"teamMember");
            setTeamMember(teamMember);
          }
          // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
          // setPlanActive()
          // response.data.data.subscriptions[0].items.data        
        }

        if(response.data.data.userDetails){
          if(response.data.data.userDetails.isComplementary){
            setPlanActive('Lifetime Promo');
          }
        }
        // console.log(response.data.data.subscriptions,"response");
        if(response.data.data.paymentMethods.data){
          console.log(response.data.data.paymentMethods.data,"response.data.data.paymentMethods.data");
          setPaymentMethod(response.data.data.paymentMethods.data);
          setSelectedTag(response.data.data.paymentMethods.data[0].id);
          setSelectedBrand(response.data.data.paymentMethods.data[0].card.brand);
          setSelectedLast(response.data.data.paymentMethods.data[0].card.last4);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchInvoices = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/invoices-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        console.log(response);
        setInvoiceList(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    if (token) {
      fetchPlans();
      fetchInvoices();
    }
  }, []);

  // console.log(paymentMethod,"paymentMethod");
  return(
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="plandetails-section">
      <Tabs>
        <div className="flex">
          <div className="plandetailsfirst-section">
            <TabList>
              <Tab>Plan Details</Tab>
              <br/>
              <Tab>Invoices</Tab> 
            </TabList>
          </div>
          <div className="plandetailssecond-section">
            <TabPanel>
              {
                planActive ? <MonthlyPlan priceActive={priceActive} planActive={planActive} paymentMethod={paymentMethod} currentPeriodEnd={currentPeriodEnd} teamMember={teamMember} selectedTag={selectedTag} selectedBrand={selectedBrand} selectedLast={selectedLast}/> : "No Plan"
              }
            </TabPanel>
            <TabPanel>
              <InvoiceDetails priceActive={priceActive} currentPeriodEnd={currentPeriodEnd} planActive={planActive} invoiceList={invoiceList}/>
            </TabPanel> 
          </div>
        </div>
      </Tabs>
    </div>
    )}
    </>
  ); 
} 
export default PlanDetails; 