/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import {React,Fragment,useState, useEffect} from "react";
import './TeamMembers.scss'
import { Menu, Transition,Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import Profile1 from '../../../assets/icons/dashboardprofile1.png';
import Profile2 from '../../../assets/icons/dashboardprofile2.png';
import Profile3 from '../../../assets/icons/dashboardprofile3.png';
import Profile4 from '../../../assets/icons/emptyprofile.svg'; 
import DeleteIcon from '../../../assets/icons/delete.svg';
import DismissButton from "../../../assets/icons/dismissButton.svg";
import BucketIcon from "../../../assets/icons/bucketIcon.svg";
import CardIcon from "../../../assets/icons/allCardIcon.svg";
import Avatar from 'react-avatar';
import LinkIcon from "../../../assets/icons/linksicon.svg";
import InviteTeam from "../../../assets/icons/inviteTeam.svg";
import SortMbl from '../../../assets/icons/sortmbl.svg';

import { toastify } from '../../Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import moment from "moment";

function TeamMembers() {
const [MemberRefresh,setMemberRefresh]=useState(false)
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      
      try {
        const response = await axios.get(
          'https://prdapi.zimzee.com/api/team/owner-team-members',
          
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTeamMembers(response?.data?.data?.teamMembers)
        setMemberRefresh(false)
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the async function immediately

   
  
    // The empty dependency array means this effect runs once after initial render.
  }, [MemberRefresh]);
  const filterDropDown = [
    { name: 'Most Recent', href: '#' },
    // { name: 'New Highlights', href: '#' },
    { name: 'Ascending', href: '#' }, 
    { name: 'Descending', href: '#' },
  ]
const[teamMembers,setTeamMembers]=useState([])
const [singleMember,setSingleMember]=useState([])
  const [filepopup, setFilePopup] = useState(false);
  const [bucketsUpdate,setBucketsUpdate]=useState(false)
  const [invitepopup, setInvitePopup] = useState(false);
  const [email,setEmail]=useState('')
  const [selectedMember,setSelectedMember]=useState({})
  const[memberId,setMemberId]=useState('')
  const [setFiltersText] = useState(filterDropDown[0].name);
  let [isOpenss, setIsOpens] = useState(false);
  const handleCopyInvite=async()=>{
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.post('https://prdapi.zimzee.com/api/team/send-team-invite',
      {
        createLink :true
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      if (response.data.data.link) {
        // Create a temporary input element to copy the link to clipboard
        const tempInput = document.createElement('input');
        tempInput.value = response.data.data.link;

        // Append the input element to the DOM
        document.body.appendChild(tempInput);

        // Select the input element's text and copy it to clipboard
        tempInput.select();
        document.execCommand('copy');

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, you can show a message to indicate that the link has been copied
        console.log(response.data.message)
        toastify("success", response.data.message);
        closeInvitePopup()
      } 
      
     
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);

    }
  }
  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }
  const handleSendInvite=async ()=>{
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.post('https://prdapi.zimzee.com/api/team/send-team-invite',
      {
     email:email
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      toastify("success", response.data.message);
      closeInvitePopup()
      console.log(response);
     
    } catch (error) {
      console.log(error,"consoleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      // console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
  const handleTeamMember=async (id)=>{
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.get(`https://prdapi.zimzee.com/api/team/member-sharedItems/${id}`,
      {
        headers: {
            Authorization: `Bearer ${token}`,
          }
      }
      );
      setBucketsUpdate(false)
      setSingleMember(response?.data?.data?.sharedItemsList)
      // toastify("success", response.data.message);
      console.log(response);
     
    } catch (error) {
      console.error(error?.response?.data?.message,"error msg");
      toastify("error", error?.response?.data?.message);
    }
  }
  useEffect(()=>{
if(bucketsUpdate===true){
  handleTeamMember(selectedMember._id)
}
  },[bucketsUpdate])



  const handleFilterText = (text) => {
    setFiltersText(text);
  }
  const handleUnshare=async(id)=>{
    const token = localStorage.getItem('token');
  
  try {
    const response = await axios.post(`https://prdapi.zimzee.com/api/team/revoke-item-access`,{temMemberId:selectedMember._id,itemId:id},
    {
      headers: {
          Authorization: `Bearer ${token}`,
        }
    }
    );

    setBucketsUpdate(true)
    toastify("success", response.data.message);
    console.log(response);
   
  } catch (error) {
    console.error(error?.response?.data?.message,"error msg");
    toastify("error", error?.response?.data?.message);
  }
  }
const handleDeleteMember=async()=>{
  const token = localStorage.getItem('token');
  
  try {
    const response = await axios.post(`https://prdapi.zimzee.com/api/team/revoke-member-fromTeam`,{teamMemberId:memberId},
    {
      headers: {
          Authorization: `Bearer ${token}`,
        }
    }
    );

    closeCardModal()
    toastify("success", response.data.message);
    setMemberRefresh(true)
    console.log(response);
   
  } catch (error) {
    console.error(error?.response?.data?.message,"error msg");
    toastify("error", error?.response?.data?.message);
  }
}
  const handleFilePopup = (mem) => {
    console.log(mem,"selected team memeber")
    
    setSelectedMember(mem)
    handleTeamMember(mem._id)
    setFilePopup(true);
  }

  const closeFilePopup = () => {
    setFilePopup(false);
  }

  const handleInvitePopup = () => {
    setInvitePopup(true);
  }
  const handleDeletePop=(e,id)=>{
    e.stopPropagation();
    setMemberId(id)
    setIsOpens(true)
  }

  const closeInvitePopup = () => {
    setInvitePopup(false);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return(
    <div className="teammembers-section">
      <ToastContainer/>
      <div className="cards-filter flex justify-between">
        <div className="card-count flex items-center">
          <h4 className="cardssubBuckets-content">Team members</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{teamMembers?.length}</span>
        </div>
        <div className="flex">
          <div className="flex items-center justify-end">
            <div className="cardsjustify-end inviteBtn">
              <div className="btndesktop-invited rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg" href="#" onClick={handleInvitePopup} style={{cursor:"pointer"}}>@ Invite People</div>
            </div>
            <span className="sorttextteam-content mx-3 dark:text-[#acacac]">Sort By</span>
            <Menu as="div" className="relative desktop-section flex-shrink-0">
              <div>
                <Menu.Button className="flex rounded-full focus:outline-none ">
                  <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                    <strong className="createdtext-content pr-4">Ascending order</strong>
                    <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                  {filterDropDown.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={()=>{handleFilterText(item.name)}}
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block py-2 px-4 text-[13px] text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
            
            <div className="mblsort-section">
              <Menu as="div" className="sortmbl-content relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                    <a
                      href="#"
                      className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                    >
                      <img src={SortMbl} alt="SortMbl" />
                    </a>
                  </Menu.Button>  
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                
                    {filterDropDown.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (

                          <a
                            onClick={()=>{handleFilterText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-sm text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="flex addmember-section">
        <div className="flex">
          <img src={InviteTeam} alt="InviteTeam" />
          <div className="availableteam-content">Available slot to invite people: <span className="teamnumber-content"> 1 </span>|</div>
          <div className="teamslot-content">+ Add new team member slot</div>
        </div>
      </div> 
      <div className="btnmbl-mblinvited rounded-full bg-[#FF6600] text-white sm:text-sm  md:text-md lg:text-lg" href="#" onClick={handleInvitePopup} style={{cursor:"pointer"}}>@ Invite People</div>
      <div className="desktopteam-section">
        <div className="flex detailstop-section">
          <div className="headingteam-section">Name</div>
          <div className="invitedheadingteam-section">Invited on</div>
          <div className="headingshare-section">Shared buckets/cards</div>
          <div className="headingaction-section">Action</div> 
        </div>
      </div>
      <div className="mblteam-section">
        <div className="flex detailstop-section justify-between">
          <div className="mblheadingteam-section">Name</div>
          <div className="mblheadingteam-section">Shared Buckets/Cards</div>
        </div>
      </div>
      <hr/>
      {teamMembers && teamMembers.map((member)=>{
        return( 
          <>
            <div className="desktopmembers-section">
              <div className="flex teamtop-section" onClick={()=>handleFilePopup(member)}>
                <div className="flex namemembers-content">
                  <div>
                    {member?.imageUrl ?<img className="profilecard-imgcontent" src={member?.imageUrl} alt="Profile1" />:
                    <div>
                
                    <Avatar name={`${member?.firstName} ${member?.lastName}`} size="50"round={true} />
                   
                  </div>
                    }
                    </div>
                  <div>
                    <div className="name-content">{`${member?.firstName} ${member?.lastName}`}</div>
                    <div className="emailname-content">{`${member?.email}`}</div>
                  </div>
                </div>
                <div className="invited-content">{moment(member?.inviteDate).format('MMM D, YYYY @ hh:mm A')}</div>
                <div className="shared-content">{member?.totalSharedItems}</div>
                <div className="delete-content"><img className="deleteteam-imgcontent" src={DeleteIcon} alt="deleteIcon" onClick={(e)=>handleDeletePop(e,member._id)} /></div>
              </div>
            </div>
            <div className="mblmembers-section">
              <div className="flex teamtop-section" >
                <div className="flex mblunselectname-content" onClick={()=>handleFilePopup(member)}><img className="profilecard-imgcontent" src={Profile4} alt="Profile1" />{`${member?.firstName} ${member?.lastName}`}</div>
                <div className="shared-content">{member?.totalSharedItems}</div>
                <div className="delete-content" onClick={()=>handleDeletePop(member._id)}><img className="deleteteam-imgcontent" src={DeleteIcon} alt="deleteIcon" /></div>
              </div>
              <div className="mblunselectinvited-content">{moment(member?.inviteDate).format('MMM D, YYYY @ hh:mm A')}</div>
            </div>
          <hr/>
          </>
        )
      })
      }
      <hr/>
      {/* <div className="desktopmembers-section">
        <div className="flex teamtop-section" onClick={handleFilePopup}>
          <div className="flex unselectname-content"><img className="profilecard-imgcontent" src={Profile4} alt="Profile1" />Bethany pearson</div>
          <div className="unselectinvited-content">Dec 4, 2021 @ 4:30 PM</div>
          <div className="shared-content">-</div>
          <div className="delete-content"><img className="deleteteam-imgcontent" src={DeleteIcon} alt="deleteIcon" /></div>
        </div>
      </div> */}

      {/* <div className="mblmembers-section">
        <div className="flex teamtop-section" onClick={handleFilePopup}>
          <div className="flex mblunselectname-content"><img className="profilecard-imgcontent" src={Profile4} alt="Profile1" />Bethany pearson</div>
          <div className="shared-content">-</div>
          <div className="delete-content"><img className="deleteteam-imgcontent" src={DeleteIcon} alt="deleteIcon" /></div>
        </div>
        <div className="mblunselectinvited-content">Dec 4, 2021 @ 4:30 PM</div>
      </div> */}
      <Transition appear show={isOpenss} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeCardModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Delete
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete?
                        </p>
                      </div>

                      <div className="mt-4">
                        <button style={{ background: '#FF6600', width: "80px", color: "#fff", padding: "10px", borderRadius: "50px" }}
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={handleDeleteMember}
                        >
                          Yes
                        </button>
                        <button style={{ background: '#000', width: "80px", color: "#fff", padding: "10px", borderRadius: "50px" }}
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeCardModal}
                        >
                          No
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          
      {filepopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeFilePopup}>
          <div className="subfilesupload-section" onClick={(e)=>e.stopPropagation()}>
            <div className="teamuploadsection flex">
              <div className="teammembers-content">Team members</div>
              <div className="dismissSubfiles-content" onClick={closeFilePopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <div className="flex profileteam-section">
              <div>
              {selectedMember?.imageUrl ?<img className="profilecard-imgcontent" src={selectedMember?.imageUrl} alt="Profile1" />:
                    <div>
                
                    <Avatar name={`${selectedMember?.firstName} ${selectedMember?.lastName}`} size="50"round={true} />
                   
                  </div>
                    }
                {/* <img className="profileteam-imgcontent" src={Profile1} alt="Profile1" /> */}
                </div>
              <div>
                <div className="teamname-content">{selectedMember?.firstName} {selectedMember?.lastName}</div>
                <div className="invitename-content">{moment(selectedMember?.inviteDate).format('MMM D, YYYY @ hh:mm A')}</div>
              </div>
            </div>
            <div className="cardteam-count flex items-center">
              <h4 className="cardssharedBuckets-content">Shared buckets/Cards</h4><span className="subbucketsbg-content background-primary text-white rounded-full ml-4 text-[16px]">{singleMember?.length}</span>
            </div>
            {singleMember.map((data)=>{
              return(
<div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={data?.type==="Bucket" ?BucketIcon:CardIcon} alt="BucketIcon" />{data?.type==="Bucket" ? data?.item?.name :data?.item?.title}</div>
                <div className="unshare-content" onClick={()=>handleUnshare(data?.item?._id)} style={{cursor:"pointer"}}>Unshare</div>
            </div>
              )
            })}
            
            {/* <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={BucketIcon} alt="BucketIcon" />Football tournament 2022</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={CardIcon} alt="BucketIcon" />Amazon web services</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={CardIcon} alt="BucketIcon" />Deyga organics</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={BucketIcon} alt="BucketIcon" />Youtube</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={BucketIcon} alt="BucketIcon" />Twitter leads</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={BucketIcon} alt="BucketIcon" />Swipe file</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={CardIcon} alt="BucketIcon" />Facebook</div>
                <div className="unshare-content">Unshare</div>
            </div>
            <div className="flex justify-between sharedtop-content">
                <div className="flex teambucket-content"><img className="teambucket-imgcontent" src={CardIcon} alt="BucketIcon" />Fire Boltt</div>
                <div className="unshare-content">Unshare</div>
            </div> */}
          </div>
        </div>
      } 

      {invitepopup &&
        <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeInvitePopup}>
          <div className="subinviteupload-section" onClick={(e)=>e.stopPropagation()}>
            <div className="teaminvitesection flex">
              <div className="teammembers-content">Invite people</div>
              <div className="dismissSubfiles-content" onClick={closeInvitePopup}><img src={DismissButton} alt="dismissButton" /></div>
            </div>
            <hr/>
            <input className="w-full flex items-center rounded-3xl border-0 menusubitems-content teaminput-content" type="text" placeholder="Email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
            <div className="flex justify-between">
              <div className="flex linkteam-content"><img className="teamlink-imgcontent" src={LinkIcon} alt="LinkIcon" />Invite with link</div>
              <div className="unshare-content" onClick={handleCopyInvite} style={{cursor:"pointer"}}>Create link</div>
            </div>
            <div className="joinlink-content">Anyone with the link can join in Zimzee</div>
            <div className="flex justify-end lastfile-section">
              <button className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white justify-end" onClick={handleSendInvite}>
                <span className="savebutton-context">Send Invite</span>
              </button>
            </div>
          </div>
        </div> 
      }

    </div>

    
  );
}
export default TeamMembers; 