// AuthRedirect.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthRedirect = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check the user's login status (you might want to use your own logic)
    // const isUserLoggedIn = /* your logic to check if the user is logged in */;
    const isUserLoggedIn = localStorage.getItem('userData') ? true : false;
    // Redirect if the user is already logged in
    if (isUserLoggedIn) {
      navigate('/app/dashboard'); // Redirect to your main app page
    }
  }, [navigate]);

  return <>{children}</>;
};

export default AuthRedirect;