import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './UpgradePlan.scss'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UpgradeMonthly from "./UpgradeMonthly/UpgradeMonthly";
import UpgardeYearly from "./UpgradeYearly/UpgradeYearly";
import Planarrow from "../../../../assets/icons/smallarrow.svg";

function UpgradePlan() {
  const navigate = useNavigate();
  const [priceListY, setPriceListY] = useState([]);
  const [priceListM, setPriceListM] = useState([]);
  const[productsList,setProductList] = useState([]);
  const [planActive, setPlanActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedLast, setSelectedLast] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const findObjectById = (array, id) => {
      return array.find(obj => obj.id === id);
    };

    const findObjectByProductId = (array, id) => {
      return array.find(obj => obj.plan.product.id === id);
    };

    const findObjectBytype = (array, subscriptionType) => {
      return array.find(obj => obj.subscriptionType === subscriptionType);
    };

    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}plans/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        // setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
        if(response){
          let productList = findObjectById(response?.data?.data?.productList,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          console.log(productList,"productList");
          setProductList(productList);
          let pricelistY = findObjectBytype(productList.priceList,'year');
          let pricelistM = findObjectBytype(productList.priceList,'month');
          setPriceListY(pricelistY);
          setPriceListM(pricelistM);
        }
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchActiveSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}subscriptions/active-subscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        if(response.data.data.subscriptions){
          let productList = findObjectByProductId(response.data.data.subscriptions,`${process.env.REACT_APP_MAIN_PLAN_ID}`);
          setProductList(productList);
          // console.log(productList.items.data[0].plan.interval,"productList");
          setPlanActive(productList.items.data[0].plan.interval);
          // productList.items.data[0].plan.interval == 'year' ? setPlanActive()
          // setPlanActive()
          // response.data.data.subscriptions[0].items.data        
        }

        if(response.data.data.paymentMethods.data){
          setSelectedTag(response.data.data.paymentMethods.data[0].id);
          setSelectedBrand(response.data.data.paymentMethods.data[0].card.brand);
          setSelectedLast(response.data.data.paymentMethods.data[0].card.last4);
        }
        // console.log(response.data.data.subscriptions,"response");
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchPlans();
      fetchActiveSubscription();
    }
  }, []);

  const handleRenew = async(price,plan,priceId) => {
      console.log(price,plan,"priceplan");
      localStorage.setItem('renewPrice',Number(price)/100);
      localStorage.setItem('renewPlan',plan);
      localStorage.setItem('renewPriceId',priceId);
      // navigate('/app/renewPlan');
      // navigate('/app/visaPlan');
      navigate(`/app/renewPlan?payment=${selectedTag}&&selectedBrand=${selectedBrand}&&selectedLast=${selectedLast}`)
  }

  return(
    <>
    {isLoading ? (
      <div className="App-loading">
        <div className="App-logo" title="Zimzee" />
      </div>
    ) : (
    <div className="upgradeplan-section">
      <div className="plandetail-content"><a href="/app/myProfile">Plan details</a> <span className="plan-content"> / <a href="/app/upgradePlan">Plans</a></span></div>
      <div className="upgradeplan-content">Upgrade your plan</div>
      <Tabs>
        <div className="flex">
          <TabList>
            <Tab>Monthly</Tab>
            <Tab>Yearly</Tab>
          </TabList>
          <div className="save-plan">
            <div className="sec-sectio">
              <h6>Save up to 33%</h6>
              <img src={Planarrow} alt="chrome-icon" />
            </div>
          </div>
        </div>
        <TabPanel>
          {
            planActive ?
            <UpgradeMonthly priceListM = {priceListM} planActive={planActive} handleRenew={handleRenew} productsList={productsList}/> : ""
          }
          
        </TabPanel>
        <TabPanel>
          {
            planActive ?
            <UpgardeYearly priceListY = {priceListY} planActive={planActive} handleRenew={handleRenew} productsList={productsList}/> : ""
          }
          
        </TabPanel>
      </Tabs>
    </div>
    )}
    </>
  );
}
export default UpgradePlan; 