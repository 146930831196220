/*global chrome*/
import React from "react";
import logo from "../../assets/icons/logo.png";
import magicLinkIcon from "../../assets/icons/magicLinkIcon.svg";
import axios from 'axios';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Intercom from "../intercom/Intercom";

function Login(){
    const user = {
        firstName: null,
        email: null,
        // createdAt: Math.floor(Date.now() / 1000),
      };
    useEffect(()=>{
        console.log(user,"user on login")
            Intercom(user)
          },[user])
    const navigate = useNavigate();
    const [resendEmail, setResendEmail] = React.useState(false);

    const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
        try {
            if(chrome && chrome?.runtime){
                chrome.runtime.sendMessage(extensionId, { jwt }, response => {
                    // if (!response.success) {
                    //   console.log('error sending message', response);
                    //   return response;
                    // }
                    // console.log('Sucesss ::: ', response.message)
                
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async(e) => {
        // /setLoading(true);
		e.preventDefault();

		const data = {
			email: e.target.email.value,
			password: e.target.password.value
		}
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}users/login`, data);
            console.log(response); // Debugging: Log the response
        
            toastify("success", "Successfully Login");
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('userData', JSON.stringify(response.data.data.user));
            sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', jwt: response.data.data.token });
        
            const setting = localStorage.getItem('settingsToken');
            console.log("get local storage: ", setting)
            setTimeout(() => {
                navigate(`app/buckets`);
            }, 1000);
          } catch(err) {
            if (err.response) {
                console.log(err.response.data.message);
                (err.response.data.message === 'Please verify your email first') ? setResendEmail(true) : setResendEmail(false);
                toastify("error", err.response.data.message);
            } else {
                console.log(err.message); // Log any other errors that don't have a response object
                toastify("error", "An error occurred");
            }
        };
      };

    return(
        <div className="loginHolder min-h-[100vh] flex items-center bg-[#F3F4F6]">
            <div className="formHolder bg-white md:w-1/2 lg:w-2/5 max-w-lg mx-auto my-5 p-8 rounded-lg shadow">
                <div className="logo">
                    <img src={logo} />
                </div>
                <h3 className="text-xl mt-7 mb-6 font-bold text-[24px]">Login to your account</h3>
                <form onSubmit={handleSubmit} className="loginForm flex flex-col">
                {/* style={{borderColor:"#D9D9D9 !important", borderWidth: '1px'}} */}
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="email" id="email" placeholder="Your Email" />
                    <input className="bg-white border-2 border-[#D9D9D9] my-3 p-3 rounded-md" type="password" id="password" placeholder="Password" />
                    {(resendEmail)? (
                        <a className="underline pl-2 my-1 text-[#000]" href="/resendEmail">Resend Email</a>
                    ) : ("")}
                    <button type="submit" className="button bg-[#EE7900] text-white py-2 rounded-[5px] mt-4">Login</button>
                    <div className="remember flex justify-between my-4">
                        <label className="text-[#394150] font-bold" style={{visibility:"hidden"}}>
                            <input className="mr-2" type="checkbox" />
                            Remember me
                        </label>
                        <a className="text-[#394150]" href="/forgotPassword">Forgot Password</a>
                    </div>
                </form>
                {/* <div className="mt-4 text-center">
                    <hr className="-mb-3 border-[#394150]" />
                    <span className="inline-block bg-white text-center text-[#394150] px-3">OR</span>
                </div> */}
                {/* <a href="/loginmagic" type="button" className="flex justify-center py-3 mt-7" style={{border:"1px solid #D9D9D9", borderRadius: "50px",width:"100%"}}>
                    <img className="pr-3" src={magicLinkIcon} />
                    <span className="font-bold">Login with a password-free Magic Link</span>
                </a> */}
                <h4 className="font-bold text-center" style={{marginTop: "45px"}}>Don't have an account? <a className="text-[#5587e9]" href="/signup">Create account</a></h4>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Login;