import { React, Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider, CssBaseline } from "@mui/material";
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
// import {faPlus, faChevronDown, faCamera} from "@fortawesome/free-solid-svg-icons";
import { faFolder as fasFaFolder } from '@fortawesome/free-regular-svg-icons';
import { Menu, Dialog, Transition } from '@headlessui/react'
import SingleBucket from "../../shades/SingleBucket";
import BucketDragPreview from "../../shades/BucketDragPreview";
import CreateBucket from "../../shades/CreateBucket";
import axios from 'axios';
import moment from "moment";
import './../../shades/compact.scss'
import './sharedwithme.scss'
import BucketIcon from '../../assets/images/bucket.svg';
import { useOutletContext } from "react-router-dom";
import Compacticongrey from '../../assets/icons/Compact icon - black.svg';
import NormaliconBlack from '../../assets/icons/normalicon - black.svg';
import SortMbl from '../../assets/icons/sortmbl.svg';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  DndProvider
} from "@minoru/react-dnd-treeview";
import { CustomDragPreview } from "../../components/Tags/CustomDragPreview";
import { theme } from "../../components/Tags/theme";
import { Placeholder } from "../../components/Tags/Placeholder";
import styles from "../../components/Tags/App.module.css";
import styless from "../../components/Tags/CustomNode.module.css";
import zIndex from "@mui/material/styles/zIndex";
import CustomizePopup from '../../shades/cards/popupfinal.js'
import BlackDismiss from '../../assets/icons/dismissButton.svg'
import DismissButton from "../../assets/icons/orangedismiss.svg";
import ShareBucket from "../../shades/cards/ShareBucket";

function SharedBuckets() {
  const [bucketList, setBucketList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalBuckets, setTotalBuckets] = useState(0);
  const [bucketCreated, setBucketCreated] = useState(false);
  // const [bucketRectangle,setRectangle] = useState(false);
  const [bucketOperation, setBucketOperation] = useState('Add');
  const [bucketNameText, setBucketNameText] = useState('');
  const [bucketUpdateId, setBucketUpdateId] = useState(0);
  const [filepopup, setFilePopup] = useState(false);
  const [Custompopup, setCustomPopup] = useState(false);
  const [selectedBucketName, setSelectedBucketName] = useState('');
  const [selectedBucketId, setSelectedBucketId] = useState('');
  const [treeData, setTreeData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [BucketSharePopup, setBucketSharePopup] = useState(false)
  const [shareBucketId, setShareBucketId] = useState('')
  let [isOpens, setIsOpen] = useState(false);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [IsSharedBuckets, setSharedBuckets] = useState(false)
  const [SharedToken, setSharedToken] = useState(false)
  const [sharedUsers, setSharedUsers] = useState([])
  const [Permission, setPermission] = useState('')
  const handleFocus = () => {
    setIsTagOpen(!isTagOpen);
  };
  const [isOpen] = useOutletContext();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const filterDropDown = [
    { name: 'Created Date', href: '#' },
    { name: 'Recent Activity', href: '#' },
    { name: 'Ascending Order', href: '#' },
    { name: 'Descending Order', href: '#' },
    // { name: 'Manual', href: '#' },
  ]
  let bucketFilterv = '';
  if (!localStorage.getItem("bucketFilter")) {
    localStorage.setItem('bucketFilter', 'Created_Date');
    bucketFilterv = 'Created_Date';
  } else {
    bucketFilterv = localStorage.getItem("bucketFilter");
  }
  console.log(bucketFilterv, "bucketFilterv");
  const [bucketFilterDefault, setBucketFilterDefault] = useState(bucketFilterv);
  let bucketViewv = 'compact';
  if (!localStorage.getItem("bucketView")) {
    localStorage.setItem('bucketView', 'compact');
    bucketViewv = 'compact';
  } else {
    bucketViewv = localStorage.getItem("bucketView");
  }
  const [bucketView, setBucketView] = useState(bucketViewv);
  // const bucketData = [
  //   {bucketName: 'Fashion Trends 2022',bucketTag: 'Sketch Bucket',bucketIcon:faCamera, subBucket: 'true'},
  //   {bucketName: 'Andy Birthday Party Celebration',bucketTag: 'Plan',bucketIcon:faCamera, subBucket: 'false'},
  //   {bucketName: 'Cooking Receipes',bucketTag: '',bucketIcon:faCamera, subBucket: 'true'}
  // ]
  function closeModal() {
    setIsOpen(false)
  }
  const handleSharePop = (id) => {
    setShareBucketId(id)
    setBucketSharePopup(true)
  }
  const closeSharePop = () => {
    console.log("close share pop")
    setBucketSharePopup(false)
  }
  const handleCustomPopup = (name, id) => {
    setSelectedBucketName(name)
    setSelectedBucketId(id)
    setCustomPopup(true);
  }
  const closeCustomPopup = () => {
    setCustomPopup(false)
  }
  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log('Child clicked');
  };

  function closeFilePopup() {
    setCustomPopup(false);
  }

  function openModal() {
    setIsOpen(true)
  }
  useEffect(() => {
    // api url
    const apiUrl = process.env.base_url;
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');

    // axios.post(`${apiUrl}/buckets/list`).then((response) => {console.log(response.data)
    //   }); 
    axios.get(`${process.env.REACT_APP_API}team/shared-withMe-items`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.data.bucketList && response.data.data.bucketList.length) {
          setSharedToken(true)
          setSharedUsers(response?.data?.data?.bucketList?.shared)
          const flattenedData = flattenObject(response.data.data.bucketList);
          console.log(flattenedData, "flattenedData");
          setTreeData(flattenedData);
        }
        const bucketList = response.data.data.bucketList;


        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        setSharedBuckets(true)
        console.log(bucketList);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [bucketCreated])

  useEffect(() => {
    console.log(Permission, "permisssssssssssssssssss")
  }, [Permission])
  const flattenObject = (data, parentId = 0) => {
    const flattened = [];

    data.forEach(item => {
      console.log(item, "item foreach");
      const newItem = {
        id: item._id,
        parent: parentId,
        droppable: true, //item.parent
        droppables: item.subBuckets ? item.subBuckets.length > 0 : false,
        name: item.name,
        text: item.name,
        cards: item.totalCards,
        subBuckets: item.subBuckets,
        pinned: item.pinned,
        isFocused: item.isFocused ? item.isFocused : false,
        shared: item.shared,
        sharedBy: item.sharedBy,
        bucketDesignId: item.bucketDesignId,
        default: item.default,
        parentDisable: item.subBuckets ? item.subBuckets.length : 0,
        isChild: false //item.parent
      };

      flattened.push(newItem);

      if (item.subBuckets) {
        if (item.subBuckets.length > 0) {
          const children = flattenObject(item.subBuckets, item._id);
          flattened.push(...children);
        }
      }

    });

    return flattened;
  };

  // (bucketFilterv == 'Created_Date') ? filterDropDown[0].name : (
  //   bucketFilterv == 'Descending_Order' ?  filterDropDown[3].name : (
  //     bucketFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
  //       text == 'Recent_Activity' ? filterDropDown[1].name : ""
  //     )
  //   )
  //   );
  const [filtersText, setFiltersText] = useState((bucketFilterv == 'Created_Date') ? filterDropDown[0].name : (
    bucketFilterv == 'Descending_Order' ? filterDropDown[3].name : (
      bucketFilterv == 'Ascending_Order' ? filterDropDown[2].name : (
        bucketFilterv == 'Recent_Activity' ? filterDropDown[1].name : ""
      )
    )
  ));
  // const [bucketDetails,setBucketDetails] = useState(bucketData);
  // console.log(bucketDetails);
  const [popup, setPopup] = useState(false);

  const handlePopup = () => {
    setBucketOperation("Add");
    setBucketNameText("");
    setBucketCreated(false);
    setPopup(true);
    setTimeout(() => {
      document.getElementsByName('name')[0].focus();
    }, 100);
  }
  // const handleSquare = () =>{
  //   setRectangle(false);
  //   setSquare(true);
  // }

  const handleRectangle = () => {
    // setSquare(false);
    // setRectangle(true);
    localStorage.setItem('bucketView', 'compact');
    setBucketView('compact');
  }
  const handleSquare = () => {
    // setRectangle(false);
    localStorage.setItem('bucketView', 'grid');
    setBucketView('grid');
  }
  const handleFilterText = (text) => {
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues = (text == 'Created Date') ? "Created_Date" : (
      text == 'Descending Order' ? "Descending_Order" : (
        text == 'Ascending Order' ? "Ascending_Order" : (
          text == 'Recent Activity' ? "Recent_Activity" : ""
        )
      )
    );
    localStorage.setItem('bucketFilter', filterValues);
    setBucketFilterDefault(filterValues);
    const token = localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API}buckets/list`, {
      limit: 100,
      page: 1,
      filterValue: filterValues
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.data.bucketList && response.data.data.bucketList.length) {
          const flattenedData = flattenObject(response.data.data.bucketList);
          console.log(flattenedData, "flattenedData");
          setTreeData(flattenedData);
        }
        setBucketList(response.data.data.bucketList);
        setTotalBuckets(response.data.data.total);
        console.log(bucketList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function bucketUpdate() {
    setBucketCreated(!bucketCreated);
  }

  function changeTxt(event) {
    setBucketNameText(event.target.value);
  }

  const handleDeleteClick = (id) => {
    setIsOpen(true);
    console.log(id);
    setBucketUpdateId(id);
  }


  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}buckets/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response, "response");
      //  toastify("sucess", response.message );
      toastify("success", response.data.message);
      axios.post(`${process.env.REACT_APP_API}buckets/list`, {
        limit: 100,
        page: 1,
        filterValue: bucketFilterDefault
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(function (response) {
          setIsLoading(false);
          if (response.data.data.bucketList && response.data.data.bucketList.length) {
            const flattenedData = flattenObject(response.data.data.bucketList);
            console.log(flattenedData, "flattenedData");
            setTreeData(flattenedData);
          }
          setBucketList(response.data.data.bucketList);
          setTotalBuckets(response.data.data.total);
          console.log(bucketList);
        })
        .catch(function (error) {
          console.log(error);
        });
      // setIsLoading(false);
      //  setBucketNameText(response.data.data.name); 
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
    setIsOpen(false);
  }

  const updateBucket = async (bucketId, pin, action) => {
    if (action != 'pinn') {
      const focusItems = bucketList.filter(item => item.isFocused);
      console.log(focusItems);
      const selectedItem = bucketList.find(item => item._id === bucketId);
      console.log(selectedItem, "selectedItem");
      if (!selectedItem.isFocused && focusItems.length >= 2) {
        // User cannot select more than two focus items
        // alert("You can't select more than two focus items!");
        toastify("error", "A maximum of two focus buckets is allowed");
        return;
      }
      // return;
    }
    setIsLoading(true);
    let bucketAction = (action == 'pinn') ? { bucketId: bucketId, pinned: pin } : { bucketId: bucketId, isFocused: pin }
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API}buckets/update`, bucketAction,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      //  toastify("success", response.data.message);
      bucketUpdate();
      //  setIsLoading(false);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleClick = async (id) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}buckets/single/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response.data.data.name, "response");
      setBucketNameText(response.data.data.name);
      console.log("heeloo bucket anem");
      setTimeout(() => {
        document.getElementsByName('name')[0].focus();
      }, 100);
    } catch (error) {
      console.error(error);
    }
    console.log('Button clicked!');
    console.log(id);
    setPopup(true);
    setBucketOperation("Update");
    setBucketUpdateId(id);

  };
  !bucketView ?
    console.log("not exist view")
    :
    console.log(bucketView, "bucketView test")
    ;


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateCreateBucket = async (id, destination) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API}buckets/update`, { bucketId: id, dragIntoBucket: true, parentId: (destination == 0) ? null : destination },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      console.log(response);
      //  toastify("success", response.data.message);
      bucketUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
      bucketUpdate();
    }
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;
    console.log(source, destination);
    setBucketList(reorder(bucketList, source.index, destination.index));
    // setList(reorder(list, source.index, destination.index));
  };

  const handleDrop = (
    newTree,
    { dragSourceId, dropTargetId, dragSource, dropTarget }
  ) => {
    // Do something
    // if(dragSource.parentDisable == 0){
    console.log(dragSourceId, dropTargetId, dragSource, dropTarget, "dragSourceId, dropTargetId");
    updateCreateBucket(dragSourceId, dropTargetId);
    setTreeData(newTree);
    // }else{
    // toastify("error", "Bucket having sub buckets cannot be moved to other bucket" );
    // }

  };

  return (
    <>

      {
        isLoading ? (
          <div className='App-loading'>
            <div className='App-logo' title='Zimzee' />
          </div>
        )
          :
          (
            <div className={bucketView == 'compact' ? 'regular' : 'CompactView-wholesection'}>
              <div className="bucketDashboardsection">
                <div className="compactdesktop-section">
                  <div className="bucketHeader">
                    <div className="grid grid-cols-3">
                      <div className="bucketFeature col-span-2">
                        <div className="flex items-center">
                          <h2 className="text-xl subbucketstext-content dark:text-[#fff]">Shared Buckets</h2>
                          <p className="total-bucktes ml-4 rounded-full  right-0 mr-2 text-white bg-[#FF6600] ml-3 inline-block rounded-full py-0.5 text-xs font-medium w-8 h-6 flex justify-center items-center menuCount">{bucketList?.length}</p>
                          {/* <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} onClick={handlePopup} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                                    <span className="createbutton-context">Create New</span>
                                    </button>  */}
                        </div>
                        {/* <div className="totalbucket-content"><img className="bucket-imgcontent" src={BucketIcon} alt="bucketIcon"/>{totalBuckets} Buckets in Total</div>         */}
                      </div>
                      {/* <div className="filterHolder flex items-center justify-end">
                              <img onClick={handleRectangle} className="icon-imgcontent compact-view w-5" src={Compacticongrey} alt="compacticonGrey" />
                            
                              <img onClick={handleSquare} className="icon-imgcontent regular-view w-5" src={NormaliconBlack} alt="normaliconBlack" />
                            <span className="sorttext-content">Sort By</span>
                            <Menu as="div" className="relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                                      <a
                                        href="#"
                                        className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                                      >
                                      <strong className="createdtext-content pr-4">{filtersText}</strong>
                                      <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={fontIcons.faChevronDown} />
                                      
                                  </a>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="filter-dropdown absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              
                                  {filterDropDown.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (

                                        <a
                                          onClick={()=>{handleFilterText(item.name)}}
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className="compactmbl-section">
                  <div className="bucketHeader">
                    <div className="grid grid-cols-2">
                      <div className="bucketFeature col-span-2">
                        <div className="flex items-center">
                          <h2 className="text-xl mybuckets-content dark:text-[#fff]">My Buckets</h2>
                          <button style={{ filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))' }} onClick={handlePopup} className="buttonpadding-content mx-4 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                            <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                            <span className="createbutton-context">Create New</span>
                          </button>
                          <div>
                            <Menu as="div" className="sortmbl-content relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex rounded-full dark:text-[#acacac] focus:outline-none">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500 "
                                  >

                                    <img className="sortimg-content" src={SortMbl} alt="SortMbl" />

                                  </a>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="filter-dropdown dropdownmbl-content absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                  {filterDropDown.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (

                                        <a
                                          onClick={() => { handleFilterText(item.name) }}
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                        <div className="totalbucket-content"><img className="bucket-imgcontent" src={BucketIcon} alt="bucketIcon" />{totalBuckets} Buckets in Total</div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  bucketList.length != 0 ?
                    <div className="mybucketswhole-content">
                      <div className={`BucketListHolder-content`}>
                        {/* <div className="fixed inset-0 flex items-center justify-center">
                <button
                  type="button"
                  onClick={openModal}
                  className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  Open dialog
                </button>
            </div> */}

                        <Transition appear show={isOpens} as={Fragment}>
                          <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                              <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                  as={Fragment}
                                  enter="ease-out duration-300"
                                  enterFrom="opacity-0 scale-95"
                                  enterTo="opacity-100 scale-100"
                                  leave="ease-in duration-200"
                                  leaveFrom="opacity-100 scale-100"
                                  leaveTo="opacity-0 scale-95"
                                >
                                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                    <Dialog.Title
                                      as="h3"
                                      className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                      Delete
                                    </Dialog.Title>
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-500">
                                        Are you sure you want to delete?
                                      </p>
                                    </div>

                                    <div className="mt-4">
                                      <button style={{ background: '#FF6600', width: "80px", color: "#fff", padding: "10px", borderRadius: "50px" }}
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => handleButtonDelete(bucketUpdateId)}
                                      >
                                        Yes
                                      </button>
                                      <button style={{ background: '#000', width: "80px", color: "#fff", padding: "10px", borderRadius: "50px" }}
                                        type="button"
                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </Dialog.Panel>
                                </Transition.Child>
                              </div>
                            </div>
                          </Dialog>
                        </Transition>
                        {BucketSharePopup &&
                          <div className="filesuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeSharePop}>
                            <div className="subinviteupload-section" onClick={(e) => e.stopPropagation()}>
                              <div className="teaminvitesection flex">
                                <div className="teammembers-content">Share</div>
                                <div className="dismissSubfiles-content" onClick={closeSharePop}><img src={BlackDismiss} alt="dismissButton" /></div>
                              </div>
                              <ShareBucket shareBucketId={shareBucketId} closeSharePop={closeSharePop} />
                            </div>
                          </div>

                        }
                        {Custompopup &&
                          <div className="filesbucketuploadpopup relative !z-[999]" style={{ height: "100%" }} onClick={closeCustomPopup}>
                            <div className="subfilesbucketupload-section" onClick={handleChildClick}>
                              <div onClick={closeFilePopup}><img className="dismissSubfiles-content" src={DismissButton} alt="dismissButton" /></div>
                              <CustomizePopup selectedBucketName={selectedBucketName} selectedBucketId={selectedBucketId} closeCustomPopup={closeCustomPopup} bucketUpdate={bucketUpdate} />
                            </div>
                          </div>
                        }
                        <div className="app">
                          <ThemeProvider theme={theme}>
                            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                              <Tree
                                tree={treeData}
                                rootId={0}
                                onDrop={handleDrop}
                                render={(bucket, { depth, isOpen, onToggle }) => (
                                  <div className={styles.root}>
                                    <div className={styles.container}>
                                      <SingleBucket bucketId={bucket.id} SharedToken={SharedToken} sharedUsers={bucket?.shared} sharedBy={bucket?.sharedBy?.fullName} bucketUpdate={bucketUpdate} handleSharePop={handleSharePop} handleCustomPopup={handleCustomPopup} updateBuckets={updateBucket} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket.name} bucketCard={bucket.cards} subBucket={bucket.subBuckets} pined={bucket.pinned} focus={bucket.isFocused ? bucket.isFocused : false} shared={bucket?.shared} bucketDesign={bucket.bucketDesignId} defaults={bucket.default ? bucket.default : ""} />
                                    </div>

                                  </div>
                                )}
                                dragPreviewRender={(monitorProps) => (
                                  <div className="relative">
                                    <BucketDragPreview bucketId={monitorProps.item.id} bucketUpdate={bucketUpdate} handleSharePop={handleSharePop} handleCustomPopup={handleCustomPopup} updateBuckets={updateBucket} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={monitorProps.item.name} bucketCard={monitorProps.item.cards} subBucket={monitorProps.item.subBuckets} pined={monitorProps.item.pinned} focus={monitorProps.item.isFocused ? monitorProps.item.isFocused : false} shared={monitorProps.item.shared} bucketDesign={monitorProps.item.bucketDesignId} defaults={monitorProps.item.default ? monitorProps.item.default : ""} />
                                  </div>
                                  // <CustomDragPreview monitorProps={monitorProps.item} />
                                )}
                                classes={{
                                  root: styles.treeRoot,
                                  draggingSource: styles.draggingSource,
                                  dropTarget: styles.dropTarget
                                }}
                                sort={false}
                                insertDroppableFirst={false}
                                canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                                  if (dragSource?.parent === dropTargetId) {
                                    return false;
                                  } else if (dragSource.parentDisable > 0) {
                                    return false;
                                  } else {
                                    return false;
                                  }
                                }}
                                dropTargetOffset={5}
                                placeholderRender={(node, { depth }) => (
                                  <Placeholder node={node} depth={depth} />
                                )}
                              />
                            </DndProvider>
                          </ThemeProvider>
                        </div>
                        {/* <p>split two libs</p> */}
                        {/* { bucketList?.length?
                 <DragDropContext onDragEnd={handleDragEnd}>
                 <Droppable droppableId="droppable">
                   {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps}>
                {bucketList.map((bucket, index)=>(
                  <Draggable
                  key={bucket._id}
                  index={index}
                  draggableId={bucket._id}
                  
                >
                  {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        className="dragsection-content"  
                        >
                      <SingleBucket bucketId={bucket._id} bucketUpdate={bucketUpdate} handleCustomPopup={handleCustomPopup} handleSharePop={handleSharePop} setIsLoading={setIsLoading} handleClick={handleClick} handleDeleteClick={handleDeleteClick} bucketName={bucket.name} bucketCard={bucket.cards} subBucket={bucket.subBuckets} pined={bucket.pinned} shared={bucket.shared} bucketDesign={bucket.bucketDesignId} defaults={bucket.default ? bucket.default : ""}/>
                        </div>
                         )}
                  </Draggable>
                  ))} 
                  </div>
                )}
              </Droppable>
                 </DragDropContext>
                : ""} */}

                      </div>
                    </div>
                    : null
                }
                {popup === true ? <CreateBucket bucketUpdate={bucketUpdate} changeTxt={changeTxt} bucketOperation={bucketOperation} bucketNameText={bucketNameText} bucketUpdateId={bucketUpdateId} parent={null} setPopup={setPopup} /> : null}
              </div>
              <ToastContainer />
            </div>
          )
      }

    </>

  )
}

export default SharedBuckets;