import { React, Fragment, useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import Masonry from "react-smart-masonry";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Tags.scss";
import "../Bookmarks/Bookmarks.scss";
import "../Files/Files.scss";
import "../../shades/Bucket.scss";
import { Menu, Dialog, Transition } from "@headlessui/react";
import SingleCard from "../../shades/cards/singleCard";
import DeleteIcon from "../../assets/icons/delete.svg";
import LightDelete from "../../assets/icons/Grey_Delete.svg";
import { ReactComponent as MySVG } from "../../assets/icons/7601886_top_arrow-update.svg";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faPencil,
  faTrash,
  faFolder,
  faChevronDown,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import magnifier from "../../assets/icons/magnifyGlass.svg";
import ThreeDots from "../../assets/icons/threeDots.svg";
// import { Menu, Transition } from '@headlessui/react';
import ListView from "../../assets/icons/listView.svg";
import compactCardIcon from "../../assets/icons/Grid-view.svg";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import Tagsicon from "../../assets/icons/tags-icon.svg";
import Dropdown from "../../assets/icons/Dropdown.svg";
import DropdownArrow from "../../assets/icons/tagsdownarrow.svg";
import DropdownArrowIcon from "../../assets/icons/tagsrightarrow.svg";
import Trash from "../../assets/icons/delete.svg";
import TagsSubIcon from "../../assets/icons/tagsSubicon.svg";
import SortMbl from "../../assets/icons/sortmbl.svg";
import DismissButton from "../../assets/icons/dismissButton.svg";
import TagsLine from "../../assets/icons/tagsLine.svg";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import Profile1 from "../../assets/icons/profile1.png";
import Profile2 from "../../assets/icons/profile2.png";
import Profile3 from "../../assets/icons/profile3.png";
import ClipBoard from "../../assets/icons/subClipboard.svg";
import Union from "../../assets/icons/Union-19.svg";
import clipboardNotes from "../../assets/icons/8725602_clipboard_notes_icon.svg";
import SubStar from "../../assets/icons/subStar.svg";
import SubTag from "../../assets/icons/subTag.svg";
import SubShare from "../../assets/icons/subShare.svg";

import resizeIcon from "../../assets/icons/resizeIcon.svg";
import logo from "../../assets/images/facebooklogo.png";
import Cardimage from "../../assets/images/cardImage.png";
import CreateTags from "../Tags/CreateTags";
import DeleteSubtag from "../Tags/DeleteSubtag";
import MoveTags from "../Tags/MoveTags";
import { toastify } from "../../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  DndProvider,
} from "@minoru/react-dnd-treeview";
import { CustomDragPreview } from "./CustomDragPreview";
import { theme } from "./theme";
import styles from "./App.module.css";
import styless from "./CustomNode.module.css";
import { Placeholder } from "./Placeholder";
import { id } from "date-fns/locale";
import SingleFile from "../../shades/cards/SingleFile";
import SingleBook from "../SingleBookmark/singlebookmark";
import ShareCard from "../../shades/cards/shareCard";
import BlackDismiss from "../../assets/icons/dismissButton.svg";
// import { DndProvider } from "react-dnd";
const breakpoints = { mobile: 0, tablet: 900, desktop: 1200 };

function TagList(shared) {
  const [bucketOperation, setBucketOperation] = useState("Add");
  const [bucketNameText, setBucketNameText] = useState("");
  const [bucketCreated, setBucketCreated] = useState(false);
  const [cardCreated, setCardCreated] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [bucketUpdateId, setBucketUpdateId] = useState(0);
  const [tagActiveId, setTagActiveId] = useState(0);
  const [tagActiveName, setTagActiveName] = useState("");
  const [selectedType, setSelectedType] = useState("All");
  let [isOpens, setIsOpen] = useState(false);
  let [isOpenss, setIsOpens] = useState(false);
  const [subtags, setSubTags] = useState([]);
  const [popup, setPopup] = useState(false);
  const [movePopup, setMovePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsCheck, setSelectedItemsCheck] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [parentId, setParentId] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [parentDelete, setParentDelete] = useState(0);
  const [bookmarksfilespopup, setBookmarksfilesPopup] = useState(false);
  const [selectedNoassigned, setSelectedNoassigned] = useState(
    "react-for-active-tabs"
  );
  const [toggleNoassigned, setToggleNoassigned] = useState(true);
  const [cardListCount, setCardListCount] = useState(0);
  const [bookmarksListCount, setBookmarksListCount] = useState(0);
  const [filesListCount, setFilesListCount] = useState(0);
  const [FileRefresh, setFileRefresh] = useState(false);
  const [ShareFileId, setShareFileId] = useState("");
  const [FileSharePopup, setFileSharePopup] = useState(false);
  const [buckets, setBuckets] = useState([]);
  const [BucketSharePopup, setBucketSharePopup] = useState(false);
  const [shareBucketId, setShareBucketId] = useState("");
  const [appTheme,setAppTheme]=useState("")
  useEffect(() => {
    const handleStorageChange = () => {
      console.log('app theme')
      const theme = localStorage.getItem("theme");
      setAppTheme(theme);
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    // Force a re-render when localStorage changes
    setAppTheme(localStorage.getItem("theme"));
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[]);
  function closeCardModal() {
    // setSelectedItems([]);
    setIsOpens(false);
  }

  const handleBookmarksfilesPopup = () => {
    setBookmarksfilesPopup(true);
  };

  function closeBookmarksfilesPopup() {
    setBookmarksfilesPopup(false);
  }

  const filterDropDown = [
    { name: "Created Date", href: "#" },
    { name: "Recent Activity", href: "#" },
    { name: "Ascending Order", href: "#" },
    { name: "Descending Order", href: "#" },
    // { name: 'Manual', href: '#' },
  ];

  let cardFilterv = "";
  if (!localStorage.getItem("cardFilter")) {
    localStorage.setItem("cardFilter", "Created_Date");
    cardFilterv = "Created_Date";
  } else {
    cardFilterv = localStorage.getItem("cardFilter");
  }
  console.log(cardFilterv, "cardFilterv");
  const [cardFilterDefault, setCardFilterDefault] = useState(cardFilterv);
  const [istag, setIstag] = useState(true);
  const [istagOpen, setIstagOpen] = useState(false);
  const [issectag, setIsSectag] = useState(true);
  const [issectagOpen, setIsSectagOpen] = useState(false);
  const [isSubtag, setIsSubtagOpen] = useState(false);

  const handleIsTag = () => {
    setIstag(true);
    setIstagOpen(true);
  };

  const handleIssecTag = () => {
    setIsSectag(true);
    setIsSectagOpen(true);
  };

  const handleIsTagOpen = () => {
    setIsSectagOpen(true);
    setIsSubtagOpen(true);
  };

  // const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
  const bucketId = useParams();

  useEffect(() => {
    // api url
    setIsLoading(true);
    const token = localStorage.getItem("token");
    //  setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}tags/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setIsLoading(false);
        setSubTags(response?.data?.data);
        if (response?.data?.data) {
          const flattenedData = flattenObject(response.data.data);
          console.log(flattenedData, "flattenedData");
          setTreeData(flattenedData);
        }

        // response?.data?.data[0]._id
        setTagActiveId(response?.data?.data[0]._id);
        // setTagActiveName(response?.data?.data[0].name);
        // setSelectedItemsCheck([response?.data?.data[0]._id]);
        // if(selectedItemsCheck?.length){
        //   axios.post(`${process.env.REACT_APP_API}tags/getCards-by-tagId`,{
        //     // tagIds:[`${response?.data?.data[0]._id}`],
        //     tagIds:selectedItemsCheck,
        //     filterValue:cardFilterDefault,
        //     selectedType: selectedType,
        //     limit: 100
        //   },{
        //     headers: {
        //     Authorization: `Bearer ${token}`,
        //   }
        //   })
        //   .then(function (response) {
        //     setIsLoading(false);
        //     setCardList(response.data.data.cardsList);
        //     setFileList(response.data.data.filesList);
        //     setBookmarkList(response.data.data.bookmarksList);
        //     setCardListCount(response.data.data.totalCards + response.data.data.totalBookmarks + response.data.data.totalFiles);
        //     setTagActiveName(selectedItemsCheck.length+ ' tags');
        //     // reorder(response.data.data);
        //     // setBucketList(response.data.data.bucketList);
        //     // setTotalBuckets(response.data.data.total);
        //     // console.log(cardList,"cardList");
        //   })
        // .catch(function (error) {
        //   console.log(error);
        // });
        // }
      })
      .catch(function (error) {
        console.log(error);
      });

    //  const token = localStorage.getItem("token");
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}buckets/user-buckets`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request header
            },
          }
        ); // Replace this with the actual API endpoint
        setBuckets(response?.data?.data?.bucketList); // Assuming the API response is an array of bucket objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (token) {
      fetchBuckets();
    }
  }, [bucketCreated, FileRefresh]);

  const cardsRefresh = () => {
    setFileRefresh(!FileRefresh);
  };

  const handleSharePop = (id) => {
    console.log(id, "share card id");
    setShareBucketId(id);
    setBucketSharePopup(true);
  };
  useEffect(() => {
    // if(selectedItemsCheck?.length){
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API}tags/getCards-by-tagId`,
        {
          tagIds: selectedItemsCheck,
          filterValue: cardFilterDefault,
          selectedType: selectedType,
          limit: 100,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        setCardList(response.data.data.cardsList);
        setFileList(response.data.data.filesList);
        setBookmarkList(response.data.data.bookmarksList);
        setCardListCount(
          response.data.data.totalCards +
            response.data.data.totalBookmarks +
            response.data.data.totalFiles
        );
        setTagActiveName(selectedItemsCheck.length + " Tag(s) Selected");
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  }, [selectedItemsCheck, cardCreated, FileRefresh]); // Include yourState as a dependency

  const flattenObject = (data, parentId = 0) => {
    const flattened = [];

    data.forEach((item) => {
      console.log(item, "item foreach");
      const newItem = {
        id: item._id,
        parent: parentId,
        droppable: item.parent,
        droppables: item.subTags ? item.subTags.length > 0 : false,
        text: item.name,
        parentDisable: item.subTags ? item.subTags.length : 0,
        isChild: item.parent,
      };

      flattened.push(newItem);

      if (item.subTags) {
        if (item.subTags.length > 0) {
          const children = flattenObject(item.subTags, item._id);
          flattened.push(...children);
        }
      }
    });

    return flattened;
  };

  // const handleDrop = (newTree) => setTreeData(newTree);
  const handleDrop = (
    newTree,
    { dragSourceId, dropTargetId, dragSource, dropTarget }
  ) => {
    // Do something
    if (dragSource.parentDisable == 0) {
      console.log(
        dragSourceId,
        dropTargetId,
        dragSource,
        dropTarget,
        "dragSourceId, dropTargetId"
      );
      updateCreateBucket(dragSourceId, dropTargetId);
      setTreeData(newTree);
    }
  };

  const handleCardClick = (text) => {
    if (text == "Select All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (!checkbox.checked) {
          var checked = true; // Replace with your desired checked state
          checkbox.checked = checked;
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
            setSelectedItems((prevSelectedItems) => [
              ...prevSelectedItems,
              checkbox.value,
            ]);
          }
        } else {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    } else if (text == "Unselect All") {
      var checkboxes = document.querySelectorAll(
        '.cardheight-content input[type="checkbox"]'
      );
      var checkedValues = []; // Array to store the values of checked checkboxes

      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          var checked = false; // Replace with your desired checked state
          checkbox.checked = checked;
          setSelectedItems([]);
        }
      });
    }
  };

  const handleRadioChange = (event) => {
    setSelectedType(event.target.value);
    console.log(event.target.value);
    const elements = document.querySelectorAll(".react-tabs__tab");
    if (selectedItemsCheck?.length) {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      axios
        .post(
          `${process.env.REACT_APP_API}tags/getCards-by-tagId`,
          {
            tagIds: selectedItemsCheck,
            filterValue: cardFilterDefault,
            selectedType: event.target.value,
            limit: 100,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setIsLoading(false);
          setCardList(response.data.data.cardsList);
          setCardListCount(
            response.data.data.totalCards +
              response.data.data.totalBookmarks +
              response.data.data.totalFiles
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    // if(event.target.value == 'option1'){
    // Loop through each element and add the "active" class
    // elements.forEach((element) => {
    //   element.classList.add('react-tabs__tab--selected');
    // });
    // }else{
    // Loop through each element and remove the "active" class
    // elements.forEach((element) => {
    //   element.classList.remove('react-tabs__tab--selected');
    // });
    // const firstElement = document.querySelector('.react-tabs__tab');
    // Add the "active" class to the first element
    // if (firstElement) {
    //   firstElement.classList.add('react-tabs__tab--selected');
    // }
    // }
  };

  const handleFilterText = (text) => {
    // setFiltersText(text);
    console.log(text, "handlefiltertext");
    setIsLoading(true);
    setFiltersText(text);
    const filterValues =
      text == "Created Date"
        ? "Created_Date"
        : text == "Descending Order"
        ? "Descending_Order"
        : text == "Ascending Order"
        ? "Ascending_Order"
        : text == "Recent Activity"
        ? "Recent_Activity"
        : "";
    localStorage.setItem("cardFilter", filterValues);
    setCardFilterDefault(filterValues);
    const token = localStorage.getItem("token");
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API}tags/getCards-by-tagId`,
        {
          tagIds: selectedItemsCheck,
          filterValue: filterValues,
          selectedType: selectedType,
          limit: 100,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        // setCardList(response.data.data.cardsList);
        // setCardListCount(response.data.data.totalCards);
        setCardList(response.data.data.cardsList);
        setFileList(response.data.data.filesList);
        setBookmarkList(response.data.data.bookmarksList);
        setCardListCount(
          response.data.data.totalCards +
            response.data.data.totalBookmarks +
            response.data.data.totalFiles
        );
        setTagActiveName(selectedItemsCheck.length + " Tag(s) Selected");
        // reorder(response.data.data);
        // setBucketList(response.data.data.bucketList);
        // setTotalBuckets(response.data.data.total);
        console.log(cardList, "cardList");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const bucketNav = [
    // {icon:faPencil, name: 'Edit Name', href: '#' },
    // { icon: faNoteSticky, name: "Create New Card", href: "#" },
    { icon: faTrash, name: "Select All", href: "#" },
    { icon: faTrash, name: "Unselect All", href: "#" },
    // {icon:faFolder, name: 'Customize New Card', href: '#' },
  ];

  const bucketTagNav = [
    // {icon:faPencil, name: 'Edit Name', href: '#' },
    { icon: faPencil, name: "Rename", href: "#" },
    { icon: faTrash, name: "Delete", href: "#" },
    // {icon:faTrash, name: 'Unselect All', href: '#' },
    // {icon:faFolder, name: 'Customize New Card', href: '#' },
  ];

  const tagNav = [
    // {icon:faPencil, name: 'Edit Name', href: '#' },
    // {icon:faNoteSticky, name: 'Add Sub tag', href: '#' },
    { icon: faTrash, name: "Rename tag", href: "#" },
    { icon: faTrash, name: "Delete tag", href: "#" },
    // {icon:faFolder, name: 'Customize New Card', href: '#' },
  ];

  const subTagNav = [
    { icon: faTrash, name: "Rename tag", href: "#" },
    { icon: faTrash, name: "Delete tag", href: "#" },
    { icon: faTrash, name: "Move tag", href: "#" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handlePopup = () => {
    setParentId(null);
    setBucketOperation("Add");
    setBucketNameText("");
    setBucketCreated(false);
    setPopup(true);
    setTimeout(() => {
      document.getElementsByName("name")[0].focus();
    }, 100);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function bucketUpdate() {
    setBucketCreated(!bucketCreated);
  }

  function cardUpdate() {
    setCardCreated(!cardCreated);
  }

  function changeTxt(event) {
    setBucketNameText(event.target.value);
  }

  const handleDeleteClick = (id) => {
    setIsOpen(true);
    console.log(id);
    setBucketUpdateId(id);
  };

  // const [filtersText, setFiltersText] = useState(filterDropDown[0].name);
  const [filtersText, setFiltersText] = useState(
    cardFilterv == "Created_Date"
      ? filterDropDown[0].name
      : cardFilterv == "Descending_Order"
      ? filterDropDown[3].name
      : cardFilterv == "Ascending_Order"
      ? filterDropDown[2].name
      : cardFilterv == "Recent_Activity"
      ? filterDropDown[1].name
      : ""
  );

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  console.log("Selected Items:", selectedItems);
  const deleteCard = async () => {
    setIsOpens(true);
  };

  const handleDeleteCard = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        "https://prdapi.zimzee.com/api/cards/delete",
        {
          data: { deletedIds: selectedItems },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      console.log(response);
      toastify("success", response.data.message);
      setSelectedItems([]);
      closeCardModal();
      cardUpdate();
    } catch (error) {
      console.error(error);
      closeCardModal();
      setIsLoading(false);
      toastify("error", error.response.data.message);
    }
  };

  const handleUnassigned = async () => {
    setToggleNoassigned(!toggleNoassigned);
    if (!toggleNoassigned == true) {
      setSelectedItemsCheck([]);
      setSelectedNoassigned("react-for-active-tabs");
      setSubTags((subtags) => {
        return subtags.map((tag) => {
          // Toggle the extraData value
          return { ...tag, focus: false };
        });
      });
    } else {
      setSelectedNoassigned("react-for-tabs");
      setCardList([]);
    }
    closeBookmarksfilesPopup();
  };

  const handleUnclickTagCards = async (id, name) => {
    const token = localStorage.getItem("token");
    console.log(id, "check statement id");
    console.log(selectedItemsCheck, "check statement");
    const updatedItems = selectedItemsCheck.filter((item) => item !== id);
    console.log(updatedItems, "updatedItems unclick");
    setSelectedItemsCheck(updatedItems);
    if (updatedItems.length == 0) {
      setToggleNoassigned(!toggleNoassigned);
      if (!toggleNoassigned == true) {
        setSelectedItemsCheck([]);
        setSelectedNoassigned("react-for-active-tabs");
        setSubTags((subtags) => {
          return subtags.map((tag) => {
            // Toggle the extraData value
            return { ...tag, focus: false };
          });
        });
      } else {
        setSelectedNoassigned("react-for-tabs");
        setCardList([]);
      }
    } else {
      setSubTags((subtags) => {
        return subtags.map((tag) => {
          if (tag._id === id) {
            // Toggle the extraData value
            return { ...tag, focus: !tag.focus };
          }
          return tag;
        });
      });
    }

    console.log(subtags, "subtags unfoucs selectedItemsCheck");
    console.log(
      selectedItemsCheck,
      "selectedItemsCheck subtags unfoucs selectedItemsCheck"
    );
    closeBookmarksfilesPopup();
    //   await axios.post(`${process.env.REACT_APP_API}tags/getCards-by-tagId`,{
    //     tagIds:updatedItems,
    //     filterValue:cardFilterDefault,
    //     selectedType: selectedType,
    //     limit: 100
    //   },{
    //     headers: {
    //     Authorization: `Bearer ${token}`,
    //   }
    //   })
    //   .then(function (response) {
    //     setIsLoading(false);
    //     setCardList(response.data.data.cardsList);
    //   })
    // .catch(function (error) {
    //   console.log(error);
    // });
  };

  const handleTagCards = async (id, name) => {
    setActiveItem(id);
    setSelectedItemsCheck((prevSelectedItems) => [...prevSelectedItems, id]);
    const selectedItem = subtags.find((tag) => tag._id === id);
    // Push extra data or modify the selected item as needed
    selectedItem.focus = true;
    setSubTags((subtags) => {
      return subtags.map((tag) => (tag._id === id ? selectedItem : tag));
    });
    // setSelectedItemsCheck((prevSelectedTagIds) => {
    //   if (prevSelectedTagIds.includes(id)) {
    //     return prevSelectedTagIds.filter((id) => id !== id); // Deselect if already selected
    //   } else {
    //     return [...prevSelectedTagIds, id]; // Select if not selected
    //   }
    // });
    setSelectedNoassigned("react-for-tabs");
    setToggleNoassigned(false);
    console.log(subtags, "subtags selectedItemsCheck");
    console.log(selectedItemsCheck, "selectedItemsCheck");
    // handleDeleteClick(id);
    setIstag(true);
    // setIstagOpen(!istagOpen);
    setIstagOpen(true);
    // setIstagOpen(!istagOpen);
    console.log(id, name, "id,name");
    closeBookmarksfilesPopup();
    // setTagActiveName(name);
    setTagActiveId(id);
  };

  const handleSubTagCards = (id, name, parentTagId) => {
    const token = localStorage.getItem("token");
    // handleDeleteClick(id);
    setIstag(true);
    setIstagOpen(true);
    setTagActiveName(name);
    setTagActiveId(parentTagId);
    axios
      .post(
        `${process.env.REACT_APP_API}tags/getCards-by-tagId`,
        {
          tagId: `${id}`,
          filterValue: cardFilterDefault,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setIsLoading(false);
        setCardList(response.data.data);
        setCardListCount(response.data.data.totalCards);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearchChange = async (event) => {
    setSearchValue(event.target.value);
    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API}tags/list/${event.target.value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // setIsLoading(false);
        if (response?.data?.data && response?.data?.data.length) {
          // if(response?.data?.data){
          const flattenedData = flattenObject(response.data.data);
          console.log(flattenedData, "flattenedData");
          setTreeData(flattenedData);
          // }
          setSubTags(response?.data?.data);
          setTagActiveId(response?.data?.data[0]._id);
        } else {
          setSubTags([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleTagClick = async (tagId, itemName, parentDisable) => {
    console.log(tagId, itemName);
    const token = localStorage.getItem("token");
    if (itemName == "Rename") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}tags/single-tag/${tagId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data.name, "response");
        setBucketNameText(response.data.data.name);
      } catch (error) {
        console.error(error);
      }
      console.log("Button clicked!");
      console.log(tagId);
      setPopup(true);
      setBucketOperation("Update");
      setBucketUpdateId(tagId);
    } else if (itemName == "Delete") {
      setParentDelete(parentDisable);
      setIsOpen(true);
      console.log(tagId);
      setBucketUpdateId(tagId);
    } else if (itemName == "Add Sub tag") {
      setBucketUpdateId(tagId);
      setParentId(tagId);
      setBucketOperation("subbucket");
      setBucketNameText("");
      setPopup(true);
      setTimeout(() => {
        document.getElementsByName("name")[0].focus();
      }, 100);
    } else if (itemName == "Move tag") {
      setBucketUpdateId(tagId);
      setMovePopup(true);
    } else if (itemName == "Make Parent") {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = localStorage.getItem("token");
      try {
        const response = await axios.patch(
          "https://prdapi.zimzee.com/api/tags/update-tag",
          { tagId: tagId, sourceId: tagId, destinationId: null },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        toastify("success", response.data.message);
        bucketUpdate();
      } catch (error) {
        console.error(error);
        toastify("error", error.response.data.message);
      }
    }
  };

  const handleButtonDelete = async (id) => {
    // setIsOpen(true);
    console.log(id);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}tags/delete-tag/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response");
      //  toastify("sucess", response.message );
      toastify("success", response.data.message);
      bucketUpdate();

      // axios.get(`${process.env.REACT_APP_API}tags/list`,{
      //   headers: {
      //    Authorization: `Bearer ${token}`,
      //  }
      // })
      // .then(function (response) {
      //  setIsLoading(false);
      //  setSubTags(response?.data?.data);
      //  // response?.data?.data[0]._id
      //  setTagActiveId(response?.data?.data[0]._id);
      //    axios.post(`${process.env.REACT_APP_API}tags/getCards-by-tagId`,{
      //      tagId:`${response?.data?.data[0]._id}`,
      //      filterValue:cardFilterDefault
      //    },{
      //      headers: {
      //      Authorization: `Bearer ${token}`,
      //    }
      //    })
      //    .then(function (response) {
      //      setIsLoading(false);
      //      setCardList(response.data.data);
      //    })
      //  .catch(function (error) {
      //    console.log(error);
      //  });
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
    setIsOpen(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    console.log(list, startIndex, endIndex, "list, startIndex, endIndex");
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateCreateBucket = async (id, destination) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/tags/update-tag",
        {
          tagId: id,
          sourceId: id,
          destinationId: destination == 0 ? null : destination,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //  toastify("success", response.data.message);
      bucketUpdate();
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleDragEnd = ({ destination, source, type, draggableId }) => {
    if (!destination) return;
    console.log(source, destination);
    const sourceIndex = source.index;
    const destIndex = destination.index;
    if (type === "droppableItem") {
      // const itemSubItemMap =subtags.reduce((acc, item) => {
      //   acc[item._id] = item.subTags;
      //   return acc;
      // }, {});
      // console.log("droppableSubItem",itemSubItemMap);
      // const sourceParentId = source.droppableId;
      // const destParentId = destination.droppableId;
      // console.log(sourceParentId,destParentId,draggableId,"sourceParentId,destParentId");
      setSubTags(reorder(subtags, source.index, destination.index));
    } else if (type === "droppableSubItem") {
      const itemSubItemMap = subtags.reduce((acc, item) => {
        acc[item._id] = item.subTags;
        return acc;
      }, {});
      console.log("droppableSubItem", itemSubItemMap);
      const sourceParentId = source.droppableId;
      const destParentId = destination.droppableId;
      console.log(
        sourceParentId,
        destParentId,
        draggableId,
        "sourceParentId,destParentId"
      );
      const sourceSubItems = itemSubItemMap[sourceParentId];
      console.log(
        itemSubItemMap,
        sourceParentId,
        sourceSubItems,
        source.droppableId,
        "sourceSubItems"
      );
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...subtags];
      console.log(newItems, "newItems");
      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );
        console.log(reorderedSubItems, "reorderedSubItems");
        newItems = newItems.map((item) => {
          if (item._id === sourceParentId) {
            item.subTags = reorderedSubItems;
          }
          return item;
        });
        // this.setState({
        //   items: newItems
        // });
        setSubTags(newItems);
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map((item) => {
          if (item._id === sourceParentId) {
            item.subTags = newSourceSubItems;
          } else if (item._id === destParentId) {
            item.subTags = newDestSubItems;
          }
          return item;
        });
        // this.setState({
        //   items: newItems
        // });
        setSubTags(newItems);
      }
      updateCreateBucket(draggableId, destParentId);
    }

    // setList(reorder(list, source.index, destination.index));
  };
  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    margin: "10px 0",
  });

  console.log(treeData);
  const closeSharePop = () => {
    console.log("close share pop");
    setBucketSharePopup(false);
  };
  //  subtags?.map(tag=>{
  //     console.log(tag.name,"tag maps");
  //  })

  return (
    <>
      {isLoading ? (
        <div className="App-loading">
          <div className="App-logo" title="Zimzee" />
        </div>
      ) : (
        <div className="tags-pagesection">
          {BucketSharePopup && (
                    <div
                      className="filesuploadpopup relative !z-[999]"
                      style={{ height: "100%" }}
                      onClick={closeSharePop}
                    >
                      <div
                        className="subinviteupload-section"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="teaminvitesection flex">
                          <div className="teammembers-content">Share</div>
                          <div
                            className="dismissSubfiles-content"
                            onClick={closeSharePop}
                          >
                            <img src={BlackDismiss} alt="dismissButton" />
                          </div>
                        </div>
                        <ShareCard
                          shareBucketId={shareBucketId}
                          closeSharePop={closeSharePop}
                          cardsRefresh={cardUpdate}
                        />
                      </div>
                    </div>
                  )}
          <div className="flex">
            <h5>Tags</h5>
            <button
              style={{
                filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))",
              }}
              className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]"
              onClick={handlePopup}
            >
              <FontAwesomeIcon
                className="pl-[23px] pr-[17.73px]"
                icon={fontIcons.faPlus}
              />
              <span className="createbutton-context">Add</span>{" "}
            </button>
          </div>
          <div className="totalbucket-content">
            <img
              className="bucket-imgcontent"
              src={Tagsicon}
              alt="bucketIcon"
            />{" "}
            {subtags ? subtags.length : 0} Tags in Total
          </div>
          <div className="row">
            <Tabs>
              <div className="tagsdesktop-section">
                <TabList>
                  <div className="search-tab flex item-center ">
                    <div className="w-full">
                      <label
                        htmlFor="search"
                        className="dark:border-white-700 sr-only"
                      >
                        Search
                      </label>
                      <div className="relative">
                        <input
                          id="search"
                          name="search"
                          className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                          placeholder="Search"
                          type="search"
                          onChange={handleSearchChange}
                          value={searchValue}
                        />
                        <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center pr-2 ">
                          <img
                            className="magnifier"
                            src={magnifier}
                            alt="magnifying-glass"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex tagradio-content">
                    <div className="tagline-section">
                      <input
                        type="radio"
                        name="selectedtag"
                        className="radiobutton-content"
                        value="All"
                        checked={selectedType === "All"}
                        onChange={handleRadioChange}
                      />
                       {" "}
                      <label className="tagsdesc-content" for="html">
                        All Selected
                      </label>
                    </div>
                    <div className="tagline-section">
                      <img src={TagsLine} alt="TagsLine" />
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="selectedtag"
                        className="radiobutton-content"
                        value="Any"
                        checked={selectedType === "Any"}
                        onChange={handleRadioChange}
                      />
                       {" "}
                      <label className="tagsdesc-content" for="html">
                        Any Selected
                      </label>
                    </div>
                  </div>
                  <div className="tab-scroll">
                    <div className="tabssection-content">
                      <div className={selectedNoassigned}>
                        <Tab onClick={() => handleUnassigned()}>
                          <div className="pr-[12px]">No Assigned Tags</div>
                        </Tab>
                      </div>
                      {subtags?.length
                        ? subtags.map((tag, index) => (
                            <div
                              className={
                                tag?.focus
                                  ? `react-for-active-tabs`
                                  : `react-for-tabs`
                              }
                            >
                              <Tab
                                onClick={() =>
                                  tag?.focus
                                    ? handleUnclickTagCards(tag._id, tag.name)
                                    : handleTagCards(tag._id, tag.name)
                                }
                                key={tag._id}
                              >
                                <div className="flex">
                                  {tag.name}
                                  {/* {
                      tag?.focus? */}
                                  <Menu
                                    as="div"
                                    className="relative custom-height"
                                  >
                                    <Menu.Button
                                      className="relative inline-flex items-center text-sm font-semibold text-gray-900 "
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <img
                                        className="dark-icons"
                                        src={ThreeDots}
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          marginTop: "2px",
                                        }}
                                        alt="menu"
                                      />
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        style={{
                                          filter:
                                            "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                                          border: "1px solid #E0E0E0",
                                        }}
                                        className="absolute center-2 z-10 mt-2 buckettagnav-content origin-top-center rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {bucketTagNav.map((item) => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <a
                                                href={item.href}
                                                className={classNames(
                                                  active ? "bg-gray-100" : "",
                                                  "block py-2 pl-[10px] text-sm text-gray-700"
                                                )}
                                                onClick={() => {
                                                  handleTagClick(
                                                    tag._id,
                                                    item.name
                                                  );
                                                }}
                                              >
                                                {/* <span className="pr-2">
                                                  <FontAwesomeIcon
                                                    className="text-[#797979]"
                                                    icon={item.icon}
                                                  />
                                                </span> */}
                                                <span
                                                  className="text-black"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {item.name}
                                                </span>
                                              </a>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                  {/* : ""
                    } */}
                                </div>
                              </Tab>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>

                  {/* </div> */}
                </TabList>
              </div>
              {subtags?.map((tag) => (
                <TabPanel key={tag._id}>
                  <div className="panel-content">
                    <div className="subBuckets-whole listingCards">
                      <div>
                        <div className="flex items-center">
                          <h6 className="tags_selected">{tagActiveName ? tagActiveName : ""}</h6>
                          <p className="total-bucktes ml-6 rounded-full  right-0 mr-2 text-white bg-[#FF6600] ml-3 inline-block rounded-full py-0.5 text-xs font-medium h-5 flex justify-center items-center menuCount">
                            {cardListCount}
                          </p>
                          <Menu as="div" className="relative pr-2">
                            <Menu.Button className="relative ml-3 inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 ">
                              <img
                                className="dark-icons"
                                src={ThreeDots}
                                alt="menu"
                              />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                style={{
                                  filter:
                                    "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                                  border: "1px solid #E0E0E0",
                                }}
                                className="absolute right-0 text-left z-10 mt-2 w-52 origin-top-right rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {bucketNav.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <a
                                        href={item.href}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block py-2 px-[10px] text-sm text-gray-700"
                                        )}
                                        style={{ textAlign: "left" }}
                                        onClick={() => {
                                          handleCardClick(item.name);
                                        }}
                                      >
                                        <span className="pr-2">
                                          <FontAwesomeIcon
                                            className="text-[#797979]"
                                            icon={item.icon}
                                          />
                                        </span>
                                        <span className="text-black">
                                          {item.name}
                                        </span>
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          {selectedItems.length > 0 ?
                <img
                src={appTheme==="light"?DeleteIcon:LightDelete}
                alt="deleteIcon"
                className="px-3"
                onClick={() => deleteCard()}
              />
              :
              <img
              src={appTheme==="light"?LightDelete:DeleteIcon}
              alt="deleteIcon"
              className="px-3"
              // onClick={() => deleteCard()}
            />
              }
                          <div className="tagsdesktop-section ml-[auto] items-center justify-end">
                            {/* <div className="filter display-content"> 
                                    <a className="viewGrid" >                            
                                    <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                                    </a>
                                    <a className="viewGrid">                            
                                    <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                                    </a>
                                </div> */}
                            <div className="flex">
                              <span className="sorttext-content mx-3 dark:text-[#acacac]">
                                Sort By
                              </span>
                              <Menu as="div" className="relative flex-shrink-0">
                                <div>
                                  <Menu.Button className="flex rounded-full focus:outline-none ">
                                    <a
                                      href="#"
                                      className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                                    >
                                      <strong className="createdtext-content pr-4">
                                        {filtersText}
                                      </strong>
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                        }}
                                        icon={faChevronDown}
                                      />
                                    </a>
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    style={{
                                      filter:
                                        "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                                      border: "1px solid #E0E0E0",
                                    }}
                                    className="absolute right-0 text-left z-10 mt-2 w-52 origin-top-right rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {filterDropDown.map((item) => (
                                      <Menu.Item key={item.name}>
                                        {({ active }) => (
                                          <a
                                            onClick={() => {
                                              handleFilterText(item.name);
                                            }}
                                            href={item.href}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              "block py-2 px-4 text-sm text-gray-700"
                                            )}
                                          >
                                            {item.name}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                          {/* <img
                            className="dark-icons"
                            src={Trash}
                            alt="menu"
                            onClick={() => deleteCard()}
                          /> */}
                          <Transition appear show={isOpenss} as={Fragment}>
                            <Dialog
                              as="div"
                              className="relative z-10"
                              onClose={closeCardModal}
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                              </Transition.Child>

                              <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                  >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all text-center">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                      >
                                        Delete
                                      </Dialog.Title>
                                      <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                          Are you sure you want to delete?
                                        </p>
                                      </div>

                                      <div className="mt-4">
                                        <button
                                          style={{
                                            background: "#FF6600",
                                            width: "80px",
                                            color: "#fff",
                                            padding: "10px",
                                            borderRadius: "50px",
                                          }}
                                          type="button"
                                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                          onClick={() => handleDeleteCard()}
                                        >
                                          Yes
                                        </button>
                                        <button
                                          style={{
                                            background: "#000",
                                            width: "80px",
                                            color: "#fff",
                                            padding: "10px",
                                            borderRadius: "50px",
                                          }}
                                          type="button"
                                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                          onClick={closeCardModal}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                            </Dialog>
                          </Transition>
                          <div className="tagsmbl-section">
                            {/* <div className="filter display-content"> 
                                      <a className="viewGrid" href={getUrl('list')}>                            
                                      <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                                      </a>
                                      <a className="viewGrid" href={getUrl('grid')}>                            
                                      <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                                      </a>
                                  </div> */}

                            <img
                              src={SortMbl}
                              alt="SortMbl"
                              onClick={handleBookmarksfilesPopup}
                            />
                          </div>
                          {bookmarksfilespopup && (
                            <div className="bookmarksGrid-wholesection">
                              <div
                                className="bookmarksfilesuploadpopup relative !z-[999]"
                                style={{ height: "100%" }}
                                onClick={closeBookmarksfilesPopup}
                              >
                                <div
                                  className="bookmarkssubfilesupload-section"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div
                                    className="dismissSubfiles-content"
                                    onClick={closeBookmarksfilesPopup}
                                  >
                                    <img
                                      className="bookmarksdismiss-button"
                                      src={DismissButton}
                                      alt="dismissButton"
                                    />
                                  </div>
                                  <TabList>
                                    <div className="search-tab flex item-center ">
                                      <div className="w-full">
                                        <label
                                          htmlFor="search"
                                          className="dark:border-white-700 sr-only"
                                        >
                                          Search
                                        </label>
                                        <div className="relative">
                                          <input
                                            id="search"
                                            name="search"
                                            className="dark:bg-[#202F4F] dark:text-white search-input block w-full rounded-3xl border-0 bg-[#F5F5F5] py-2.5 pr-3 text-gray-900 placeholder:text-gray-400 focus:outline-none m:text-sm sm:leading-6"
                                            placeholder="Search"
                                            type="search"
                                            onChange={handleSearchChange}
                                            value={searchValue}
                                          />
                                          <div className="dark:bg-[#2F436A] search-magnifier  pointer-events-none rounded-3xl absolute inset-y-0 flex items-center  ">
                                            <img
                                              className="magnifier"
                                              src={magnifier}
                                              alt="magnifying-glass"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-scroll">
                                      <div className="tabssection-content">
                                      <div className={selectedNoassigned}>
                                        <Tab onClick={() => handleUnassigned()}>
                                          <div className="pr-[12px]">No Assigned Tags</div>
                                        </Tab>
                                      </div>
                                      {subtags?.length
                                        ? subtags.map((tag, index) => (
                                            <div
                                              className={
                                                tag?.focus
                                                  ? `react-for-active-tabs`
                                                  : `react-for-tabs`
                                              }
                                            >
                                              <Tab
                                                onClick={() =>
                                                  tag?.focus
                                                    ? handleUnclickTagCards(tag._id, tag.name)
                                                    : handleTagCards(tag._id, tag.name)
                                                }
                                                key={tag._id}
                                              >
                                                <div className="flex">
                                                  {tag.name}
                                                  {/* {
                                      tag?.focus? */}
                                                  <Menu
                                                    as="div"
                                                    className="relative custom-height"
                                                  >
                                                    <Menu.Button
                                                      className="relative inline-flex items-center text-sm font-semibold text-gray-900 "
                                                      onClick={(e) => e.stopPropagation()}
                                                    >
                                                      <img
                                                        className="dark-icons"
                                                        src={ThreeDots}
                                                        style={{
                                                          width: "16px",
                                                          height: "16px",
                                                          marginTop: "2px",
                                                        }}
                                                        alt="menu"
                                                      />
                                                    </Menu.Button>
                                                    <Transition
                                                      as={Fragment}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95"
                                                    >
                                                      <Menu.Items
                                                        style={{
                                                          filter:
                                                            "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                                                          border: "1px solid #E0E0E0",
                                                        }}
                                                        className="absolute center-2 z-10 mt-2 buckettagnav-content origin-top-center rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        onClick={(e) => e.stopPropagation()}
                                                      >
                                                        {bucketTagNav.map((item) => (
                                                          <Menu.Item key={item.name}>
                                                            {({ active }) => (
                                                              <a
                                                                href={item.href}
                                                                className={classNames(
                                                                  active ? "bg-gray-100" : "",
                                                                  "block py-2 pl-[10px] text-sm text-gray-700"
                                                                )}
                                                                onClick={() => {
                                                                  handleTagClick(
                                                                    tag._id,
                                                                    item.name
                                                                  );
                                                                }}
                                                              >
                                                                {/* <span className="pr-2">
                                                                  <FontAwesomeIcon
                                                                    className="text-[#797979]"
                                                                    icon={item.icon}
                                                                  />
                                                                </span> */}
                                                                <span
                                                                  className="text-black"
                                                                  style={{ fontSize: "14px" }}
                                                                >
                                                                  {item.name}
                                                                </span>
                                                              </a>
                                                            )}
                                                          </Menu.Item>
                                                        ))}
                                                      </Menu.Items>
                                                    </Transition>
                                                  </Menu>
                                                  {/* : ""
                                    } */}
                                                </div>
                                              </Tab>
                                            </div>
                                          ))
                                        : ""}
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </TabList>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="tagsalign-content">
                        <p className="last-updated mt-[10px]">
                          Last updated on Jan 5, 2022 @ 4:30 PM
                        </p>
                        <div className="tagsdesktop-section ml-[auto] items-center justify-end">
                          {/* <div className="filter display-content"> 
                                    <a className="viewGrid" >                            
                                    <img className="mt-1 listcard-imgcontent" src={ListView} alt="listView" />
                                    </a>
                                    <a className="viewGrid">                            
                                    <img className="mt-1 compact-imgcontent" src={compactCardIcon} style={{color:'#000000'}} alt="compactIcon" />
                                    </a>
                                </div> */}
                          {/* <div className="flex">
                            <span className="sorttext-content mx-3 dark:text-[#acacac]">
                              Sort By
                            </span>
                            <Menu as="div" className="relative flex-shrink-0">
                              <div>
                                <Menu.Button className="flex rounded-full focus:outline-none ">
                                  <a
                                    href="#"
                                    className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500"
                                  >
                                    <strong className="createdtext-content pr-4">
                                      {filtersText}
                                    </strong>
                                    <FontAwesomeIcon
                                      style={{
                                        fontSize: "12px",
                                        color: "#707070",
                                      }}
                                      icon={faChevronDown}
                                    />
                                  </a>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  style={{
                                    filter:
                                      "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                                    border: "1px solid #E0E0E0",
                                  }}
                                  className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  {filterDropDown.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            handleFilterText(item.name);
                                          }}
                                          href={item.href}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block py-2 px-4 text-sm text-gray-700"
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div> */}
                        </div>
                      </div>
                      <div className="files-wholesection">
                        <div className="cards">
                          <div className="compactdesktop-section">
                            <div className="bucketHeader">
                              <div className="grid grid-cols-3">
                                <div className="bucketFeature  col-span-2">
                                  <div className="flex items-center">
                                    <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                                      Cards
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Masonry
                        breakpoints={breakpoints}
                        columns={{
                          mobile: 1,
                          tablet: 3,
                          desktop: 3,
                        }}
                        gap={{ mobile: 2, tablet: 1, desktop: 15 }}
                        // style={{paddingBottom:"10px"}}
                      >
                        {cardList?.map((card) => {
                          // console.log(card);
                          return (
                            <SingleCard
                              key={card._id}
                              handleSharePop={handleSharePop}
                              starred={card.starred}
                              cardUpdate={cardUpdate}
                              cardId={card._id}
                              cardType={card.cardType}
                              sharedLength={card.shared.length}
                              title={card.title}
                              content={card.content}
                              note={card.note}
                              tags={card.tags}
                              postDetail={
                                card.postDetail ? card.postDetail : ""
                              }
                              updated={card.updatedAt}
                              handleCheckboxChange={handleCheckboxChange}
                            />
                          );
                        })}
                      </Masonry>
                    </div>
                    {/* <div className="tagsbookmarks-wholesection"> */}
                    <div className="files-wholesection">
                      <div className="cards">
                        <div className="compactdesktop-section">
                          <div className="bucketHeader">
                            <div className="grid grid-cols-3">
                              <div className="bucketFeature col-span-2 ">
                                <div className="flex items-center">
                                  <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                                    Files
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="filescard-section flex flex-wrap">
                        {fileList.map((file) => {
                          return (
                            <SingleFile
                              key={file?._id}
                              Url={file?.content?.imageUrl[0]?.url}
                              handleSharePop={handleSharePop}
                              starred={file?.starred}
                              buckets={buckets}
                              cardId={file?._id}
                              cardType={file?.cardType}
                              sharedLength={file?.shared.length}
                              title={file?.title}
                              note={file?.note}
                              tags={file?.tags}
                              created={file?.createdAt}
                              content={file?.content}
                              handleCheckboxChange={handleCheckboxChange}
                              handleCardClick={handleCardClick}
                              cardsRefresh={cardsRefresh}
                            />
                          );
                        })}
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="tagsbookmarks-wholesection">
                      <div className="bookmarksGrid-wholesection">
                        <div className="bucketHeader">
                          <div className="grid grid-cols-3">
                            <div className="bucketFeature col-span-2">
                              <div className="flex items-center">
                                <h2 className="text-xl mybuckets-content dark:text-[#fff]">
                                  Bookmarks
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filescard-section flex flex-wrap">
                          {bookmarkList.map((card) => {
                            return (
                              <>
                                <SingleBook
                                  cardsRefresh={cardsRefresh}
                                  card={card._id}
                                  note={card.note}
                                  tags={card?.tags}
                                  starred={card.starred}
                                  cardId={card._id}
                                  title={card.title}
                                  content={card.content}
                                  handleSharePop={handleSharePop}
                                  updated={card.createdAt}
                                  handleCheckboxChange={handleCheckboxChange}
                                />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
              {/* <TabPanel>
      
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>      
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>      
      <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel>     
       <TabPanel>
         <div className="panel-content">
         </div>
      </TabPanel> */}
            </Tabs>
          </div>
          {popup === true ? (
            <CreateTags
              bucketUpdate={bucketUpdate}
              changeTxt={changeTxt}
              bucketOperation={bucketOperation}
              bucketNameText={bucketNameText}
              bucketUpdateId={bucketUpdateId}
              parent={parentId}
              setPopup={setPopup}
            />
          ) : null}
          {/* {movePopup === true ? <MoveTags bucketUpdate={bucketUpdate} changeTxt={changeTxt} bucketOperation={bucketOperation} bucketNameText={bucketNameText} bucketUpdateId={bucketUpdateId} parent= {parentId} setPopup = {setMovePopup} subtags = {subtags} /> : null} */}
          {isOpens === true ? (
            <DeleteSubtag
              bucketUpdate={bucketUpdate}
              changeTxt={changeTxt}
              bucketOperation={bucketOperation}
              bucketNameText={bucketNameText}
              bucketUpdateId={bucketUpdateId}
              parent={parentId}
              setPopup={setIsOpen}
              parentDelete={parentDelete}
            />
          ) : null}
          <ToastContainer />
        </div>
      )}
      <ScrollToTop smooth color="#fff" component={<MySVG />} />
    </>
  );
}
export default TagList;
